import React from "react";

import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import constants from "variables/constants";

import { formater } from "components/Utils/formater";
import { smoothApi } from "components/Utils/smoothApi";

import { Row } from "reactstrap";
import messages from "variables/messages";

class FichaResumida extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: -1,
      formData: {
        paymentDates: [],
        paymentIds: [],
        totalPayments: [],
        eventDate: new Date(),
      },

      timezone: "",
    };
  }

  render() {
    const {
      brideEmail,
      brideMobilePhone,
      brideName,
      eventStartTime,
      groomMobilePhone,
      groomName,
    } = this.state.formData;

    return (
      <div className="container-impressao">
        <div className="max-width">
          <div className="first-item">
            <span>
              <strong>Data do evento: </strong>
              {this.getEventDate()}
            </span>{" "}
            -{" "}
            <span>
              <strong>Horário do evento: </strong>{" "}
              {eventStartTime !== "" ? this.getHourEvent(eventStartTime) : ""}
            </span>
          </div>

          <div className="item">
            <span>
              <strong>Nome do noivo: </strong>
              {groomName !== "" ? groomName : ""}
            </span>{" "}
          </div>
          <div className="item">
            <span>
              <strong>Celular do noivo: </strong>
              {groomMobilePhone !== ""
                ? formater.template(groomMobilePhone, "(00) 00000-0000")
                : ""}
            </span>
          </div>
          <div className="item">
            <span>
              <strong>Nome da noiva: </strong>
              {brideName !== "" ? brideName : ""}
            </span>{" "}
          </div>

          <div className="item">
            <span>
              <strong>Celular da noiva: </strong>
              {brideMobilePhone !== ""
                ? formater.template(brideMobilePhone, "(00) 00000-0000")
                : ""}
            </span>
          </div>

          <div className="item">
            <span>
              <strong>Email da noiva: </strong>
              {brideEmail !== "" ? brideEmail : ""}
            </span>
          </div>

          <div
            className={`item itemPayment`}
            style={{
              width:
                this.calculateTextWidth("Recebido(s): ") +
                this.getPaymentIds().width,
            }}
          >
            <span>
              <strong>Recibo(s): </strong>
              {this.getPaymentIds().payment}
            </span>
          </div>

          <div
            className="item itemDate"
            style={{
              width:
                this.calculateTextWidth("Data(s): ") +
                this.getPaymentDates().width,
            }}
          >
            <span>
              <strong>Data(s): </strong>
              {this.getPaymentDates().date}
            </span>
          </div>

          <div>
            <Row lg="12" className="justify-content-center mt-6 button">
              <a
                className="btn button-print float-lg-right text-white mt-2"
                href="javascript:window.print()"
              >
                Imprimir ficha
              </a>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id });
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Event.GetSummarizedWeddingSheet +
      "/" +
      this.state.eventId;

    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ formData: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.setState({ timezone });
  }

  getPaymentDates() {
    let { formData, timezone } = this.state;

    let date = formData.paymentDates.map((date) => {
      return utcToZonedTime(date, timezone);
    });

    date = date.map((date) => {
      return format(date, "dd/MM/yyyy");
    });

    if (date.length > 3) {
      return {
        length: date.length,
        date: date.map((date) => date + ", "),
        width:
          this.calculateTextWidth(date[0]) +
          this.calculateTextWidth(date[1]) +
          this.calculateTextWidth(date[2]) +
          40,
      };
    }

    switch (date.length) {
      case 3:
        return {
          length: date.length,
          date: date[0] + ", " + date[1] + " e " + date[2],
          width:
            this.calculateTextWidth(date[0]) +
            this.calculateTextWidth(date[1]) +
            this.calculateTextWidth(date[2]) +
            40,
        };

      case 2:
        return {
          length: date.length,
          date: date[0] + " e " + date[1],
          width:
            this.calculateTextWidth(date[0]) +
            this.calculateTextWidth(date[1]) +
            40,
        };
      case 1:
        return {
          length: date.length,
          date: date[0],
          width: this.calculateTextWidth(date[0]) + 40,
        };

      default:
        return "";
    }
  }

  calculateTextWidth(text) {
    let span = document.createElement("span");
    span.innerText = text;

    document.body.appendChild(span);
    let width = span.offsetWidth;
    span.parentNode.removeChild(span);

    return width;
  }

  getPaymentIds() {
    let { formData } = this.state;

    let paymentIds = formData.paymentIds;

    if (paymentIds.length > 3) {
      return {
        length: paymentIds.length,
        payment: paymentIds.map((paymentId) => paymentId + ", "),
        width:
          this.calculateTextWidth(paymentIds[0]) +
          this.calculateTextWidth(paymentIds[1]) +
          this.calculateTextWidth(paymentIds[2]) +
          20,
      };
    }

    switch (paymentIds.length) {
      case 1:
        return {
          length: paymentIds.length,
          payment: paymentIds[0],
          width: this.calculateTextWidth(paymentIds[0]) + 20,
        };
      case 2:
        return {
          length: paymentIds.length,
          payment: paymentIds[0] + " e " + paymentIds[1],
          width:
            this.calculateTextWidth(paymentIds[0]) +
            this.calculateTextWidth(paymentIds[1]) +
            20,
        };
      case 3:
        return {
          length: paymentIds.length,
          payment: paymentIds[0] + ", " + paymentIds[1] + " e " + paymentIds[2],
          width:
            this.calculateTextWidth(paymentIds[0]) +
            this.calculateTextWidth(paymentIds[1]) +
            this.calculateTextWidth(paymentIds[2]) +
            20,
        };

      default:
        return "";
    }
  }

  getTotalPayments() {
    let { formData } = this.state;

    let totals = formData.totalPayments;

    totals = totals.map((payment) => {
      payment = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(payment);

      return payment;
    });

    if (totals.length > 3) {
      return {
        length: totals.length,
        totals: totals.map((item) => item + ", "),
        width:
          this.calculateTextWidth(totals[0]) +
          this.calculateTextWidth(totals[1]) +
          this.calculateTextWidth(totals[2]) +
          40,
      };
    }

    switch (totals.length) {
      case 3:
        return {
          length: totals.length,
          totals: totals[0] + ", " + totals[1] + " e " + totals[2],
          width:
            this.calculateTextWidth(totals[0]) +
            this.calculateTextWidth(totals[1]) +
            this.calculateTextWidth(totals[2]) +
            40,
        };
      case 2:
        return {
          length: totals.length,
          totals: totals[0] + " e " + totals[1],
          width:
            this.calculateTextWidth(totals[0]) +
            this.calculateTextWidth(totals[1]) +
            40,
        };
      case 1:
        return {
          length: totals.length,
          totals: totals[0],
          width: this.calculateTextWidth(totals[0]) + 40,
        };

      default:
        return "";
    }
  }

  getEventDate() {
    let { formData, timezone } = this.state;

    let eventDate = formData.eventDate;

    eventDate = utcToZonedTime(eventDate, timezone);
    eventDate = format(eventDate, "dd/MM/yyyy");

    if (eventDate) {
      return eventDate;
    } else {
      return "";
    }
  }

  getHourEvent(hour) {
    return hour ? hour.substr(0, 5) : "";
  }
}

export default FichaResumida;
