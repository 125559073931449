//#region Imports

import Modal from "react-modal";
import React from "react";
import Wizard from "components/Utils/Wizard";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import { objectToFormData } from "object-to-formdata";
import { formater } from "components/Utils/formater";

import { Link, Redirect } from "react-router-dom";

import { Container, Row, Col, Form } from "reactstrap";

//#endregion


class OutrasInfos extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      errorMessage: "",
      personId: 0,
      formData: {},
      wizardResponse: null,
      authenticationFailure: false,
      otherReasonRequired: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.loadWizard = this.loadWizard.bind(this);
    this.loadPerson = this.loadPerson.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "/admin/Pessoas/Pessoa/?id=" + this.state.personId,
          wizzardConfiguration.Status.Completed
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Familia/?id=" + this.state.personId,
          this.state.wizardResponse.hasFamily
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Grupo/?id=" + this.state.personId,
          this.state.wizardResponse.hasMinistry
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Atividade/?id=" + this.state.personId,
          this.state.wizardResponse.hasCourse
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Outras/?id=" + this.state.personId,
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Documento/?id=" + this.state.personId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Dizimo/?id=" + this.state.personId,
          this.state.wizardResponse.hasTithe
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.formData["name"]}
                  {!this.state.isDeceased || (
                    <small className="text-deceased"> - falecido(a)</small>
                  )}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Form>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">
                    Outras informações - Questionário Sínodo
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Por que frequenta a paróquia?
                  </label>
                  <select
                    name="reasonSelect"
                    id="reasonSelect"
                    ref="reasonSelect"
                    class={
                      "text-dark font-weight-900 form-control showFilterActive"
                    }
                    onChange={this.onInputChange}
                  >
                    <option value="">Selecione</option>
                    <option
                      value="0"
                      ref="0"
                      selected={this.state.formData.residesNear ? true : false}
                    >
                      Mora próximo
                    </option>
                    <option
                      value="1"
                      ref="1"
                      selected={
                        this.state.formData.comesByAffinity ? true : false
                      }
                    >
                      Vem por afinidade
                    </option>
                    <option
                      value="2"
                      ref="2"
                      selected={this.state.formData.marriedHere ? true : false}
                    >
                      Foi casado aqui
                    </option>
                    <option
                      value="3"
                      ref="3"
                      selected={
                        this.state.formData.baptizedOrSonsBaptizedHere
                          ? true
                          : false
                      }
                    >
                      Você ou seus filhos foram batizados aqui
                    </option>
                    <option
                      value="4"
                      ref="4"
                      selected={
                        this.state.formData.otherReazonToCome != null
                          ? true
                          : false
                      }
                    >
                      Outros motivos
                    </option>
                  </select>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Com que frequência vai à paróquia?
                  </label>
                  <select
                    name="frequencySelect"
                    id="frequencySelect"
                    ref="frequencySelect"
                    class={
                      "text-dark font-weight-900 form-control showFilterActive"
                    }
                    onChange={this.onInputChange}
                  >
                    <option value="">Selecione</option>
                    <option
                      value="0"
                      selected={
                        this.state.formData.comesOnceAweek ? true : false
                      }
                    >
                      Uma vez por semana
                    </option>
                    <option
                      value="1"
                      selected={
                        this.state.formData.comesMoreThanOnceAweek
                          ? true
                          : false
                      }
                    >
                      Mais de uma vez por semana
                    </option>
                    <option
                      value="2"
                      selected={
                        this.state.formData.comeOnSundays ? true : false
                      }
                    >
                      Aos Domingos
                    </option>
                    <option
                      value="3"
                      selected={
                        this.state.formData.comesOccasionally ? true : false
                      }
                    >
                      Ocasionalmente
                    </option>
                  </select>
                </div>
              </Col>
            </Row>
            {this.state.formData.otherReazonToCome ||
            this.state.otherReasonRequired ? (
              <>
                <Row>
                  <Col lg="12">
                    <div className="form-group">
                      <label className="text-dark font-weight-900">
                        Outros motivos
                      </label>
                      <input
                        type="name"
                        required={this.state.otherReasonRequired ? true : false}
                        className="form-control text-dark font-weight-900"
                        id="otherReazonToCome"
                        maxLength="150"
                        ref="otherReasonInput"
                        name="otherReazonToCome"
                        value={this.state.formData["otherReazonToCome"]}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <> </>
            )}
            <Row>
              <Col lg="12">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Comentários
                  </label>
                  <textarea
                    type="name"
                    rows="7"
                    className="form-control text-dark font-weight-900"
                    id="personalComments"
                    name="personalComments"
                    value={this.state.formData["personalComments"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Pessoas"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    Salvar e continuar
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {!this.state.authenticationFailure || <Redirect to={"/auth/Login"} />}
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ personId: id });
  }

  componentDidMount() {
    this.loadWizard();

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Outras.GetByPerson +
      "/?personId=" +
      this.state.personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        formData.id = ret.id;
        formData.personalComments = ret.personalComments;
        formData.residesNear = ret.residesNear;
        formData.comesByAffinity = ret.comesByAffinity;
        formData.marriedHere = ret.marriedHere;
        formData.baptizedOrSonsBaptizedHere = ret.baptizedOrSonsBaptizedHere;
        formData.otherReazonToCome = ret.otherReazonToCome;

        if (formData.otherReazonToCome)
          this.setState({ otherReasonRequired: true });
        else this.setState({ otherReasonRequired: false });

        formData.comesOnceAweek = ret.comesOnceAweek;
        formData.comesMoreThanOnceAweek = ret.comesMoreThanOnceAweek;
        formData.comeOnSundays = ret.comeOnSundays;
        formData.comesOccasionally = ret.comesOccasionally;

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadPerson();
  }

  //#endregion

  //#region Custom Events

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      this.state.personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let formData = this.state.formData;
        let ret = result.response;

        formData.name = ret.name;

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Wizard;
    let data = { personId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "otherReazonToCome":
          value = formater.maxLength(target.value, 150);
          break;

        case "personalComments":
          value = formater.maxLength(target.value, 500);
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    let formData = this.state.formData;

    //#region Reazon Select

    formData.residesNear = this.refs.reasonSelect.value === "0";
    formData.comesByAffinity = this.refs.reasonSelect.value === "1";
    formData.marriedHere = this.refs.reasonSelect.value === "2";
    formData.baptizedOrSonsBaptizedHere = this.refs.reasonSelect.value === "3";

    if (this.refs.reasonSelect.value === "4") {
      if (!this.refs.otherReasonInput || !this.refs.otherReasonInput.value) {
        this.setState({ otherReasonRequired: true });
        return;
      }
      this.setState({ otherReasonRequired: true });
      formData.otherReazonToCome = this.refs.otherReasonInput.value;
    } else {
      this.setState({ otherReasonRequired: false });
      formData.otherReazonToCome = null;
    }

    //#endregion

    //#region Frequency Select

    formData.comesOnceAweek = this.refs.frequencySelect.value === "0";
    formData.comesMoreThanOnceAweek = this.refs.frequencySelect.value === "1";
    formData.comeOnSundays = this.refs.frequencySelect.value === "2";
    formData.comesOccasionally = this.refs.frequencySelect.value === "3";

    //#endregion

  }

  handleSubmit() {
    let formData = this.state.formData;

    formData.personId = this.state.personId;
    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Outras.Put;
    let data = formData;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ errorMessage: messages.Errors.UnexpectedFormat });
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));

          return;
        }

        if (result.response == null) {
          this.setState({ errorMessage: messages.Errors.BlankResponse });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.loadWizard();

        window.location.href =
          "/admin/Pessoas/documento/?id=" + this.state.personId;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }
  //#endregion
}

export default OutrasInfos;
