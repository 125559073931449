//#region Imports

import React from "react";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import Dating from "components/Utils/Dating.jsx";

import {
    Container,
    Row,
    Col
} from "reactstrap";


//#endregion

class Test extends React.Component {

    //#region Constructor

    constructor(props) {

        super(props);

        //#region Initial States

        this.state = {

            calendarSelectedYear: (new Date().getFullYear()),
            calendarSelectedMonth: (new Date().getMonth() + 1),
            calendarSelectedDay: null,
            //calendarMarkedDays: [],
            loadMonthEventsResposne: null

        };

        //#endregion

        //#region Event Binding

        this.loadMonthEvents = this.loadMonthEvents.bind(this);
        this.dateClickHandler = this.dateClickHandler.bind(this);
        this.navigationClickHandler = this.navigationClickHandler.bind(this);

        //#endregion

    }

    //#endregion

    //#region Render

    render() {

        // let selectedDate = new Date("2020-04-23");
        // let dateCallBack = new function () { };
        // let naviagationCallBack = new function () { };

        // let targetMonth = selectedDate.getMonth();
        // let monthName = '';

        // switch (targetMonth) {

        //     case 0:
        //         monthName = 'Janeiro';
        //         break;
        //     case 1:
        //         monthName = 'Fevereiro';
        //         break;
        //     case 2:
        //         monthName = 'Março';
        //         break;
        //     case 3:
        //         monthName = 'Abril';
        //         break;
        //     case 4:
        //         monthName = 'Maio';
        //         break;
        //     case 5:
        //         monthName = 'Junho';
        //         break;
        //     case 6:
        //         monthName = 'Julho';
        //         break;
        //     case 7:
        //         monthName = 'Agosto';
        //         break;
        //     case 8:
        //         monthName = 'Setembro';
        //         break;
        //     case 9:
        //         monthName = 'Outubro';
        //         break;
        //     case 10:
        //         monthName = 'Novembro';
        //         break;
        //     case 11:
        //         monthName = 'Dezembro';
        //         break;

        // }

        // let firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        // let firstDayInWeek = firstDay.getDay();
        // let daysCount = 0;
        // let calendarDays = [];

        // for (let x = 0; x < 6; x++) {

        //     for (let y = 0; y < 7; y++) {

        //         let loopDate = null;

        //         if (x === 0) {

        //             if (firstDayInWeek > y) {

        //                 let diff = y - firstDayInWeek;
        //                 loopDate = dateHelper.addDays(firstDay, diff);

        //             } else {

        //                 loopDate = dateHelper.addDays(firstDay, daysCount);
        //                 daysCount++;

        //             }

        //         } else {

        //             loopDate = dateHelper.addDays(firstDay, daysCount);
        //             daysCount++;

        //         }

        //         let sameMonth = (loopDate.getMonth() === firstDay.getMonth());

        //         calendarDays.push(
        //             <div className={"d_baseBox d_date " + (sameMonth ? "" : "d_fadded")} key={"dateBlock_" + x + "_" + y} onClick={() => {

        //                 dateCallBack(loopDate.getDate(), loopDate, sameMonth);

        //             }} >{loopDate.getDate()}</div>
        //         );

        //         if (loopDate.getDay() === 6) {

        //             calendarDays.push(
        //                 <div className="d_clear" key={"lineBreak_" + x + "_" + y}></div>
        //             );

        //         }

        //     }

        //}

        //#endregion

        let calendarMarkedDays = [];

        if (this.state.loadMonthEventsResposne) {

            if (this.state.loadMonthEventsResposne.length) {

                calendarMarkedDays = this.state.loadMonthEventsResposne.map((el) => {

                    let tempData = new Date(el.eventDate);

                    if (isNaN(tempData.getTime()))
                        return 0;

                    return tempData.getDate();

                })

            }

        }

        console.log('%c 🔧 state 🔧  {   ', 'background: linear-gradient(#0040ff, #00b0ff);color: white;text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 5px 3px -5px rgba(0, 0, 0, 0.5), 0 -13px 5px -10px rgba(255, 255, 255, 0.4) inset;line-height: 40px;');
        console.log(this.state);
        console.log('%c } ', 'background: linear-gradient(#00b0ff, #0040ff);color: white;text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 5px 3px -5px rgba(0, 0, 0, 0.5), 0 -13px 5px -10px rgba(255, 255, 255, 0.4) inset;line-height: 40px;');



        return (
            <>
                <Container fluid className="minHeightFooter">

                    <Row className="pt-4 pl-5">

                        <Col lg="12">

                            <div>

                                <h1 className="text-dark">Workbench</h1>
                                <img className="rectangle-orange" alt="..." src={require("assets/img/brand/home/Rectangle.png")} />

                            </div>

                            <div>

                                <Dating
                                    navigationClickHandler={this.navigationClickHandler}
                                    dateClickHandler={this.dateClickHandler}
                                    markedDays={calendarMarkedDays}
                                    day={this.state.calendarSelectedDay}
                                    month={this.state.calendarSelectedMonth}
                                    year={this.state.calendarSelectedYear}
                                />

                            </div>

                        </Col>

                    </Row>

                </Container>
            </>
        );

    }

    componentDidMount() {

        this.loadMonthEvents(this.state.calendarSelectedMonth, this.state.calendarSelectedYear);

    }

    dateClickHandler(day, date, sameMonth) {

        alert('Dia ' + day + ' clicado, ele ' + (sameMonth ? '' : 'não ') + 'pertence ao mês seleionado');

        if (sameMonth)
            this.setState({ calendarSelectedDay: day });

    }

    navigationClickHandler(left) {

        let targetMonth = this.state.calendarSelectedMonth;
        let targetYear = this.state.calendarSelectedYear;

        if (left)
            targetMonth--;
        else
            targetMonth++;

        if (targetMonth > 12) {

            targetMonth = 1;
            targetYear++;

        }

        if (targetMonth < 1) {

            targetMonth = 12;
            targetYear--;

        }

        this.setState({ calendarSelectedYear: targetYear, calendarSelectedMonth: targetMonth, calendarSelectedDay: null });
        this.loadMonthEvents(targetMonth, targetYear);

    }

    loadMonthEvents(month, year) {

        this.setState({ loadMonthEventsResposne: null });

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.CalendarSearch;
        let data = { year: year, month: month };

        smoothApi.post(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {

                    console.error(messages.Errors.UnexpectedFormat, result);
                    return;

                }

                if (!result.success) {

                    console.error(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {

                    console.error(messages.Errors.BlankResponse, result.errors);
                    return;

                }

                this.setState({ loadMonthEventsResposne: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

            }

        );

    }

    //#region Events

    //#endregion

    //#region Component Events

    //#endregion

    //#region Custom Components

    //#endregion

}

export default Test;
