//#region Imports

import React, { Component } from 'react';

// reactstrap components
import {
    Container
} from "reactstrap";

//#endregion

class ErrorAuth extends Component {
    render() {
        return (
            <Container fluid className="minHeightFooter">
                <div className="mt-8">
                    <div className="text-center pt-6">
                        <h1><i class="far fa-frown"></i> 404</h1>
                        <h1>  Oops! Pagina não encontrada!</h1>
                    </div>
                </div>
            </Container>
        );
    }
}

export default ErrorAuth;