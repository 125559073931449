import React from "react";

import Modal from "react-modal";
import Form from "reactstrap/lib/Form";
import { Link } from "react-router-dom";
import TemplateXLSX from "assets/Modelo-de-aquivo-adicao-em-massa.xlsx"

import messages from "variables/messages";
import constants from "variables/constants";

import Loading from "components/Utils/Loading";
import { diruma } from "components/Utils/diruma";
import DataList from "components/Utils/dataList.jsx";
import { smoothApi } from "components/Utils/smoothApi";

import { Container, Row, Col } from "reactstrap";
import { formatNumberOfContact } from "components/Utils/formarNumberOfContact";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class Cursos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        id: null,
        eventTypeIds: [],
        hasRegistration: false,
        hasSitePublishing: false,
        IsSiteBannerDeleted: false,
        IsRelatedDocumentDeleted: false,
        vacancyNumber: "",
        registrationStartDate: "",
        registrationEndDate: "",
        siteText: "",

        siteBannerUrl: "",
      },

      isLoading: false,

      noSubscribers: false,
      subscribersInTheCourse: [],

      limitTextQuestion: false,

      currentPage: 0,
      eventLoading: false,
      exporting: false,

      uploadedBanner: false,
      uploadedDocument: false,

      listFile: [],
      renderListFile: true,

      searchMode: false,

      courseList: [],
      courseId: -1,
      totalEnrolled: 0,
      file: null,
      fileName: "",
      preview: "",
      entryId: 0,
      enrolledId: 0,

      modalIsOpen: false,
      deleteModalIsOpen: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.openModal = this.openModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.enrollmentQueryByCourse = this.enrollmentQueryByCourse.bind(this);
    this.timer = null;
    this._allowedMineTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];
  }

  openModal(id) {
    this.setState({ enrolledId: id, deleteModalIsOpen: true });
  }

  render() {

    let tableCollumnsEnrolled = [];
    let tableDataEnrolled = [];

    let tableCollumns = [];
    let tableData = [];
    let courseSelectItems = [];

    if (this.state.courseList.length) {
      try {
        courseSelectItems = this.state.courseList.map((item, index) => {
          return (
            <option
              selected={
                this.state.formData.courseTypeId === item.id ? true : false
              }
              value={item.id}
              key={"situation" + item.id}
            >
              {item.name}
            </option>
          );
        });
      } catch (ex) {
        courseSelectItems = [];
        console.error("Falha ao interpretar resposta", ex);
      }
    }

    if (this.state.listFile && this.state.listFile.length) {
      tableCollumns = [
        { name: "Nome do arquivo", size: 20, property: "name" },
        { name: "Tipo", size: 20, property: "typeDocument" },
        { name: "", size: 20, property: "excluirButton" },
      ];

      tableData = this.state.listFile;

      tableData = tableData.map((item, index) => {
        return {
          name: item.name,
          typeDocument: item.typeDocument,
          excluirButton: (
            <button
              disabled={this.state.onlyView ? true : false}
              type="button"
              className="btn btn-sm exc"
              key={index}
              onClick={() => {
                this.setState({ entryId: index, modalIsOpen: true });
              }}
            >
              Excluir
            </button>
          ),
        };
      });
    }

    const { formData, uploadedBanner } = this.state;

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "name",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        // {
        //   ref: "siteText",
        //   validations: [],
        //   customValidations: [
        //     function hasSitePublishingRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(
        //           formData.hasSitePublishing === true &&
        //           formData.siteText === ""
        //         )
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se as informações devem ser publicadas no site, o Texto para o site é obrigatório",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },

        //     function hasSitePublishingRequiredTwo(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(
        //           formData.hasSitePublishing === true &&
        //           uploadedBanner === false
        //         )
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se as informações devem ser publicadas no site, o Banner para o site é obrigatório",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },

        //     function hasSitePublishingNotRequiredTwo(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(
        //           formData.hasSitePublishing === false &&
        //           uploadedBanner === true
        //         )
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se as informações não devem ser publicadas no site, o Banner para o site não deve ser preenchido",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },

        //     function hasSitePublishingNotRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(
        //           formData.hasSitePublishing === false &&
        //           formData.siteText !== ""
        //         )
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se as informações não devem ser publicadas no site, o Texto para o site não deve ser preenchido",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },
        //   ],
        // },
        // {
        //   ref: "vacancyNumber",
        //   validations: [],
        //   customValidations: [
        //     function vacancyNumberRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(formData.hasRegistration) &&
        //         formData.vacancyNumber === ""
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message: "Se é necessário inscrições, Vagas é obrigatório",
        //         };
        //         return validation;
        //       } else if (
        //         !!parseInt(formData.hasRegistration) == false &&
        //         formData.vacancyNumber != ""
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se não é necessário inscrições, Vagas não deve ser preenchido",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },
        //   ],
        // },
        // {
        //   ref: "registrationStartDate",
        //   validations: [],
        //   customValidations: [
        //     function registrationStartDateRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         formData.registrationStartDate > formData.registrationEndDate
        //       ) {
        //         validation = {
        //           validation: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Data de início das inscrições deve ser menor do que a Data de fim das inscrições",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },
        //     function registrationStartDateNotRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(formData.hasRegistration) === false &&
        //         formData.registrationStartDate !== ""
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se não é necessário inscrições, Data de início das inscrições não deve ser preenchido",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },

        //     function hasRegistrationRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(formData.hasRegistration) === true &&
        //         formData.registrationStartDate === ""
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se é necessário inscrições, Data de início das inscrições é obrigatório",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },

        //     function registrationStartDateBiggerStartDate(
        //       currentItemValue,
        //       fals
        //     ) {
        //       let validation = {};

        //       if (formData.registrationStartDate > formData.startDate) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Data de início das inscrições deve ser menor do que a Data de início do curso",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },
        //   ],
        // },
        // {
        //   ref: "registrationEndDate",
        //   validations: [],
        //   customValidations: [
        //     function registrationEndDateRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(formData.hasRegistration) === true &&
        //         formData.registrationEndDate === ""
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se é necessário inscrições, Data de fim das inscrições é obrigatório",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },

        //     function registrationEndDateNotRequired(currentItemValue, fals) {
        //       let validation = {};

        //       if (
        //         !!parseInt(formData.hasRegistration) === false &&
        //         formData.registrationEndDate !== ""
        //       ) {
        //         validation = {
        //           value: currentItemValue,
        //           sucess: false,
        //           message:
        //             "Se não é necessário inscrições, Data de fim das inscrições não deve ser preenchido",
        //         };
        //         return validation;
        //       }
        //       return (validation = {
        //         value: currentItemValue,
        //         sucess: true,
        //         message: "",
        //       });
        //     },
        //   ],
        // },
        {
          ref: "courseTypeId",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "startDate",
          validations: [diruma.validations.required],
          customValidations: [
            function Date(currentItemValue, fals) {
              let validation = {};

              if (formData.endDate <= formData.startDate) {
                validation = {
                  value: currentItemValue,
                  sucess: false,
                  message:
                    "Data de início do curso deve ser menor ou igual a Data de fim do curso",
                };

                return validation;
              }

              return (validation = {
                value: currentItemValue,
                sucess: true,
                message: "",
              });
            },
          ],
        },
        {
          ref: "endDate",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    if (this.state.subscribersInTheCourse.length) {

      tableCollumnsEnrolled = [
        { name: "Nome", size: 20, property: "name" },
        { name: "Telefone", size: 20, property: "phone" },
        { name: "", size: 10, property: "deleteButton" }
      ];

      tableDataEnrolled = this.state.subscribersInTheCourse;


      tableDataEnrolled = tableDataEnrolled.map((item) => {
        return {
          ...{
            ...item,
            name: item.personName,
            phone: formatNumberOfContact(item.personMobilePhone),
            deleteButton: (
              <button
                key={item.courseRegistrationId}
                onClick={() => this.openModal(item.courseRegistrationId)}
                className="btn btn-sm exc"
              >Deletar</button>
            ),
          },
        };
      });
    }

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => {
            this.setState({ modalIsOpen: false });
          }}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.setState({ modalIsOpen: false });
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            Tem certeza de que deseja <br /> excluir esta entrada?
          </h2>
          <div className="text-center">
            <button
              id="alertExclusion"
              className="alertExclusion btn btn-group button-yes mr-4"
              key={this.state.entryId}
              onClick={(e) => this.deleteFile(this.state.entryId)}
            >
              Sim
            </button>
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.setState({ modalIsOpen: false });
              }}
            >
              Não
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.deleteModalIsOpen}
          onRequestClose={() => {
            this.setState({ deleteModalIsOpen: false });
          }}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.setState({ deleteModalIsOpen: false });
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            Tem certeza de que deseja <br /> excluir esta entrada?
          </h2>
          <div className="text-center">
            <button
              id="alertExclusion"
              className="alertExclusion btn btn-group button-yes mr-4"
              onClick={(e) => this.deleteEnrolled(this.state.enrolledId)}
              key={this.state.enrolledId}
            >
              Sim
            </button>
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.setState({ deleteModalIsOpen: false });
              }}
            >
              Não
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);

          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              OK
            </button>
          </div>
        </Modal>
        <Container fluid className="minHeightFooter">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark">Cadastro de cursos</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>

          <Form>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="10">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Nome do curso
                  </label>
                  <input
                    ref="name"
                    type="text"
                    id="name"
                    name="name"
                    value={
                      this.state.formData["name"]
                        ? this.state.formData["name"]
                        : ""
                    }
                    class={
                      "form-control showFilterActive text-dark " +
                      (this.state.formData["name"] ? "filterActive" : "")
                    }
                    onChange={(event) => {
                      let value = "";
                      value = event.target.value;
                      let valid = true;

                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        if (value.length > 500) valid = false;
                      }

                      if (valid) {
                        this.onInputChange(event);
                      }
                    }}
                  />
                </div>
              </Col>
              {/* <Col lg="2">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Quantidade de vagas
                  </label>
                  <input
                    type="text"
                    ref="vacancyNumber"
                    id="vacancyNumber"
                    name="vacancyNumber"
                    value={
                      this.state.formData["vacancyNumber"]
                        ? this.state.formData["vacancyNumber"]
                        : ""
                    }
                    class={
                      "form-control showFilterActive text-dark " +
                      (this.state.formData["vacancyNumber"]
                        ? "filterActive"
                        : "")
                    }
                    onChange={(event) => {
                      let value = "";
                      value = event.target.value;
                      let valid = true;

                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        if (value.length > 500) valid = false;
                      }

                      if (valid) {
                        event.target.value = formater.number(
                          event.target.value
                        );
                        this.onInputChange(event);
                      }
                    }}
                  />
                </div>
              </Col> */}
            </Row>
            <Row className="pl-5 pr-5">
              {/* <Col lg="3">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Data de início das inscrições
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["registrationStartDate "]
                        ? "filterActive"
                        : "")
                    }
                    type="date"
                    ref="registrationStartDate"
                    id="registrationStartDate"
                    value={
                      this.state.formData["registrationStartDate"]
                        ? this.state.formData["registrationStartDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col> */}
              {/* <Col lg="3">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Data de fim das inscrições
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["registrationEndDate"]
                        ? "filterActive"
                        : "")
                    }
                    type="date"
                    id="registrationEndDate"
                    ref="registrationEndDate"
                    value={
                      this.state.formData["registrationEndDate"]
                        ? this.state.formData["registrationEndDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col> */}
              <Col lg="6">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Tipos de curso
                  </label>

                  <select
                    name="courseTypeId"
                    id="courseTypeId"
                    ref="courseTypeId"
                    className={
                      "text-dark font-weight-900 form-control showFilterActive" +
                      (courseSelectItems.length === 0 ? " loadingSelect" : "")
                    }
                    disabled={courseSelectItems.length === 0 ? true : false}
                    onChange={this.onInputChange}
                  >
                    <option
                      value=""
                      defaultValue={
                        this.state.formData.courseTypeId ? false : true
                      }
                    >
                      Selecione
                    </option>
                    {courseSelectItems.length === 0 || courseSelectItems}
                  </select>
                  {/* <input
                    ref="courseTypeId"
                    type="text"
                    id="courseTypeId"
                    name="courseTypeId"
                    value={
                      this.state.formData["courseTypeId"]
                        ? this.state.formData["courseTypeId"]
                        : ""
                    }
                    class={
                      "form-control showFilterActive text-dark " +
                      (this.state.formData["courseTypeId"]
                        ? "filterActive"
                        : "")
                    }
                    onChange={(event) => {
                      let value = "";
                      value = event.target.value;
                      let valid = true;

                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        valid = true;
                      }

                      if (valid) {
                        this.onInputChange(event);
                      }
                    }}
                  /> */}
                </div>
              </Col>
            </Row>
            <Row className="pl-5 pr-5">
              <Col lg="3">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Data de início do curso
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["startDate"] ? "filterActive" : "")
                    }
                    ref="startDate"
                    type="date"
                    id="startDate"
                    value={
                      this.state.formData["startDate"]
                        ? this.state.formData["startDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="3">
                <div className="pt-4 pb-4">
                  <label className="text-dark font-weight-900">
                    Data de fim do curso
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["endDate"] ? "filterActive" : "")
                    }
                    ref="endDate"
                    type="date"
                    id="endDate"
                    value={
                      this.state.formData["endDate"]
                        ? this.state.formData["endDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row className="pl-5 pr-5">
              <Col lg="7">
                <label className="text-dark font-weight-900">
                  Publicar informações no site?{" "}
                </label>
                <ButtonGroupNsb
                  id="hasSitePublishing"
                  disabled={this.state.onlyView ? true : false}
                  option1="Não"
                  option2="Sim"
                  key="hasSitePublishing"
                  activeIndex={this.state.formData["hasSitePublishing"]}
                  dflex="Sim"
                  changeHandler={this.onInputChange}
                />
              </Col>
              <Col lg="5">
                <label className="text-dark font-weight-900">
                  Necessário inscrições?{" "}
                </label>
                <ButtonGroupNsb
                  id="hasRegistration"
                  disabled={this.state.onlyView ? true : false}
                  option1="Não"
                  option2="Sim"
                  key="hasRegistration"
                  activeIndex={this.state.formData["hasRegistration"]}
                  dflex="Sim"
                  changeHandler={this.onInputChange}
                />
              </Col>
            </Row> */}
            {/* <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Texto da pergunta dissertativa
                  </label>
                  <textarea
                    type="name"
                    rows="7"
                    className="form-control text-dark font-weight-900"
                    id="essayQuestion"
                    name="essayQuestion"
                    value={this.state.formData["essayQuestion"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row> */}
            {/* <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Texto informativo para site
                  </label>
                  <CKEditor
                    ref="siteText"
                    data={formData.siteText}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      if (data.replace(/<[^>]*>+/g, "").length > 1000) {
                        this.setState({
                          limitTextQuestion: true,
                        });
                        const div =
                          document.getElementsByClassName("ck-editor__main")[0];

                        if (div) {
                          div.classList.add("validationError");
                        }
                        return;
                      } else {
                        const div =
                          document.getElementsByClassName("ck-editor__main")[0];
                        if (div) {
                          div.classList.remove("validationError");
                        }
                        this.setState({
                          limitTextQuestion: false,
                        });
                      }
                      const value = data.replace(/<[^>]*>+/g, "");

                      if (value) this.debounce(value, 500);
                    }}
                  />
                </div>
                {this.state.limitTextQuestion && (
                  <span className="validationError font-weight-900">
                    Você excedeu o limite de 1000 caracteres
                  </span>
                )}
              </Col>
            </Row> */}
            <Row className="pt-4 pl-5 pr-5 pb-5">
              {/* <Col lg="6">
                <div
                  className="mb-5 d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <label className="text-dark font-weight-900">
                    Banner para o site
                  </label>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="banner"
                    name="banner"
                    type="file"
                    ref="banner"
                    disabled={this.state.uploadedBanner}
                    onChange={this.handleChangeFile}
                  />
                  <label htmlFor="banner">
                    <span className="btn button-add">Upload Banner</span>
                  </label>
                </div>
              </Col>
              <Col lg="6">
                <div
                  className="mb-5 d-flex"
                  style={{ flexDirection: "column", alignItems: "flex-end" }}
                >
                  <label className="text-dark font-weight-900">
                    Documento relacionado{" "}
                  </label>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="document"
                    name="document"
                    type="file"
                    onChange={this.handleChangeFile}
                  />
                  <label htmlFor="document">
                    <span className="btn button-add">Upload Documento</span>
                  </label>
                </div>
              </Col> */}
              <Col lg="6">
                <div
                  className="mb-5 d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <label className="text-dark font-weight-900">
                    Download da planilha modelo
                  </label>
                  <a
                    href={TemplateXLSX}
                    download="Template_upload_em_massa"
                    target="_blank"
                    rel="noopener noreferrer">
                    <button
                      type="button"
                      className="btn btn-outline-light btn-excel m-auto"
                    >
                      Download do Modelo
                    </button>
                  </a>

                </div>
              </Col>
            </Row>
            <Row className="pt-4 pl-5 pr-5 pb-5">
              <Col lg="12">
                {this.state.renderListFile ? (
                  <DataList
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                  />
                ) : (
                  <>
                    {!this.state.addPaymentLoading || <h1> Carregando...</h1>}
                  </>
                )}
              </Col>
            </Row>
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Cursos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.isLoading ? <Loading load="Sim" /> : "Salvar"}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="d pt-4 pl-5">
            <Col col="12">
              {this.state.renderCourseList ? (
                <DataList
                  loading={this.state.eventLoading}
                  tableCollumns={tableCollumnsEnrolled}
                  tableData={tableDataEnrolled}
                  paging={true}
                  currentPage={this.state.currentPage}
                  pageSize={constants.Pagination.DefaultPageSize}
                  total={this.state.totalEnrolled}
                  pageChangeHandler={(index) => {
                    this.enrollmentQueryByCourse(index);
                  }}
                  exportHandler={exportHandler}
                  exporting={true}
                  exportProcessing={this.state.exporting}
                />
              ) : (
                <>
                  {this.state.noSubscribers === false || (
                    <span className=" pt-8 pb-8 d-flex justify-content-center">
                      Ainda não há inscritos neste curso
                    </span>
                  ) || <h1>Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({
        courseId: id,
      });
    }
  }

  componentDidMount() {
    this.getCourseType();
    this.getUpdateCurse();
    this.enrollmentQueryByCourse(0);
  }

  getUpdateCurse() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase + constants.Endpoint.Course.Get + "/" + id;

      let data = null;

      smoothApi.get(
        url,
        data,
        token,
        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }
          let data = result.response;

          if (data.endDate) {
            data.endDate = data.endDate.substring(0, 10);
          }

          console.log("data", data);

          if (data.startDate) {
            data.startDate = data.startDate.substring(0, 10);
          }

          if (data.registrationEndDate) {
            data.registrationEndDate = data.registrationEndDate.substring(
              0,
              10
            );
          }

          if (data.registrationStartDate) {
            data.registrationStartDate = data.registrationStartDate.substring(
              0,
              10
            );
          }

          data.IsSiteBannerDeleted = false;

          data.hasSitePublishing = data.hasSitePublishing === true ? "1" : "0";
          data.hasRegistration = data.hasRegistration === true ? "1" : "0";

          data.IsRelatedDocumentDeleted = false;

          data.essayQuestion =
            data.essayQuestion === "undefined" ? "" : data.essayQuestion;

          for (let prop in data) {
            if (data[prop] === null) {
              data[prop] = "";
            }
          }

          console.log("hre", data);
          this.setState({
            formData: data,
          });
        },
        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({ exporting: false });
        }
      );
    }
  }

  getCourseType() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.CourseType.getCourseType;
    const data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.errors || !result.response) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let courseList = result.response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            isValid: item.isValid,
          };
        });

        this.setState({
          courseList,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  deleteEnrolled(enrolledId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Course.DeleteCourseRegistration +
      "/" + enrolledId;
    const data = null;

    smoothApi.delete(
      url,
      data,
      token,
      (result) => {

        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        if (result.success) {
          this.setState({
            deleteModalIsOpen: false,
          });
          this.enrollmentQueryByCourse(0)
        }


      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
  /**
    * 
    * @param {Number} currentPage 
    */
  enrollmentQueryByCourse(currentPage) {
    this.setState({ currentPage: currentPage });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Course.courseRegistration + '/' + this.state.courseId;
    let data =
      "CurrentPage=" +
      currentPage +
      "&PageSize=" +
      constants.Pagination.DefaultPageSize;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        if (!data.length) {
          this.setState({
            noSubscribers: true,
            subscribersInTheCourse: [],
            renderCourseList: false,
          });
          return;
        }

        this.setState({
          noSubscribers: false,
          renderCourseList: true,
          totalEnrolled: result.response.total,
          subscribersInTheCourse: result.response.list,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }

  // debounce(value, wait) {
  //   clearTimeout(this.timer);

  //   this.timer = setTimeout(() => {
  //     this.setState({
  //       formData: Object.assign(this.state.formData, {
  //         siteText: value,
  //       }),
  //     });
  //   }, wait);
  // }

  handleChangeFile(event) {
    let [files] = event.target.files;

    if (!this._allowedMineTypes.includes(files.type)) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro durante o registro, por favor verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li>
                    Formato de arquivo inválido. Os formatos permitidos são JPG,
                    PNG, PDF, GIF, tamanho máximo por arquivo 10MB.
                  </li>
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    const preview = URL.createObjectURL(files);

    if (event.target.name === "banner" && this.state.uploadedBanner === false) {
      files.typeDocument = "banner";

      this.setState({
        uploadedBanner: true,
        formData: {
          ...this.state.formData,
          ...{ IsSiteBannerDeleted: false },
        },
      });
    } else if (event.target.name === "document") {
      files.typeDocument = "documento";

      this.setState({
        uploadedDocument: true,
        formData: {
          ...this.state.formData,
          ...{ isRelatedDocumentDeleted: false },
        },
      });
    }

    this.setState({
      preview,
      listFile: [...this.state.listFile, files],
    });
  }

  handleSubmit() {
    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    const { listFile } = this.state;

    const siteBanner = listFile.filter(
      (item) => item.typeDocument === "banner"
    );

    const relatedDocument = listFile.filter(
      (item) => item.typeDocument === "document"
    );

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.CourseType.Post;

    const { formData } = this.state;

    const id = this.state.courseId !== -1 ? this.state.courseId : "";

    const dataForm = new FormData();
    dataForm.append("id", id);
    dataForm.append("name", formData.name);
    dataForm.append("courseTypeId", formData.courseTypeId);
    // dataForm.append("hasRegistration", undefined);
    // dataForm.append("vacancyNumber", undefined);
    // dataForm.append("registrationStartDate", undefined);
    // dataForm.append("registrationEndDate", undefined);
    dataForm.append("startDate", formData.startDate);
    dataForm.append("endDate", formData.endDate);
    // dataForm.append("essayQuestion", undefined);
    // dataForm.append(
    //   "hasSitePublishing",
    //   undefined
    // );
    // dataForm.append("siteText", undefined);
    // dataForm.append("siteBanner", undefined);
    // dataForm.append("IsSiteBannerDeleted", undefined);
    // dataForm.append("relatedDocument", undefined);
    // dataForm.append(
    //   "isRelatedDocumentDeleted",
    //   undefined
    // );

    const data = dataForm;

    this.setState({ isLoading: true });

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ isLoading: false });
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            isLoading: false,
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
          }));

          return;
        }

        if (result.response == null) {
          this.setState({ isLoading: false });
          console.error(messages.Errors.BlankResponse, result);
          return;
        }

        if (id) {
          this.setState((state) => ({
            message: {
              isLoading: false,
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: <>Curso editado com sucesso!</>,
                _defaultCloseAction: () => {
                  window.history.back();
                },
              },
            },
            submitLoading: false,
          }));
        } else {
          this.setState((state) => ({
            message: {
              isLoading: false,
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: <>Curso cadastrado com sucesso!</>,
                _defaultCloseAction: () => {
                  window.history.back();
                },
              },
            },
            submitLoading: false,
          }));
        }

        return;
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  deleteFile(index) {
    let { listFile } = this.state;

    const [deletedFile] = listFile.splice(index, 1);

    if (deletedFile.typeDocument)

      if (deletedFile.typeDocument === "banner") {
        this.setState({
          uploadedBanner: false,
          formData: {
            ...this.state.formData,
            ...{ isSiteBannerDeleted: true },
          },
        });
      } else if (deletedFile.typeDocument === "document") {
        this.setState({
          uploadedDocument: false,
          formData: {
            ...this.state.formData,
            ...{ isRelatedDocumentDeleted: true },
          },
        });
      }

    this.setState({
      listFile,
      modalIsOpen: false,
    });
  }

  onInputChange(event) {
    const target = event.target;
    let name = "";
    let value = "";
    let isDropdown = false;

    if (!target.name) {
      if (target.id.indexOf("=") !== -1) name = target.id.split("=")[1];
      else name = target.id;
    } else name = target.name;

    if (target.type === "checkbox") {
      isDropdown = true;

      if (this.state.formData[name].length > 0) {
        if (this.state.formData[name].indexOf(target.id.split("=")[1]) === -1) {
          value = target.id;
        } else value = "";
      } else value = target.id;
    } else {
      value = "";
      value = target.value;
    }

    if (isDropdown === false) {
      this.setState({
        formData: Object.assign(this.state.formData, { [name]: value }),
      });
    }

    value = value.split("=")[1];

    if (isDropdown) {
      if (value) {
        this.state.formData[name].push(value);
      } else {
        this.state.formData[name].splice(
          this.state.formData[name].indexOf(target.id.split("=")[1]),
          1
        );
      }
    }

    // if (this.state.formData.eventTypeIds.length > 0)
    //   this.setState({ isChecked: true });
    // else this.setState({ isChecked: false });
  }

  export() {
    this.setState({ exporting: true });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Course.ExportByCourse +
      "/" +
      this.state.courseId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          constants.Endpoint.UrlBase +
          constants.Endpoint.Course.DownloadByCourse +
          "?fileKey=" +
          result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default Cursos;
