//#region Imports

import Modal from "react-modal";
import React from "react";
import Wizard from "components/Utils/Wizard";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import ButtonGroupNsb from "components/Utils/buttonGroupNsb";
import { formater } from "components/Utils/formater";
import Loading from "components/Utils/Loading.jsx";

import { Link, Redirect } from "react-router-dom";

import { Container, Row, Col, Form } from "reactstrap";

//#endregion


class Dizimo extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      errorMessage: "",
      personId: 0,
      formData: {},
      wizardResponse: null,
      authenticationFailure: false,
      otherReasonRequired: false,
      recurrenceResponse: null,
      tithePaymentMethodResponse: null,
      isValidDay: false,
      submitLoading: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.loadWizard = this.loadWizard.bind(this);
    this.loadPerson = this.loadPerson.bind(this);
    this.loadSelects = this.loadSelects.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    console.log("recurrenceResponse", this.state.recurrenceResponse);
    let recurrenceSelectItems = [];
    let tithePaymentMethodSelectItems = [];

    if (this.state.recurrenceResponse) {
      if (this.state.recurrenceResponse.length > 0) {
        try {
          recurrenceSelectItems = this.state.recurrenceResponse.map((item) => {
            if (this.state.formData["contributionRecurrency"]) {
              if (this.state.formData["contributionRecurrency"] === item.id)
                return (
                  <option value={item.id} key={"recurrence" + item.id} selected>
                    {item.recurrence1}
                  </option>
                );
              else
                return (
                  <option value={item.id} key={"recurrence" + item.id}>
                    {item.recurrence1}
                  </option>
                );
            } else {
              return (
                <option value={item.id} key={"recurrence" + item.id}>
                  {item.recurrence1}
                </option>
              );
            }
          });
        } catch (ex) {
          recurrenceSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.tithePaymentMethodResponse) {
      if (this.state.tithePaymentMethodResponse.length > 0) {
        try {
          tithePaymentMethodSelectItems =
            this.state.tithePaymentMethodResponse.map((item) => {
              if (this.state.formData["contributionPaymentMethodId"]) {
                if (
                  this.state.formData["contributionPaymentMethodId"] === item.id
                )
                  return (
                    <option
                      value={item.id}
                      key={"tithePaymentMethod" + item.id}
                      selected
                    >
                      {item.paymentMethod}
                    </option>
                  );
                else
                  return (
                    <option
                      value={item.id}
                      key={"tithePaymentMethod" + item.id}
                    >
                      {item.paymentMethod}
                    </option>
                  );
              } else {
                return (
                  <option value={item.id} key={"tithePaymentMethod" + item.id}>
                    {item.paymentMethod}
                  </option>
                );
              }
            });
        } catch (ex) {
          tithePaymentMethodSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "/admin/Pessoas/Pessoa/?id=" + this.state.personId,
          wizzardConfiguration.Status.Completed
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Familia/?id=" + this.state.personId,
          this.state.wizardResponse.hasFamily
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Grupo/?id=" + this.state.personId,
          this.state.wizardResponse.hasMinistry
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Atividade/?id=" + this.state.personId,
          this.state.wizardResponse.hasCourse
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Outras/?id=" + this.state.personId,
          this.state.wizardResponse.hasPersonAdditionalInfo
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Documento/?id=" + this.state.personId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Dizimo/?id=" + this.state.personId,
          wizzardConfiguration.Status.Active
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.formData["name"]}
                  {!this.state.isDeceased || (
                    <small className="text-deceased"> - falecido(a)</small>
                  )}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Form>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">Dízimo</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <label className="text-dark font-weight-900">
                  É dizimista?
                </label>
                <ButtonGroupNsb
                  id="isTithe"
                  option1="Não"
                  option2="Sim"
                  key="isTithe"
                  dflex="Sim"
                  activeIndex={this.state.formData["isTithe"]}
                  changeHandler={this.onInputChange}
                />
              </Col>
              {this.state.formData.isTithe === "1" ? (
                <>
                  <Col lg="3">
                    <div className="form-group">
                      <label className="text-dark font-weight-900">
                        Recorrência da contribuição
                      </label>
                      <select
                        name="contributionRecurrency"
                        id="contributionRecurrency"
                        ref="contributionRecurrency"
                        className="text-dark font-weight-900 form-control"
                        onChange={this.onInputChange}
                      >
                        <option value="">Selecione</option>
                        {recurrenceSelectItems.length === 0 ||
                          recurrenceSelectItems}
                      </select>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="form-group">
                      <label className="text-dark font-weight-900">
                        Meio de pagamento
                      </label>
                      <select
                        name="contributionPaymentMethodId"
                        id="contributionPaymentMethodId"
                        ref="contributionPaymentMethodId"
                        class={"text-dark font-weight-900 form-control"}
                        onChange={this.onInputChange}
                      >
                        <option value="">Selecione</option>
                        {tithePaymentMethodSelectItems.length === 0 ||
                          tithePaymentMethodSelectItems}
                      </select>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="form-group">
                      <label className="text-dark font-weight-900">
                        Melhor dia
                      </label>
                      <select
                        name="contributionBestDay"
                        id="contributionBestDay"
                        ref="contributionBestDay"
                        class={"text-dark font-weight-900 form-control"}
                        onChange={this.onInputChange}
                      >
                        <option value="">Selecione</option>
                        <option
                          value="1"
                          selected={
                            this.state.formData.contributionBestDay === 1
                              ? true
                              : false
                          }
                        >
                          1
                        </option>
                        <option
                          value="2"
                          selected={
                            this.state.formData.contributionBestDay === 2
                              ? true
                              : false
                          }
                        >
                          5
                        </option>
                        <option
                          value="3"
                          selected={
                            this.state.formData.contributionBestDay === 3
                              ? true
                              : false
                          }
                        >
                          10
                        </option>
                        <option
                          value="4"
                          selected={
                            this.state.formData.contributionBestDay === 4
                              ? true
                              : false
                          }
                        >
                          15
                        </option>
                        <option
                          value="5"
                          selected={
                            this.state.formData.contributionBestDay === 5
                              ? true
                              : false
                          }
                        >
                          20
                        </option>
                        <option
                          value="6"
                          selected={
                            this.state.formData.contributionBestDay === 6
                              ? true
                              : false
                          }
                        >
                          25
                        </option>
                      </select>
                    </div>
                  </Col>
                </>
              ) : (
                <> </>
              )}
            </Row>
            {this.state.formData.isTithe === "1" ? (
              <>
                <Row>
                  <Col lg="3">
                    <div className="form-group">
                      <label className="text-dark font-weight-900">
                        Valor recorrente
                      </label>
                      <input
                        name="contributionValue"
                        id="contributionValue"
                        ref="contributionValue"
                        value={this.state.formData["contributionValue"]}
                        class={"text-dark font-weight-900 form-control"}
                        onChange={this.onInputChange}
                      />
                      {/* <CurrencyInput name="contributionValue" id="contributionValue" ref="contributionValue" decimalSeparator="," thousandSeparator="." value={this.state.formData['contributionValue']} class={"text-dark font-weight-900 form-control"} onChange={this.onInputChange}/> */}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <> </>
            )}
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Pessoas"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <>
                        <Loading load={true} />
                      </>
                    ) : (
                      "Salvar e concluir"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {!this.state.authenticationFailure || <Redirect to={"/auth/Login"} />}
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ personId: id });
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Tithe.GetByPerson;
    let data = "PersonId=" + this.state.personId;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          let formData = this.state.formData;
          formData.isTithe = "0";
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        formData.id = ret.id;
        formData.contributionRecurrency = ret.contributionRecurrency;
        formData.contributionPaymentMethodId = ret.contributionPaymentMethodId;
        formData.contributionBestDay = ret.contributionBestDay;

        if (ret.contributionValue) {
          ret.contributionValue = ret.contributionValue.toFixed(2);
        }

        formData.contributionValue = formater.monetaryBRL(
          ret.contributionValue + "",
          true
        );

        if (formData.id) {
          formData.isTithe = "1";
        } else formData.isTithe = "0";

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadPerson();
    this.loadWizard();
    this.loadSelects();
  }

  //#endregion

  //#region Custom Events

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      this.state.personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        formData.name = ret.name;

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Wizard;
    let data = { personId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadRecurrence();
    this.loadTithePaymentMethod();
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "contributionValue":
          value = formater.monetaryBRL(target.value, true);
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    let formData = this.state.formData;

    console.log("formData", formData);
  }

  loadRecurrence() {
    this.setState({ recurrenceResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Recurrence.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ recurrenceResponse: result.response });
        console.log("recurrenceResponse", this.state.recurrenceResponse);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadTithePaymentMethod() {
    this.setState({ tithePaymentMethodResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.TithePaymentMethod.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        this.setState({ tithePaymentMethodResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  deleteTithe(titheId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Tithe.Delete +
      "/" +
      titheId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ errorMessage: messages.Errors.UnexpectedFormat });
          return;
        }

        if (!result.success) {
          this.setState({ errorMessage: messages.Errors.ResponseError });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ errorMessage: messages.Errors.BlankResponse });
          console.error(messages.Errors.BlankResponse);
          return;
        }
        console.log("Success");
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let formData = this.state.formData;

    if (formData.isTithe === 0 && formData.id) {
      this.deleteTithe(formData.id);

      window.location.href = "/admin/Pessoas/";

      this.setState({ submitLoading: false });
    } else if (formData.isTithe === 1) {
      formData.personId = this.state.personId;

      formData.contributionValue = formater.numberMonetary(
        formData.contributionValue
      );

      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url = constants.Endpoint.UrlBase + constants.Endpoint.Tithe.Put;
      let data = formData;

      smoothApi.put(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState({
              errorMessage: messages.Errors.UnexpectedFormat,
              submitLoading: false,
            });
            return;
          }

          if (!result.success) {
            this.setState({
              errorMessage: messages.Errors.ResponseError,
              submitLoading: false,
            });
            console.error(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            this.setState({
              errorMessage: messages.Errors.BlankResponse,
              submitLoading: false,
            });
            console.error(messages.Errors.BlankResponse);
            return;
          }

          window.location.href = "/admin/Pessoas/";
          this.setState({ submitLoading: false });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            personLoading: false,
            renderPersonList: false,
            submitLoading: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    } else {
      window.location.href = "/admin/Pessoas/";

      this.setState({ submitLoading: false });
    }
  }

  // handleDelete(titheId) {

  //   let token = window.localStorage.getItem(constants.LocalStorage.Token);
  //   let url = constants.Endpoint.UrlBase + constants.Endpoint.Tithe.DeleteTithe  + '/' + titheId;

  //   smoothApi.delete(url, token,

  //     (result) => {

  //       if (!result || !result.errors || !result.response || result.success == null) {
  //         console.error(messages.Errors.UnexpectedFormat, result);
  //         this.setState({ errorMessage: messages.Errors.UnexpectedFormat });
  //         return;

  //       }

  //       if (!result.success) {
  //         this.setState({ errorMessage: messages.Errors.ResponseError });
  //         console.error(messages.Errors.ResponseError, result.errors);
  //         return;

  //       }

  //       if (result.response == null) {
  //         this.setState({ errorMessage: messages.Errors.BlankResponse });
  //         console.error(messages.Errors.BlankResponse);
  //         return;

  //       }
  //       console.log('Success');
  //     },

  //     (error, httpCode) => {

  //       if (httpCode === 401)
  //         window.location.href = "/auth/Login";

  //       this.setState({ personLoading: false, renderPersonList: false, errorMessage: messages.Errors.HttpError });

  //     }

  //   );
  // }
  //#endregion
}

export default Dizimo;
