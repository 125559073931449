/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";


class Header extends React.Component {


  render() {
    return (
      <>
        <Container fluid>
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                
                {/* <Switch>{this.getRoutes(routes)}</Switch> */}
                <h1 className="text-dark">Home</h1>
                <img className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Header;
