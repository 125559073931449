//#region Imports

import Modal from "react-modal";
import React from "react";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import { objectToFormData } from "object-to-formdata";
import messages from "variables/messages";
import { formater } from "components/Utils/formater";
import { diruma } from "components/Utils/diruma";
import Loading from "components/Utils/Loading.jsx";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";

//#endregion


class CadastroFornecedor extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      messageExclusion: "",
      submitLoading: false,
      formData: { serviceIds: [] },
      serviceResponse: null,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.onInputChange = this.onInputChange.bind(this);

    //#endregion
  }

  render() {
    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "name",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "businessEmail",
          validations: [diruma.validations.email],
          customValidations: [],
        },
      ],
    };

    return (
      <>
        {/* //#region Modal */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>
        {/* //#endregion Modal */}

        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Fornecedores</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>

          <Form>
            <Row lg="12" className="pt-7 pl-5">
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Nome da empresa
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="name"
                    name="name"
                    ref="name"
                    value={this.state.formData["name"]}
                    placeholder=""
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Pessoa responsável
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="contactName"
                    name="contactName"
                    value={this.state.formData["contactName"]}
                    placeholder=""
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pl-5">
              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Telefone celular
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="mobilePhone"
                    name="mobilePhone"
                    value={this.state.formData["mobilePhone"]}
                    placeholder=""
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Telefone comercial
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="businessPhone"
                    name="businessPhone"
                    value={this.state.formData["businessPhone"]}
                    placeholder=""
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    E-mail
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="businessEmail"
                    name="businessEmail"
                    ref="businessEmail"
                    value={this.state.formData["businessEmail"]}
                    placeholder=""
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pl-5">
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Observações
                  </label>
                  <textarea
                    type="name"
                    rows="10"
                    className="form-control text-dark font-weight-900"
                    id="information"
                    name="information"
                    value={this.state.formData["information"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Serviços prestados
                  </label>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="advisory"
                      onChange={this.onInputChange}
                      checked={this.state.formData["advisory"] ? true : false}
                    />
                    <label class="custom-control-label pb-3" for="advisory">
                      Assessoria
                    </label>
                    <br />
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="decoration"
                      onChange={this.onInputChange}
                      checked={this.state.formData["decoration"] ? true : false}
                    />
                    <label class="custom-control-label pb-3" for="decoration">
                      Decoração
                    </label>
                    <br />
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="photography"
                      onChange={this.onInputChange}
                      checked={
                        this.state.formData["photography"] ? true : false
                      }
                    />
                    <label class="custom-control-label pb-3" for="photography">
                      Fotografia
                    </label>
                    <br />
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="music"
                      onChange={this.onInputChange}
                      checked={this.state.formData["music"] ? true : false}
                    />
                    <label class="custom-control-label pb-3" for="music">
                      Música
                    </label>
                    <br />
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="video"
                      onChange={this.onInputChange}
                      checked={this.state.formData["video"] ? true : false}
                    />
                    <label class="custom-control-label pb-3" for="video">
                      Vídeo
                    </label>
                    <br />
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="partyRoom"
                      onChange={this.onInputChange}
                      checked={this.state.formData["partyRoom"] ? true : false}
                    />
                    <label class="custom-control-label pb-3" for="partyRoom">
                      Salão de festas
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Cadastros/fornecedores"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({ personId: id });
    }
  }

  componentDidMount() {
    if (this.state.personId) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase +
        constants.Endpoint.Provider.Get +
        "/" +
        this.state.personId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let formData = { ...this.state.formData, ...ret };

          if (formData.providerService) {
            formData.providerService.map((item) => {
              if (item.service.id === 1) formData.advisory = true;

              if (item.service.id === 2) formData.decoration = true;

              if (item.service.id === 3) formData.photography = true;

              if (item.service.id === 4) formData.music = true;

              if (item.service.id === 5) formData.video = true;

              if (item.service.id === 6) formData.partyRoom = true;
            });
          }

          formData.businessPhone = formater.template(
            formData.businessPhone,
            "(00) 0000-0000"
          );
          formData.mobilePhone = formater.template(
            formData.mobilePhone,
            "(00) 00000-0000"
          );

          formData = smoothApi.helpers.orderProperties(formData);
          this.setState({ formData: formData });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }

    this.loadSelects();
  }

  //#endregion

  //#region Custom Events

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "businessPhone":
          value = formater.template(target.value, "(00) 0000-0000");
          break;

        case "mobilePhone":
          value = formater.template(target.value, "(00) 00000-0000");
          break;

        case "name":
          value = formater.maxLength(target.value, 100);
          break;

        case "contactName":
          value = formater.maxLength(target.value, 150);
          break;

        case "businessEmail":
          value = formater.maxLength(target.value, 254);
          break;

        case "information":
          value = formater.maxLength(target.value, 500);
          break;

        default:
          value = target.value;
      }


    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  loadSelects() {
    this.loadService();
  }

  loadService() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Service.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ serviceResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let formData = this.state.formData;
    let serviceSelected = [];

    if (formData.advisory !== undefined)
      if (formData.advisory === true) serviceSelected.push("Assessoria");

    if (formData.decoration !== undefined)
      if (formData.decoration === true) serviceSelected.push("Decoração");

    if (formData.photography !== undefined)
      if (formData.photography === true) serviceSelected.push("Fotografia");

    if (formData.music !== undefined)
      if (formData.music === true) serviceSelected.push("Música");

    if (formData.video !== undefined)
      if (formData.video === true) serviceSelected.push("Vídeo");

    if (formData.partyRoom !== undefined)
      if (formData.partyRoom === true) serviceSelected.push("Salão de festas");

    this.state.serviceResponse.map((item) => {
      if (!(serviceSelected.indexOf(item.name) === -1))
        formData.serviceIds.push(item.id);
    });

    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Provider.Post;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            submitLoading: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
          }));

          return;
        }

        window.location.href = "/admin/Cadastros/fornecedores";
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }
}

export default CadastroFornecedor;
