
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import constants from "variables/constants";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            exact={true}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);

    if (token) {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/nsb-blue-logo 1.png"),
              imgAlt: "..."
            }}
          />
          <div className="main-content" ref="mainContent">
            {/* <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          /> */}
            <Switch>{this.getRoutes(routes)}</Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
      )
    }
    else {
      return  (
        <>
          <Redirect to={"/auth/Login"}></Redirect>
        </>
      );
    }
  }
}

export default Admin;
