//#region Imports

import React from "react";
import Modal from "react-modal";
import constants from "variables/constants";
import Wizard from "components/Utils/Wizard";
import cpfMask from "components/Utils/Mask/cpf";
import { Link, Redirect } from "react-router-dom";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import ButtonGroupNsb from "components/Utils/buttonGroupNsb";
import { viaCEP } from "components/Utils/viaCEP";
import { formater } from "components/Utils/formater";
import { diruma } from "components/Utils/diruma";
import { objectToFormData } from "object-to-formdata";
import Loading from "components/Utils/Loading.jsx";
import { Container, Row, Col, Form } from "reactstrap";

class Pessoa extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      waitingResponseSubmit: false,
      fotoModal: false,
      webCamModal: false,
      falecidoModal: false,
      exclusaoModal: false,
      vivoModal: false,
      defaultPicture: require("assets/img/brand/maskUpload.png"),
      selectedPictureFile: "",
      selectedPictureContent: "",
      formData: {},
      data: {},
      isDeceased: false,
      idModal: "",
      personId: -1,
      dateBirthPerson: "",
      personDeleted: false,
      documentId: "",
      accessSystem: false,
      image: null,
      educationResponse: null,
      wizardResponse: null,
      authenticationFailure: false,
      isMaleResponse: null,
      selectedFileContent: null,
      selectedFileName: null,
      professionResponse: null,
      situationResponse: null,
      accessLevelResponse: null,
      familyStatusResponse: null,
      cityResponse: null,
      optIn: null,
      stateResponse: null,
      accessLevelId: 0,
      accessLevelName: "",
      citySearch: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "name",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "cpf",
          validations: [diruma.validations.cpf],
          customValidations: [],
        },
        {
          ref: "email",
          validations: [diruma.validations.email],
          customValidations: [],
        },
        {
          ref: "birthDate",
          validations: [diruma.validations.date],
          customValidations: [],
        },
      ],
    };


    this.onInputChange = this.onInputChange.bind(this);
    this.maskCpf = this.maskCpf.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.startWebcamCanvas = this.startWebcamCanvas.bind(this);
    this.endWebcamCanvas = this.endWebcamCanvas.bind(this);
    this.saveCanvasImage = this.saveCanvasImage.bind(this);
    this.handleCEP = this.handleCEP.bind(this);
    this.loadWizard = this.loadWizard.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }



  render() {

    let educationSelectItems = [];
    let baptismSituationSelectItems = [];
    let eucharistSituationSelectItems = [];
    let chrismSituationSelectItems = [];
    let marriageSituationSelectItems = [];
    let professionSelectItems = [];
    let stateSelectItems = [];
    let citySelectItems = [];
    let accessLevelSelectItems = [];
    let familyStatusSelectItems = [];

    if (this.state.stateResponse) {
      if (this.state.stateResponse.length > 0) {
        try {
          stateSelectItems = this.state.stateResponse.map((item) => {
            if (this.state.formData["stateId"]) {
              if (this.state.formData["stateId"] === item.id)
                return (
                  <option value={item.id} key={"state" + item.id} selected>
                    {item.name}
                  </option>
                );
              else
                return (
                  <option value={item.id} key={"state" + item.id}>
                    {item.name}
                  </option>
                );
            } else {
              return (
                <option value={item.id} key={"state" + item.id}>
                  {item.name}
                </option>
              );
            }
          });
        } catch (ex) {
          stateSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.cityResponse) {
      if (this.state.cityResponse.length > 0) {
        if (this.state.citySearch) {
          let city = null;
          city = this.state.cityResponse.filter((item) => {
            return (
              item.name.toLocaleLowerCase() ===
              this.state.citySearch.toLocaleLowerCase()
            );
          });

          if (city) {
            let formData = this.state.formData;
            formData.cityId = city[0].id;
            this.setState({ citySearch: "", formData: formData });
          }
        }

        try {
          citySelectItems = this.state.cityResponse.map((item) => {
            if (this.state.formData["cityId"]) {
              if (this.state.formData["cityId"] === item.id)
                return (
                  <option value={item.id} key={"city" + item.id} selected>
                    {item.name}
                  </option>
                );
              else
                return (
                  <option value={item.id} key={"city" + item.id}>
                    {item.name}
                  </option>
                );
            } else {
              return (
                <option value={item.id} key={"city" + item.id}>
                  {item.name}
                </option>
              );
            }
          });
        } catch (ex) {
          citySelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.accessLevelResponse) {
      if (this.state.accessLevelResponse.length > 0) {
        try {
          accessLevelSelectItems = this.state.accessLevelResponse.map(
            (item) => {
              if (this.state.formData["accessLevelId"]) {
                if (this.state.formData["accessLevelId"] === item.id)
                  return (
                    <option
                      value={item.id}
                      key={"acessLevel" + item.id}
                      selected
                    >
                      {item.name}
                    </option>
                  );
                else
                  return (
                    <option value={item.id} key={"acessLevel" + item.id}>
                      {item.name}
                    </option>
                  );
              } else {
                return (
                  <option value={item.id} key={"acessLevel" + item.id}>
                    {item.name}
                  </option>
                );
              }
            }
          );
        } catch (ex) {
          accessLevelSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.familyStatusResponse) {
      if (this.state.familyStatusResponse.length > 0) {
        try {
          familyStatusSelectItems = this.state.familyStatusResponse.map(
            (item) => {
              if (this.state.formData["familyStatusId"]) {
                if (this.state.formData["familyStatusId"] === item.id)
                  return (
                    <option
                      value={item.id}
                      key={"familyStatus" + item.id}
                      selected
                    >
                      {item.description}
                    </option>
                  );
                else
                  return (
                    <option value={item.id} key={"familyStatus" + item.id}>
                      {item.description}
                    </option>
                  );
              } else {
                return (
                  <option value={item.id} key={"familyStatus" + item.id}>
                    {item.description}
                  </option>
                );
              }
            }
          );
        } catch (ex) {
          familyStatusSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.length > 0) {
        try {
          baptismSituationSelectItems = this.state.situationResponse.map(
            (item) => {
              if (this.state.formData["baptismSituationId"]) {
                if (this.state.formData["baptismSituationId"] === item.id) {
                  return (
                    <option
                      selected
                      value={item.id}
                      key={"baptismSituation" + item.id}
                    >
                      {item.description}
                    </option>
                  );
                } else {
                  return (
                    <option value={item.id} key={"baptismSituation" + item.id}>
                      {item.description}
                    </option>
                  );
                }
              } else {
                return (
                  <option value={item.id} key={"baptismSituation" + item.id}>
                    {item.description}
                  </option>
                );
              }
            }
          );

          eucharistSituationSelectItems = this.state.situationResponse.map(
            (item) => {
              if (this.state.formData["eucharistSituationId"]) {
                if (this.state.formData["eucharistSituationId"] === item.id) {
                  return (
                    <option
                      selected
                      value={item.id}
                      key={"eucharistSituation" + item.id}
                    >
                      {item.description}
                    </option>
                  );
                } else {
                  return (
                    <option
                      value={item.id}
                      key={"eucharistSituation" + item.id}
                    >
                      {item.description}
                    </option>
                  );
                }
              } else {
                return (
                  <option value={item.id} key={"eucharistSituation" + item.id}>
                    {item.description}
                  </option>
                );
              }
            }
          );

          chrismSituationSelectItems = this.state.situationResponse.map(
            (item) => {
              if (this.state.formData["chrismSituationId"]) {
                if (this.state.formData["chrismSituationId"] === item.id) {
                  return (
                    <option
                      selected
                      value={item.id}
                      key={"chrismSituation" + item.id}
                    >
                      {item.description}
                    </option>
                  );
                } else {
                  return (
                    <option value={item.id} key={"chrismSituation" + item.id}>
                      {item.description}
                    </option>
                  );
                }
              } else {
                return (
                  <option value={item.id} key={"chrismSituation" + item.id}>
                    {item.description}
                  </option>
                );
              }
            }
          );

          marriageSituationSelectItems = this.state.situationResponse.map(
            (item) => {
              if (this.state.formData["marriageSituationId"]) {
                if (this.state.formData["marriageSituationId"] === item.id) {
                  return (
                    <option
                      selected
                      value={item.id}
                      key={"marriageSituation" + item.id}
                    >
                      {item.description}
                    </option>
                  );
                } else {
                  return (
                    <option value={item.id} key={"marriageSituation" + item.id}>
                      {item.description}
                    </option>
                  );
                }
              } else {
                return (
                  <option value={item.id} key={"marriageSituation" + item.id}>
                    {item.description}
                  </option>
                );
              }
            }
          );
        } catch (ex) {
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.educationResponse) {
      if (this.state.educationResponse.length > 0) {
        try {
          educationSelectItems = this.state.educationResponse.map((item) => {
            if (this.state.formData["educationId"]) {
              if (this.state.formData["educationId"] === item.id)
                return (
                  <option value={item.id} key={"education" + item.id} selected>
                    {item.description}
                  </option>
                );
              else
                return (
                  <option value={item.id} key={"education" + item.id}>
                    {item.description}
                  </option>
                );
            } else {
              return (
                <option value={item.id} key={"education" + item.id}>
                  {item.description}
                </option>
              );
            }
          });
        } catch (ex) {
          educationSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }


    if (this.state.professionResponse) {
      if (this.state.professionResponse.length > 0) {
        try {
          professionSelectItems = this.state.professionResponse.map((item) => {
            if (this.state.formData["professionId"]) {
              if (this.state.formData["professionId"] === item.id)
                return (
                  <option value={item.id} key={"profession" + item.id} selected>
                    {item.description}
                  </option>
                );
              else
                return (
                  <option value={item.id} key={"profession" + item.id}>
                    {item.description}
                  </option>
                );
            } else {
              return (
                <option value={item.id} key={"profession" + item.id}>
                  {item.description}
                </option>
              );
            }
          });
        } catch (ex) {
          professionSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];
    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Informações <br /> pessoais
        </>,
        "/admin/Pessoas/Pessoa" +
        (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
        wizzardConfiguration.Status.Active
      )
    );

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Familia/?id=" + this.state.personId,
          this.state.wizardResponse.hasFamily
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Grupo/?id=" + this.state.personId,
          this.state.wizardResponse.hasMinistry
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Atividade/?id=" + this.state.personId,
          this.state.wizardResponse.hasCourse
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Outras/?id=" + this.state.personId,
          this.state.wizardResponse.hasPersonAdditionalInfo
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Documento/?id=" + this.state.personId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Dizimo/?id=" + this.state.personId,
          this.state.wizardResponse.hasTithe
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Pessoa" +
          (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Pessoa" +
          (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Pessoa" +
          (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Pessoa" +
          (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Pessoa" +
          (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Pessoa" +
          (this.state.personId !== -1 ? "/?id=" + this.state.personId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    return (
      <>

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.fotoModal}
            onRequestClose={() => {
              this.setState({ fotoModal: false });
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel="Upload Imagem"
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.setState({ fotoModal: false });
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Qual tipo de upload <br /> você deseja fazer?
            </h2>
            <div className="text-center">
              <label
                className="btn btn-group button-capture-img"
                for="photoCapture"
              >
                Tirar foto
              </label>
              <label
                className="btn btn-group button-select-img"
                for="photoInput"
              >
                Escolher foto
              </label>
            </div>
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.falecidoModal}
            onRequestClose={() => {
              this.setState({ falecidoModal: false });
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel="Status Falecimento"
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.setState({ falecidoModal: false });
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Marcar esta pessoa como <br /> falecida?
            </h2>
            <div className="text-center">
              <button
                id=""
                className="alertExclusion btn btn-group button-yes mr-4"
                onClick={() =>
                  this.setState({ isDeceased: true, falecidoModal: false })
                }
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.setState({ falecidoModal: false });
                }}
              >
                Não
              </button>
            </div>
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.webCamModal}
            onAfterOpen={this.startWebcamCanvas}
            onRequestClose={() => {
              this.setState({ webCamModal: false });
              this.endWebcamCanvas();
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel="Upload Imagem"
            ref="webCamModal"
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.setState({ webCamModal: false });
                this.endWebcamCanvas();
              }}
            >
              X
            </span>
            <div className="p-4">
              <video ref="videoElement" width="340" height="370"></video>
              <button
                type="button"
                className="btn btn-group button-capture-img webcam"
                href="#"
                id="capture"
                onClick={this.saveCanvasImage}
              >
                Capturar foto
              </button>
            </div>
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.exclusaoModal}
            onRequestClose={() => {
              this.setState({ exclusaoModal: false });
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel="Exclusão de Registro"
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.setState({ exclusaoModal: false });
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir este cadastro?
            </h2>
            <div className="text-center">
              <button
                id=""
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.personId}
                onClick={() => {
                  this.deletePerson();
                }}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.setState({ exclusaoModal: false });
                }}
              >
                Não
              </button>
            </div>
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.vivoModal}
            onRequestClose={() => {
              this.setState({ vivoModal: false });
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel="Status Falecimento"
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.setState({ vivoModal: false });
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Desmarcar esta pessoa como <br /> falecida?
            </h2>
            <div className="text-center">
              <button
                id=""
                className="alertExclusion btn btn-group button-yes mr-4"
                onClick={() =>
                  this.setState({ isDeceased: false, vivoModal: false })
                }
              >
                Sim
              </button>
              <button
                type="button"
                className="btn btn-group button-no"
                onClick={() => {
                  this.setState({ vivoModal: false });
                }}
              >
                Não
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.formData["name"] ? (
                    <>{this.state.formData["name"]}</>
                  ) : (
                    <>Nova Pessoa</>
                  )}
                  {!this.state.isDeceased || (
                    <small className="text-deceased"> - falecido(a)</small>
                  )}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Row>
            <Col>
              <div className="pt-4">
                <h2 className="text-dark mb-5">Dados cadastrais</h2>
              </div>
            </Col>
          </Row>

          <Form>
            <div className="pb-9">
              <Row>
                <Col lg="auto" className="mb-3">
                  <div
                    className="fileinput fileinput-new text-center"
                    data-provides="fileinput"
                  >
                    <div className="fileinput-new thumbnail img-raised back-upload">
                      {this.state.selectedPictureContent ? (
                        <img
                          src={this.state.selectedPictureContent}
                          id="canvas"
                          rel="nofollow"
                          className="imgUploaded"
                          alt="..."
                        ></img>
                      ) : (
                        <>
                          {this.state.formData.pictureFileName ? (
                            <img
                              src={this.state.formData.pictureFileName}
                              id="pictureFileName"
                              className="imgUploaded"
                              rel="nofollow"
                              alt="..."
                            ></img>
                          ) : (
                            <img
                              src={this.state.defaultPicture}
                              id="pictureFileName"
                              className="imgUploaded"
                              rel="nofollow"
                              alt="..."
                            ></img>
                          )}
                        </>
                      )}
                    </div>

                    <button
                      id="uploadImg"
                      type="button"
                      className="my-4 btn-upload-img"
                      onClick={() => {
                        this.setState({ fotoModal: true });
                      }}
                    >
                      {this.state.selectedFileName ||
                        this.state.formData.pictureFileName !==
                        require("assets/img/brand/maskUpload.png") ? (
                        <>Editar foto</>
                      ) : (
                        <>+ Adicionar foto</>
                      )}
                    </button>

                    <canvas
                      ref="canvasElement"
                      className="input-img-hidden"
                      width="400"
                      height="300"
                    ></canvas>
                    <input
                      type="file"
                      accept="image/*"
                      id="photoInput"
                      className="input-img-hidden"
                      onClick={() => {
                        this.setState({ webCamModal: false, fotoModal: false });
                      }}
                      onChange={(e) => {
                        this.handleFile(e);
                      }}
                    />
                    <input
                      type="button"
                      id="photoCapture"
                      className="input-img-hidden"
                      onClick={() => {
                        this.setState({ webCamModal: true, fotoModal: false });
                      }}
                    />
                  </div>
                </Col>

                <Col lg="6">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Nome Completo{" "}
                      <small className="text-gray">
                        <i>- obrigatório</i>
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control text-dark font-weight-900"
                      id="name"
                      name="name"
                      ref="name"
                      value={this.state.formData["name"]}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <Row className="mb-4">
                    <Col lg="6">
                      <label className="text-dark font-weight-900">Sexo</label>
                      <ButtonGroupNsb
                        id="isMaleOptions"
                        option1="Masculino"
                        option2="Feminino"
                        key="isMaleOptions"
                        activeIndex={this.state.formData["isMaleOptions"]}
                        changeHandler={this.onInputChange}
                        dflex="s"
                      />
                    </Col>
                    <Col lg="6">
                      <label className="text-dark font-weight-900">
                        Data de nascimento
                      </label>
                      <input
                        type="text"
                        className="form-control text-dark font-weight-900"
                        id="birthDate"
                        name="birthDate"
                        ref="birthDate"
                        value={this.state.formData["birthDate"]}
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg="6">
                      <label className="text-dark font-weight-900">
                        Setor de atuação
                      </label>
                      <select
                        name="professionId"
                        id="professionId"
                        className="form-control text-dark font-weight-900"
                        onChange={this.onInputChange}
                      >
                        <option value="">Selecione</option>
                        {professionSelectItems.length === 0 ||
                          professionSelectItems}
                      </select>
                    </Col>
                    <Col lg="6">
                      <label className="text-dark font-weight-900">
                        Telefone residencial
                      </label>
                      <input
                        type="text"
                        className="form-control text-dark font-weight-900"
                        id="homePhone"
                        value={this.state.formData["homePhone"]}
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <label className="text-dark font-weight-900">CEP</label>
                      <input
                        type="text"
                        className="form-control text-dark font-weight-900"
                        id="zipCode"
                        name="zipCode"
                        onChange={this.handleCEP}
                        value={
                          this.state.formData["zipCode"]
                            ? this.state.formData["zipCode"]
                            : ""
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <label className="text-dark font-weight-900">
                        Logradouro
                      </label>
                      <input
                        type="text"
                        className="form-control text-dark font-weight-900"
                        id="address"
                        name="address"
                        onChange={this.onInputChange}
                        value={
                          this.state.formData["address"]
                            ? this.state.formData["address"]
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">CPF</label>
                    <input
                      type="text"
                      className="form-control text-dark font-weight-900"
                      id="cpf"
                      value={this.state.formData["cpf"]}
                      name="cpf"
                      ref="cpf"
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Escolaridade
                    </label>
                    <select
                      name="educationId"
                      id="educationId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {educationSelectItems.length === 0 ||
                        educationSelectItems}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Telefone Celular
                    </label>
                    <input
                      className="form-control text-dark font-weight-900"
                      type="text"
                      id="mobilePhone"
                      name="mobilePhone"
                      value={this.state.formData["mobilePhone"]}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-dark font-weight-900">Número</label>
                    <input
                      className="form-control text-dark font-weight-900"
                      type="text"
                      id="number"
                      name="number"
                      value={this.state.formData["number"]}
                      onChange={this.onInputChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Complemento
                    </label>
                    <input
                      className="form-control text-dark font-weight-900"
                      type="text"
                      id="complement"
                      name="complement"
                      onChange={this.onInputChange}
                      value={
                        this.state.formData["complement"]
                          ? this.state.formData["complement"]
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">Bairro</label>
                    <input
                      className="form-control text-dark font-weight-900"
                      type="text"
                      id="neighborhood"
                      name="neighborhood"
                      onChange={this.onInputChange}
                      value={
                        this.state.formData["neighborhood"]
                          ? this.state.formData["neighborhood"]
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">Estado</label>
                    <select
                      name="stateId"
                      id="stateId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.handleState}
                    >
                      <option value="">Selecione</option>
                      {stateSelectItems.length === 0 || stateSelectItems}
                    </select>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">Cidade</label>
                    <select
                      name="cityId"
                      id="cityId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                      value={
                        this.state.formData["cityId"]
                          ? this.state.formData["cityId"]
                          : ""
                      }
                    >
                      <option value="">Selecione</option>
                      {citySelectItems.length === 0 || citySelectItems}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Situação familiar
                    </label>
                    <select
                      name="familyStatusId"
                      id="familyStatusId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {familyStatusSelectItems.length === 0 ||
                        familyStatusSelectItems}
                    </select>
                  </div>
                </Col>
                <Col lg="3">
                  <label className="text-dark font-weight-900">
                    Tem filhos?
                  </label>
                  <ButtonGroupNsb
                    id="haveChildrenOptions"
                    option1="Sim"
                    option2="Não"
                    changeHandler={this.onInputChange}
                    activeIndex={this.state.formData["haveChildrenOptions"]}
                    dflex="s"
                  />
                </Col>
                <Col lg="6">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      E-mail
                      {this.state.formData["systemAccessOptions"] === "0" ? (
                        <small className="text-gray">
                          <i> - obrigatório</i>
                        </small>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      className="form-control text-dark font-weight-900"
                      required={
                        this.state.formData["systemAccessOptions"] === "0"
                          ? true
                          : false
                      }
                      type="text"
                      ref="email"
                      id="email"
                      name="email"
                      value={this.state.formData["email"]}
                      onChange={this.onInputChange}
                    />
                  </div>
                </Col>
                <Col lg="4">

                  {this.state.formData.id ? (
                    <>
                      <label className="text-dark font-weight-900">
                        Receber comunicação e notificação via e-mail
                      </label>
                      <select
                        name="optIn"
                        id="optIn"
                        disabled
                        className="form-control text-dark font-weight-900"
                        onChange={this.onInputChange}
                        value={this.state.formData["optIn"]}
                      >
                        <option value="">Não determinado</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </>
                  ) : <div></div>}
                </Col>
              </Row>
              {this.state.accessLevelId === "1" ||
                this.state.accessLevelId === "2" ? (
                <Row>
                  <Col lg="3">
                    <div className="form-group">
                      <label className="text-dark font-weight-900">
                        Permitir acesso ao sistema?
                      </label>
                      <ButtonGroupNsb
                        id="systemAccessOptions"
                        option1="Sim"
                        option2="Não"
                        changeHandler={this.onInputChange}
                        activeIndex={
                          this.state.formData["systemAccessOptions"]
                            ? this.state.formData["systemAccessOptions"]
                            : "1"
                        }
                        dflex="s"
                      />
                    </div>
                  </Col>

                  {this.state.formData["systemAccessOptions"] !== "0" || (
                    <Col lg="3">
                      <div className="form-group">
                        <label className="text-dark font-weight-900">
                          Perfil
                          {this.state.formData["systemAccessOptions"] ===
                            "0" ? (
                            <small className="text-gray">
                              <i> - obrigatório</i>
                            </small>
                          ) : (
                            <></>
                          )}
                        </label>
                        <select
                          name="accessLevelId"
                          id="accessLevelId"
                          className="form-control text-dark font-weight-900 mb-4"
                          onChange={this.onInputChange}
                        >
                          <option value="">Selecione</option>
                          {accessLevelSelectItems.length === 0 ||
                            accessLevelSelectItems}
                        </select>
                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <></>
              )}
              <div className="pt-4">
                <h2 className="text-dark mb-5 font-weight-900">Sacramentos</h2>
              </div>
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">Batismo</label>
                    <select
                      name="baptismSituationId"
                      id="baptismSituationId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {baptismSituationSelectItems.length === 0 ||
                        baptismSituationSelectItems}
                    </select>
                  </div>
                </Col>
                {this.state.formData.baptismSituationId == 1 ? (
                  <>
                    <Col lg="3">
                      <div className="form-group">
                        <label className="text-dark font-weight-900">
                          Realizado em qual paróquia?
                        </label>
                        <ButtonGroupNsb
                          id="whichParishBaptismOptions"
                          option1="NSB"
                          option2="Outra"
                          changeHandler={this.onInputChange}
                          activeIndex={
                            this.state.formData["whichParishBaptismOptions"]
                          }
                          dflex="s"
                        />
                      </div>
                    </Col>
                    {this.state.formData.whichParishBaptismOptions == "1" ? (
                      <>
                        <Col lg="3">
                          <div className="form-group">
                            <label className="text-dark font-weight-900">
                              Nome da paróquia
                            </label>
                            <input
                              className="form-control text-dark font-weight-900"
                              type="text"
                              id="baptismLocal"
                              name="baptismLocal"
                              value={this.state.formData["baptismLocal"]}
                              onChange={this.onInputChange}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <> </>
                    )}
                    <Col lg="3">
                      <label className="text-dark font-weight-900">Data</label>
                      <input
                        className="form-control text-dark font-weight-900"
                        type="text"
                        id="baptismDate"
                        name="baptismDate"
                        value={this.state.formData["baptismDate"]}
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </>
                ) : (
                  <> </>
                )}
              </Row>
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Primeira Comunhão
                    </label>
                    <select
                      name="eucharistSituationId"
                      id="eucharistSituationId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {eucharistSituationSelectItems.length === 0 ||
                        eucharistSituationSelectItems}
                    </select>
                  </div>
                </Col>
                {this.state.formData.eucharistSituationId == 1 ? (
                  <>
                    <Col lg="3">
                      <div className="form-group">
                        <label className="text-dark font-weight-900">
                          Realizado em qual paróquia?
                        </label>
                        <ButtonGroupNsb
                          id="whichParishFirstCommunionOptions"
                          option1="NSB"
                          option2="Outra"
                          changeHandler={this.onInputChange}
                          activeIndex={
                            this.state.formData[
                            "whichParishFirstCommunionOptions"
                            ]
                          }
                          dflex="s"
                        />
                      </div>
                    </Col>
                    {this.state.formData.whichParishFirstCommunionOptions ===
                      "1" ? (
                      <>
                        <Col lg="3">
                          <div className="form-group">
                            <label className="text-dark font-weight-900">
                              Nome da paróquia
                            </label>
                            <input
                              className="form-control text-dark font-weight-900"
                              type="text"
                              id="eucharistLocal"
                              name="eucharistLocal"
                              value={this.state.formData["eucharistLocal"]}
                              onChange={this.onInputChange}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <> </>
                    )}
                    <Col lg="3">
                      <label className="text-dark font-weight-900">Data</label>
                      <input
                        className="form-control text-dark font-weight-900"
                        type="text"
                        id="eucharistDate"
                        name="eucharistDate"
                        value={this.state.formData["eucharistDate"]}
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </>
                ) : (
                  <> </>
                )}
              </Row>
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">Crisma</label>
                    <select
                      name="chrismSituationId"
                      id="chrismSituationId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {chrismSituationSelectItems.length === 0 ||
                        chrismSituationSelectItems}
                    </select>
                  </div>
                </Col>
                {this.state.formData.chrismSituationId == 1 ? (
                  <>
                    <Col lg="3">
                      <div className="form-group">
                        <label className="text-dark font-weight-900">
                          Realizado em qual paróquia?
                        </label>
                        <ButtonGroupNsb
                          id="whichParishChrismOptions"
                          option1="NSB"
                          option2="Outra"
                          changeHandler={this.onInputChange}
                          activeIndex={
                            this.state.formData["whichParishChrismOptions"]
                          }
                          dflex="s"
                        />
                      </div>
                    </Col>
                    {this.state.formData.whichParishChrismOptions === "1" ? (
                      <>
                        <Col lg="3">
                          <div className="form-group">
                            <label className="text-dark font-weight-900">
                              Nome da paróquia
                            </label>
                            <input
                              className="form-control text-dark font-weight-900"
                              type="text"
                              id="chrismLocal"
                              name="chrismLocal"
                              value={this.state.formData["chrismLocal"]}
                              onChange={this.onInputChange}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <> </>
                    )}
                    <Col lg="3">
                      <label className="text-dark font-weight-900">Data</label>
                      <input
                        className="form-control text-dark font-weight-900"
                        type="text"
                        id="chrismDate"
                        name="chrismDate"
                        value={this.state.formData["chrismDate"]}
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </>
                ) : (
                  <> </>
                )}
              </Row>
              <Row>
                <Col lg="3">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Casamento
                    </label>
                    <select
                      name="marriageSituationId"
                      id="marriageSituationId"
                      className="form-control text-dark font-weight-900 mb-4"
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {marriageSituationSelectItems.length === 0 ||
                        marriageSituationSelectItems}
                    </select>
                  </div>
                </Col>
                {this.state.formData.marriageSituationId == 1 ? (
                  <>
                    <Col lg="3">
                      <div className="form-group">
                        <label className="text-dark font-weight-900">
                          Realizado em qual paróquia?
                        </label>
                        <ButtonGroupNsb
                          id="whichParishMarriageOptions"
                          option1="NSB"
                          option2="Outra"
                          changeHandler={this.onInputChange}
                          activeIndex={
                            this.state.formData["whichParishMarriageOptions"]
                          }
                          dflex="s"
                        />
                      </div>
                    </Col>
                    {this.state.formData.whichParishMarriageOptions === "1" ? (
                      <>
                        <Col lg="3">
                          <div className="form-group">
                            <label className="text-dark font-weight-900">
                              Nome da paróquia
                            </label>
                            <input
                              className="form-control text-dark font-weight-900"
                              type="text"
                              id="marriageLocal"
                              name="marriageLocal"
                              value={this.state.formData["marriageLocal"]}
                              onChange={this.onInputChange}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <> </>
                    )}
                    <Col lg="3">
                      <label className="text-dark font-weight-900">Data</label>
                      <input
                        className="form-control text-dark font-weight-900"
                        type="text"
                        id="marriageDate"
                        name="marriageDate"
                        value={this.state.formData["marriageDate"]}
                        onChange={this.onInputChange}
                      />
                    </Col>
                  </>
                ) : (
                  <> </>
                )}
              </Row>
              <Row className="pt-4 pt-5 pr-5">
                <Col lg="12">
                  <div className="float-lg-left">
                    <button
                      id="deletePerson"
                      type="button"
                      className="btn-info-person-exc"
                      onClick={() => {
                        this.setState({ exclusaoModal: true });
                      }}
                    >
                      Excluir registro
                    </button>

                    {!this.state.isDeceased ? (
                      <>
                        <button
                          id="Deceased"
                          type="button"
                          className="btn-info-person-obito ml-4"
                          onClick={() => {
                            this.setState({ falecidoModal: true });
                          }}
                        >
                          Registrar óbito
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          id="notDeceased"
                          type="button"
                          className="btn-info-person-obito ml-4"
                          onClick={() => {
                            this.setState({ vivoModal: true });
                          }}
                        >
                          Remover óbito
                        </button>
                      </>
                    )}
                  </div>
                  <div className="float-lg-right">
                    <Link to={"/admin/Pessoas"}>
                      <button type="button" className="btn-info-person-cancel">
                        Cancelar
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn-info-person ml-4"
                      onClick={this.handleSubmit}
                    >
                      {this.state.waitingResponseSubmit ? (
                        <>
                          <Loading load={true} />
                        </>
                      ) : (
                        "Salvar e continuar"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        {!this.state.authenticationFailure || <Redirect to={"/auth/Login"} />}
      </>
    );
  }


  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({ personId: id });
    }

    let accessLevelId = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelId
    );
    let accessLevelName = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelName
    );

    this.setState({
      accessLevelId: accessLevelId,
      accessLevelName: accessLevelName,
    });
  }

  componentDidMount() {
    if (this.state.personId) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase +
        constants.Endpoint.Pessoas.Get +
        "/" +
        this.state.personId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let formData = { ...this.state.formData, ...ret };

          formData.cpf = formater.template(formData.cpf, "000.000.000-00");
          formData.zipCode = formater.template(formData.zipCode, "00000-000");
          formData.homePhone = formater.template(
            formData.homePhone,
            "(00) 0000-0000"
          );
          formData.mobilePhone = formater.template(
            formData.mobilePhone,
            "(00) 00000-0000"
          );

          if (ret.systemAcess) {
            formData.accessLevelId = ret.systemAcess.accessLevelId;
          }



          if (formData.isMale) formData.isMaleOptions = "0";
          else formData.isMaleOptions = "1";

          if (formData.baptismLocal) {
            if (formData.baptismLocal === "NSB")
              formData.whichParishBaptismOptions = "0";
            else formData.whichParishBaptismOptions = "1";
          }

          if (formData.eucharistLocal) {
            if (formData.eucharistLocal === "NSB")
              formData.whichParishFirstCommunionOptions = "0";
            else formData.whichParishFirstCommunionOptions = "1";
          }

          if (formData.chrismLocal) {
            if (formData.chrismLocal === "NSB")
              formData.whichParishChrismOptions = "0";
            else formData.whichParishChrismOptions = "1";
          }

          if (formData.marriageLocal) {
            if (formData.marriageLocal === "NSB")
              formData.whichParishMarriageOptions = "0";
            else formData.whichParishMarriageOptions = "1";
          }

          if (formData.haveChildren !== null) {
            if (formData.haveChildren) formData.haveChildrenOptions = "0";
            else formData.haveChildrenOptions = "1";
          }

          if (formData.systemAcessId) formData.systemAccessOptions = "0";
          else formData.systemAccessOptions = "1";

          if (ret.birthDay && ret.birthMonth && ret.birthYear) {
            let day = ret.birthDay.toString();
            let month = ret.birthMonth.toString();
            let year = ret.birthYear.toString();
            let convertedDate = new Date(month + "/" + day + "/" + year);

            if (day.length < 2) day = "0" + day;

            if (month.length < 2) month = "0" + month;

            if (isNaN(convertedDate.getTime())) {
              formData.birthDate = "";
              console.error("Data inválida", convertedDate);
            } else formData.birthDate = day + "/" + month + "/" + year;
          }

          if (ret.baptismday && ret.baptismmonth && ret.baptismyear) {
            let day = ret.baptismday.toString();
            let month = ret.baptismmonth.toString();
            let year = ret.baptismyear.toString();
            let convertedDate = new Date(month + "/" + day + "/" + year);

            if (day.length < 2) day = "0" + day;

            if (month.length < 2) month = "0" + month;

            if (isNaN(convertedDate.getTime())) {
              formData.baptismDate = "";
              console.error("Data inválida", convertedDate);
            } else formData.baptismDate = day + "/" + month + "/" + year;
          }

          if (ret.eucharistDay && ret.eucharistMonth && ret.eucharistYear) {
            let day = ret.eucharistDay.toString();
            let month = ret.eucharistMonth.toString();
            let year = ret.eucharistYear.toString();
            let convertedDate = new Date(month + "/" + day + "/" + year);

            if (day.length < 2) day = "0" + day;

            if (month.length < 2) month = "0" + month;

            if (isNaN(convertedDate.getTime())) {
              formData.eucharistDate = "";
              console.error("Data inválida", convertedDate);
            } else formData.eucharistDate = day + "/" + month + "/" + year;
          }

          if (ret.chrismDay && ret.chrismMonth && ret.chrismYear) {
            let day = ret.chrismDay.toString();
            let month = ret.chrismMonth.toString();
            let year = ret.chrismYear.toString();
            let convertedDate = new Date(month + "/" + day + "/" + year);

            if (day.length < 2) day = "0" + day;

            if (month.length < 2) month = "0" + month;

            if (isNaN(convertedDate.getTime())) {
              formData.chrismDate = "";
              console.error("Data inválida", convertedDate);
            } else formData.chrismDate = day + "/" + month + "/" + year;
          }

          if (ret.marriageDay && ret.marriageMonth && ret.marriageYear) {
            let day = ret.marriageDay.toString();
            let month = ret.marriageMonth.toString();
            let year = ret.marriageYear.toString();
            let convertedDate = new Date(month + "/" + day + "/" + year);

            if (day.length < 2) day = "0" + day;

            if (month.length < 2) month = "0" + month;

            if (isNaN(convertedDate.getTime())) {
              formData.marriageDate = "";
              console.error("Data inválida", convertedDate);
            } else formData.marriageDate = day + "/" + month + "/" + year;
          }

          if (ret.isMale) this.setState({ isMaleResponse: true });
          else this.setState({ isMaleResponse: false });

          let currentCityResponse = formData.cityResponse;
          let currentCitySearch = formData.citySearch;

          if (formData.city) {
            formData["stateId"] = formData.city.stateId;
            currentCitySearch = formData.city.name;
            currentCityResponse = null;
            this.loadCity(formData.city.stateId);
          }

          let isOptIn = formData.optIn;

          if (isOptIn === true) {
            formData.optIn = "1";
          }
          else if (isOptIn === false) {
            formData.optIn = "2";
          }
          else {
            formData.optIn = null;
          }

          let isDeceased = formData.deceased;

          formData = smoothApi.helpers.orderProperties(formData);
          this.setState({
            formData: formData,
            cityResponse: currentCityResponse,
            citySearch: currentCitySearch,
            isDeceased: isDeceased,
          });

        },


        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }

    this.loadSelects();
    this.loadWizard();
  }


  handleSubmit() {
    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );
    let formData = this.state.formData;



    if (formData.systemAccessOptions === "0" && !formData.accessLevelId) {
      validations.sucess = false;
      validations.errors.push(
        "Se o usuário tem acesso ao sistema, o campo Perfil se torna obrigatório."
      );
    }

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    if (!formData.isMaleOptions) {
      validations.sucess = false;
      validations.errors.push("O campo Sexo é obrigatório");
    }

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    if (this.state.waitingResponseSubmit) return;

    let formDataFilter = [
      "id",
      "name",
      "email",
      "cpf",
      "isMale",
      "birthDay",
      "birthMonth",
      "birthYear",
      "educationId",
      "professionId",
      "familyStatusId",
      "optIn",
      "haveChildren",
      "pictureFileName",
      "faceBookProfile",
      "twitterProfile",
      "instagranProfile",
      "linkedinProfile",
      "address",
      "number",
      "complement",
      "neighborhood",
      "zipCode",
      "cityId",
      "mobilePhone",
      "businessPhone",
      "homePhone",
      "baptismGodFather",
      "baptismGodMother",
      "chrismSponsor",
      "baptismSituationId",
      "baptismLocal",
      "baptismday",
      "baptismmonth",
      "baptismyear",
      "chrismSituationId",
      "chrismLocal",
      "chrismDay",
      "chrismMonth",
      "chrismYear",
      "eucharistSituationId",
      "eucharistLocal",
      "eucharistDay",
      "eucharistMonth",
      "eucharistYear",
      "marriageSituationId",
      "marriageLocal",
      "marriageDay",
      "marriageMonth",
      "marriageYear",
      "spouseId",
      "systemAcessEmail",
      "systemAcessId",
      "deceased",
      "imageFile",
    ];
    let formDataAPI = smoothApi.helpers.filterProperties(
      formData,
      formDataFilter
    );


    formDataAPI.deceased = this.state.isDeceased;

    formDataAPI.isMale = formData.isMaleOptions === "0";
    formData.hasSystemAccess = formData.systemAccessOptions === "0";

    let accessLeveld = parseInt(this.state.accessLevelId);



    if (accessLeveld > 2) {
      formDataAPI.accessLevelId = null;
    } else {
      if (formData.hasSystemAccess)
        formDataAPI.accessLevelId = formData.accessLevelId;
      else formDataAPI.accessLevelId = null;
    }

    if (formData.baptismSituationId) {
      if (formData.whichParishBaptismOptions === "0")
        formDataAPI.baptismLocal = "NSB";
    }

    if (formData.eucharistSituationId) {
      if (formData.whichParishFirstCommunionOptions === "0")
        formDataAPI.eucharistLocal = "NSB";
    }

    if (formData.chrismSituationId) {
      if (formData.whichParishChrismOptions === "0")
        formDataAPI.chrismLocal = "NSB";
    }

    if (formData.marriageSituationId) {
      if (formData.whichParishMarriageOptions === "0")
        formDataAPI.marriageLocal = "NSB";
    }

    formDataAPI.haveChildren = formData.haveChildrenOptions === "0";

    if (formData.birthDate) {
      if (formData.birthDate.length === 10) {
        if (formData.birthDate.split("/").length === 3) {
          let convertedDate = new Date(
            formData.birthDate.split("/")[1] +
            "/" +
            formData.birthDate.split("/")[0] +
            "/" +
            formData.birthDate.split("/")[2]
          );

          if (!isNaN(convertedDate.getTime())) {
            formDataAPI.birthDay = formData.birthDate.split("/")[0];
            formDataAPI.birthMonth = formData.birthDate.split("/")[1];
            formDataAPI.birthYear = formData.birthDate.split("/")[2];
          }
        }
      }
    }

    if (formData.baptismDate) {
      if (formData.baptismDate.length === 10) {
        if (formData.baptismDate.split("/").length === 3) {
          let convertedDate = new Date(
            formData.baptismDate.split("/")[1] +
            "/" +
            formData.baptismDate.split("/")[0] +
            "/" +
            formData.baptismDate.split("/")[2]
          );

          if (!isNaN(convertedDate.getTime())) {
            formDataAPI.baptismday = formData.baptismDate.split("/")[0];
            formDataAPI.baptismmonth = formData.baptismDate.split("/")[1];
            formDataAPI.baptismyear = formData.baptismDate.split("/")[2];
          }
        }
      }
    }

    if (formData.eucharistDate) {
      if (formData.eucharistDate.length === 10) {
        if (formData.eucharistDate.split("/").length === 3) {
          let convertedDate = new Date(
            formData.eucharistDate.split("/")[1] +
            "/" +
            formData.eucharistDate.split("/")[0] +
            "/" +
            formData.eucharistDate.split("/")[2]
          );

          if (!isNaN(convertedDate.getTime())) {
            formDataAPI.eucharistDay = formData.eucharistDate.split("/")[0];
            formDataAPI.eucharistMonth = formData.eucharistDate.split("/")[1];
            formDataAPI.eucharistYear = formData.eucharistDate.split("/")[2];
          }
        }
      }
    }

    if (formData.chrismDate) {
      if (formData.chrismDate.length === 10) {
        if (formData.chrismDate.split("/").length === 3) {
          let convertedDate = new Date(
            formData.chrismDate.split("/")[1] +
            "/" +
            formData.chrismDate.split("/")[0] +
            "/" +
            formData.chrismDate.split("/")[2]
          );

          if (!isNaN(convertedDate.getTime())) {
            formDataAPI.chrismDay = formData.chrismDate.split("/")[0];
            formDataAPI.chrismMonth = formData.chrismDate.split("/")[1];
            formDataAPI.chrismYear = formData.chrismDate.split("/")[2];
          }
        }
      }
    }

    if (formData.marriageDate) {
      if (formData.marriageDate.length === 10) {
        if (formData.marriageDate.split("/").length === 3) {
          let convertedDate = new Date(
            formData.marriageDate.split("/")[1] +
            "/" +
            formData.marriageDate.split("/")[0] +
            "/" +
            formData.marriageDate.split("/")[2]
          );

          if (!isNaN(convertedDate.getTime())) {
            formDataAPI.marriageDay = formData.marriageDate.split("/")[0];
            formDataAPI.marriageMonth = formData.marriageDate.split("/")[1];
            formDataAPI.marriageYear = formData.marriageDate.split("/")[2];
          }
        }
      }
    }

    if (this.state.personId) formDataAPI.personId = this.state.personId;

    formDataAPI.cpf = formater.number(formDataAPI.cpf);
    formDataAPI.zipCode = formater.number(formDataAPI.zipCode);
    formDataAPI.homePhone = formater.number(formDataAPI.homePhone);
    formDataAPI.mobilePhone = formater.number(formDataAPI.mobilePhone);
    formDataAPI.documentName = this.state.selectedFileName;
    let convertedFormData = objectToFormData(formDataAPI);
    let selectedPictureFile = this.state.selectedPictureFile;

    if (this.state.selectedPictureContent && !selectedPictureFile)
      selectedPictureFile = smoothApi.helpers.convertBase64ToFile(
        this.state.selectedPictureContent,
        "webCamImage.png"
      );

    if (selectedPictureFile)
      convertedFormData.append("imageFile", selectedPictureFile);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Post;
    let data = convertedFormData;

    this.setState((state) => ({ waitingResponseSubmit: true }));

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          this.setState((state) => ({
            waitingResponseSubmit: false,
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));
          return;
        }

        if (result.response == null) {
          this.setState({ waitingResponseSubmit: false });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        window.location.href =
          "/admin/Pessoas/Familia/?id=" + result.response.id;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
          waitingResponseSubmit: false,
        });
      }
    );
  }

  loadSelects() {
    this.loadEducation();
    this.loadProfessions();
    this.loadSituations();
    this.loadAccessLevel();
    this.loadFamilyStatus();
    this.loadState();
  }

  loadEducation() {
    this.setState({ educationResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Education.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ educationResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Wizard;
    let data = { personId: id };

    if (personId > 0) {
      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          this.setState({ wizardResponse: result.response });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  maskCpf(e) {
    this.setState({ documentId: cpfMask(e.target.value) });
  }





  onInputChange(event) {

    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "cpf":
          value = formater.template(target.value, "000.000.000-00");
          break;

        case "birthDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "zipCode":
          value = formater.template(target.value, "00000-000");
          break;

        case "homePhone":
          value = formater.template(target.value, "(00) 0000-0000");
          break;

        case "mobilePhone":
          value = formater.template(target.value, "(00) 00000-0000");
          break;

        case "number":
          value = formater.maxLength(target.value, 10);
          break;

        case "address":
          value = formater.maxLength(target.value, 150);
          break;

        case "name":
          value = formater.maxLength(target.value, 100);
          break;

        case "complement":
          value = formater.maxLength(target.value, 50);
          break;

        case "neighborhood":
          value = formater.maxLength(target.value, 100);
          break;

        case "baptismDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "marriageDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "chrismDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "eucharistDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "baptismLocal":
          value = formater.maxLength(target.value, 150);
          break;

        case "marriageLocal":
          value = formater.maxLength(target.value, 150);
          break;

        case "chrismLocal":
          value = formater.maxLength(target.value, 150);
          break;

        case "eucharistLocal":
          value = formater.maxLength(target.value, 150);
          break;


        default:
          value = target.value;
      }


      // if (!validations.sucess)
      //   console.log(validations);
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  deletePerson() {
    let personId = this.state.personId;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Delete +
      "/" +
      personId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            modalIsOpen: false,
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            personDeleted: true,
            messageExclusion: "",
            errorMessage: "",
          });
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          this.setState({
            modalIsOpen: false,
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            personDeleted: true,
            messageExclusion: "",
            errorMessage: "",
          });
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          this.setState({
            modalIsOpen: false,
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            personDeleted: true,
            messageExclusion: "",
            errorMessage: "",
          });
          return;
        }

        this.setState({
          modalIsOpen: false,
          personLoading: false,
          renderPersonList: true,
          confirmExclusao: false,
          personDeleted: true,
          errorMessage: "",
          messageExclusion: "",
          namePerson: "",
        });
        window.location.href = "/admin/pessoas";
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  handleFile(e) {
    if (!e || !e.target || !e.target.files) {
      console.error(
        'Parametro "e" fora do formato "onchangeEvent" esperado',
        e
      );
      return;
    }

    if (e.target.files.length === 0) {
      this.setState({
        selectedFileContent: null,
        selectedFileName: require("assets/img/brand/maskUpload.png"),
      });
      return;
    }

    let file = e.target.files[0];

    if (!file) {
      console.error("Arquivo invalido", file);
      return;
    }

    let fileExtension = file.name.split(".").pop().toLowerCase();

    if (constants.AllowedExtensions.Document.indexOf(fileExtension) < 0) {
      console.error("Extensão não suportada", fileExtension);
      return;
    }

    let fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.setState({
        selectedPictureFile: file,
        selectedPictureContent: fileReader.result,
      });
    };

    fileReader.readAsDataURL(file);
  }

  startWebcamCanvas() {
    let videoElement = this.refs.videoElement;

    if (!videoElement) {
      console.error('Elemento não encontrado "videoElement"');
      return;
    }

    navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (!navigator.getMedia) {
      console.error("Navegador não suportado");
      return;
    }

    navigator.getMedia(
      { video: true },
      function (mediaStream) {
        videoElement.srcObject = mediaStream;
        videoElement.play();
      },
      function (error) {
        console.error("Falha ao capturar imagem", error);
      }
    );
  }

  endWebcamCanvas() {
    let videoElement = this.refs.videoElement;

    if (!videoElement) {
      console.error('Elemento não encontrado "videoElement"');
      return;
    }

    navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (!navigator.getMedia) {
      console.error("Navegador não suportado");
      return;
    }

    navigator.getMedia(
      { video: true },
      function (mediaStream) {
        videoElement.srcObject = mediaStream;
        mediaStream.getTracks().map(function (track) {
          track.stop();
        });
      },
      function (error) {
        console.error("Falha ao parar captura de imagem", error);
      }
    );
  }

  saveCanvasImage() {
    this.setState({ webCamModal: false });
    let canvasElement = this.refs.canvasElement;
    let videoElement = this.refs.videoElement;

    if (!canvasElement) {
      console.error('Elemento não encontrado "canvasElement"');
      return;
    }

    if (!videoElement) {
      console.error('Elemento não encontrado "videoElement"');
      return;
    }

    let canvasContext = canvasElement.getContext("2d");

    if (!canvasContext) {
      console.error("Contexto do canvas não encontrado");
      return;
    }

    canvasContext.drawImage(videoElement, 0, 0, 400, 300);
    var dataURL = canvasElement.toDataURL("image/png");
    canvasContext.href = dataURL;

    this.setState({ selectedPictureContent: dataURL });
    this.endWebcamCanvas();
  }

  handleState(e) {
    if (!e || !e.target) return;

    if (!e.target.value) {
      this.setState({ cityResponse: null, citySearch: "" });
      return;
    }

    let formData = this.state.formData;
    formData["stateId"] = e.target.value;
    this.setState({ citySearch: "", cityResponse: null, formData: formData });
    this.loadCity(e.target.value);
  }

  handleCEP(e) {
    this.setState({
      formData: Object.assign(this.state.formData, {
        "zipCode": formater.template(e.target.value, "00000-000"),
      }),
    });

    let treatedCEP = formater.number(e.target.value);

    if (treatedCEP.length !== 8) return;

    viaCEP.consulta(treatedCEP).then((result) => {
      if (result.sucess === true) {
        let estado = null;
        let formData = this.state.formData;
        formData["address"] = result.data.logradouro;
        formData["neighborhood"] = result.data.bairro;
        formData["zipCode"] = result.data.cep;
        formData["cityId"] = "";
        formData["stateId"] = "";
        this.setState({ formData: formData });

        if (this.state.stateResponse) {
          estado = this.state.stateResponse.filter((item) => {
            return (
              item.initials.toLocaleLowerCase() ===
              result.data.uf.toLocaleLowerCase()
            );
          });
        }

        if (estado) {
          if (estado.length > 0) {
            formData["stateId"] = estado[0].id;
            this.setState({
              citySearch: result.data.localidade,
              cityResponse: null,
            });
            this.loadCity(estado[0].id);
          }
        }
      }
    });
  }

  loadSituations() {
    this.setState({ situationResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Situation.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ situationResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadProfessions() {
    this.setState({ professionResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Profession.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ professionResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadAccessLevel() {
    this.setState({ accessLevelResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.AccessLevel.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ accessLevelResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadFamilyStatus() {
    this.setState({ familyStatusResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.FamilyStatus.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ familyStatusResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadState() {
    this.setState({ stateResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.State.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ stateResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadCity(stateId) {
    this.setState({ cityResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.City.GetByState +
      "?StateId=" +
      stateId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ cityResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

}

export default Pessoa;
