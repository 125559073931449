//#region Imports

import React from "react";
import constants from "variables/constants";
import axios from 'axios';
// reactstrap components
import DialogConfirm from '../../components/Utils/dialogConfirm.jsx';
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from "reactstrap";
import { Redirect } from "react-router-dom";

//#endregion

class LoginPrimeiroAcesso extends React.Component {

  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial States

    this.state = {
      form: {
        password: '',
        passwordConfirm: '',
        setSuccessConfirm: false,
        erroSenha: false
      },
      openConfirm: false,
      errorMessageLogin: '',
      loadingLogin: false,
      redirectLogin: false,
      error: ''
    }

    //#endregion

    //#region Event Binding

    this.changePassword = this.changePassword.bind(this);
    this.inputsForm = this.inputsForm.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {

    //#endregion
    return (

      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img
                alt="Nossa Senhora do Brasil"
                src={require("assets/img/brand/logo-nossa-senhora-do-brasil-2 1.png")}
              />
            </div>
            <Form role="form" onSubmit={this.changePassword}>
              <Row className="pb-4">
                <Col xs="12" className="text-white text-center">
                  <small className="ft-16">Bem-vindo ao sistema de gestão de paroquianos!</small>
                </Col>
                <Col xs="12" className="text-white text-center">
                  <small className="ft-16">Cadastre sua nova senha:</small>
                </Col>
              </Row>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input className="cor-input" placeholder="Nova senha" type="password" name="password"
                    value={this.state.form.password}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input className="cor-input" placeholder="Confirmar" type="password" name="passwordConfirm"
                    value={this.state.form.passwordConfirm}
                    onChange={this.inputsForm}
                  />
                </InputGroup>

                {(this.state.setSuccessConfirm) ||
                  <DialogConfirm open={this.state.openConfirm}
                    title="Erro!"
                    text={this.state.errorMessageLogin}
                    handleClose={() => { this.setState({ openConfirm: false }) }} />
                }
                {(!this.state.setSuccessConfirm) ||
                  // <DialogConfirm open={this.state.openConfirm}
                  //   title="Success!"
                  //   success="Sim"
                  //   text={this.state.errorMessageLogin}
                  //   handleClose={() => { this.setState({ openConfirm: false }) }} />
                  <Redirect to={"/admin/index"} />
                }
              </FormGroup>
              <div className="text-center">
                <Button className="my-4 botao-laranja" type="submit"
                >
                  Entrar
                  </Button>
              </div>
            </Form>
          </Container>
        </Col>
      </>
    );
  }


  //#region Events

  //#endregion

  //#region Component Events

  //#endregion

  //#region Custom Components

  changePassword(e) {
    const { password, passwordConfirm } = this.state.form;

    if (password && passwordConfirm) {
      if (password === passwordConfirm) {
        const url = constants.Endpoint.UrlBase + constants.Endpoint.Auth.ChangePass;
        const token = window.localStorage.getItem(constants.LocalStorage.Token);
        const auth = constants.Auth.DefaultScheme + ' ' + token;

        this.setState({ loadingLogin: true });

        let data = {
          password: password
        }
        let config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        config.headers[constants.Auth.DefaultHeader] = auth;
        axios.post(url, data, config)
          .then((response) => {
            console.log('aqui 1');
            if (!response || !response.data || !response.data.errors || response.data.success == null) {

              this.setState({ openConfirm: true, errorMessageLogin: 'Resposta fora do padrão esperado', loadingLogin: false });
              return;

            }

            if (!response.data.success) {

              this.setState({ openConfirm: true, errorMessageLogin: response.data.errors.join('.<br/>'), loadingLogin: false });
              console.log(response.data.errors);
              return;
            }

            if (response.data.response == null) {

              console.log("Resposta fora do padrão esperado");
              this.setState({ openConfirm: true, errorMessageLogin: 'Resposta fora do padrão esperado', loadingLogin: false });
              return;
            }

            if (response.data.response.firstAccess) {
              this.setState({ redirectFirstAccess: true })
            }
            this.setState({ openConfirm: true, setSuccessConfirm: true, errorMessageLogin: 'Senha alterada com sucesso!', loadingLogin: false, redirectLogin: true });
          })

          .catch((error) => {
            this.setState({ renderPersonList: false, errorMessage: 'Erro ao tentar consultar dados' });
          });
      }
      else {
        this.setState({ openConfirm: true, setSuccessConfirm: false, errorMessageLogin: 'As duas senhas não coincidem!', loadingLogin: false, redirectLogin: false })
      }
    }
    else {
      this.setState({ openConfirm: true, setSuccessConfirm: false, errorMessageLogin: 'Ambos campos devem ser preenchidos!', loadingLogin: false, redirectLogin: false })
    }
    e.preventDefault();
  }

  inputsForm(e) {
    let form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form: form });
  }

  //#endregion
}

export default LoginPrimeiroAcesso; 
