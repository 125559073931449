//#region Imports

import Modal from 'react-modal';
import React from "react";
import Wizard from "components/Utils/Wizard";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import Loading from 'components/Utils/Loading.jsx';
import { formater } from 'components/Utils/formater';
import { diruma } from 'components/Utils/diruma';
import { dateHelper } from 'components/Utils/dateHelper';
import { Link } from 'react-router-dom';
import DataList from "components/Utils/dataList.jsx";
import {
    Container,
    Row,
    Col,
} from "reactstrap";


//#endregion




class Atividades extends React.Component {

    //#region Constructor

    constructor(props) {

        super(props);

        //#region Initial State

        this.state = {

            errorMessage: '',
            eventId: null,
            formData: {},
            activityResponse: [],
            activityBulkDelete: [],
            activityEditingIndex: null,
            message: {

                visible: false,
                leftButtomText: "Ok",
                rightButtomText: null,
                text: 'teste',
                title: '',
                closeCallBack: () => { },
                leftButtomClickCallBack: () => { },
                rightButtomClickCallBack: () => { },
                _defaultCloseAction: (_this) => {

                    if (!_this)
                        return;

                    if (typeof _this !== 'object')
                        return;

                    if (!_this.state || !_this.setState)
                        return;

                    if (typeof _this.state !== 'object' || typeof _this.setState !== 'function')
                        return;

                    if (_this.state.message.closeCallBack) {

                        if (typeof _this.state.closeCallBack === 'function')
                            _this.state.message.closeCallBack();

                    }

                    _this.setState((state) => ({ message: { ...state.message, ...{ visible: false, text: '', title: '', closeCallBack: null } } }));

                }

            }

        }

        //#endregion 

        //#region Validations 

        this.validation = {

            refs: () => { return this.refs },

            elements: [

                { ref: 'date', validations: [diruma.validations.date, diruma.validations.required], customValidations: [] },
                { ref: 'description', validations: [diruma.validations.required], customValidations: [] }

            ]

        };

        //#endregion

        //#region Activity Binding

        this.onInputChange = this.onInputChange.bind(this);
        this.deleteActivity = this.deleteActivity.bind(this);
        this.saveActivity = this.saveActivity.bind(this);
        this.editActivity = this.editActivity.bind(this);
        this.cancelActivityEditing = this.cancelActivityEditing.bind(this);
        this.loadWizard = this.loadWizard.bind(this);
        this.activityGet = this.activityGet.bind(this);

        //#endregion

    }


    render() {

        let wizzardConfiguration = Wizard({ getConfiguration: true });
        let wizardNodeConfiguration = [];

        wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Tipo do <br /> evento</>, '/admin/Evento/tipoEvento/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), wizzardConfiguration.Status.Completed));
        wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Informações <br /> do evento</>, '/admin/Evento/Casamento/infoCasamento/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), wizzardConfiguration.Status.Completed));


        if (this.state.wizardResponse) {

            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Data do evento</>, '/admin/Evento/Casamento/data/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasDate ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Documentos</>, '/admin/Evento/documentos/?id=' + this.state.eventId, (this.state.wizardResponse.hasDocument ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Testemunhas</>, '/admin/Evento/Casamento/testemunhas/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasWitness ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Cerimonial</>, '/admin/Evento/Casamento/cerimonial/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasCeremony ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Engajamento</>, '/admin/Evento/Casamento/engajamento/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasAttendance && this.state.wizardResponse.hasChart ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Atividades</>, '/admin/Evento/Casamento/atividades/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (wizzardConfiguration.Status.Active)));

        } else {

            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Data do evento</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Documentos</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Testemunhas</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Cerimonial</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Engajamento</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Atividades</>, '', wizzardConfiguration.Status.Disabled));

        }

        let activityTableCollumns = [];
        let activityTableData = [];

        if (this.state.activityResponse.length) {

            activityTableCollumns = [
                { name: 'Atividade', size: 40, property: 'description' },
                { name: 'Data', size: 20, property: 'date' },
                { name: '', size: 5, property: 'editarButton' },
                { name: '', size: 5, property: 'excluirButton' }
            ];

            this.state.activityResponse.forEach((item, index) => {

                let deletedItem = !!this.state.activityBulkDelete.find((search) => (item.id === search))

                if (!deletedItem) {

                    let day = new Date(item.date).getDate().toString();
                    let month = (new Date(item.date).getMonth() + 1).toString();
                    let year = new Date(item.date).getFullYear().toString();

                    activityTableData.push({
                        ...item, ...
                        {
                            editarButton: <button type="button" className="btn btn-sm edit" key={index} onClick={() => this.editActivity(index)}>Editar</button>,
                            excluirButton: <button type="button" className="btn btn-sm exc" key={index} onClick={() => this.showDeleteActivityConfirm(index)}>Excluir</button>,
                            description: item.description,
                            date: (
                                (
                                    (day.length === 1 ? "0" + day : day) + "/" +
                                    (month.length === 1 ? "0" + month : month) + "/" +
                                    (year.length === 1 ? "0" + year : year)
                                )
                            ),
                        }

                    });

                }

            });

        }

        return (
            <>

                {/* //#region Modal */}

                <>

                    <Modal
                        isOpen={this.state.message.visible}
                        onRequestClose={() => { this.state.message._defaultCloseAction(this); }}
                        style={constants.Modal.DefaultStyle}
                        contentLabel={this.state.message.title}
                    >
                        <span className="float-lg-right x" onClick={() => { this.state.message._defaultCloseAction(this); }}>X</span>
                        <h2 className="text-dark text-center pt-4 pb-4 text-modal">{this.state.message.text}</h2>
                        <div className="text-center">
                            {
                                !this.state.message.leftButtomText ||

                                <button className="btn btn-group button-no" onClick={() => { this.state.message._defaultCloseAction(this); this.state.message.leftButtomClickCallBack(); }}>{this.state.message.leftButtomText}</button>
                            }
                            {
                                !this.state.message.rightButtomText ||

                                <button className="btn btn-group button-no" onClick={() => { this.state.message._defaultCloseAction(this); this.state.message.rightButtomClickCallBack(); }}>{this.state.message.rightButtomText}</button>
                            }
                        </div>
                    </Modal>

                </>

                {/* //#endregion Modal */}

                <Container fluid className="pb-7">
                    <Row className="pt-4 pl-5">
                        <Col lg="12">
                            <div>
                                <h1 className="text-dark text-person">Novo casamento</h1>
                                <img className="rectangle-orange"
                                    alt="..."
                                    src={require("assets/img/brand/home/Rectangle.png")}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Wizard nodes={wizardNodeConfiguration} />
                </Container>

                <div className="container-fluid minHeightFooter mt-2">
                    <Row className="pl-4">
                        <Col>
                            <div className="pt-6">
                                <h2 className="text-dark mb-5 font-weight-900">Atividades</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row lg="12" className="pl-47">
                        <Col lg="9">
                            <div className="form-group">
                                <label className="text-family font-weight-900 pb-1 mt-2">Atividade<small className="text-gray"><i> - obrigatório</i></small></label>
                                <input type="name" className="form-control text-dark font-weight-900 mt--2" ref="description" id="description" name="description"  value={this.state.formData["description"] ? this.state.formData["description"] : ""} onChange={this.onInputChange} />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div class="form-group">
                                <label for="date" class="form-control-label text-dark font-weight-900">Data<small className="text-gray"><i> - obrigatório</i></small></label>
                                <input class="form-control text-dark" id="date" name="date" ref="date" value={(this.state.formData['date'] ? this.state.formData['date'] : "")} onChange={this.onInputChange} />
                            </div>
                        </Col>

                    </Row>
                    <div className="container-fluid mb-6 mt-6">
                        <Row>
                            <Col>
                                <div className="float-lg-right">
                                    {(this.state.activityEditingIndex === null) ||
                                        <button type="button" className="btn-info-person-cancel mr-2" onClick={() => { this.cancelActivityEditing(); }}>
                                            Cancelar Edição
                                        </button>
                                    }
                                    <button type="button" className="btn-person-search ml-4" onClick={() => { this.saveActivity(); }}>
                                        {this.state.activityEditingIndex === null ? 'Adicionar' : 'Editar'}
                                    </button>

                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row className="pt-4 pl-5">

                        <Col lg="12">
                            {(this.state.activityResponse.length > 0) ?

                                <>

                                    <DataList tableCollumns={activityTableCollumns} tableData={activityTableData} paging={false} />

                                </>
                                :
                                <> </>

                            }
                        </Col>
                    </Row>
                    <div className="container-fluid mb-6 mt-6">
                        <Row>
                            <Col>
                                <div className="float-lg-right">

                                    <Link to={"/admin/Eventos"}><button type="button" className="btn-info-person-cancel">Cancelar</button></Link>

                                    <button type="button" className="btn-info-person ml-4" onClick={() => { this.activityBulk(); }}>
                                        {this.state.submitLoading ?
                                            <Loading load="Sim" /> :
                                            'Salvar e concluir'
                                        }
                                    </button>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                
            </>
        );
    }

    //#region Component Events

    componentWillMount() {

        let urlString = window.location.href;
        let url = new URL(urlString);
        let id = url.searchParams.get("id");

        if (id)
            this.setState({ eventId: id });

    }

    componentDidMount() {

        this.activityGet();
        this.loadWizard();

    }

    //#endregion

    //#region Custom Events

    loadWizard() {

        const { eventId } = this.state;
        let id = parseInt(eventId);
        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
        let data = { eventId: id };

        smoothApi.post(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {

                    console.error(messages.Errors.UnexpectedFormat, result);
                    return;

                }

                if (!result.success) {

                    console.log(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {

                    console.log(messages.Errors.BlankResponse, result.errors);
                    return;

                }

                this.setState({ wizardResponse: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

            }

        );

    }

    activityBulk() {

        if (this.state.activityBulkDelete.length === 0 && this.state.activityResponse.length === 0)
            window.location.href = "/admin/Eventos";
        //let formDataApi = this.state.formData;

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.EventActivity.Bulk;
        let data = { toSave: this.state.activityResponse, toDelete: this.state.activityBulkDelete };


        smoothApi.post(url, data, token,

            (result) => {


                if (!result || !result.errors || !result.response || result.success == null) {

                    console.error(messages.Errors.UnexpectedFormat, result);
                    this.setState((state) => ({
                        message: {
                            ...state.message, ...{
                                title: 'Aviso',
                                visible: true,
                                text: <>
                                    Ocorreu um erro durante o registro, por favor verifique a <span className="errorText"> mensagem </span> e tente novamente:

                                    <ul className="errorSumary">
                                        {

                                            result.errors.map((el, currentIndex) => {

                                                return <li key={"validationItem_" + currentIndex} >{el + "."}</li>

                                            })

                                        }
                                    </ul>
                                </>

                            }
                        },
                        submitLoading: false,
                        changeLoading: false
                    }));

                    return;

                }

                if (!result.success) {

                    console.error(messages.Errors.ResponseError, result.errors);

                    this.setState((state) => ({
                        message: {
                            ...state.message, ...{
                                title: 'Aviso',
                                visible: true,
                                closeCallBack: () => { },
                                leftButtomClickCallBack: () => { },
                                rightButtomClickCallBack: () => { },
                                text: <>
                                    Ocorreu um erro durante o registro, por favor verifique a <span className="errorText"> mensagem </span> e tente novamente:

                                    <ul className="errorSumary">
                                        {

                                            result.errors.map((el, currentIndex) => {

                                                return <li key={"validationItem_" + currentIndex} >{el + "."}</li>

                                            })

                                        }
                                    </ul>
                                </>

                            }
                        },
                        submitLoading: false,
                        changeLoading: false
                    }));

                    return;

                }

                if (result.response == null) {
                    this.setState({ errorMessage: messages.Errors.BlankResponse, submitLoading: false, changeLoading: false });
                    console.error(messages.Errors.BlankResponse);
                    return;

                }

                window.location.href = "/admin/Eventos";

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

                this.setState({ submitLoading: false, changeLoading: false, errorMessage: messages.Errors.HttpError });


            }

        );

    }

    cancelActivityEditing() {

        this.setState({ formData: {}, activityEditingIndex: null });

    }

    showDeleteActivityConfirm(index) {

        this.setState((state) => ({
            message: {
                ...state.message, ...{
                    leftButtomText: "Sim",
                    rightButtomText: "Não",
                    title: 'Aviso',
                    visible: true,
                    closeCallBack: () => { },
                    leftButtomClickCallBack: () => { this.deleteActivity(index); },
                    rightButtomClickCallBack: () => { alert('teste'); },
                    text: <>
                        Deseja realmente <span className="errorText"> excluir </span> esse registro?
                    </>
                }
            }
        }));

    }

    deleteActivity(index) {

        let activityResponse = this.state.activityResponse;
        let activityBulkDelete = this.state.activityBulkDelete;

        if (!this.state.activityResponse[index]) {

            console.error("Falha ao encontrar indice de exclusão");
            return;

        }

        let removed = activityResponse.splice(index, 1)[0];

        if (removed.id)
            activityBulkDelete.push(removed.id);

        if (this.state.activityEditingIndex === null) {

            this.setState({ activityResponse: activityResponse, activityBulkDelete: activityBulkDelete });

        } else {

            if (this.state.activityEditingIndex === index) {

                this.setState({ activityResponse: activityResponse, activityEditingIndex: null, activityBulkDelete: activityBulkDelete });

            } else {

                if (this.state.activityEditingIndex > index)
                    this.setState({ activityResponse: activityResponse, activityEditingIndex: this.state.activityEditingIndex - 1, activityBulkDelete: activityBulkDelete });
                else
                    this.setState({ activityResponse: activityResponse, activityBulkDelete: activityBulkDelete });
            }

        }

    }

    editActivity(index) {

        let formData = this.state.formData;
        let editItem = this.state.activityResponse[index];

        if (!editItem) {

            console.error("Falha ao encontrar indice de edição");
            return;

        }

        let date = dateHelper.formatToBrazilian(editItem.date); //new Date(editItem.date.split("/")[1] + '/' + editItem.date.split("/")[0] + '/' + editItem.date.split("/")[2]);
        let description = editItem.description;
        this.setState({ activityEditingIndex: index, formData: { date: date, description: description } });

    }

    saveActivity() {

        let validations = diruma.validadeOnChange(this.validation, this.state.formData, null, true);

        if (!validations.sucess) {

            this.setState((state) => ({
                message: {
                    ...state.message, ...{
                        leftButtomText: "Ok",
                        rightButtomText: null,
                        title: 'Aviso',
                        visible: true,
                        closeCallBack: () => { },
                        leftButtomClickCallBack: () => { },
                        rightButtomClickCallBack: () => { },
                        text: <>
                            Por favor verifique os campos destacados em <span className="errorText"> vermelho </span> para prosseguir:

                            <ul className="errorSumary">
                                {

                                    validations.errors.map((el, currentIndex) => {

                                        return <li key={"validationItem_" + currentIndex} >{el}</li>

                                    })

                                }
                            </ul>
                        </>

                    }
                }
            }));

            return;

        }

        let formData = this.state.formData;
        let activityResponse = this.state.activityResponse;
        let date = new Date(formData.date.split("/")[1] + '/' + formData.date.split("/")[0] + '/' + formData.date.split("/")[2]);
        let description = formData.description;
        let eventId = this.state.eventId;

        if (this.state.activityEditingIndex === null) {

            activityResponse.push({ date: date, description: description, eventId: eventId })

        } else {

            let index = this.state.activityEditingIndex;

            if (!this.state.activityResponse[index]) {

                console.error("Falha ao encontrar indice de edição");
                return;

            }

            activityResponse[index].date = date;
            activityResponse[index].description = description;

        }

        this.setState({ activityResponse: activityResponse, activityEditingIndex: null, formData: {} });

    }

    onInputChange(event) {

        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        if (target.type === 'checkbox') {

            value = target.checked;

        } else {

            switch (name) {

                case 'date':
                    value = formater.template(target.value, '00/00/0000');
                    break;

                case 'description':
                    value = formater.maxLength(target.value, 200);
                    break;

                default:
                    value = target.value;

            }

        }

        this.setState({

            formData: Object.assign(this.state.formData, { [name]: value })

        });

        diruma.validadeOnChange(this.validation, this.state.formData, target, false);

    }

    activityGet() {

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.EventActivity.GetByEvent + "?eventId=" + this.state.eventId;

        smoothApi.get(url, null, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null)
                    return;

                if (!result.success) {

                    console.log(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {

                    console.log(messages.Errors.BlankResponse, result.errors);
                    return;

                }

                this.setState({ activityResponse: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

            }

        );

    }

}

export default Atividades;
