let defaultBrazilian = '';
let defaultDays = null;
let defaultMonths = null;
let defaultYears = null;

export const dateHelper = {
	
	formatToBrazilian: (date) => {
		
        if (!date) {

            return defaultBrazilian;

        }

        let parsedDate = new Date(date);

        if (isNaN(parsedDate.getTime())) {

            return defaultBrazilian;

        }
       
        return parsedDate.getDate().toString().padStart(2, '0') + "/" +  (parsedDate.getMonth() + 1).toString().padStart(2, '0') + "/" + parsedDate.getFullYear();
		
	},

    addDays: (date, days) => {

        if (!date || days === null) {

            return defaultDays;

        }

        let parsedDays = parseInt(days);

        if (isNaN(parsedDays)) {

            return defaultDays;

        }

        let parsedDate = new Date(date);

        if (isNaN(parsedDate.getTime())) {

            return defaultDays;

        }

        parsedDate.setDate(parsedDate.getDate() + parsedDays);
        return parsedDate;

    },

    addMonths: (date, months) =>  {

        if (!date || !months) {

            return defaultMonths;

        }

        let parsedMonths = parseInt(months);

        if (isNaN(parsedMonths)) {

            return defaultMonths;

        }

        let parsedDate = new Date(date);

        if (isNaN(parsedDate.getTime())) {

            return defaultMonths;

        }

        parsedDate.setMonth(parsedDate.getMonth() + parsedMonths);
        return parsedDate;

    },

    addYears: (date, years) =>  {

        if (!date || !years) {

            return defaultYears;

        }

        let parsedYears = parseInt(years);

        if (isNaN(parsedYears)) {

            return defaultYears;

        }

        let parsedDate = new Date(date);

        if (isNaN(parsedDate.getTime())) {

            return defaultYears;

        }

        parsedDate.setFullYear(parsedDate.getFullYear() + parsedYears);
        return parsedDate;

    }

}