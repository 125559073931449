import React from "react";
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import { formater } from "components/Utils/formater";
import "assets/scss/pages/print.scss";
import { Container, Row, Col } from "reactstrap";

class EmitirRecibo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisibleDeleteMsg: false,
      paymentId: 0,
      formData: {},
      paymentResponse: null,
      renderPaymentList: false,
    };
  }

  render() {
    let tableCollumns = [];
    let tableData = [];

    let tableCollumnsSecond = [];
    let tableDataSecond = [];

    if (this.state.paymentResponse) {
      tableCollumns = [
        { name: "Nome do pagante", size: 35, property: "payerName" },

        { name: "Valor", size: 20, property: "amountReal" },
        { name: "Forma de pagamento", size: 20, property: "paymentMethod" },
        { name: "Descrição", size: 20, property: "description" },
        { name: "N° do documento", size: 20, property: "documentNumber" },
      ];

      tableData = this.state.paymentResponse;

      tableData = tableData.map((item, index) => {
        let paymentMethod = "";
        let treatedAmount = item.amount.toFixed(2);

        if (item.paymentMethodId) {
          if (parseInt(item.paymentMethodId) === 1) paymentMethod = "Dinheiro";

          if (parseInt(item.paymentMethodId) === 2) paymentMethod = "Cheque";

          if (parseInt(item.paymentMethodId) === 3) paymentMethod = "Boleto";

          if (parseInt(item.paymentMethodId) === 4)
            paymentMethod = "Cartão - Débito";

          if (parseInt(item.paymentMethodId) === 5)
            paymentMethod = "Cartão - Crédito";

          if (parseInt(item.paymentMethodId) === 6)
            paymentMethod = "Transferência bancária";
        }

        if (treatedAmount.indexOf("R$") !== -1)
          treatedAmount = formater.numberMonetary(treatedAmount);

        if (
          treatedAmount.length === 1 ||
          treatedAmount.length === 2 ||
          treatedAmount.length > 4
        ) {
          treatedAmount = parseFloat(treatedAmount);
          treatedAmount = treatedAmount.toFixed(2);
        }

        let amount = formater.monetaryBRL(treatedAmount + "", true);

        return {
          ...item,
          ...{
            payerName:
              item.payerName || (item.payer !== null && item.payer.name),

            amountReal: amount,
            paymentMethod: paymentMethod,
          },
        };
      });
    }

    if (this.state.paymentResponse) {
      tableCollumnsSecond = [
        { name: "Nome do pagante", size: 35, property: "payerName" },
        { name: "Valor", size: 20, property: "amountReal" },
        { name: "Forma de pagamento", size: 20, property: "paymentMethod" },
        { name: "Descrição", size: 20, property: "description" },
        { name: "N° do documento", size: 20, property: "documentNumber" },
      ];

      tableDataSecond = this.state.paymentResponse;

      tableDataSecond = tableDataSecond.map((item, index) => {
        let paymentMethod = "";
        let treatedAmount = item.amount.toFixed(2);

        if (item.paymentMethodId) {
          if (parseInt(item.paymentMethodId) === 1) paymentMethod = "Dinheiro";

          if (parseInt(item.paymentMethodId) === 2) paymentMethod = "Cheque";

          if (parseInt(item.paymentMethodId) === 3) paymentMethod = "Boleto";

          if (parseInt(item.paymentMethodId) === 4)
            paymentMethod = "Cartão - Débito";

          if (parseInt(item.paymentMethodId) === 5)
            paymentMethod = "Cartão - Crédito";

          if (parseInt(item.paymentMethodId) === 6)
            paymentMethod = "Transferência bancária";
        }

        if (treatedAmount.indexOf("R$") !== -1)
          treatedAmount = formater.numberMonetary(treatedAmount);

        if (
          treatedAmount.length === 1 ||
          treatedAmount.length === 2 ||
          treatedAmount.length > 4
        ) {
          treatedAmount = parseFloat(treatedAmount);
          treatedAmount = treatedAmount.toFixed(2);
        }

        let amount = formater.monetaryBRL(treatedAmount + "", true);

        return {
          ...item,
          ...{
            payerName:
              item.payerName || (item.payer !== null && item.payer.name),
            amountReal: amount,
            paymentMethod: paymentMethod,
          },
        };
      });
    }

    //#endregion

    return (
      <>
        <div size="A4">
          {/* ----------------------------------------- Page 1 ----------------------------------------- */}

          <Row lg="12" sm="12">
            <div>
              <img
                alt="Nossa Senhora do Brasil"
                src={require("assets/img/brand/logo-print.png")}
              />
            </div>
            <div>
              <h1 className="text-dark">Paróquia Nossa Senhora do Brasil</h1>
              <h2 className="text-family">CNPJ 63.089.825/0120-70</h2>
              <h3 className="text-family nsb-endereco">
                Praça Nossa Senhora do Brasil, 1 - Avenida Brasil, esquina com
                Rua Colômbia - Jd. América <br />{" "}
                www.nossasenhoradobrasil.com.br - T: (11) 3082 9786 - CEP
                01438-060 - São Paulo, SP
              </h3>
            </div>
            <h1 className="text-dark ml-auto">
              Recibo n° {this.state.formData["id"]}
            </h1>
          </Row>

          <Row lg="12" className="justify-content-center mt-6"></Row>
          <Container className="ml-2">
            <Row className="ml-1">
              <table>
                <tbody>
                  <tr className="h-3">
                    <td colSpan="1" className="w-20">
                      <span className="text-family ">
                        Recebi de &nbsp;
                        <b>
                          {!this.state.formData["payerName"] ||
                            this.state.formData["payerName"]}
                        </b>
                      </span>
                    </td>

                    <td className="w-20">
                      <span className="text-family">
                        Horário do evento &nbsp;
                        <b>
                          {!this.state.formData["eventTime"] ||
                            this.state.formData["eventTime"]}
                        </b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-30">
                      <span className="text-family">
                        Referente a &nbsp;
                        <b>
                          {!this.state.formData["incomeTypeName"] ||
                            this.state.formData["incomeTypeName"]}
                        </b>
                      </span>
                    </td>

                    <td className="w-20">
                      <span className="text-family">
                        Data do evento &nbsp;
                        <b>
                          {!this.state.formData["eventDate"] ||
                            this.state.formData["eventDate"]}
                        </b>
                      </span>
                    </td>
                  </tr>
                  <tr className="h-3">
                    <td className="w-20">
                      <span className="text-family">
                        A importância de &nbsp;
                        <b>
                          {!this.state.formData["valorTotal"] ||
                            this.state.formData["valorTotal"]}
                        </b>
                      </span>
                    </td>

                    <td className="w-30">
                      <span className="text-family">
                        Do evento de &nbsp;
                        <b>
                          {!this.state.formData["eventName"] ||
                            this.state.formData["eventName"]}
                        </b>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
            <Row className="pt-6">
              <Col lg="12">
                {!this.state.renderPaymentList || (
                  <DataList
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    isPrint={true}
                  />
                )}
              </Col>
            </Row>
          </Container>

          <Row lg="12" className=" mt-8">
            <span className="text-family">
              São Paulo, {this.state.formData["paymentDay"]} de{" "}
              {this.state.formData["paymentMonth"]} de{" "}
              {this.state.formData["paymentYear"]}
            </span>
          </Row>
          <Row lg="12" className="justify-content-center mt--6">
            <div className="separator">
              <hr />
            </div>
          </Row>
          <Row lg="12" className="justify-content-center mt-6 ml-20">
            <span className="text-family">
              {!this.state.formData["registerPersonName"] ||
                this.state.formData["registerPersonName"]}
            </span>
          </Row>

          <Row lg="12" className="justify-content-center mt-4">
            <div className="separatorPage">
              <hr />
            </div>
          </Row>

          {/* ----------------------------------------- Page 2 ----------------------------------------- */}

          <Row lg="12" sm="12" className="mt-4">
            <div>
              <img
                alt="Nossa Senhora do Brasil"
                src={require("assets/img/brand/logo-print.png")}
              />
            </div>
            <div>
              <h1 className="text-dark">Paróquia Nossa Senhora do Brasil</h1>
              <h2 className="text-family">CNPJ 63.089.825/0120-70</h2>
              <h3 className="text-family nsb-endereco">
                Praça Nossa Senhora do Brasil, 1 - Avenida Brasil, esquina com
                Rua Colômbia - Jd. América <br />{" "}
                www.nossasenhoradobrasil.com.br - T: (11) 3082 9786 - CEP
                01438-060 - São Paulo, SP
              </h3>
            </div>
            <h1 className="text-dark ml-auto">
              Recibo n° {this.state.formData["id"]}
            </h1>
          </Row>

          <Row lg="12" className="justify-content-center mt-6"></Row>
          <Container className="ml-2">
            <Row className="ml-1">
              <table>
                <tbody>
                  <tr className="h-3">
                    <td colSpan="3" className="w-20">
                      <span className="text-family">
                        Recebi de &nbsp;
                        <b>
                          {!this.state.formData["payerName"] ||
                            this.state.formData["payerName"]}
                        </b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-30">
                      <span className="text-family">
                        Referente a &nbsp;
                        <b>
                          {!this.state.formData["incomeTypeName"] ||
                            this.state.formData["incomeTypeName"]}
                        </b>
                      </span>
                    </td>

                    <td className="w-20">
                      <span className="text-family">
                        Data do evento &nbsp;
                        <b>
                          {!this.state.formData["eventDate"] ||
                            this.state.formData["eventDate"]}
                        </b>
                      </span>
                    </td>
                  </tr>
                  <tr className="h-3">
                    <td className="w-20">
                      <span className="text-family">
                        A importância de &nbsp;
                        <b>
                          {!this.state.formData["valorTotal"] ||
                            this.state.formData["valorTotal"]}
                        </b>
                      </span>
                    </td>

                    <td className="w-30">
                      <span className="text-family">
                        Do evento de &nbsp;
                        <b>
                          {!this.state.formData["eventName"] ||
                            this.state.formData["eventName"]}
                        </b>
                      </span>
                    </td>

                    <td className="w-30">
                      <span className="text-family">
                        Horário do evento &nbsp;
                        <b>
                          {!this.state.formData["eventTime"] ||
                            this.state.formData["eventTime"]}
                        </b>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
            <Row className="pt-6">
              <Col lg="12">
                {!this.state.renderPaymentList || (
                  <DataList
                    tableCollumns={tableCollumnsSecond}
                    tableData={tableDataSecond}
                    isPrint={true}
                  />
                )}
              </Col>
            </Row>
          </Container>

          <Row lg="12" className=" mt-8">
            <span className="text-family">
              São Paulo, {this.state.formData["paymentDay"]} de{" "}
              {this.state.formData["paymentMonth"]} de{" "}
              {this.state.formData["paymentYear"]}
            </span>
          </Row>
          <Row lg="12" className="justify-content-center mt--6">
            <div className="separator">
              <hr />
            </div>
          </Row>
          <Row lg="12" className="justify-content-center mt-6 ml-20">
            <span className="text-family">
              {!this.state.formData["registerPersonName"] ||
                this.state.formData["registerPersonName"]}
            </span>
          </Row>

          <Row lg="12" className="justify-content-center mt-8 mb-4 button">
            <a
              className="btn button-print float-lg-right text-white mt-2"
              href="javascript:window.print()"
            >
              Imprimir recibo
            </a>
          </Row>
        </div>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ paymentId: id });
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Payment.Get +
      "/" +
      this.state.paymentId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let ret = result.response;
        if (ret.paymentAmounts.length) {
          const listPaymentAmounts = ret.paymentAmounts.map((item) => {
            return {
              ...item,
              name: item.payerName || (item.payer !== null && item.payer.name),
            };
          });

          ret.listPaymentAmounts = this.nonDuplicated(listPaymentAmounts);

          if (ret.listPaymentAmounts.length === 1) {
            ret.payerName = ret.listPaymentAmounts[0].name;
          }

          if (ret.listPaymentAmounts.length === 2) {
            let firstName = ret.listPaymentAmounts[0].name;

            let secondName = ret.listPaymentAmounts[1].name;

            ret.payerName = firstName + " e " + secondName;
          }
        }

        if (ret.paymentAmounts.length > 0) {
          let valorTotal = ret.paymentAmounts.reduce(
            (total, valor) => total + valor.amount,
            0
          );
          valorTotal = valorTotal.toFixed(2);
          ret.valorTotal = formater.monetaryBRL(valorTotal + "", true);
        }

        if (ret.paymentAmounts)
          if (ret.paymentAmounts.length > 0) {
            this.setState({
              paymentResponse: ret.paymentAmounts,
              renderPaymentList: true,
            });
          }

        if (ret.incomeType)
          if (ret.incomeType.name) ret.incomeTypeName = ret.incomeType.name;

        if (ret.event) {
          if (ret.event.eventBaptisms.length > 0) {
            if (ret.event.eventBaptisms[0].baptizedName)
              ret.eventName = ret.event.eventBaptisms[0].baptizedName;
          }

          if (ret.event.eventMasses.length > 0) {
            if (ret.event.eventMasses[0].massIntention)
              ret.eventName = ret.event.eventMasses[0].massIntention;
          }

          if (ret.event.eventWeddings.length > 0) {
            if (
              ret.event.eventWeddings[0].groomName !== null &&
              ret.event.eventWeddings[0].brideName !== null
            ) {
              ret.eventName =
                "Casamento " +
                ret.event.eventWeddings[0].groomName +
                " e " +
                ret.event.eventWeddings[0].brideName;
            }

            if (
              ret.event.eventWeddings[0].groom !== null &&
              ret.event.eventWeddings[0].bride !== null
            ) {
              ret.eventName =
                "Casamento " +
                ret.event.eventWeddings[0].groom.name +
                " e " +
                ret.event.eventWeddings[0].bride.name;
            }
          }

          let eventDay = new Date(ret.event.eventReservations[0].eventDate)
            .getDate()
            .toString();
          let eventMonth = (
            new Date(ret.event.eventReservations[0].eventDate).getMonth() + 1
          ).toString();
          let eventYear = new Date(ret.event.eventReservations[0].eventDate)
            .getFullYear()
            .toString();

          ret.eventDate =
            (eventDay.length === 1 ? "0" + eventDay : eventDay) +
            "/" +
            (eventMonth.length === 1 ? "0" + eventMonth : eventMonth) +
            "/" +
            (eventYear.length === 1 ? "0" + eventYear : eventYear);
          let eventStartTime =
            ret.event.eventReservations[0].eventStartTime.substring(0, 5);
          let eventEndTime =
            ret.event.eventReservations[0].eventEndTime.substring(0, 5);

          ret.eventTime = eventStartTime + " às " + eventEndTime;
        } else ret.eventName = "";

        if (ret.registerPerson)
          if (ret.registerPerson.name)
            ret.registerPersonName = ret.registerPerson.name;

        let months = [
          "janeiro",
          "fevereiro",
          "março",
          "abril",
          "maio",
          "junho",
          "julho",
          "agosto",
          "setembro",
          "outubro",
          "novembro",
          "dezembro",
        ];

        let paymentDay = new Date(ret.paymentDate).getDate().toString();
        let paymentMonth = (
          new Date(ret.paymentDate).getMonth() + 1
        ).toString();
        let paymentYear = new Date(ret.paymentDate).getFullYear().toString();

        ret.paymentDate =
          (paymentMonth.length === 1 ? "0" + paymentMonth : paymentMonth) +
          "/" +
          (paymentDay.length === 1 ? "0" + paymentDay : paymentDay) +
          "/" +
          (paymentYear.length === 1 ? "0" + paymentYear : paymentYear);

        let newData = new Date(ret.paymentDate);

        ret.paymentDay =
          paymentDay.length === 1 ? "0" + paymentDay : paymentDay;
        ret.paymentMonth = months[newData.getMonth()];
        ret.paymentYear =
          paymentYear.length === 1 ? "0" + paymentYear : paymentYear;

        let formData = { ...this.state.formData, ...ret };

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  nonDuplicated(paymentList = []) {
    paymentList = paymentList.map((item) => {
      return {
        ...item,
        parishioner: !!item.payer,
      };
    });

    let nonDuplicatePayment = [];

    paymentList.forEach((item, index) => {
      const filteredPayment = nonDuplicatePayment.filter((payment) => {
        return (
          payment.name === paymentList[index].name &&
          payment.parishioner === paymentList[index].parishioner
        );
      });

      if (!filteredPayment.length) {
        nonDuplicatePayment.push(paymentList[index]);
      }
    });

    return nonDuplicatePayment;
  }

  //#endregion

  //#region Custom Events

  //#endregion
}

export default EmitirRecibo;
