//#region Imports

import Modal from "react-modal";
import React from "react";
import Wizard from "components/Utils/Wizard";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import { objectToFormData } from "object-to-formdata";
import Loading from "components/Utils/Loading.jsx";
import { formater } from "components/Utils/formater";
import { diruma } from "components/Utils/diruma";
import Dating from "components/Utils/Dating.jsx";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//#endregion

class DataCasamento extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarSelectedYear: new Date().getFullYear(),
      calendarSelectedMonth: new Date().getMonth() + 1,
      calendarSelectedDay: new Date().getDate(),

      formData: {},
      eventId: 0,

      loadMonthEventsResponse: null,
      wizardResponse: null,
      eventStatusResponse: null,
      hasEventLocation: null,

      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    // this.loadDataList = this.loadDataList.bind(this);

    //#region Validations

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "eventDate",
          validations: [diruma.validations.required, diruma.validations.date],
          customValidations: [],
        },
        {
          ref: "eventDate",
          validations: [diruma.validations.date],
          customValidations: [],
        },
        {
          ref: "eventStartTime",
          validations: [
            diruma.validations.required,
            diruma.validations.time24Hour,
          ],
          customValidations: [],
        },
        {
          ref: "locationId",
          validations: [
            diruma.validations.required,
          ],
          customValidations: [],
        },
        {
          ref: "eventEndTime",
          validations: [
            diruma.validations.required,
            diruma.validations.time24Hour,
          ],
          customValidations: [],
        },
        {
          ref: "eventStatusId",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "reservationValidUntilDate",
          validations: [diruma.validations.date, diruma.validations.date],
          customValidations: [],
        },
      ],
    };

    this.loadWizard = this.loadWizard.bind(this);
    //this.clearFormState = this.clearFormState.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadMonthEvents = this.loadMonthEvents.bind(this);
    this.dateClickHandler = this.dateClickHandler.bind(this);
    this.dateTypingHandler = this.dateTypingHandler.bind(this);
    this.navigationClickHandler = this.navigationClickHandler.bind(this);
  }

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Tipo do <br /> evento
        </>,
        "/admin/Evento/tipoEvento/" +
        (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Completed
      )
    );
    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Informações <br /> do evento
        </>,
        "/admin/Evento/Casamento/infoCasamento/" +
        (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Completed
      )
    );

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "/admin/Evento/Casamento/data/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/documentos/?id=" + this.state.eventId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "/admin/Evento/Casamento/testemunhas/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasWitness
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "/admin/Evento/Casamento/cerimonial/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasCeremony
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "/admin/Evento/Casamento/engajamento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasAttendance &&
            this.state.wizardResponse.hasChart
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "/admin/Evento/Casamento/atividades/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasActivity
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    let eventStatusSelectItems = [];
    let eventLocations = []
    let calendarMarkedDays = [];

    let selectedDateEvents = [
      <div key={"noEvents"} style={{ marginTop: "20px" }} className="text-dark">
        <h3>Clique em algum dia no calendário ao lado</h3>
        <div>
          <i
            style={{ fontSize: "300px", marginTop: "30px", color: "#3F4C70" }}
            className="fas fa-calendar-alt"
          ></i>
        </div>
      </div>,
    ];

    if (this.state.calendarSelectedDay)
      selectedDateEvents = (
        <div
          key={"noEvents"}
          style={{ marginTop: "20px", textAlign: "center" }}
          className="text-dark"
        >
          <img
            style={{ width: "50%", marginBottom: "10px" }}
            alt="Nao ha eventos para esta data"
            src={require("assets/img/images/calendar.png")}
          />
          <h1 className="text-dark">Não há eventos para esta data</h1>
        </div>
      );

    if (this.state.loadMonthEventsResponse) {
      if (this.state.loadMonthEventsResponse.length) {
        calendarMarkedDays = this.state.loadMonthEventsResponse.map((el) => {
          let tempData = new Date(el.eventDate);

          if (isNaN(tempData.getTime())) return 0;

          return tempData.getDate();
        });

        let selectedDate = new Date(
          this.state.calendarSelectedYear,
          this.state.calendarSelectedMonth - 1,
          this.state.calendarSelectedDay
        );


        if (!isNaN(selectedDate.getTime())) {
          if (
            !!calendarMarkedDays.find((el) => {
              return el === selectedDate.getDate();
            })
          ) {
            let dayEvents = [];

            this.state.loadMonthEventsResponse.forEach((el) => {
              let tempData = new Date(el.eventDate);

              if (!isNaN(tempData.getTime())) {
                if (tempData.getDate() === selectedDate.getDate()) {
                  dayEvents.push(el);
                }
              }
            });

            if (!!dayEvents) {
              if (dayEvents.length) {
                selectedDateEvents = dayEvents.map((el) => {
                  let boopColor = "#E1E2EF";
                  let textElementClasses = "";
                  let elementError = false;

                  if (
                    el.eventStatusId === constants.EventStatusId.Cancelado ||
                    el.eventStatusId ===
                    constants.EventStatusId.CanceladoComPendencia
                  ) {
                    textElementClasses = " lineThrough";
                  } else {
                    let eventType = constants.EventTypes.find((el2) => {
                      return el2.id === el.eventTypeId;
                    });

                    if (!eventType) {
                      console.error(
                        "Unable to find compatible event type id for: " +
                        el.eventTypeId
                      );
                      elementError = true;
                    } else {
                      boopColor = eventType.boopColor;
                    }
                  }

                  if (elementError) {
                    return <></>;
                  } else {
                    return (
                      <div
                        style={{ marginTop: "20px" }}
                        key={"eventDetails " + el.eventId}
                      >
                        <i
                          style={{ color: boopColor, fontSize: "20px" }}
                          class="fas fa-circle"
                        ></i>
                        <span
                          className={"text-dark" + textElementClasses}
                          style={{ fontSize: "20px", marginLeft: "10px" }}
                        >
                          {el.eventStartTime.split(":").slice(0, 2).join(":") +
                            " - " +
                            el.eventEndTime.split(":").slice(0, 2).join(":") +
                            " - " +
                            el.eventDescription}
                        </span>
                      </div>
                    );
                  }
                });
              }
            }
          }
        }
      }
    }

    let diaLabel = "Selecione uma data";

    if (this.state.calendarSelectedDay) {
      let selectedDate = new Date(
        this.state.calendarSelectedYear,
        this.state.calendarSelectedMonth - 1,
        this.state.calendarSelectedDay
      );

      if (!isNaN(selectedDate.getTime())) {
        let weekDay = "";
        let monthName = "";

        switch (selectedDate.getDay()) {
          case 0:
            weekDay = "Domingo";
            break;
          case 1:
            weekDay = "Segunda-feira";
            break;
          case 2:
            weekDay = "Terça-feira";
            break;
          case 3:
            weekDay = "Quarta-feira";
            break;
          case 4:
            weekDay = "Quinta-feira";
            break;
          case 5:
            weekDay = "Sexta-feira";
            break;
          case 6:
            weekDay = "Sábado";
            break;
        }

        switch (selectedDate.getMonth()) {
          case 0:
            monthName = "janeiro";
            break;
          case 1:
            monthName = "fevereiro";
            break;
          case 2:
            monthName = "março";
            break;
          case 3:
            monthName = "abril";
            break;
          case 4:
            monthName = "maio";
            break;
          case 5:
            monthName = "junho";
            break;
          case 6:
            monthName = "julho";
            break;
          case 7:
            monthName = "agosto";
            break;
          case 8:
            monthName = "setembro";
            break;
          case 9:
            monthName = "outubro";
            break;
          case 10:
            monthName = "novembro";
            break;
          case 11:
            monthName = "dezembro";
            break;
        }

        diaLabel = weekDay + ", " + selectedDate.getDate() + " de " + monthName;
      }
    }

    if (this.state.eventStatusResponse) {
      if (this.state.eventStatusResponse.length > 0) {
        try {
          eventStatusSelectItems = this.state.eventStatusResponse.map(
            (item) => {
              return (
                <option
                  selected={
                    this.state.formData["eventStatusId"] === item.id
                      ? true
                      : false
                  }
                  value={item.id}
                  key={"situation" + item.id}
                >
                  {item.name}
                </option>
              );
            }
          );
        } catch (ex) {
          eventStatusSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }


    if (this.state.locationResponse) {
      if (this.state.locationResponse.length > 0 || this.state.locationList.length > 0) {        
        try {
          {this.state.hasEventLocation.length !== 0 ?
            eventLocations = this.state.locationList.map(
              (item) => {
                  return (
                  <option
                    selected={
                      this.state.formData["locationId"] === item.id
                        ? true
                        : false
                    }
                    value={item.id}
                    key={"situation" + item.id}
                  >
                    {item.name}
                  </option>
                );
              }
            )
          :
          eventLocations = this.state.locationResponse.map(
            (item) => {
                return (
                <option
                  selected={
                    this.state.formData["locationId"] === item.id
                      ? true
                      : false
                  }
                  value={item.id}
                  key={"situation" + item.id}
                >
                  {item.name}
                </option>
              );
            }
          );
        }
         
} catch (ex) {
          eventLocations = [];
          console.error("Falha ao interpretar resposta", ex);
       }
      }
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">Novo casamento</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Row>
            <Col>
              <div className="pt-4">
                <h2 className="text-dark mb-5 font-weight-900 ">
                  Selecione uma data e um horário
                </h2>
              </div>
            </Col>
          </Row>

          <Row lg="12">
            <Col lg="6" style={{ textAlign: "center" }}>
              <Dating
                navigationClickHandler={this.navigationClickHandler}
                dateClickHandler={this.dateClickHandler}
                markedDays={calendarMarkedDays}
                day={this.state.calendarSelectedDay}
                month={this.state.calendarSelectedMonth}
                year={this.state.calendarSelectedYear}
              />
            </Col>

            <Col lg="6" className="pt-4">
              <>
                <h1 className="text-dark">{diaLabel}</h1>

                <div>{selectedDateEvents}</div>
              </>
            </Col>
          </Row>

          <Row lg="12" className="mt-6">
            <Col lg="3">
              <div className="form-group">
                <label className="text-dark font-weight-900">
                  Data do evento
                  <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>
                </label>
                <input
                  disabled={
                    this.state.formData.eventStatusId === 3 ||
                    this.state.formData.eventStatusId === 4
                  }
                  type="text"
                  className="form-control text-dark font-weight-900"
                  id="eventDate"
                  value={
                    this.state.formData["eventDate"]
                      ? this.state.formData["eventDate"]
                      : ""
                  }
                  name="eventDate"
                  ref="eventDate"
                  onChange={this.onInputChange}
                  maxlength="10"
                />
              </div>
            </Col>
            <Col lg="2">
              <div className="form-group">
                <label className="text-dark font-weight-900">
                  Horário de início
                  <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>
                </label>
                <input
                  disabled={
                    this.state.formData.eventStatusId === 3 ||
                    this.state.formData.eventStatusId === 4
                  }
                  className="form-control text-dark font-weight-900"
                  type="text"
                  id="eventStartTime"
                  name="eventStartTime"
                  ref="eventStartTime"
                  value={
                    this.state.formData["eventStartTime"]
                      ? this.state.formData["eventStartTime"]
                      : ""
                  }
                  onChange={this.onInputChange}
                  maxlength="5"
                />
              </div>
            </Col>
            <Col lg="2">
              <div className="form-group">
                <label className="text-dark font-weight-900">
                  Horário de término
                  <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>
                </label>
                <input
                  disabled={
                    this.state.formData.eventStatusId === 3 ||
                    this.state.formData.eventStatusId === 4
                  }
                  className="form-control text-dark font-weight-900"
                  type="text"
                  id="eventEndTime"
                  name="eventEndTime"
                  ref="eventEndTime"
                  value={
                    this.state.formData["eventEndTime"]
                      ? this.state.formData["eventEndTime"]
                      : ""
                  }
                  onChange={this.onInputChange}
                  maxlength="5"
                />
              </div>
            </Col>

            <Col lg="2">
              <div className="form-group">
                <label className="text-dark font-weight-900">
                  Reserva válida até
                </label>
                <input
                  disabled={
                    this.state.formData.eventStatusId === 3 ||
                    this.state.formData.eventStatusId === 4
                  }
                  className="form-control text-dark font-weight-900"
                  type="text"
                  id="reservationValidUntilDate"
                  name="reservationValidUntilDate"
                  ref="reservationValidUntilDate"
                  value={
                    this.state.formData["reservationValidUntilDate"]
                      ? this.state.formData["reservationValidUntilDate"]
                      : ""
                  }
                  onChange={this.onInputChange}
                  maxlength="10"
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label className="text-dark font-weight-900">Status</label>

                <select
                  name="eventStatusId"
                  id="eventStatusId"
                  ref="eventStatusId"
                  className="form-control text-dark font-weight-900 mb-4"
                  onChange={this.onInputChange}
                  disabled={this.state.formData.eventStatusId === 3}
                >
                  {this.state.formData.eventStatusId === 3 ||
                    this.state.formData.eventStatusId === 4 ? null : (
                    <option value="">Selecione</option>
                  )}
                  {eventStatusSelectItems.length === 0 ||
                    eventStatusSelectItems}
                </select>
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label className="text-dark font-weight-900">Local</label>
                <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>

                <select
                  name="locationId"
                  id="locationId"
                  ref="locationId"
                  className="form-control text-dark font-weight-900 mb-4"
                  onChange={this.onInputChange}
                >
                  <option value="">Selecione</option>
                  {eventLocations.length === 0 ||
                    eventLocations}
                </select>
              </div>
            </Col>
          </Row>

          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Eventos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar e continuar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id });

    let eventDay = this.state.calendarSelectedDay;
    let eventMonth = this.state.calendarSelectedMonth;
    let eventYear = this.state.calendarSelectedYear;

    let selectedDate =
      (eventDay.toString().length === 1 ? "0" + eventDay : eventDay) +
      "/" +
      (eventMonth.toString().length === 1 ? "0" + eventMonth : eventMonth) +
      "/" +
      (eventYear.toString().length === 1 ? "0" + eventYear : eventYear);

    this.setState({
      formData: Object.assign(this.state.formData, { eventDate: selectedDate }),
    });
  }

  componentDidMount() {
    this.loadLocations()
    this.loadWizard();
    this.loadSelects();

    //this.loadDataList(targetYear,targetMonth, targetDay);

    if (this.state.eventId) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase +
        constants.Endpoint.Event.Get +
        "/" +
        this.state.eventId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }
          this.setState({
            hasEventLocation : result.response.eventReservations
          });
          console.log(this.state.hasEventLocation)


        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }

    if (this.state.eventId !== 0) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase +
        constants.Endpoint.EventReservation.GetByEvent;
      let data = "eventId=" + this.state.eventId;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          let hasError = false;

          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            hasError = true;
          } else if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            hasError = true;
          } else if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            hasError = true;
          }

          if (hasError) {
            this.loadMonthEvents(
              this.state.calendarSelectedMonth,
              this.state.calendarSelectedYear
            );
            return;
          }

          let formData = this.state.formData;
          let ret = result.response;
          formData.locationId = ret.locationId

          if (this.state.eventId)
            if (this.state.eventId !== 0)
              window.localStorage.setItem(
                constants.LocalStorage.EventId,
                this.state.eventId
              );

          formData.id = ret.id;

          function dateFormat(date) {
            let day = "";
            let month = "";
            let year = "";
            let dateFormated = "";

            day = new Date(date).getDate().toString();
            month = (new Date(date).getMonth() + 1).toString();
            year = new Date(date).getFullYear().toString();

            dateFormated =
              (day.toString().length === 1 ? "0" + day : day) +
              "/" +
              (month.toString().length === 1 ? "0" + month : month) +
              "/" +
              (year.toString().length === 1 ? "0" + year : year);

            return dateFormated;
          }

          let eventStartTime = ret.eventStartTime.substring(0, 5);
          let eventEndTime = ret.eventEndTime.substring(0, 5);

          formData.eventDate = dateFormat(ret.eventDate);
          formData.eventStartTime = eventStartTime;
          formData.eventEndTime = eventEndTime;
          if (formData.eventStartTime.length === 5) {
            let day = parseInt(formData.eventDate.substring(0, 2))
            let month = parseInt(formData.eventDate.substring(3, 5))
            let year = parseInt((formData.eventDate.substring(6, 10)))
            let hour = formData.eventStartTime.substring(0, 2)
            let minutes = formData.eventStartTime.substring(3, 5)
            this.loadDataList(
              year, month, day, hour, minutes
            )
          }

          if (ret.reservationValidUntilDate)
            formData.reservationValidUntilDate = dateFormat(
              ret.reservationValidUntilDate
            );

          if (ret.eventStatusId) formData.eventStatusId = ret.eventStatusId;

          let eventDay = new Date(ret.eventDate).getDate().toString();
          let eventMonth = (new Date(ret.eventDate).getMonth() + 1).toString();
          let eventYear = new Date(ret.eventDate).getFullYear().toString();

          this.loadMonthEvents(eventMonth, eventYear);

          this.setState({
            formData: formData,
            calendarSelectedDay: eventDay,
            calendarSelectedMonth: eventMonth,
            calendarSelectedYear: eventYear,
          });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  dateClickHandler(day, date, sameMonth) {
    if (sameMonth) {
      this.setState({ calendarSelectedDay: day });
    } else {
      let targetMonth = this.state.calendarSelectedMonth;
      let targetYear = this.state.calendarSelectedYear;

      if (this.state.calendarSelectedMonth - 2 === date.getMonth())
        targetMonth--;
      else targetMonth++;

      if (targetMonth > 12) {
        targetMonth = 1;
        targetYear++;
      }

      if (targetMonth < 1) {
        targetMonth = 12;
        targetYear--;
      }

      this.setState({
        calendarSelectedYear: targetYear,
        calendarSelectedMonth: targetMonth,
        calendarSelectedDay: day,
      });
    }

    if (date) {
      let eventDay = new Date(date).getDate().toString();
      let eventMonth = (new Date(date).getMonth() + 1).toString();
      let eventYear = new Date(date).getFullYear().toString();

      let selectedDate =
        (eventDay.length === 1 ? "0" + eventDay : eventDay) +
        "/" +
        (eventMonth.length === 1 ? "0" + eventMonth : eventMonth) +
        "/" +
        (eventYear.length === 1 ? "0" + eventYear : eventYear);


      this.setState({
        formData: Object.assign(this.state.formData, {
          eventDate: selectedDate, eventStartTime: "", eventEndTime: "",  locationId: ""
        }),
        locationResponse: null
      });
      
    }
  }

  validateEventTime() {
    const eventId = parseInt(this.state.eventId);
    const eventDateBrFormat = this.state.formData.eventDate;
    const eventStartTime = this.state.formData.eventStartTime;
    const eventEndTime = this.state.formData.eventEndTime;
    const eventStatusId = parseInt(this.state.formData.eventStatusId);

    if (eventStartTime > eventEndTime) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro durante o registro, por favor verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li>
                    Horário de início do evento não pode ser maior do que o
                    horário de término do evento
                  </li>
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return false;
    }

    if (
      eventStatusId === constants.EventStatusId.Cancelado ||
      eventStatusId === constants.EventStatusId.CanceladoComPendencia
    )
      return true;

    const monthEvents = this.state.loadMonthEventsResponse;
    let monthReservations = [];

    if (monthEvents && monthEvents.length > 0) {
      const parts = eventDateBrFormat.split("/");
      const eventDate = new Date(parts[2], parts[1] - 1, parts[0]);

      monthReservations = monthEvents.filter(function (reservation) {
        const reservationEventDate = new Date(reservation.eventDate);

        return (
          eventDate.getTime() === reservationEventDate.getTime() &&
          reservation.eventTypeId === constants.EventTypeId.Casamento
        );
      });
    }

    if (monthReservations && monthReservations.length > 0) {
      for (let index = 0; index < monthReservations.length; index++) {
        const reservation = monthReservations[index];

        if (reservation.eventId === eventId) continue;

        if (
          reservation.eventStatusId === constants.EventStatusId.Cancelado ||
          reservation.eventStatusId ===
          constants.EventStatusId.CanceladoComPendencia
        )
          continue;



        // if (
        //   reservationStartTime < eventEndTime &&
        //   eventStartTime < reservationEndTime
        // ) {
        //   this.setState((state) => ({
        //     message: {
        //       ...state.message,
        //       ...{
        //         title: "Aviso",
        //         visible: true,
        //         text: (
        //           <>
        //             Ocorreu um erro durante o registro, por favor verifique a{" "}
        //             <span className="errorText"> mensagem </span> e tente
        //             novamente:
        //             <ul className="errorSumary">
        //               <li>
        //                 A data e os horários informados entram em conflito com
        //                 um casamento cadastrado
        //               </li>
        //             </ul>
        //           </>
        //         ),
        //       },
        //     },
        //     submitLoading: false,
        //   }));

        //   return false;
        // }
      }
    }

    return true;
  }

  dateTypingHandler(date, hasDate) {
    if (hasDate) {
      let day = date.substring(0, 2);
      let month = date.substring(3, 5);
      let year = date.substring(6, 10);

      this.setState({
        calendarSelectedYear: year,
        calendarSelectedMonth: month,
        calendarSelectedDay: day,
      });
    } else {
      this.setState({
        calendarSelectedYear: new Date().getFullYear(),
        calendarSelectedMonth: new Date().getMonth() + 1,
        calendarSelectedDay: new Date().getDate(),
      });
    }
  }

  navigationClickHandler(left) {
    let targetMonth = this.state.calendarSelectedMonth;
    let targetYear = this.state.calendarSelectedYear;
    let targetDay = 1;

    if (left) targetMonth--;
    else targetMonth++;

    if (targetMonth > 12) {
      targetMonth = 1;
      targetYear++;
    }

    if (targetMonth < 1) {
      targetMonth = 12;
      targetYear--;
    }

    if (new Date().getMonth() + 1 === targetMonth)
      targetDay = new Date().getDate();

    let selectedDate =
      (targetDay.toString().length === 1 ? "0" + targetDay : targetDay) +
      "/" +
      (targetMonth.toString().length === 1 ? "0" + targetMonth : targetMonth) +
      "/" +
      (targetYear.toString().length === 1 ? "0" + targetYear : targetYear);

    this.setState({
      calendarSelectedYear: targetYear,
      calendarSelectedMonth: targetMonth,
      calendarSelectedDay: targetDay,
      formData: Object.assign(this.state.formData, { eventDate: selectedDate }),
    });

    this.loadMonthEvents(targetMonth, targetYear);
  }

  loadMonthEvents(month, year) {
    this.setState({ loadMonthEventsResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Event.CalendarSearch;
    let data = { year: year, month: month };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ loadMonthEventsResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadEventStatus() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.EventStatus.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ eventStatusResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { eventId } = this.state;
    let id = parseInt(eventId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
    let data = { eventId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadEventStatus();
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;

    } else {
      switch (name) {
        case "eventDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "reservationValidUntilDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "eventStartTime":
          value = formater.template(target.value, "00:00");
          break;

        case "eventEndTime":
          value = formater.template(target.value, "00:00");
          break;

        default:
          value = target.value;
      }


    }

    if (name === "eventDate") {
      if (value.length === 10) this.dateTypingHandler(value, true);
      else this.dateTypingHandler(value, false);
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });


    if (target.name === 'eventStartTime' && this.state.formData.eventStartTime.length === 5) {

      this.setState({
        formData: Object.assign(this.state.formData, { locationId: "" }),
        hasEventLocation : [],
      })

      let hour = this.state.formData.eventStartTime.substring(0, 2)
      let minutes = this.state.formData.eventStartTime.substring(3, 5)
      this.loadDataList(
        this.state.calendarSelectedYear,
        this.state.calendarSelectedMonth,
        this.state.calendarSelectedDay,
        hour, minutes)
    }
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    let formData = this.state.formData;

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }



    if (!this.validateEventTime()) return;

    let formDataFilter = [
      "id",
      "eventStatusId",
      "eventDate",
      "eventStartTime",
      "eventEndTime",
      "locationId",
      "reservationValidUntilDate",
    ];
    let formDataAPI = smoothApi.helpers.filterProperties(
      formData,
      formDataFilter
    );

    function dateFormater(date) {
      let day = date.substring(0, 2);
      let month = date.substring(3, 5);
      let year = date.substring(6, 10);

      return year + "/" + month + "/" + day;
    }

    if (this.state.eventId) formDataAPI.eventId = this.state.eventId;

    if (!formDataAPI.id) formDataAPI.id = null;

    if (formData.eventDate) {
      formDataAPI.eventDate = dateFormater(formData.eventDate);
    }

    if (formData.reservationValidUntilDate)
      formDataAPI.reservationValidUntilDate = dateFormater(
        formData.reservationValidUntilDate
      );

    let convertedFormData = objectToFormData(formDataAPI);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.EventReservation.Post;




    smoothApi.post(
      url,
      convertedFormData,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            submitLoading: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
          }));

          return;
        }

        this.setState({ submitLoading: false });

        window.location.href =
          "/admin/Evento/documentos" +
          (this.state.eventId ? "?id=" + this.state.eventId : "");
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );

  }

  loadLocations() {

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Location.Get;
    const data = null;
  
    smoothApi.get(
      url,
      data,
      token,
  
      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }
  
        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }
  
        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }
        this.setState({ locationList: result.response });

    
      },
  
      (error, httpCode) => {
        console.log(error)
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  
  }

  //#endregion
  loadDataList(year, month, day, hour, minutes) {

    this.setState({ locationResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Location.SearchByDate;
    let data = { year, month, day, hour: hour, minutes: minutes }





    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }
        this.setState({ locationResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

  }

}

export default DataCasamento;
