export default Object.freeze({
  Errors: {
    UnexpectedFormat: "Resposta fora do padrão esperado",
    ResponseError: "Erro ao consultar a api",
    HttpError: "Erro inesperado ao consultar a api",
    BlankResponse: "Resposta em branco",
  },

  Sucess: {},
});
