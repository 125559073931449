/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

const ButtonGroupNsb = (props) => {
  const changeHandler = props.changeHandler ? props.changeHandler : () => {};
  const id = props.id ? props.id : "";
  const ref = props.ref ? props.ref : "";
  const dflex = props.dflex ? props.dflex : "";
  const disabled = props.disabled ? props.disabled : null;
  const option1 = props.option1 ? props.option1 : "";
  const option2 = props.option2 ? props.option2 : "";
  const activeIndex =
    props.activeIndex !== null && props.activeIndex !== undefined
      ? props.activeIndex + ""
      : "";

  let buttonGroupOption1Active = false;
  let buttonGroupOption2Active = false;

  if (activeIndex === "0") buttonGroupOption1Active = true;
  else if (activeIndex === "1") buttonGroupOption2Active = true;
  else {
    buttonGroupOption1Active = false;
    buttonGroupOption2Active = false;
  }

  let changeValue = (value) => {
    changeHandler({ target: { id: id, value: value, avoidValidation: true } });
  };

  return (
    <>
      <div
        className={"btn-group btn-group-toggle" + (dflex ? " d-flex" : "")}
        data-toggle="buttons"
      >
        <label
          id="btn-group-first"
          className={
            "btn btn-group-nsb" + (buttonGroupOption1Active ? " active" : "")
          }
        >
          <input
            disabled={disabled ? true : false}
            type="radio"
            name="options"
            id="option1"
            autoComplete="off"
            checked=""
            onClick={() => {
              changeValue("0");
            }}
          />{" "}
          {option1}
        </label>
        <label
          id="btn-group-second"
          className={
            "btn btn-group-nsb" + (buttonGroupOption2Active ? " active" : "")
          }
        >
          <input
            disabled={disabled ? true : false}
            type="radio"
            name="options"
            id="option2"
            autoComplete="off"
            onClick={() => {
              changeValue("1");
            }}
          />{" "}
          {option2}
        </label>
      </div>
    </>
  );
};

export default ButtonGroupNsb;
