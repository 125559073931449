//#region Imports

import Modal from 'react-modal';
import React from "react";
import Wizard from "components/Utils/Wizard";
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import messages from "variables/messages";
import Loading from 'components/Utils/Loading.jsx';
import { formater } from 'components/Utils/formater';
import { diruma } from 'components/Utils/diruma';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Form,
} from "reactstrap";


//#endregion

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '440px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '3px'
    }
};


class Testemunhas extends React.Component {

    //#region Constructor

    constructor(props) {

        super(props);

        //#region Initial State

        this.state = {

            //Loading
            submitLoading: false,
            addWitnessLoading: false,

            //Modal
            modalIsOpen: false,
            modalWitnessOpen: false,

            //FormData
            formData: {},
            eventId: 0,
            witnessId: 0,
            witnessChange: -1,

            //API Responses
            witnessResponse: [],
            wizardResponse: null,

            //Witness Lists
            renderWitnessList: true,
            toDelete: [],

            //Modal Message
            errorMessage: '',
            message: {

                visible: false,
                text: 'teste',
                title: '',
                closeCallBack: null,
                _defaultCloseAction: (_this) => {

                    if (!_this)
                        return;

                    if (typeof _this !== 'object')
                        return;

                    if (!_this.state || !_this.setState)
                        return;

                    if (typeof _this.state !== 'object' || typeof _this.setState !== 'function')
                        return;

                    if (_this.state.message.closeCallBack) {

                        if (typeof _this.state.closeCallBack === 'function')
                            _this.state.message.closeCallBack();

                    }

                    _this.setState((state) => ({ message: { ...state.message, ...{ visible: false, text: '', title: '', closeCallBack: null } } }));

                }

            }

        }


        //#endregion 

        //#region Validations 

        this.validation = {

            refs: () => { return this.refs },

            elements: [

                { ref: 'cpf', validations: [diruma.validations.cpf], customValidations: [] },

            ]

        };

        //#endregion

        //#region Event Binding

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.loadWizard = this.loadWizard.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.deleteWitness = this.deleteWitness.bind(this);
        this.editWitness = this.editWitness.bind(this);
        this.witnessEditionCancel = this.witnessEditionCancel.bind(this);

        //#endregion

    }

    render() {

        let wizzardConfiguration = Wizard({ getConfiguration: true });
        let wizardNodeConfiguration = [];

        wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Tipo do <br /> evento</>, '/admin/Evento/tipoEvento/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), wizzardConfiguration.Status.Completed));
        wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Informações <br /> do evento</>, '/admin/Evento/Casamento/infoCasamento/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), wizzardConfiguration.Status.Completed));


        if (this.state.wizardResponse) {

            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Data do evento</>, '/admin/Evento/Casamento/data/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasDate ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Documentos</>, '/admin/Evento/documentos/?id=' + this.state.eventId, (this.state.wizardResponse.hasDocument ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Testemunhas</>, '/admin/Evento/Casamento/testemunhas/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), wizzardConfiguration.Status.Active));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Cerimonial</>, '/admin/Evento/Casamento/cerimonial/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasCeremony ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Engajamento</>, '/admin/Evento/Casamento/engajamento/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasAttendance && this.state.wizardResponse.hasChart ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Atividades</>, '/admin/Evento/Casamento/atividades/' + (this.state.eventId ? '?id=' + this.state.eventId : ""), (this.state.wizardResponse.hasActivity ? wizzardConfiguration.Status.Completed : wizzardConfiguration.Status.Enabled)));


        } else {

            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Data do evento</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Documentos</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Testemunhas</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Cerimonial</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Engajamento</>, '', wizzardConfiguration.Status.Disabled));
            wizardNodeConfiguration.push(wizzardConfiguration.Configuration.Formater(<>Atividades</>, '', wizzardConfiguration.Status.Disabled));


        }

        let witnessTableColumns = [];
        let witnessTableData = [];

        if (this.state.witnessResponse) {

            witnessTableColumns = [
                { name: 'Nome da testemunha', size: 20, property: 'name' },
                { name: 'RG', size: 20, property: 'rg' },
                { name: 'CPF', size: 20, property: 'cpf' },
                { name: '', size: 5, property: 'editarButton' },
                { name: '', size: 5, property: 'excluirButton' }
            ];

            witnessTableData = this.state.witnessResponse;

            witnessTableData = witnessTableData.map((item, index) => {

                return {
                    ...item, ...
                    {
                        editarButton: <button type="button" className="btn btn-sm edit" key={index} onClick={() => this.editWitness(index)}>Editar</button>,
                        excluirButton: <button type="button" className="btn btn-sm exc" key={index} onClick={() => this.openModal(index, "witness")}>Excluir</button>,
                    }

                };

            });

        }

        return (
            <>

                {/* //#region Modal */}

                <>

                    <Modal
                        isOpen={this.state.modalWitnessOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <span className="float-lg-right x" onClick={this.closeModal}>X</span>
                        <h2 className="text-dark text-center pt-4 pb-4 text-modal">Tem certeza de que deseja <br /> excluir esta testemunha?</h2>
                        <div className="text-center">
                            <button id="alertExclusion" className="alertExclusion btn btn-group button-yes mr-4" key={this.state.witnessId} onClick={() => this.deleteWitness(this.state.witnessId)} >Sim</button>
                            <button className="btn btn-group button-no" onClick={this.closeModal} >Não</button>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={this.state.message.visible}
                        onRequestClose={() => { this.state.message._defaultCloseAction(this); }}
                        style={constants.Modal.DefaultStyle}
                        contentLabel={this.state.message.title}
                    >
                        <span className="float-lg-right x" onClick={() => { this.state.message._defaultCloseAction(this); }}>X</span>
                        <h2 className="text-dark text-center pt-4 pb-4 text-modal">{this.state.message.text}</h2>
                        <div className="text-center">
                            <button className="btn btn-group button-no" onClick={() => { this.state.message._defaultCloseAction(this); }}>OK</button>
                        </div>
                    </Modal>


                </>
                {/* //#endregion Modal */}

                <Container fluid className=" bg-body">
                    <Row className="pt-4 pl-5">
                        <Col lg="6">
                            <div>
                                <h1 className="text-dark">Novo casamento</h1>
                                <img className="rectangle-orange"
                                    alt="..."
                                    src={require("assets/img/brand/home/Rectangle.png")}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Wizard nodes={wizardNodeConfiguration} />
                </Container>

                <div className="container-fluid minHeightFooter mt-9">
                    <Form>
                        <Row className="pl-4">
                            <Col>
                                <div className="pt-4">
                                    <h2 className="text-dark mb-5 font-weight-900 ">Testemunhas</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pl-5">
                            <Col lg="6">
                                <div className="form-group">
                                    <label className="text-family font-weight-900 pb-1">Nome completo da testemunha <small className="text-gray"><i>- obrigatório</i></small></label>
                                    <input type="name" className="form-control text-dark font-weight-900 mt--2" id="name" name="name" ref="name" value={this.state.formData['name'] ? this.state.formData['name'] : ""} onChange={this.onInputChange} />
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="form-group">
                                    <label className="text-family font-weight-900 pb-1">RG </label>
                                    <input type="name" className="form-control text-dark font-weight-900 mt--2" id="rg" name="rg" ref="rg" value={this.state.formData['rg'] ? this.state.formData['rg'] : ""} onChange={this.onInputChange} />
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="form-group">
                                    <label className="text-family font-weight-900 pb-1">CPF </label>
                                    <input type="name" className="form-control text-dark font-weight-900 mt--2" id="cpf" name="cpf" ref="cpf" value={this.state.formData['cpf'] ? this.state.formData['cpf'] : ""} onChange={this.onInputChange} />
                                </div>
                            </Col>

                        </Row>

                        <div className="container-fluid mb-6 mt-6">
                            <Row>
                                <Col>
                                    <div className="float-lg-right">
                                        {this.state.witnessChange !== -1 ?

                                            <button type="button" className="btn-info-person-cancel mr-2" onClick={() => { this.witnessEditionCancel(); }}>
                                                Cancelar Edição
                                                </button>
                                            :
                                            <> </>

                                        }
                                        <button type="button" className="btn-person-search ml-4" onClick={() => { this.addWitness(); }}>
                                            {this.state.witnessChange === -1 ?
                                                <>
                                                    {
                                                        this.state.addWitnessLoading ?
                                                            <Loading load="Sim" /> :
                                                            'Adicionar'
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        this.state.addWitnessLoading ?
                                                            <Loading load="Sim" /> :
                                                            'Editar'
                                                    }
                                                </>
                                            }

                                        </button>

                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row className="pt-4 pl-5">

                            <Col lg="12">
                                {(!this.state.renderWitnessList ||
                                    <>

                                        <DataList tableCollumns={witnessTableColumns} tableData={witnessTableData} />

                                    </>

                                )}

                            </Col>
                        </Row>
                    </Form>
                    <div className="container-fluid mb-6 mt-6">
                        <Row>
                            <Col>
                                <div className="float-lg-right pb-4">

                                    <Link to={"/admin/Eventos"}><button type="button" className="btn-info-person-cancel">Cancelar</button></Link>

                                    <button type="button" className="btn-info-person ml-4" onClick={() => { this.handleSubmit(); }}>
                                        {this.state.submitLoading ?
                                            <Loading load="Sim" /> :
                                            'Salvar e continuar'
                                        }
                                    </button>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }

    //#region Component Events

    componentWillMount() {

        let urlString = window.location.href;
        let url = new URL(urlString);
        let id = url.searchParams.get("id");

        if (id)
            this.setState({ eventId: id });

    }

    componentDidMount() {

        this.loadSelects();

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.WeddingWitness.Get;
        let data = null;

        smoothApi.get(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {


                    console.error(messages.Errors.UnexpectedFormat, result);
                    return;

                }

                if (!result.success) {

                    console.log(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {

                    console.log(messages.Errors.BlankResponse, result.errors);
                    return;

                }

                let ret = result.response;
                let witness = this.state.witnessResponse;
                let eventId = this.state.eventId;

                witness = ret.map((item) => {

                    return {

                        id: item.id,
                        eventId: item.eventId,
                        name: item.name,
                        rg: formater.template(item.rg, '00.000.000-0'),
                        cpf: formater.template(item.cpf, '000.000.000-00')

                    };

                });

                witness = witness.filter(function (item) {

                    return item.eventId === eventId;

                });

                this.setState({ witnessResponse: witness });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

            }

        );


    }

    loadSelects() {

        this.loadWizard();

    }

    loadWizard() {

        const { eventId } = this.state;
        let id = parseInt(eventId);
        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
        let data = { eventId: id };

        smoothApi.post(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {

                    console.error(messages.Errors.UnexpectedFormat, result);
                    return;

                }

                if (!result.success) {

                    console.log(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {

                    console.log(messages.Errors.BlankResponse, result.errors);
                    return;

                }

                this.setState({ wizardResponse: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

            }

        );

    }

    //#endregion

    //#region Custom Events

    openModal(id) {

        this.setState({ witnessId: id, modalWitnessOpen: true });

    }

    closeModal() {
        this.setState({ modalWitnessOpen: false });
    }

    addWitness() {

        this.setState({ addWitnessLoading: true });

        let validations = diruma.validadeOnChange(this.validation, this.state.formData, null, true);

        if (!validations.sucess) {

            this.setState((state) => ({
                message: {
                    ...state.message, ...{
                        title: 'Aviso',
                        visible: true,
                        text: <>
                            Por favor verifique os campos destacados em <span className="errorText"> vermelho </span> para prosseguir:

                            <ul className="errorSumary">
                                {

                                    validations.errors.map((el, currentIndex) => {

                                        return <li key={"validationItem_" + currentIndex} >{el}</li>

                                    })

                                }
                            </ul>
                        </>
                    }
                },
                addWitnessLoading: false
            }));

            return;

        }

        let formData = this.state.formData;
        let witnessList = this.state.witnessResponse;
        let isChange = this.state.witnessChange;


        if (formData.name) {

            if (isChange === -1) {

                witnessList.push({
                    eventId: this.state.eventId,
                    name: formData.name,
                    rg: formData.rg,
                    cpf: formData.cpf,
                });

                delete formData.name;
                delete formData.rg;
                delete formData.cpf;

                this.setState({ addWitnessLoading: false, renderWitnessList: true, witnessResponse: witnessList });

            }
            else {

                witnessList.splice(isChange, 1);

                witnessList.push({
                    eventId: this.state.eventId,
                    id: formData.id ? formData.id : null,
                    name: formData.name,
                    rg: formData.rg,
                    cpf: formData.cpf,
                });

                delete formData.name;
                delete formData.rg;
                delete formData.cpf;
                delete formData.id;
                delete formData.eventId;

                isChange = -1;

                this.setState({ addWitnessLoading: false, renderWitnessList: true, witnessResponse: witnessList, witnessChange: isChange });

            }

        }
        else {
            this.setState((state) => ({
                message: {
                    ...state.message, ...{
                        title: 'Aviso',
                        visible: true,
                        text: <>
                            Ocorreu um erro durante o registro, por favor verifique a <span className="errorText"> mensagem </span> e tente novamente:

                            <ul className="errorSumary">
                                <li>Digite o nome da testemunha</li>
                            </ul>
                        </>

                    }
                },
                addWitnessLoading: false
            }));

            return;
        }

    }


    deleteWitness(index) {

        let witnessList = this.state.witnessResponse;
        let toDelete = this.state.toDelete;

        toDelete.push(witnessList[index].id);

        witnessList.splice(index, 1);

        if (witnessList.length <= 0)
            this.setState({ renderWitnessList: false });

        this.setState({ modalWitnessOpen: false, witnessResponse: witnessList, toDelete: toDelete });

    }

    editWitness(index) {

        let formData = this.state.formData;
        let witnessList = this.state.witnessResponse;

        let itemEdit = witnessList[index];

        formData = { ...itemEdit };

        this.setState({ formData: formData, witnessChange: index })

    }

    witnessEditionCancel() {

        let formData = this.state.formData;

        delete formData.name;
        delete formData.rg;
        delete formData.cpf;

        this.setState({ witnessChange: -1, formData: formData });

    }


    onInputChange(event) {

        const target = event.target;
        const name = target.id;
        let value = '';

        if (target.type === 'checkbox') {

            value = target.checked;

        }

        else {

            switch (name) {

                case 'cpf':
                    value = formater.template(target.value, '000.000.000-00');
                    break;

                case 'rg':
                    value = formater.template(target.value, '00.000.000-0');
                    break;

                default:
                    value = target.value;
            }

        }

        this.setState({

            formData: Object.assign(this.state.formData, { [name]: value })

        });


    }

    handleSubmit() {

        this.setState({ submitLoading: true });


        let formDataAPI = this.state.formData;
        let witness = this.state.witnessResponse;
        let toDelete = this.state.toDelete;


        formDataAPI.toSave = [];
        formDataAPI.toDelete = [];
        
        formDataAPI.toSave = witness.filter(function (item) {

            return item !== undefined;

        });

        formDataAPI.toDelete = toDelete.filter(function (item) {

            return item !== undefined;

        });


        if ((formDataAPI.toSave.length > 0) || (formDataAPI.toDelete.length > 0)) {

            let token = window.localStorage.getItem(constants.LocalStorage.Token);
            let url = constants.Endpoint.UrlBase + constants.Endpoint.WeddingWitness.Bulk;
            let data = formDataAPI;

            smoothApi.post(url, data, token,

                (result) => {


                    if (!result || !result.errors || !result.response || result.success == null) {

                        debugger;

                        console.error(messages.Errors.UnexpectedFormat, result);
                        this.setState((state) => ({
                            message: {
                                ...state.message, ...{
                                    title: 'Aviso',
                                    visible: true,
                                    text: <>
                                        Ocorreu um erro durante o registro, por favor verifique a <span className="errorText"> mensagem </span> e tente novamente:

                                        <ul className="errorSumary">
                                            {

                                                result.errors.map((el, currentIndex) => {

                                                    return <li key={"validationItem_" + currentIndex} >{el + "."}</li>

                                                })

                                            }
                                        </ul>
                                    </>

                                }
                            },
                            submitLoading: false
                        }));

                        return;

                    }

                    if (!result.success) {

                        console.error(messages.Errors.ResponseError, result.errors);

                        this.setState((state) => ({
                            message: {
                                ...state.message, ...{
                                    title: 'Aviso',
                                    visible: true,
                                    text: <>
                                        Ocorreu um erro durante o registro, por favor verifique a <span className="errorText"> mensagem </span> e tente novamente:

                                        <ul className="errorSumary">
                                            {

                                                result.errors.map((el, currentIndex) => {

                                                    return <li key={"validationItem_" + currentIndex} >{el + "."}</li>

                                                })

                                            }
                                        </ul>
                                    </>

                                }
                            },
                            submitLoading: false
                        }));

                        return;

                    }

                    if (result.response == null) {
                        this.setState({ errorMessage: messages.Errors.BlankResponse, submitLoading: false });
                        console.error(messages.Errors.BlankResponse);
                        return;

                    }

                    this.setState({ submitLoading: false, errorMessage: '' });

                    window.location.href = "/admin/Evento/Casamento/Cerimonial" + (this.state.eventId ? "?id=" + this.state.eventId : "");

                },

                (error, httpCode) => {

                    if (httpCode === 401)
                        window.location.href = "/auth/Login";

                    this.setState({ submitLoading: false, errorMessage: messages.Errors.HttpError });


                }

            );

        }
        else {

            this.setState({ submitLoading: false, errorMessage: '' });

            window.location.href = "/admin/Evento/Casamento/Cerimonial" + (this.state.eventId ? "?id=" + this.state.eventId : "");

        }

    }

}


export default Testemunhas;
