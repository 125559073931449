import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const Wizard = (props) => {
  const availableStatus = {
    Disabled:
      "yEJVNxHMAx3XqERvMZQNMQHy4qvCK7yApZNsPMkPFqb3PdBxyvaM3h2MFPSxcz6VEuEqKrKN5AtEtkGZgRLK8UKEqdp9c4zHvSV8HktA2xPffRaWZ9XnFhy4Ddge3y",
    Active:
      "WvpEB3AXyqbhtwAkAa5qCmwUvcaAUpbX3LwEK5LJQjyvyHc2mpNNMCxKec5QYtCR84m5rjLAn2ZWmn6zmGTwkg8VsQK5gMC75yYJWLdThuxK9Vy8AqDHxkN7Utpx8E",
    Enabled:
      "WTGPFTUsWxpV6tGSyh7rcyAZ2PXEjfDbULaLztyGEZHDszRkU8Sqcf2XYw75ZN9tBpG92TbCPQd3ueWnQcqU6ffas6Q8JuJLfupzk7NHzD5dDe5w6NZyasaYWVXFNY",
    Completed:
      "DY4Ws7CUjAZfGQmmy4sCP3Yk6bLD2NgXVVvTWHafSGGWxunjhDGQaJAEdkudXZBCB9MFeJ3X2PsyjUDC5Lta5azxRh9KtMMXexwf67r9ueZMSu6snsqWPHmBHEdq4j",
  };

  const getConfiguration =
    props.getConfiguration == null ? false : !!props.getConfiguration;

  if (getConfiguration) {
    return {
      Status: availableStatus,
      Configuration: {
        Formater: (name, url, status) => {
          if (!name || !status) {
            console.error(
              "🧙‍♂️: Please inform all parameters to generate a configuration entry."
            );
            console.error("name:", name);
            console.error("url:", url);
            console.error("status:", status);
            return null;
          }

          if (typeof url !== "string" || typeof status !== "string") {
            console.error(
              "🧙‍♂️: Please inform valid parameters values to generate a configuration entry."
            );
            console.error("url:", url);
            console.error("status:", status);
            return null;
          }

          if (
            status !== availableStatus.Disabled &&
            status !== availableStatus.Active &&
            status !== availableStatus.Enabled &&
            status !== availableStatus.Completed
          ) {
            console.error(
              "🧙‍♂️: Please inform a valid status using the Status configuration."
            );
            console.error("status:", status);
            return null;
          }

          return { name: name, url: url, status: status };
        },
      },
    };
  }

  const nodes = props.nodes == null ? [] : props.nodes;

  if (typeof nodes !== "object") {
    console.error("🧙‍♂️: Please inform a valid node configuration.");
    return <></>;
  }

  if (!nodes.length) {
    console.error("🧙‍♂️: Please inform a valid node configuration.");
    return <></>;
  }

  let validConfiguration = true;

  nodes.forEach((el) => {
    if (!el.name || !el.status) {
      console.error("🧙‍♂️: Please inform all parameters.");
      console.error("name:", el.name);
      console.error("url:", el.url);
      console.error("status:", el.status);
      validConfiguration = false;
      return;
    }

    if (typeof el.url !== "string" || typeof el.status !== "string") {
      console.error("🧙‍♂️: Please inform valid parameters.");
      console.error("url:", el.url);
      console.error("status:", el.status);
      validConfiguration = false;
      return;
    }

    if (
      el.status !== availableStatus.Disabled &&
      el.status !== availableStatus.Active &&
      el.status !== availableStatus.Enabled &&
      el.status !== availableStatus.Completed
    ) {
      console.error(
        "🧙‍♂️: Please inform a valid status using the Status configuration."
      );
      console.error("status:", el.status);
      validConfiguration = false;
      return;
    }

    el.textStyle = "title-wizard";
    el.nodeStyle = "node";
    el.containerStyle = "step";

    if (el.status === availableStatus.Disabled) {
      el.textStyle += " notAllowedText";
      el.nodeStyle += " notAllowedBall";
      el.containerStyle += " in-progress";
    }

    if (el.status === availableStatus.Active) {
      el.containerStyle += " in-progress";
    }

    if (el.status === availableStatus.Completed) {
      el.containerStyle += " complete";
    }
  });

  if (!validConfiguration) return <></>;

  return (
    <>
      <Row>
        <Col lg="12" className="mt-5">
          <div className="wizard-progress">
            {nodes.map((item, index) => {
              return (
                <div className={item.containerStyle}>
                  <div className={item.textStyle}>{item.name}</div>
                  {item.status !== availableStatus.Disabled ? (
                    <Link to={item.url}>
                      <div className={item.nodeStyle}></div>
                    </Link>
                  ) : (
                    <div className={item.nodeStyle}></div>
                  )}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Wizard;
