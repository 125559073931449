//#region Imports

import Modal from "react-modal";
import React from "react";
import Wizard from "components/Utils/Wizard";
import { smoothApi } from "components/Utils/smoothApi.js";
import constants from "variables/constants";
import messages from "variables/messages";

import { Link } from "react-router-dom";

import { Container, Row, Col, Form } from "reactstrap";

//#endregion


class TipoEvento extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      errorMessage: "",
      eventId: 0,
      formData: {},
      wizardResponse: null,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.loadWizard = this.loadWizard.bind(this);

    this.onInputChange = this.onInputChange.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];
    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Tipo do <br /> evento
        </>,
        "/admin/Evento/tipoEvento" +
          (this.state.eventId ? "/?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Active
      )
    );

    if (this.state.wizardResponse) {
      if (this.state.formData.eventTypeId === "1") {
        wizardNodeConfiguration.push(
          wizzardConfiguration.Configuration.Formater(
            <>
              Informações <br /> do evento
            </>,
            "/admin/Evento/Batismo/infoBatismo/" +
              (this.state.eventId ? "?id=" + this.state.eventId : ""),
            this.state.eventId
              ? wizzardConfiguration.Status.Completed
              : wizzardConfiguration.Status.Enabled
          )
        );
      }

      if (this.state.formData.eventTypeId === "3") {
        wizardNodeConfiguration.push(
          wizzardConfiguration.Configuration.Formater(
            <>
              Informações <br /> do evento
            </>,
            "/admin/Evento/Missa/infoMissa/" +
              (this.state.eventId ? "?id=" + this.state.eventId : ""),
            this.state.eventId
              ? wizzardConfiguration.Status.Completed
              : wizzardConfiguration.Status.Enabled
          )
        );
      }

      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/documentos/?id=" + this.state.eventId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> do evento
          </>,
          "/admin/Evento/tipoEvento" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "/admin/Evento/tipoEvento" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/tipoEvento" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">Novo evento</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Form>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">
                    Selecione uma opção abaixo
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="2">
                <div className="form-group">
                  <select
                    disabled={this.state.eventId ? true : false}
                    name="eventTypeId"
                    id="eventTypeId"
                    ref="eventTypeId"
                    class={
                      "text-dark font-weight-900 form-control showFilterActive"
                    }
                    onChange={this.onInputChange}
                  >
                    <option value="">Tipo de evento</option>
                    <option
                      value="1"
                      selected={
                        this.state.formData.eventTypeId === "1" ? true : false
                      }
                    >
                      Batizado
                    </option>
                    <option
                      value="2"
                      selected={
                        this.state.formData.eventTypeId === "2" ? true : false
                      }
                    >
                      Casamento
                    </option>
                    <option
                      value="3"
                      selected={
                        this.state.formData.eventTypeId === "3" ? true : false
                      }
                    >
                      Missa
                    </option>
                  </select>
                </div>
              </Col>
            </Row>
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Eventos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    Salvar e continuar
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id });
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Event.Get +
      "/" +
      this.state.eventId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let formData = this.state.formData;

        if (ret.personId)
          window.localStorage.setItem(
            constants.LocalStorage.PersonId,
            ret.personId
          );

        formData.eventTypeId = ret.eventTypeId.toString();

        window.localStorage.setItem(
          constants.LocalStorage.EventType,
          formData.eventTypeId
        );

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadWizard();
  }

  //#endregion

  //#region Custom Events

  loadWizard() {
    const { eventId } = this.state;
    let id = parseInt(eventId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
    let data = { eventId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  handleSubmit() {
    let formData = this.state.formData;

    switch (formData.eventTypeId) {
      case "1":
        window.localStorage.setItem(constants.LocalStorage.EventType, 1);
        window.location.href =
          "/admin/Evento/Batismo/infoBatismo" +
          (this.state.eventId ? "/?id=" + this.state.eventId : "");

        break;

      case "2":
        window.localStorage.setItem(constants.LocalStorage.EventType, 2);
        window.location.href =
          "/admin/Evento/Casamento/infoCasamento" +
          (this.state.eventId ? "/?id=" + this.state.eventId : "");

        break;

      case "3":
        window.localStorage.setItem(constants.LocalStorage.EventType, 3);
        window.location.href =
          "/admin/Evento/Missa/infoMissa" +
          (this.state.eventId ? "/?id=" + this.state.eventId : "");

        break;

      default:
        window.location.href =
          "/admin/Evento/tipoEvento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : "");
    }

    this.loadWizard();
  }

  //#endregion
}

export default TipoEvento;
