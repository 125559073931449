//#region Imports

import React from "react";
import axios from "axios";
import constants from "variables/constants";
// reactstrap components
import DialogConfirm from "../../components/Utils/dialogConfirm.jsx";
import Loading from "../../components/Utils/Loading.jsx";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";

//#endregion

class Login extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial States

    this.state = {
      form: {
        email: "",
        password: "",
      },
      openConfirm: false,
      errorMessageLogin: "",
      loadingLogin: false,
      redirectLogin: false,
      redirectFirstAccess: false,
    };

    //#endregion

    //#region Event Binding

    this.inputsForm = this.inputsForm.bind(this);
    this.AuthUser = this.AuthUser.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    if (this.state.redirectFirstAccess) {
      return <Redirect to={"/auth/LoginPrimeiroAcesso"} />;
    } else if (this.state.redirectLogin) {
      return <Redirect to={"/admin/index"} />;
    }

    //#endregion
    return (
      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img
                alt="Nossa Senhora do Brasil"
                src={require("assets/img/brand/logo-nossa-senhora-do-brasil-2 1.png")}
              />
            </div>
            <Form role="form" onSubmit={this.AuthUser}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="cor-input"
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    value={this.state.form.email}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative cor-input mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="cor-input"
                    placeholder="Senha"
                    type="password"
                    name="password"
                    value={this.state.form.password}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
                {!this.state.openConfirm || (
                  <DialogConfirm
                    open={this.state.openConfirm}
                    title="Erro"
                    text={this.state.errorMessageLogin}
                    handleClose={() => {
                      this.setState({ openConfirm: false });
                    }}
                  />
                )}
              </FormGroup>
              <Row className="mt-3">
                <Col xs="12" className="text-white text-center">
                  <Link to="/auth/LoginEsqueciSenha">
                    <small className="login-link-form text-underline">
                      Esqueceu sua senha?
                    </small>
                  </Link>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="my-4 botao-laranja" type="submit">
                  {this.state.loadingLogin ? <Loading load="Sim" /> : "Entrar"}
                </Button>
              </div>
            </Form>
          </Container>
        </Col>
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  //#endregion

  //#region Custom Components

  AuthUser(e) {
    const { email, password } = this.state.form;
    if (email && password) {
      this.setState({ loadingLogin: true });

      const url = constants.Endpoint.UrlBase + constants.Endpoint.Auth.Login;
      let data = {
        email: email,
        password: password,
      };

      axios
        .post(url, data, {
          headers: { "Content-Type": "application/json" },
        })

        .then((response) => {
          if (
            !response ||
            !response.data ||
            !response.data.errors ||
            response.data.success == null
          ) {
            this.setState({
              openConfirm: true,
              errorMessageLogin: "Resposta fora do padrão esperado",
              loadingLogin: false,
            });
            return;
          }
          if (!response.data.success) {
            this.setState({
              openConfirm: true,
              errorMessageLogin: response.data.errors.join(".<br/>"),
              loadingLogin: false,
            });
            return;
          }

          if (response.data.response == null) {
            console.log("Resposta fora do padrão esperado");
            this.setState({
              openConfirm: true,
              errorMessageLogin: "Resposta fora do padrão esperado",
              loadingLogin: false,
            });
            return;
          }

          if (response.data.response.firstAccess) {
            this.setState({ redirectFirstAccess: true });
          }

          window.localStorage.setItem(
            constants.LocalStorage.Token,
            response.data.response.token
          );
          window.localStorage.setItem(
            constants.LocalStorage.UserName,
            response.data.response.profileName
          );
          window.localStorage.setItem(
            constants.LocalStorage.UserPhoto,
            response.data.response.profilePhoto
          );
          window.localStorage.setItem(
            constants.LocalStorage.AccessLevelId,
            response.data.response.accessLevelId
          );
          window.localStorage.setItem(
            constants.LocalStorage.AccessLevelName,
            response.data.response.accessLevelName
          );
          this.setState({
            openConfirm: false,
            errorMessageLogin: "",
            loadingLogin: false,
            redirectLogin: true,
          });
        })
        .catch((error) => {
          console.log('erro aqui',error)
          this.setState({
            renderPersonList: false,
            errorMessage: "Erro ao tentar consultar dados",
            loadingLogin: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        openConfirm: true,
        errorMessageLogin: "Os campos estão vázio",
      });
    }
    e.preventDefault();
  }

  inputsForm(e) {
    let form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form: form });
  }

  //#endregion
}

export default Login;
