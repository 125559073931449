/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";


const DialogConfirm = (props) => {
    const open = (!!props.open);
    const title = (props.title ? props.title : "Aviso");
    const text = (props.text ? props.text : "Deseja realmente fazer isso ?");
    const handleClose = (props.handleClose ? props.handleClose : () => { });
    const success = (props.success ? props.success : null);
    return (
        <>
        
        {(!open) || 
        
        <div>
            {(success) ?
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <span class="alert-inner--icon"><i class=""></i></span>
                    <span class="alert-inner--text ml-2"><strong class="mr-2">👍 {title}</strong>{text}</span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                        :
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <span class="alert-inner--icon"><i class=""></i></span>
                    <span class="alert-inner--text ml-2"><strong class="mr-2">👎 {title}</strong>{text}</span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            </div>
        }
        </>
    );
}



export default DialogConfirm;
