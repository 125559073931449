//#region Imports

import Modal from "react-modal";
import React from "react";
import DialogConfirm from "components/Utils/dialogConfirm.jsx";
import Wizard from "components/Utils/Wizard";
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import messages from "variables/messages";
import Loading from "components/Utils/Loading.jsx";

import { Link, Redirect } from "react-router-dom";

import { Container, Row, Col, Form } from "reactstrap";
import { objectToFormData } from "object-to-formdata";

//#endregion

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class documento extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      documentResponse: null,
      documentLoading: true,
      renderDocumentList: true,
      errorMessage: "",
      messageExclusion: "",
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      personId: 0,
      documentId: 0,
      confirmExclusion: false,
      openConfirm: false,
      selectedFileContent: null,
      selectedFileName: null,
      formData: {},
      authenticationFailure: false,
      loadingLogin: false,
      wizardResponse: null,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.confirmExclusion = this.confirmExclusion.bind(this);
    this.loadWizard = this.loadWizard.bind(this);
    this.loadPerson = this.loadPerson.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    let tableCollumns = [];
    let tableData = [];

    if (this.state.documentResponse) {
      tableCollumns = [
        {
          name: "Nome do documento",
          size: 50,
          property: "documentNameElement",
        },
        { name: "Data de upload", size: 30, property: "treatedDate" },
        { name: "", size: 10, property: "downloadButton" },
        { name: "", size: 10, property: "deleteButton" },
      ];

      tableData = this.state.documentResponse;

      tableData = tableData.map((item) => {
        let convertedDate = new Date(item.insertedDate);
        let treatedDate = "---";

        if (!isNaN(convertedDate.getTime()))
          treatedDate =
            ((convertedDate.getDate() + "").length === 1
              ? "0" + convertedDate.getDate()
              : convertedDate.getDate()) +
            "/" +
            ((convertedDate.getMonth() + "").length === 1
              ? "0" + convertedDate.getMonth()
              : convertedDate.getMonth()) +
            "/" +
            convertedDate.getFullYear();

        return {
          ...item,
          ...{
            treatedDate: treatedDate,
            documentNameElement: (
              <a href={item.documentUrl} target="_blank" rel="noopener noreferrer">
                {item.documentName}
              </a>
            ),
            downloadButton: (
              <a href={item.documentUrl} target="_blank" rel="noopener noreferrer">
                <button className="btn btn-sm edit">Baixar</button>
              </a>
            ),
            deleteButton: (
              <button
                className="btn btn-sm exc"
                key={item.id}
                onClick={() => this.openModal(item.id)}
              >
                Excluir
              </button>
            ),
          },
        };
      });
    }

    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "/admin/Pessoas/Pessoa/?id=" + this.state.personId,
          wizzardConfiguration.Status.Completed
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Familia/?id=" + this.state.personId,
          this.state.wizardResponse.hasFamily
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Grupo/?id=" + this.state.personId,
          this.state.wizardResponse.hasMinistry
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Atividade/?id=" + this.state.personId,
          this.state.wizardResponse.hasCourse
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Outras/?id=" + this.state.personId,
          this.state.wizardResponse.hasPersonAdditionalInfo
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Documento/?id=" + this.state.personId,
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Dizimo/?id=" + this.state.personId,
          this.state.wizardResponse.hasTithe
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.formData["name"]}
                  {!this.state.isDeceased || (
                    <small className="text-deceased"> - falecido(a)</small>
                  )}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Form>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div>
                  <div className="pt-4">
                    <h1 className="text-dark font-weight-900">
                      Upload de documentos
                    </h1>

                    <Row className="pl-3 pt-1">
                      <span className="small">
                        Formatos permitidos:{" "}
                        <b>jpg, jpeg, png, gif, bmp, svg, pdf, doc, docx</b>
                      </span>
                    </Row>

                    <div className="mt-4">
                      <button
                        type="button"
                        className="btn-person-search mr-2 "
                        onClick={() => {
                          this.refs.photoInput.click();
                        }}
                      >
                        Escolher arquivo
                      </button>
                      <input
                        ref="photoInput"
                        type="file"
                        className="input-img-hidden"
                        onClick={this.closeModal}
                        onChange={(e) => {
                          this.handleFile(e);
                        }}
                      />

                      {this.state.selectedFileName &&
                      this.state.selectedFileContent ? (
                        <>
                          <span className="selectedFile">
                            Arquivo Selecionado:{" "}
                            <span className="selectedFileName">
                              <strong>{this.state.selectedFileName}</strong>
                            </span>
                          </span>

                          <div className="mt-4">
                            <button
                              type="button"
                              className="btn-person-clean"
                              onClick={() => {
                                this.setState({
                                  selectedFileContent: null,
                                  selectedFileName: "",
                                });
                              }}
                            >
                              Remover
                            </button>
                            <button
                              type="button"
                              className="btn-info-person ml-4 "
                              onClick={() => {
                                this.handleSubmit();
                              }}
                            >
                              {this.state.loadingLogin ? (
                                <Loading load="Sim" />
                              ) : (
                                "Adicionar"
                              )}
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* <span className="selectedFile">Clique no botão ao lado para selecionar um arquivo</span> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>

          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div className="title-people pt-4 pb-5">
                {/* <h1>Documentos cadastrados</h1> */}

                {this.state.renderDocumentList ? (
                  <DataList
                    loading={this.state.documentLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={false}
                    exporting={false}
                  />
                ) : (
                  <>
                    <h1>Falha ao carregar listagem. =[ </h1>
                    <h2>{this.state.errorMessage}</h2>
                  </>
                )}
              </div>

              <div>
                {!this.state.confirmExclusao || (
                  <section class="container">
                    <Row>
                      <Col col="md-12">
                        <div
                          id="msgExclusion"
                          className={
                            " " +
                            (this.state.isVisibleDeleteMsg
                              ? "msgVisiblePerson"
                              : "")
                          }
                        >
                          <DialogConfirm
                            open={this.state.openConfirm}
                            title={this.state.namePerson}
                            text={this.state.messageExclusion}
                            success="Sim"
                            handleClose={() => {
                              this.setState({ openConfirm: false });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </section>
                )}

                {this.state.confirmExclusao || (
                  <section class="container">
                    <Row>
                      <Col col="md-12">
                        <div
                          id="msgExclusion"
                          className={
                            " " +
                            (this.state.isVisibleDeleteMsg
                              ? "msgVisiblePerson"
                              : "")
                          }
                        >
                          <DialogConfirm
                            open={this.state.openConfirm}
                            title="Erro"
                            text={this.state.messageExclusion}
                            handleClose={() => {
                              this.setState({ openConfirm: false });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </section>
                )}

                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <span className="float-lg-right x" onClick={this.closeModal}>
                    X
                  </span>
                  <h2 className="text-dark text-center pt-4 pb-4 text-modal">
                    Tem certeza de que deseja <br /> excluir este registro?
                  </h2>

                  <div className="text-center">
                    <button
                      id="alertExclusion"
                      className="alertExclusion btn btn-group button-yes mr-4"
                      key={this.state.documentId}
                      onClick={() => this.deleteDocument(this.state.documentId)}
                    >
                      Sim
                    </button>
                    <button
                      className="btn btn-group button-no"
                      onClick={this.closeModal}
                    >
                      Não
                    </button>
                  </div>
                </Modal>
              </div>
            </Col>
          </Row>
          <div className="container-fluid mb-9 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Pessoas"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <Link to={`/admin/Pessoas/dizimo/?id=${this.state.personId}`}>
                    <button className="btn-info-person ml-4">
                      Salvar e continuar
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {!this.state.authenticationFailure || <Redirect to={"/auth/Login"} />}
      </>
    );
  }

  //#region Events

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ personId: id });
  }

  componentDidMount() {
    this.loadPerson();
    this.loadWizard();
    this.loadDataList();
  }

  //#endregion

  //#region Custom Events

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      this.state.personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        formData.id = ret.id;
        formData.name = ret.name;

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Wizard;
    let data = { personId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  handleSubmit() {
    this.setState({ loadingLogin: true });

    let rawformData = {};

    rawformData.entitySourceId = 1;
    rawformData.entityId = this.state.personId;
    rawformData.documentName = this.state.selectedFileName;

    let formData = objectToFormData(rawformData);
    formData.append("imageFile", this.state.selectedFileContent);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.DocsAttached.Post;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            personLoading: false,
            renderPersonList: false,
            loadingLogin: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));

          return;
        }

        if (!result.success) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            loadingLogin: false,
            errorMessage: messages.Errors.ResponseError,
          });

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));

          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            loadingLogin: false,
            errorMessage: messages.Errors.BlankResponse,
          });

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));

          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          selectedFileContent: null,
          loadingLogin: false,
          selectedFileName: "",
        });
        this.loadDataList();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          loadingLogin: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  handleFile(e) {
    if (!e || !e.target || !e.target.files) {
      console.error(
        'Parametro "e" fora do formato "onchangeEvent" esperado',
        e
      );
      return;
    }

    if (e.target.files.length === 0) {
      this.setState({ selectedFileContent: null, selectedFileName: "" });
      return;
    }

    let file = e.target.files[0];

    if (!file) {
      console.error("Arquivo invalido", file);
      return;
    }

    let fileExtension = file.name.split(".").pop().toLowerCase();

    if (constants.AllowedExtensions.Document.indexOf(fileExtension) < 0) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro ao selecionar o arquivo, por favor verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li className="pl-4">
                    {"Extensão " +
                      fileExtension.toUpperCase() +
                      " não suportada."}
                  </li>
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    let fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.setState({ selectedFileContent: file, selectedFileName: file.name });
    };

    fileReader.readAsDataURL(file);
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  confirmExclusion(name) {
    this.loadDataList();

    if (name) {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        namePerson: name,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ documentId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  loadDataList() {
    this.setState({ personLoading: true });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.DocsAttached.GetByPerson;
    let data = "PersonId=" + this.state.personId;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.ResponseError, result);
          this.setState({
            documentLoading: false,
            renderDocumentList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          this.setState({
            documentLoading: false,
            renderDocumentList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse);
          this.setState({
            documentLoading: false,
            renderDocumentList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          documentLoading: false,
          renderDocumentList: true,
          errorMessage: "",
          documentResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          documentLoading: false,
          renderDocumentList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  deleteDocument(documentId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.DocsAttached.Delete +
      "/" +
      documentId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (!result || result.success == null) {
          this.setState({
            modalIsOpen: false,
            documentLoading: false,
            renderDocumentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          return;
        }

        if (!result.success) {
          this.setState({
            modalIsOpen: false,
            documentLoading: false,
            renderDocumentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
        }

        if (result.response == null) {
          this.setState({
            modalIsOpen: false,
            documentLoading: false,
            renderDocumentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          return;
        }

        this.setState({
          modalIsOpen: false,
          documentLoading: false,
          renderDocumentList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
          namePerson: "",
        });
        this.confirmExclusion(result.response.documentName);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderDocumentList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  //#endregion

  //#endregion
}

export default documento;
