import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { pt } from "date-fns/locale";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export function formatDate(date) {
  if (date) {
    const dateTimezone = utcToZonedTime(date, timezone);

    const dateFormatted = format(dateTimezone, "dd/MM/yyyy");
    return dateFormatted;
  }

  return "";
}

export function monthAndYear(date) {
  if (date) {
    const dateTimezone = utcToZonedTime(date, timezone);

    const monthAndYear = format(dateTimezone, "MMM/yyyy", { locale: pt });

    return monthAndYear;
  }

  return "";
}
