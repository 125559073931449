//#region Imports

import React from "react";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import "assets/scss/pages/print.scss";
import { Container, Row } from "reactstrap";

//#endregion

class ImprimirFicha extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      isVisibleDeleteMsg: false,
      formData: {},

      //Providers
      hasWeddingPlanner: false,
      hasDecoration: false,
      hasPhotography: false,
      hasMusic: false,
      hasVideo: false,
      hasWeddingHall: false,
    };

    //#endregion

    //#region Event Binding

    this.loadWeddingCeremony = this.loadWeddingCeremony.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    //#endregion

    return (
      <>
        <div>
          <Row lg="12" sm="12">
            <div className="logo-center">
              <img
                alt="Nossa Senhora do Brasil"
                src={require("assets/img/brand/logo-print.png")}
              />
            </div>
          </Row>

          <Row lg="12" className="justify-content-center mt-5">
            <h1 className="text-dark">Ficha de Cerimonial - Casamento</h1>
          </Row>
        </div>
        <Container className="mt-6 ceremony">
          <Row className="ml-1">
            <table>
              <tr>
                <td className="w-34">
                  <span className="text-family-two">
                    <b>Noivo: </b> &nbsp;
                    {!this.state.formData["groomName"] ||
                      this.state.formData["groomName"]}
                  </span>
                </td>

                <td className="w-34">
                  <span className="text-family-two">
                    <b>Celebrante: </b> &nbsp;
                    {!this.state.formData["celebrantName"] ||
                      this.state.formData["celebrantName"]}
                  </span>
                </td>
              </tr>

              <tr>
                <td className="w-34">
                  <span className="text-family-two">
                    <b>Noiva: </b> &nbsp;
                    {!this.state.formData["brideName"] ||
                      this.state.formData["brideName"]}
                  </span>
                </td>

                <td className="w-34">
                  <span className="text-family-two">
                    <b>Detalhes da cerimônia: </b> &nbsp;
                    {!this.state.formData["ceremonyDetails"] ||
                      this.state.formData["ceremonyDetails"]}
                  </span>
                </td>
                <td className="w-34 pt-2"></td>
              </tr>

              <tr>
                <td className="w-34">
                  <span className="text-family-two">
                    <b>Data: </b> &nbsp;
                    {!this.state.formData["eventDate"] ||
                      this.state.formData["eventDate"]}
                  </span>
                </td>
                <td className="w-34 pt-2"></td>
              </tr>

              <tr>
                <td className="w-34">
                  <span className="text-family-two">
                    <b>Horário: </b> &nbsp;
                    {!this.state.formData["eventTime"] ||
                      this.state.formData["eventTime"]}
                  </span>
                </td>

                <td className="w-34 pt-2"></td>
              </tr>
            </table>
          </Row>

          <Row lg="12" className="justify-content-center mt-6 pb-4">
            <h1 className="text-dark">Fornecedores</h1>
          </Row>

          {!this.state.hasWeddingPlanner || (
            <>
              <Row className="ml-1">
                <table>
                  <tr>
                    <td>
                      <h4 className="text-dark pb-1">Assessoria</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-15">
                      <span className="text-family-two">
                        {this.state.formData.weddingPlanner["name"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.weddingPlanner["mobilePhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.weddingPlanner["businessPhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.weddingPlanner["businessEmail"]}
                      </span>
                    </td>

                    <td className="w-15 pl-6">
                      <span className="text-family-two">
                        {this.state.formData.weddingPlanner["contactName"]}
                      </span>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row lg="12" className="justify-content-center">
                <div className="separatorProvider">
                  <hr />
                </div>
              </Row>
            </>
          )}

          {!this.state.hasDecoration || (
            <>
              <Row className="ml-1 pt-4">
                <table>
                  <tr>
                    <td>
                      <h4 className="text-dark pb-1">Decoração</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-15">
                      <span className="text-family-two">
                        {this.state.formData.decoration["name"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.decoration["mobilePhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.decoration["businessPhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.decoration["businessEmail"]}
                      </span>
                    </td>

                    <td className="w-15 pl-6">
                      <span className="text-family-two">
                        {this.state.formData.decoration["contactName"]}
                      </span>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row lg="12" className="justify-content-center">
                <div className="separatorProvider">
                  <hr />
                </div>
              </Row>
            </>
          )}

          {!this.state.hasPhotography || (
            <>
              <Row className="ml-1 pt-4">
                <table>
                  <tr>
                    <td>
                      <h4 className="text-dark pb-1">Fotografia</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-15">
                      <span className="text-family-two">
                        {this.state.formData.photography["name"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.photography["mobilePhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.photography["businessPhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.photography["businessEmail"]}
                      </span>
                    </td>

                    <td className="w-15 pl-6">
                      <span className="text-family-two">
                        {this.state.formData.photography["contactName"]}
                      </span>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row lg="12" className="justify-content-center">
                <div className="separatorProvider">
                  <hr />
                </div>
              </Row>
            </>
          )}

          {!this.state.hasMusic || (
            <>
              <Row className="ml-1 pt-4">
                <table>
                  <tr>
                    <td>
                      <h4 className="text-dark pb-1">Música</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-15">
                      <span className="text-family-two">
                        {this.state.formData.music["name"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.music["mobilePhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.music["businessPhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.music["businessEmail"]}
                      </span>
                    </td>

                    <td className="w-15 pl-6">
                      <span className="text-family-two">
                        {this.state.formData.music["contactName"]}
                      </span>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row lg="12" className="justify-content-center">
                <div className="separatorProvider">
                  <hr />
                </div>
              </Row>
            </>
          )}

          {!this.state.hasVideo || (
            <>
              <Row className="ml-1 pt-4">
                <table>
                  <tr>
                    <td>
                      <h4 className="text-dark pb-1">Vídeo</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-15">
                      <span className="text-family-two">
                        {this.state.formData.video["name"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.video["mobilePhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.video["businessPhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.video["businessEmail"]}
                      </span>
                    </td>

                    <td className="w-15 pl-6">
                      <span className="text-family-two">
                        {this.state.formData.video["contactName"]}
                      </span>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row lg="12" className="justify-content-center">
                <div className="separatorProvider">
                  <hr />
                </div>
              </Row>
            </>
          )}

          {!this.state.hasWeddingHall || (
            <>
              <Row className="ml-1 pt-4">
                <table>
                  <tr>
                    <td>
                      <h4 className="text-dark pb-1">Salão de festas</h4>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-15">
                      <span className="text-family-two">
                        {this.state.formData.weddingHall["name"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.weddingHall["mobilePhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.weddingHall["businessPhone"]}
                      </span>
                    </td>

                    <td className="w-15 pl-4">
                      <span className="text-family-two">
                        {this.state.formData.weddingHall["businessEmail"]}
                      </span>
                    </td>

                    <td className="w-15 pl-6">
                      <span className="text-family-two">
                        {this.state.formData.weddingHall["contactName"]}
                      </span>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row lg="12" className="justify-content-center">
                <div className="separatorProvider">
                  <hr />
                </div>
              </Row>
            </>
          )}

          <Row lg="12" className="justify-content-center mt-4 mb-6 button">
            <a
              className="btn button-print float-lg-right text-white mt-2"
              href="javascript:window.print()"
            >
              Imprimir ficha
            </a>
          </Row>
        </Container>
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id });
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Event.Get +
      "/" +
      this.state.eventId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;
        let eventWeddings = null;
        let eventReservations = null;
        let eventStartTime = "";
        let eventEndTime = "";

        function dateFormat(date) {
          let day = "";
          let month = "";
          let year = "";
          let dateFormated = "";

          day = new Date(date).getDate().toString();
          month = (new Date(date).getMonth() + 1).toString();
          year = new Date(date).getFullYear().toString();

          dateFormated =
            (day.toString().length === 1 ? "0" + day : day) +
            "/" +
            (month.toString().length === 1 ? "0" + month : month) +
            "/" +
            (year.toString().length === 1 ? "0" + year : year);

          return dateFormated;
        }

        //Event Weddings

        if (ret.eventWeddings) {
          if (ret.eventWeddings.length > 0) {
            eventWeddings = ret.eventWeddings[0];

            //Noivo
            if (eventWeddings.groomPersonId) {
              if (eventWeddings.groom) {
                if (eventWeddings.groom.name)
                  formData.groomName = eventWeddings.groom.name;
              }
            } else formData.groomName = eventWeddings.groomName;

            //Noiva
            if (eventWeddings.bridePersonId) {
              if (eventWeddings.bride) {
                if (eventWeddings.bride.name)
                  formData.brideName = eventWeddings.bride.name;
              }
            } else formData.brideName = eventWeddings.brideName;

            if (eventWeddings.companyName)
              formData.companyName = eventWeddings.companyName;
          }
        }

        //Event Reservations

        if (ret.eventReservations) {
          if (ret.eventReservations.length > 0) {
            eventReservations = ret.eventReservations[0];

            if (eventReservations.eventDate)
              formData.eventDate = dateFormat(eventReservations.eventDate);

            if (eventReservations.eventStartTime)
              eventStartTime = eventReservations.eventStartTime.substring(0, 5);

            if (eventReservations.eventEndTime)
              eventEndTime = eventReservations.eventEndTime.substring(0, 5);

            formData.eventTime = eventStartTime + " - " + eventEndTime;
          }
        }

        if (ret.person) {
          if (ret.person.name) formData.responsibleName = ret.person.name;

          if (ret.person.mobilePhone)
            formData.responsiblePhone = ret.person.mobilePhone;
          else {
            if (ret.person.homePhone)
              formData.responsiblePhone = ret.person.homePhone;
          }

          if (ret.person.email) formData.responsibleEmail = ret.person.email;
        } else {
          if (ret.responsibleName)
            formData.responsibleName = ret.responsibleName;

          if (ret.responsibleMobilePhone)
            formData.responsiblePhone = ret.responsibleMobilePhone;
          else {
            if (ret.responsibleHomePhone)
              formData.responsiblePhone = ret.responsibleHomePhone;
          }

          if (ret.responsibleEmail)
            formData.responsibleEmail = ret.responsibleEmail;
        }

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadWeddingCeremony();
  }

  loadWeddingCeremony() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.WeddingCeremony.GetByEvent;
    let data = "eventId=" + this.state.eventId;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;
        let ceremonyDetails = [];
        formData.weddingPlanner = {};
        formData.decoration = {};
        formData.photography = {};
        formData.music = {};
        formData.video = {};
        formData.weddingHall = {};

        if (ret.celebrant) {
          if (ret.celebrant.name) formData.celebrantName = ret.celebrant.name;
        }

        if (ret.isReligious) ceremonyDetails.push("Religioso");

        if (ret.hasCivilEffect) ceremonyDetails.push("Efeito Civil");

        if (ret.hasMass) ceremonyDetails.push("Missa");

        if (ret.hasCommunion) ceremonyDetails.push("Comunhão");

        if (ret.hasBells) ceremonyDetails.push("Sinos");

        if (ceremonyDetails.length > 0)
          formData.ceremonyDetails = ceremonyDetails.join(", ");

        if (ret.comments) formData.comments = ret.comments;

        if (ret.weddingPlannerProvider) {
          this.setState({ hasWeddingPlanner: true });

          if (ret.weddingPlannerProvider.name)
            formData.weddingPlanner.name = ret.weddingPlannerProvider.name;

          if (ret.weddingPlannerProvider.mobilePhone)
            formData.weddingPlanner.mobilePhone =
              ret.weddingPlannerProvider.mobilePhone;

          if (ret.weddingPlannerProvider.businessPhone)
            formData.weddingPlanner.businessPhone =
              ret.weddingPlannerProvider.businessPhone;

          if (ret.weddingPlannerProvider.businessEmail)
            formData.weddingPlanner.businessEmail =
              ret.weddingPlannerProvider.businessEmail;

          if (ret.weddingPlannerProvider.contactName)
            formData.weddingPlanner.contactName =
              ret.weddingPlannerProvider.contactName;
        }

        if (ret.decorationProvider) {
          this.setState({ hasDecoration: true });

          if (ret.decorationProvider.name)
            formData.decoration.name = ret.decorationProvider.name;

          if (ret.decorationProvider.mobilePhone)
            formData.decoration.mobilePhone =
              ret.decorationProvider.mobilePhone;

          if (ret.decorationProvider.businessPhone)
            formData.decoration.businessPhone =
              ret.decorationProvider.businessPhone;

          if (ret.decorationProvider.businessEmail)
            formData.decoration.businessEmail =
              ret.decorationProvider.businessEmail;

          if (ret.decorationProvider.contactName)
            formData.decoration.contactName =
              ret.decorationProvider.contactName;
        }

        if (ret.photographyProvider) {
          this.setState({ hasPhotography: true });

          if (ret.photographyProvider.name)
            formData.photography.name = ret.photographyProvider.name;

          if (ret.photographyProvider.mobilePhone)
            formData.photography.mobilePhone =
              ret.photographyProvider.mobilePhone;

          if (ret.photographyProvider.businessPhone)
            formData.photography.businessPhone =
              ret.photographyProvider.businessPhone;

          if (ret.photographyProvider.businessEmail)
            formData.photography.businessEmail =
              ret.photographyProvider.businessEmail;

          if (ret.photographyProvider.contactName)
            formData.photography.contactName =
              ret.photographyProvider.contactName;
        }

        if (ret.musicProvider) {
          this.setState({ hasMusic: true });

          if (ret.musicProvider.name)
            formData.music.name = ret.musicProvider.name;

          if (ret.musicProvider.mobilePhone)
            formData.music.mobilePhone = ret.musicProvider.mobilePhone;

          if (ret.musicProvider.businessPhone)
            formData.music.businessPhone = ret.musicProvider.businessPhone;

          if (ret.musicProvider.businessEmail)
            formData.music.businessEmail = ret.musicProvider.businessEmail;

          if (ret.musicProvider.contactName)
            formData.music.contactName = ret.musicProvider.contactName;
        }

        if (ret.videoProvider) {
          this.setState({ hasVideo: true });

          if (ret.videoProvider.name)
            formData.video.name = ret.videoProvider.name;

          if (ret.videoProvider.mobilePhone)
            formData.video.mobilePhone = ret.videoProvider.mobilePhone;

          if (ret.videoProvider.businessPhone)
            formData.video.businessPhone = ret.videoProvider.businessPhone;

          if (ret.videoProvider.businessEmail)
            formData.video.businessEmail = ret.videoProvider.businessEmail;

          if (ret.videoProvider.contactName)
            formData.video.contactName = ret.videoProvider.contactName;
        }

        if (ret.weddingHallProvider) {
          this.setState({ hasWeddingHall: true });

          if (ret.weddingHallProvider.name)
            formData.weddingHall.name = ret.weddingHallProvider.name;

          if (ret.weddingHallProvider.mobilePhone)
            formData.weddingHall.mobilePhone =
              ret.weddingHallProvider.mobilePhone;

          if (ret.weddingHallProvider.businessPhone)
            formData.weddingHall.businessPhone =
              ret.weddingHallProvider.businessPhone;

          if (ret.weddingHallProvider.businessEmail)
            formData.weddingHall.businessEmail =
              ret.weddingHallProvider.businessEmail;

          if (ret.weddingHallProvider.contactName)
            formData.weddingHall.contactName =
              ret.weddingHallProvider.contactName;
        }

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  //#endregion

  //#region Custom Events

  //#endregion
}

export default ImprimirFicha;
