//#region imports

import React from "react";
// reactstrap components
import { Link } from 'react-router-dom';
import Loading from '../../components/Utils/Loading.jsx';
import axios from 'axios';
import DialogConfirm from '../../components/Utils/dialogConfirm.jsx';
import constants from "variables/constants";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from "reactstrap";

//#endregion

class LoginEsqueciSenha extends React.Component {

  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial States

    this.state = {
      email: '',
      setSuccessConfirm: false,
      openConfirm: false,
      errorMessageEmail: '',
      loadingEmail: false
    }

    //#endregion

    //#region Event Binding

    this.submitEmail = this.submitEmail.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render
  render() {

    //#endregion
    return (
      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img
                alt="Nossa Senhora do Brasil"
                src={require("assets/img/brand/logo-nossa-senhora-do-brasil-2 1.png")}
              />
            </div>
            <Form role="form" onSubmit={this.submitEmail}>
              <Row className="pb-4">
                <Col xs="12" className="text-white text-center">
                  <small className="ft-16">Por favor, insira seu e-mail e mandaremos <br /> uma nova senha.</small>
                </Col>
              </Row>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input className="cor-input"
                    placeholder="E-mail"
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </InputGroup><br />
                {(this.state.setSuccessConfirm) ||
                  <DialogConfirm open={this.state.openConfirm}
                    title="Erro!"
                    text={this.state.errorMessageEmail}
                    handleClose={() => { this.setState({ openConfirm: false }) }} />
                }
                {(!this.state.setSuccessConfirm) ||
                  <DialogConfirm open={this.state.openConfirm}
                    title="Success!"
                    text={this.state.errorMessageEmail}
                    success="Sim"
                    handleClose={() => { this.setState({ openConfirm: false }) }} />
                }
              </FormGroup>
              <Col xs="12" className="text-white text-center pt-2">
                <Link to="/auth/Login">
                  <small className="login-link-form text-underline">Voltar para o Login</small>
                </Link>
              </Col>
              <div className="text-center">
                <Button className="my-4 botao-laranja" type="submit" value="Post">
                  {this.state.loadingEmail ?
                    <Loading load="Sim" /> :
                    'Enviar'
                  }
                </Button>
              </div>
            </Form>
          </Container>
        </Col>
      </>
    );
  }


  //#region Events

  //#endregion

  //#region Component Events

  //#endregion

  //#region Custom Components

  submitEmail(e) {
    this.setState({ setSuccessConfirm: false, openConfirm: false, errorMessageEmail: '' });
    const { email } = this.state;
    const url = constants.Endpoint.UrlBase + constants.Endpoint.Auth.ForgotPass;


    if (email) {
      this.setState({ loadingEmail: true });

      axios({
        method: 'POST',
        url: url,
        data: { email: email },
      })
        .then((response) => {

          if (!response || !response.data || !response.data.errors || response.data.success == null) {
            this.setState({ openConfirm: true, setSuccessConfirm: false, errorMessageEmail: 'Resposta fora do padrão esperado', loadingEmail: false });
            return;
          }

          if (!response.data.success) {
            this.setState({ openConfirm: true, setSuccessConfirm: false, errorMessageEmail: response.data.errors.join('.<br/>'), loadingEmail: false });
            console.log(response.data.errors);
            return;
          }

          if (response.data.response == null) {
            console.log("Resposta fora do padrão esperado");
            this.setState({ openConfirm: true, setSuccessConfirm: false, errorMessageEmail: 'Resposta fora do padrão esperado', loadingEmail: false });
            return;
          }

          this.setState({ openConfirm: true, setSuccessConfirm: true, errorMessageEmail: 'E-mail enviado', loadingEmail: false });
        })

        .catch((error) => {
          this.setState({ renderPersonList: false, errorMessage: 'Erro ao tentar consultar dados' });
        });
    }
    else {
      this.setState({ openConfirm: true, setSuccessConfirm: false, errorMessageEmail: 'Digite um e-mail válido', loadingEmail: false });
    }
    e.preventDefault();
  }

  //#endregion


}

export default LoginEsqueciSenha; 
