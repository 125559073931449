import React from "react";
import Modal from "react-modal";
import Papa from 'papaparse';

import Form from "reactstrap/lib/Form";
import { Link } from "react-router-dom";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";

import messages from "variables/messages";
import constants from "variables/constants";
import { formatDate } from "components/Utils/FormatDate";

import DataList from "components/Utils/dataList";
import { smoothApi } from "components/Utils/smoothApi";

import { Container, Row, Col } from "reactstrap";
import DialogConfirm from "components/Utils/dialogConfirm";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "540px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};


class Cursos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: { courseTypeIds: [], name: '', endDate: "", startDate: "" },
      courseList: [],

      hasLinkOpen: false,
      hasOpenSuccessModal: false,
      hasOpenModalDelete: false,
      hasOpenModalOfError: false,


      courseListResponse: null,
      courseID: 0,
      currentPage: 0,
      renderCourseList: false,
      messageExclusion: "",
      errorMessage: '',
      openEventNotFound: false,

      files: [],
      fileLoading: false,
      courseLoading: false,
      deleteCourseLoadig: false,
      courseNotFound: false,
      errorCourseNotFound: false,
    };

    this.submitSearch = this.submitSearch.bind(this);
    this.deleteCouse = this.deleteCouse.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalOfError = this.openModalOfError.bind(this);
    this.closeModalOfError = this.closeModalOfError.bind(this);
    this.openModalSuccess = this.openModalSuccess.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.closeModalSuccess = this.closeModalSuccess.bind(this);
    this.closeModalDelete = this.closeModalDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.readUploadFile = this.readUploadFile.bind(this);
    this.massRegistration = this.massRegistration.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFieldsFilter = this.clearFieldsFilter.bind(this);
  }




  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    let courseSelectItems = [];

    if (this.state.courseList.length) {
      try {
        courseSelectItems = this.state.courseList.map((item, index) => {
          return (
            <option
              selected={
                this.state.formData.courseTypeId === item.id ? true : false
              }
              value={item.id}
              key={"situation" + item.id}
            >
              {item.name}
            </option>
          );
        });
      } catch (ex) {
        courseSelectItems = [];
        console.error("Falha ao interpretar resposta", ex);
      }
    }

    if (
      this.state.courseListResponse &&
      this.state.courseListResponse.list &&
      this.state.courseListResponse.total
    ) {
      tableCollumns = [
        { name: "Tipo", size: 15, property: "type" },
        { name: "Nome", size: 15, property: "name" },
        { name: "Início", size: 15, property: "init" },
        { name: "Término", size: 15, property: "termination" },
        { name: "", size: 11, property: "editarButton" },
        { name: "", size: 11, property: "registerMassa" },
        { name: "", size: 11, property: "excluirButton" },
      ];


      tableData = this.state.courseListResponse.list.map((item) => {
        return {
          ...item,
          type: item.courseType.name,
          init: formatDate(item.startDate),
          termination: formatDate(item.endDate),
          editarButton: (
            <Link to={`/admin/Cursos/CadastroDeCurso/?id=${item.id}`}>
              <button className="btn btn-sm edit">Visualizar</button>
            </Link>
          ),
          registerMassa: (
            <>
              <button className="btn btn-sm edit" onClick={() => this.setState({
                courseID: item.id
              })}>
                <label className="label-none" id='label-none' htmlFor="upload" style={{ marginBottom: 0 }}>Importar</label>
              </button>
              <input
                className="d-none"
                type="file"
                name="upload"
                id="upload"
                onChange={(e) => this.readUploadFile(e)}
              />
            </>
          ),
          excluirButton: (
            <button
              type="button"
              className="btn btn-sm exc"
              key={item.id}
              onClick={() => {
                this.openModalDelete();
                this.setState({ courseID: item.id });
              }}
            >
              Excluir
            </button>
          ),
        };
      });

      total = this.state.courseListResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.submitSearch(index);
    };

    let exportHandler = (index) => {
      this.export();
    };


    const ModalSuccess = () => {
      return (
        <Modal
          isOpen={this.state.hasOpenSuccessModal}
          onRequestClose={this.closeModalSuccess}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="container">
            <span className="float-lg-right x" onClick={this.closeModalSuccess}>
              X
            </span>
          </div>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            Sucesso
          </h2>
          <div className="text-center">
            <h3 className="text-dark text-center pb-2 text-modal">
              Os inscritos foram importados com sucesso
            </h3>
            {
              this.state.files ? (
                <span>
                  {`Foram carregados ${this.state.files.length} registros`}
                </span>
              ) : null
            }
            <br />
            {
              this.state.errorMessage !== "" ? (
                this.state.errorMessage.map(message => (
                  <>
                    <span className="text-orange text-center pt-4 pb-4 text-modal">
                      {message}
                    </span>
                    <br />
                  </>

                ))
              ) : <></>
            }
          </div>
          <div>
            <button
              style={{ marginTop: 20, marginLeft: 200, marginRight: 200 }}
              className="btn btn-group button-no"
              onClick={() => {
                this.closeModalSuccess()
              }}
            >
              OK
            </button>
          </div>
        </Modal>)
    }

    const ModalDelete = () => {
      return (
        <Modal
          isOpen={this.state.hasOpenModalDelete}
          onRequestClose={this.closeModalDelete}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span className="float-lg-right x" onClick={this.closeModalDelete}>
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            Confirmação para excluir curso:
          </h2>
          {
            this.state.errorMessage !== "" ? (
              <h2 className="text-red text-center pt-4 pb-4 text-modal">
                {this.state.errorMessage}
              </h2>
            ) : <></>
          }

          <div className="text-center pt-4">
            {
              this.state.deleteCourseLoadig ? (<h1>Carregando...</h1>) : (
                <>
                  <button
                    className="btn btn-group button-no"
                    onClick={() => {
                      this.closeModalDelete()
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-group button-no"
                    onClick={() => {
                      this.deleteCouse(this.state.courseID)
                    }}
                  >
                    Excluir
                  </button>
                </>
              )
            }
          </div>
        </Modal>
      )
    }

    const ModalOfError = () => {
      return (
        <Modal
          isOpen={this.state.hasOpenModalOfError}
          onRequestClose={this.closeModalOfError}
          style={customStyles}
          contentLabel="Modal de Erro"
        >
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            Algo deu Errado !
            <br />
            Reveja o arquivo enviado e tente novamente.

          </h2>
          <div className="container d-flex justify-content-center">
            {
              this.state.errorMessage !== "" ? (
                this.state.errorMessage.map(message => (
                  <span className="text-red text-center pt-4 pb-4 text-modal">
                    {message}
                  </span>
                ))
              ) : <></>
            }
          </div>
          <div className="text-center pt-4">
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.closeModalOfError()
              }}
            >
              Voltar
            </button>
          </div>
        </Modal>
      )
    }


    return (
      <>
        <>
          <Modal
            isOpen={this.state.hasLinkOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Confirmação para inserção de inscritos:
            </h2>
            <div className="text-center">
              <>
                <h3 className="text-dark text-center pb-2 text-modal">
                  Você deseja apagar todos os participantes e substituí-los pelos que estão nesta lista ou manter os atuais participantes e apenas adicionar estes como novos?
                </h3>
                {
                  this.state.files ? (
                    <span>
                      {`Foram carregados ${this.state.files.length} registros`}
                    </span>
                  ) : null
                }
              </>

            </div>

            <div className="text-center pt-4">
              {
                this.state.fileLoading ? (<h1>Carregando...</h1>) : (
                  <>
                    <button
                      className="btn btn-group button-no"
                      onClick={() => {
                        this.massRegistration(true)
                      }}
                    >
                      Substituir todos
                    </button>
                    <button
                      className="btn btn-group button-no"
                      onClick={() => {
                        this.massRegistration(false)
                      }}
                    >
                      Adicionar novos
                    </button>
                  </>
                )
              }
            </div>

          </Modal >
        </>
        <>
          <ModalSuccess />
          <ModalDelete />
          <ModalOfError />
        </>
        <Container fluid className="minHeightFooter">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Cursos</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
            <Col lg="6">
              <Link to="/admin/Cursos/CadastroDeCurso">
                <button className="btn button-add-people float-lg-right text-white mt-2">
                  + Novo Registro
                </button>
              </Link>
            </Col>
          </Row>

          <Form>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div className="pt-4 pb-4">
                  <input
                    type="text"
                    id="name"
                    value={
                      this.state.formData["name"]
                        ? this.state.formData["name"]
                        : ""
                    }
                    class={
                      "form-control showFilterActive text-dark " +
                      (this.state.formData["name"] ? "filterActive" : "")
                    }
                    placeholder="Digite o nome do curso"
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div className="pt-4">
                  <h2 className="text-dark">Ou utilize os filtros abaixo:</h2>
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pt-4 pl-5">
              <Col lg="4">
                <div className="form-group">
                  <UncontrolledDropdown>
                    <label className="text-dark font-weight-900 pt-1">
                      Tipo de curso
                    </label>
                    <DropdownToggle
                      className={
                        "form-control text-dark font-weight-900  mt--1 text-left default-select " +
                        (this.state.formData.courseTypeIds.length > 0
                          ? "filterActive"
                          : "")
                      }
                      onClick={(e) => e.preventDefault()}
                    >
                      <label className="text-select font-weight-900 label-checkbox">
                        Selecione
                      </label>
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdown-menu-arrow col-12"
                      flip={false}
                    >
                      {this.state.courseList.length
                        ? this.state.courseList.map((item, index) => {
                          return (
                            <div class="custom-control custom-checkbox select-search">
                              <input
                                type="checkbox"
                                class={
                                  "custom-control-input input-checkbox"
                                }
                                name="courseTypeIds"
                                id={"baptism=" + item.id}
                                onChange={this.onInputChange}
                                checked={
                                  this.state.formData.courseTypeIds.indexOf(
                                    String(item.id)
                                  ) !== -1
                                    ? true
                                    : false
                                }
                              />
                              <label
                                class="custom-control-label pb-3"
                                for={"baptism=" + item.id}
                              >
                                {item.name}
                              </label>
                              <br />
                            </div>
                          );
                        })
                        : null}


                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
              <Col lg="4">
                <div class="form-group">
                  <label for="startDate" class="form-control-label text-dark">
                    Período (De)
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["startDate"] ? "filterActive" : "")
                    }
                    type="date"
                    id="startDate"
                    value={
                      this.state.formData["startDate"]
                        ? this.state.formData["startDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="4">
                <div class="form-group">
                  <label for="endDate" class="form-control-label text-dark">
                    Período (Até)
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["endDate"] ? "filterActive" : "")
                    }
                    type="date"
                    id="endDate"
                    value={
                      this.state.formData["endDate"]
                        ? this.state.formData["endDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-4 pt-5 pr-5">
              <Col lg="12">
                <div className="float-lg-right">
                  <button
                    className="btn-person-clean"
                    type="reset"
                    onClick={this.clearFieldsFilter}
                  >
                    Limpar
                  </button>
                  <button
                    className="btn-person-search ml-4"
                    type="button"
                    onClick={() => {
                      this.submitSearch(0);
                    }}
                  >
                    Buscar
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          {this.state.openEventNotFound ? (
            <section class="container" style={{ marginTop: 25 }}>
              <Row>
                <Col col="md-12">
                  <div
                    id="msgExclusion"
                    className={
                      " " +
                      (this.state.isVisibleDeleteMsg
                        ? "msgVisiblePerson"
                        : "")
                    }
                  >
                    <DialogConfirm
                      success="Sim"
                      open={this.state.openEventNotFound}
                      text={this.state.messageExclusion}
                      handleClose={() => {
                        this.setState({ openEventNotFound: false });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </section>
          ) : (
            <></>
          )}
          <div className="pt-5 pb-5 text-lg-center">
            <h2 className="text-dark font-weight-bold">Cursos cadastrados</h2>
          </div>
          <Row className="pt-4 pl-5">
            <Col col="12">
              {this.state.renderCourseList ? (
                <DataList
                  loading={this.state.eventLoading}
                  tableCollumns={tableCollumns}
                  tableData={tableData}
                  paging={true}
                  currentPage={this.state.currentPage}
                  pageSize={constants.Pagination.DefaultPageSize}
                  total={total}
                  pageChangeHandler={(index) => {
                    pageChangeHandler(index);
                  }}
                  exportHandler={exportHandler}
                  exporting={true}
                  exportProcessing={this.state.exporting}
                />
              ) : (
                <>
                  {this.state.courseNotFound ||
                    this.state.errorCourseNotFound || <h1>Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentDidMount() {
    this.submitSearch(0);
    this.getCourseType();
  }

  getCourseType() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.CourseType.getCourseType;
    const data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.errors || !result.response) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let courseList = result.response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            isValid: item.isValid,
          };
        });

        this.setState({
          courseList,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  openModal() {
    this.setState({
      hasLinkOpen: true,
    });
  }
  closeModal() {
    this.setState({
      hasLinkOpen: false,
    });
  }
  openModalSuccess() {
    this.setState({
      hasOpenSuccessModal: true,
    });
  }
  closeModalSuccess() {
    this.setState({
      hasOpenSuccessModal: false,
    });
  }
  openModalDelete() {
    this.setState({
      hasOpenModalDelete: true,
    });
  }
  openModalOfError() {
    this.setState({
      hasOpenModalOfError: true,
    });
  }
  closeModalOfError() {
    this.setState({
      hasOpenModalOfError: false,
    });
  }
  closeModalDelete() {
    this.setState({
      hasOpenModalDelete: false,
    });
  }
  readUploadFile(e) {
    e.preventDefault();
    const file = e.target.files[0]
    if (e.target.files) {
      const fileName = e.target.files[0].name.toLowerCase();

      if (!fileName.endsWith('.csv')) {
        alert('Por favor, selecione um arquivo Excel (.csv)');
        e.target.value = null;
        return;
      }
    }

    /**
     * 
     * @param {String} stringValue 
     * @returns {String}
     */
    function capitalizeFirstLetters(stringValue) {
      return stringValue.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    }
    /**
   * 
   * @param {String} stringValue 
   * @returns {String}
   */
    function removerCaractereEspecial(stringValue) {
      const regex = /^[^\w\s_]/;
      if (regex.test(stringValue)) {
        return stringValue.substring(1);
      }

      return stringValue;
    }

    Papa.parse(file, {
      header: true,
      encoding: "UTF-8",
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        const convertedFile = result.data.map(item => {
          return ({
            name: capitalizeFirstLetters(item.nomeCompleto),
            email: removerCaractereEspecial(item.email),
            Telefone: item.telefone.toString().replace(/[^\d]/g, ""),
            optIn: item.inptin,
            courseId: this.state.courseID,
            personId: 0,
            essayAnswer: 'Planilha',
            OptIn: item.optin,
          })
        })
        this.setState({
          files: convertedFile
        })
        this.openModal();
      },
    });
  }
  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage === undefined || currentPage === null) currentPage = 0;

    this.setState({
      courseLoading: true,
      currentPage: currentPage,
      // searchMode: true,
    });
    let formData = this.state.formData;

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Course.PagedSearch;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            errorCourseNotFound: true,
            // openErrorEventNotFound: true,
            // errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            errorCourseNotFound: true,
            // openErrorEventNotFound: true,
            // errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            errorCourseNotFound: true,
            // openErrorEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total === 0) {
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            courseNotFound: true,
            // openEventNotFound: true,
            // errorMessage: messages.Errors.BlankResponse,
            // messageExclusion: "Evento não encontrado",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          courseLoading: false,
          renderCourseList: true,
          courseNotFound: false,
          // openEventNotFound: false,
          // errorEventNotFound: false,
          // openErrorEventNotFound: false,
          errorMessage: "",
          courseListResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          courseLoading: false,
          renderCourseList: false,
          errorCourseNotFound: true,
          // openErrorEventNotFound: true,
          // errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  massRegistration(excludeExists) {
    if (this.state.files == null) return;

    this.setState({
      fileLoading: true,
    });
    let formData = this.state.files;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Course.CourseRegistration + "?excludeExists=" + excludeExists;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {

        if (
          !result.validationErrors ||
          result.systemErrors > 0
        ) {
          this.openModalOfError()
          this.closeModal()
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            fileLoading: false,
            errorMessage: result.systemErrors,
          });
          return;
        }
        if (
          !result.systemErrors ||
          result.validationErrors.length > 0
        ) {
          this.openModalOfError()
          this.closeModal()
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            fileLoading: false,
            errorMessage: result.validationErrors,
          });
          return;
        }



        this.closeModal();
        this.openModalSuccess();
        this.setState({
          fileLoading: false,
          errorMessage: result.personalizedMessage,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          fileLoading: false,
          // openErrorEventNotFound: true,
          // errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  deleteCouse(id) {
    this.setState({
      deleteCourseLoadig: true,
    });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Course.DeleteCourse + '/' + id
    let data = null


    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            deleteCourseLoadig: false,
            openErrorEventNotFound: true,
            errorMessage: result.errors,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            deleteCourseLoadig: false,
            openErrorEventNotFound: true,
            errorMessage: result.errors[0],
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            deleteCourseLoadig: false,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total === 0) {
          this.setState({
            deleteCourseLoadig: false,
            openEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }
        this.closeModalDelete()
        this.submitSearch(0);


        this.setState({
          deleteCourseLoadig: false,
          openEventNotFound: true,
          errorEventNotFound: false,
          openErrorEventNotFound: false,
          messageExclusion: "Curso excluido com sucesso",
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          deleteCourseLoadig: false,
          openEventNotFound: true,
        });
      }
    );
  }
  clearFieldsFilter = () => {
    this.setState({
      formData: { courseTypeIds: [], name: '', endDate: "", startDate: "" },
    });
  };

  onInputChange(event) {
    const target = event.target;
    let name = "";
    let value = "";
    let isDropdown = false;

    if (!target.name) {
      if (target.id.indexOf("=") !== -1) name = target.id.split("=")[1];
      else name = target.id;
    } else name = target.name;

    if (target.type === "checkbox") {
      isDropdown = true;

      if (this.state.formData[name].length > 0) {
        if (this.state.formData[name].indexOf(target.id.split("=")[1]) === -1) {
          value = target.id;
        } else value = "";
      } else value = target.id;
    } else {
      value = "";
      value = target.value;
    }

    if (isDropdown === false) {
      this.setState({
        formData: Object.assign(this.state.formData, { [name]: value }),
      });
    }

    value = value.split("=")[1];

    if (isDropdown) {
      if (value) {
        this.state.formData[name].push(value);
      } else {
        this.state.formData[name].splice(
          this.state.formData[name].indexOf(target.id.split("=")[1]),
          1
        );
      }
    }

    if (this.state.formData.courseTypeIds.length > 0)
      this.setState({ isChecked: true });
    else this.setState({ isChecked: false });
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Course.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          constants.Endpoint.UrlBase +
          constants.Endpoint.Course.Download +
          "?fileKey=" +
          result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default Cursos;
