//#region Imports

import React from "react";

import Modal from "react-modal";
import constants from "variables/constants";
import Wizard from "components/Utils/Wizard";
import InputSearch from "components/Utils/inputSearch";
import { Link, Redirect } from "react-router-dom";
import { smoothApi } from "components/Utils/smoothApi.js";
import messages from "variables/messages";
import { objectToFormData } from "object-to-formdata";
import { formater } from "components/Utils/formater";

import { Container, Row, Col, Form } from "reactstrap";

//#endregion

class Familia extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      pictures: [],
      formData: { childsId: [] },
      sugestedList: [],
      modalIsOpen: false,
      isDeceased: false,
      idModal: "",
      personId: -1,
      personDeleted: false,
      documentId: "",
      accessSystem: false,
      image: null,
      url: "",
      authenticationFailure: false,
      father: [],
      fatherId: -1,
      mother: [],
      motherId: -1,
      children: [],
      childrenId: [],
      spouse: [],
      spouseId: -1,
      addFieldChildren: [],
      moreChildren: [],
      wizardResponse: null,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.onInputChange = this.onInputChange.bind(this);
    this.loadWizard = this.loadWizard.bind(this);
    this.loadPerson = this.loadPerson.bind(this);
    this.loadFamily = this.loadFamily.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    let childs = [];
    let addChilds = [];

    if (this.state.children) {
      if (this.state.children.length > 0) {
        try {
          childs = this.state.children.map((item, contChild) => {
            contChild += 1;

            return (
              <InputSearch
                title={"Nome do filho(a)"}
                fieldId={"childName" + contChild}
                pageHandler="family"
                value={item}
                changeHandler={(id) => {
                  this.state.childrenId.push(id);
                }}
                deleteChild={(id) => {
                  this.state.childrenId.splice(
                    this.state.childrenId.indexOf(id),
                    1
                  );
                }}
                sugestedList={this.state.sugestedList}
                personId={this.state.personId}
                fatherId={this.state.fatherId}
                motherId={this.state.motherId}
                spouseId={this.state.spouseId}
                childrenId={this.state.childrenId}
                moreChildren={this.state.moreChildren}
                hasPhoto={true}
              />
            );
          });
        } catch (ex) {
          childs = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.addFieldChildren) {
      if (this.state.addFieldChildren.length > 0) {
        addChilds = this.state.addFieldChildren.map((item, contChild) => {
          contChild += 1;

          return (
            <InputSearch
              title={"Nome do filho(a)"}
              fieldId={"moreChildName" + contChild}
              pageHandler="family"
              changeHandler={(id) => {
                this.state.moreChildren.push(id);
              }}
              deleteChild={(id) => {
                this.state.moreChildren.splice(
                  this.state.moreChildren.indexOf(id),
                  1
                );
              }}
              sugestedList={this.state.sugestedList}
              personId={this.state.personId}
              fatherId={this.state.fatherId}
              motherId={this.state.motherId}
              spouseId={this.state.spouseId}
              childrenId={this.state.childrenId}
              moreChildren={this.state.moreChildren}
              hasPhoto={true}
            />
          );
        });
      }
    }

    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "/admin/Pessoas/Pessoa/?id=" + this.state.personId,
          wizzardConfiguration.Status.Completed
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Familia/?id=" + this.state.personId,
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Grupo/?id=" + this.state.personId,
          this.state.wizardResponse.hasMinistry
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Atividade/?id=" + this.state.personId,
          this.state.wizardResponse.hasCourse
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Outras/?id=" + this.state.personId,
          this.state.wizardResponse.hasPersonAdditionalInfo
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Documento/?id=" + this.state.personId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Dizimo/?id=" + this.state.personId,
          this.state.wizardResponse.hasTithe
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.formData["name"]}
                  {!this.state.isDeceased || (
                    <small className="text-deceased"> - falecido(a)</small>
                  )}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Form>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">Batismo</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900">
                    Nome do padrinho
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900"
                    id="baptismGodFather"
                    name="baptismGodFather"
                    value={this.state.formData["baptismGodFather"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900">
                    Nome da madrinha
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900"
                    id="baptismGodMother"
                    name="baptismGodMother"
                    value={this.state.formData["baptismGodMother"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900">Crisma</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900">
                    Nome do padrinho ou madrinha
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900"
                    id="chrismSponsor"
                    name="chrismSponsor"
                    value={this.state.formData["chrismSponsor"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5">Filiação</h2>
                </div>
              </Col>
            </Row>

            <InputSearch
              title="Nome do Pai"
              fieldId="fatherName"
              ref="fatherName"
              pageHandler="family"
              value={this.state.father}
              changeHandler={(id) => {
                this.setState({ fatherId: id });
              }}
              sugestedList={this.state.sugestedList}
              personId={this.state.personId}
              fatherId={this.state.fatherId}
              motherId={this.state.motherId}
              spouseId={this.state.spouseId}
              childrenId={this.state.childrenId}
              moreChildren={this.state.moreChildren}
              hasPhoto={true}
            />

            <InputSearch
              title="Nome da Mãe"
              fieldId="motherName"
              ref="motherName"
              pageHandler="family"
              value={this.state.mother}
              changeHandler={(id) => {
                this.setState({ motherId: id });
              }}
              sugestedList={this.state.sugestedList}
              personId={this.state.personId}
              fatherId={this.state.fatherId}
              motherId={this.state.motherId}
              spouseId={this.state.spouseId}
              childrenId={this.state.childrenId}
              moreChildren={this.state.moreChildren}
              hasPhoto={true}
            />

            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5">Cônjuge</h2>
                </div>
              </Col>
            </Row>
            <InputSearch
              title="Nome do cônjuge"
              fieldId="spouse"
              pageHandler="family"
              value={this.state.spouse}
              changeHandler={(id) => {
                this.setState({ spouseId: id });
              }}
              sugestedList={this.state.sugestedList}
              personId={this.state.personId}
              fatherId={this.state.fatherId}
              motherId={this.state.motherId}
              spouseId={this.state.spouseId}
              childrenId={this.state.childrenId}
              moreChildren={this.state.moreChildren}
              hasPhoto={true}
            />

            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-4">Filhos</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <button
                  type="button"
                  className="btn button-add-child text-white mb-3"
                  onClick={this.addFieldChildren}
                >
                  Adicionar filho
                </button>
              </Col>
            </Row>

            {this.state.children.length > 0 ? (
              <>{childs.length === 0 || childs}</>
            ) : (
              <>
                <InputSearch
                  title="Nome do filho(a)"
                  fieldId="defaultChildName"
                  pageHandler="family"
                  value={this.state.children}
                  changeHandler={(id) => {
                    this.state.childrenId.push(id);
                  }}
                  deleteChild={(id) => {
                    this.state.childrenId.splice(
                      this.state.childrenId.indexOf(id),
                      1
                    );
                  }}
                  sugestedList={this.state.sugestedList}
                  personId={this.state.personId}
                  fatherId={this.state.fatherId}
                  motherId={this.state.motherId}
                  spouseId={this.state.spouseId}
                  childrenId={this.state.childrenId}
                  moreChildren={this.state.moreChildren}
                  hasPhoto={true}
                />

                <InputSearch
                  title="Nome do filho(a)"
                  fieldId="ChildNamess"
                  pageHandler="family"
                  value={this.state.children}
                  changeHandler={(id) => {
                    this.state.childrenId.push(id);
                  }}
                  deleteChild={(id) => {
                    this.state.childrenId.splice(
                      this.state.childrenId.indexOf(id),
                      1
                    );
                  }}
                  sugestedList={this.state.sugestedList}
                  personId={this.state.personId}
                  fatherId={this.state.fatherId}
                  motherId={this.state.motherId}
                  spouseId={this.state.spouseId}
                  childrenId={this.state.childrenId}
                  moreChildren={this.state.moreChildren}
                  hasPhoto={true}
                />
              </>
            )}
          </Form>
          {/* aqui */}

          {!this.state.addFieldChildren.length > 0 || (
            <>{addChilds.length === 0 || addChilds}</>
          )}

          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Pessoas"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    Salvar e continuar
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {!this.state.authenticationFailure || <Redirect to={"/auth/Login"} />}
      </>
    );
  }

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({ personId: id });
    }
  }

  componentDidMount() {
    this.loadWizard();

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.GetBasicInfo;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let personList = result.response.map(function (item, indice) {
          return {
            id: item.id,
            name: item.name,
            pictureFileName: item.pictureFileName,
          };
        });

        this.setState({ sugestedList: personList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadPerson();
  }

  //#endregion

  //#region Custom Events

  addFieldChildren = () => {
    let addFieldChildren = this.state.addFieldChildren;

    addFieldChildren.push({ childrens: addFieldChildren.length + 1 });
    this.setState({ addFieldChildren: addFieldChildren });
  };

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "baptismGodFather":
          value = formater.maxLength(target.value, 300);
          break;

        case "baptismGodMother":
          value = formater.maxLength(target.value, 300);
          break;

        case "chrismSponsor":
          value = formater.maxLength(target.value, 300);
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
    let formData = this.state.formData;

    if (name === "baptismGodFatherSearch") formData.baptismGodFather = value;
  }

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      this.state.personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        formData.id = ret.id;
        formData.name = ret.name;
        formData.baptismGodFather = ret.baptismGodFather;
        formData.baptismGodMother = ret.baptismGodMother;
        formData.chrismSponsor = ret.chrismSponsor;

        if (ret.spouseId) this.loadSpouse(ret.spouseId);

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadFamily();
  }

  loadSpouse(personId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let spouse = this.state.spouse;
        let ret = result.response;

        spouse = ret;

        this.setState({ spouse: spouse });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadFamily() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Family.Search;
    let data = { personId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let father = this.state.father;
        let mother = this.state.mother;
        let spouse = this.state.spouse;
        let children = this.state.children;
        let childrenId = this.state.childrenId;

        let ret = result.response;
        formData.id = ret.id;

        father = ret.father;
        mother = ret.mother;
        spouse = ret.spouse;
        children = ret.children;

        if (children)
          children.map((item) => {
            childrenId.push(item.id);
          });

        if (father) {
          this.setState({ fatherId: father.id });
        }

        if (mother) {
          this.setState({ motherId: mother.id });
        }

        if (spouse) {
          this.setState({ spouseId: spouse.id });
        }

        this.setState({
          formData: formData,
          father: father,
          mother: mother,
          spouse: spouse,
          children: children,
          childrenId: childrenId,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Wizard;
    let data = { personId: id };

    if (personId > 0) {
      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          this.setState({ wizardResponse: result.response });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  handleSubmit() {
    let formData = this.state.formData;
    let childrenId = this.state.childrenId;
    let moreChildren = this.state.moreChildren;

    while (formData.childsId.length) {
      formData.childsId.pop();
    }

    formData.personId = this.state.personId;

    if (this.state.fatherId !== -1) formData.fatherId = this.state.fatherId;
    else formData.fatherId = null;

    if (this.state.motherId !== -1) formData.motherId = this.state.motherId;
    else formData.motherId = null;

    if (this.state.spouseId !== -1) formData.spouseId = this.state.spouseId;
    else formData.spouseId = null;

    childrenId.map((item) => {
      if (item !== undefined) formData.childsId.push(item);
    });

    moreChildren.map((item) => {
      if (item !== undefined) formData.childsId.push(item);
    });

    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Family.Put;
    let data = formData;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ errorMessage: messages.Errors.UnexpectedFormat });

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));

          return;
        }

        window.location.href =
          "/admin/Pessoas/outras/?id=" + this.state.personId;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
    this.loadWizard();
  }

  //#endregion
}

export default Familia;
