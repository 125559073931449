import React from "react";
import { dateHelper } from "components/Utils/dateHelper.js";

const Dating = (props) => {

    let defaultMonth = (new Date().getMonth() + 1);
    let defaultYear = (new Date().getFullYear());

    const year = (props.year ? props.year : defaultYear);
    const month = (props.month ? props.month : defaultMonth);
    const day = (props.day ? props.day : null);
    const markedDays = (props.markedDays ? props.markedDays : []);
    const dateClickHandler = (typeof props.dateClickHandler !== "function" ? () => { } : props.dateClickHandler);
    const navigationClickHandler = (typeof props.navigationClickHandler !== "function" ? () => { } : props.navigationClickHandler);

    let treatedMarkedDays = markedDays.map(

        (el) => {

            let treatedValue = 0;

            try {

                if (!isNaN(parseInt(el)))
                    treatedValue = parseInt(el);

            } catch {

                console.error("💁🏿‍♀️ Invalid 'markedDay' value: " + el);
                treatedValue = 0;

            }

            return treatedValue;

        }

    );

    let parsedMonth = parseInt(month);

    if (isNaN(parsedMonth)) {

        console.error("💁🏿‍♀️ Invalid 'month' value: " + month);
        parsedMonth = defaultMonth;

    }

    if (month < 1 || month > 12) {

        console.error("💁🏿‍♀️ Invalid 'month' value: " + month);
        parsedMonth = defaultMonth;

    }

    parsedMonth -= 1;
    let parsedYear = parseInt(year);

    if (isNaN(parsedYear)) {

        console.error("💁🏿‍♀️ Invalid 'year' value: " + year);
        parsedYear = defaultYear;

    }

    if (year < 1900 || year > 275760) {

        console.error("💁🏿‍♀️ Invalid 'year' value: " + year);
        parsedYear = defaultYear;

    }

    let parsedDay = null;

    if (day !== null) {

        parsedDay = parseInt(day);

        if (isNaN(parsedDay))
            parsedDay = null;

        if (day < 1 || day > 32) {

            console.error("💁🏿‍♀️ Invalid 'day' value: " + day);
            parsedDay = null;

        }

    }

    let monthName = '';

    switch (parsedMonth) {

        case 0:
            monthName = 'Janeiro';
            break;
        case 1:
            monthName = 'Fevereiro';
            break;
        case 2:
            monthName = 'Março';
            break;
        case 3:
            monthName = 'Abril';
            break;
        case 4:
            monthName = 'Maio';
            break;
        case 5:
            monthName = 'Junho';
            break;
        case 6:
            monthName = 'Julho';
            break;
        case 7:
            monthName = 'Agosto';
            break;
        case 8:
            monthName = 'Setembro';
            break;
        case 9:
            monthName = 'Outubro';
            break;
        case 10:
            monthName = 'Novembro';
            break;
        case 11:
            monthName = 'Dezembro';
            break;

    }

    let firstDay = new Date(parsedYear, parsedMonth, 1);
    let firstDayInWeek = firstDay.getDay();
    let daysCount = 0;
    let calendarDays = [];

    for (let x = 0; x < 6; x++) {

        for (let y = 0; y < 7; y++) {

            let loopDate = null;

            if (x === 0) {

                if (firstDayInWeek > y) {

                    let diff = y - firstDayInWeek;
                    loopDate = dateHelper.addDays(firstDay, diff);

                } else {

                    loopDate = dateHelper.addDays(firstDay, daysCount);
                    daysCount++;

                }

            } else {

                loopDate = dateHelper.addDays(firstDay, daysCount);
                daysCount++;

            }

            let sameMonth = (loopDate.getMonth() === firstDay.getMonth());

            if (sameMonth) {

                calendarDays.push(
                    <div className={"d_baseBox d_date" + ((loopDate.getDate() === parsedDay) ? " d_selected" : "")} key={"dateBlock_" + x + "_" + y} onClick={() => {

                        dateClickHandler(loopDate.getDate(), loopDate, sameMonth);

                    }}>

                        {loopDate.getDate()}

                        {

                            !!treatedMarkedDays.find((el) => { return el === loopDate.getDate() }) ?

                                <div className="d_boop" key={"boop_" + loopDate.getDate()}><i class="fas fa-circle"></i></div>

                                :

                                <></>

                        }

                    </div>
                );

            } else {

                calendarDays.push(
                    <div className={"d_baseBox d_date d_fadded"} key={"dateBlock_" + x + "_" + y} onClick={() => {

                        dateClickHandler(loopDate.getDate(), loopDate, sameMonth);

                    }}>

                        {loopDate.getDate()}

                    </div>
                );

            }

            if (loopDate.getDay() === 6) {

                calendarDays.push(
                    <div className="d_clear" key={"lineBreak_" + x + "_" + y}></div>
                );

            }

        }

    }

    return (
        <>

            <div>

                <div className="dating">

                    <div className="d_title">

                        <div onClick={() => { navigationClickHandler(true) }} className="d_pointer d_leftPointer"><i className="fas fa-chevron-left"></i></div>
                        <div className="d_month">{monthName} - {firstDay.getFullYear()}</div>
                        <div onClick={() => { navigationClickHandler(false) }} className="d_pointer d_rightPointer"><i className="fas fa-chevron-right"></i></div>
                        <div className="d_clear"></div>

                    </div>

                    <div className="d_header">

                        <div className="d_baseBox">D</div><div className="d_baseBox">S</div><div className="d_baseBox">T</div><div className="d_baseBox">Q</div><div className="d_baseBox">Q</div><div className="d_baseBox">S</div><div className="d_baseBox">S</div>

                    </div>

                    <div className="d_days">

                        {calendarDays}

                    </div>

                    <div className="d_clear"></div>

                </div>

            </div>

        </>
    );

}



export default Dating;
