import axios from 'axios';
import constants from "variables/constants";

export const smoothApi = {

    get: (url, data, acessToken, done, error) => {

        if (!url || !done)
            return;

        let authToken = null;
        let completeUrl = url;

        if (acessToken) {

            authToken = {};
            authToken[constants.Auth.DefaultHeader] = constants.Auth.DefaultScheme + ' ' + acessToken;

        }

        if (data) {

            completeUrl = completeUrl + "?";

            if (typeof data === typeof '')
                completeUrl = completeUrl + data;

            if (typeof data === typeof {}) {

                if (data.length === undefined) {

                    completeUrl = completeUrl + data.keys.reduce((acc, cur, index) => {

                        let returnValue = '';

                        if (index)
                            returnValue = '&';

                        returnValue = returnValue + encodeURI(data[cur]);
                        return acc + returnValue;

                    }, '');

                } else {

                    if (data.length > 0) {

                        completeUrl = completeUrl + data.reduce((acc, cur, index) => {

                            let returnValue = '';

                            if (index)
                                returnValue = '&';

                            returnValue = returnValue + encodeURI(cur);
                            return acc + returnValue;

                        }, '');

                    }

                }

            }

        }

        axios({
            method: 'GET',
            headers: authToken,
            url: completeUrl
        })
            .then((response) => {

                done(response.data);

            })
            .catch((response) => {

                if (!response || !response.response) {

                    console.error("Erro não tratado na função 'done' ", response);
                    return;

                }

                console.error("Falha ao realizar consulta, erro " + response.response.status, response);
                error(response.toJSON(), response.response.status);

            });

    },

    post: (url, data, acessToken, done, error) => {

        if (!url || !done)
            return;

        let authToken = null;
        let completeUrl = url;

        if (acessToken) {

            authToken = {};
            authToken[constants.Auth.DefaultHeader] = constants.Auth.DefaultScheme + ' ' + acessToken;

        }

        axios({
            method: 'POST',
            headers: authToken,
            data: data,
            url: completeUrl
        })
            .then((response) => {

                done(response.data);

            })
            .catch((response) => {

                if (!response || !response.response) {

                    console.error("Erro não tratado na função 'done' ", response);
                    return;

                }

                console.error("Falha ao realizar consulta, erro " + response.response.status, error);
                error(response.toJSON(), response.response.status);

            });

    },

    put: (url, data, acessToken, done, error) => {

        if (!url || !done)
            return;

        let authToken = null;
        let completeUrl = url;

        if (acessToken) {

            authToken = {};
            authToken[constants.Auth.DefaultHeader] = constants.Auth.DefaultScheme + ' ' + acessToken;

        }

        axios({
            method: 'PUT',
            headers: authToken,
            data: data,
            url: completeUrl
        })
            .then((response) => {

                done(response.data);

            })
            .catch((response) => {

                if (!response || !response.response) {

                    console.error("Erro não tratado na função 'done' ", response);
                    return;

                }

                console.error("Falha ao realizar consulta, erro " + response.response.status, error);
                error(response.toJSON(), response.response.status);

            });

    },

    delete: (url, data, acessToken, done, error) => {

        if (!url || !done)
            return;

        let authToken = null;
        let completeUrl = url;

        if (acessToken) {

            authToken = {};
            authToken[constants.Auth.DefaultHeader] = constants.Auth.DefaultScheme + ' ' + acessToken;

        }

        if (data) {

            completeUrl = completeUrl + "?";

            if (typeof data === typeof '')
                completeUrl = completeUrl + data;

            if (typeof data === typeof {}) {

                if (data.length === undefined) {

                    completeUrl = completeUrl + data.keys.reduce((acc, cur, index) => {

                        let returnValue = '';

                        if (index)
                            returnValue = '&';

                        returnValue = returnValue + encodeURI(data[cur]);
                        return acc + returnValue;

                    }, '');

                } else {

                    if (data.length > 0) {

                        completeUrl = completeUrl + data.reduce((acc, cur, index) => {

                            let returnValue = '';

                            if (index)
                                returnValue = '&';

                            returnValue = returnValue + encodeURI(cur);
                            return acc + returnValue;

                        }, '');

                    }

                }

            }

        }

        axios({
            method: 'DELETE',
            headers: authToken,
            url: completeUrl
        })
            .then((response) => {

                done(response.data);

            })
            .catch((response) => {

                if (!response || !response.response) {

                    console.error("Erro não tratado na função 'done' ", response);
                    return;

                }

                console.error("Falha ao realizar consulta, erro " + response.response.status, error);
                error(response.toJSON(), response.response.status);

            });

    },

    helpers: {

        convertBase64ToFile: (dataUrl, fileName) => {

            if (!dataUrl || !fileName)
                return undefined;

            if (typeof dataUrl !== "string" || typeof fileName !== "string")
                return undefined;

            try {

                let arr = dataUrl.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);

                while (n--) {

                    u8arr[n] = bstr.charCodeAt(n);

                }

                return new File([u8arr], fileName, { type: mime })

            } catch (ex) {

                console.error('Falha ao converter imagem', ex);
                console.error('dataUrl', dataUrl);
                console.error('fileName', fileName);
                return undefined;

            }

        },

        orderProperties: (formData) => {

            if (!formData)
                return null;

            if (typeof formData !== "object")
                return null;

            try {

                return Object.keys(formData).sort().reduce((acc, cur) => { acc[cur] = formData[cur]; return acc }, {});

            } catch (ex) {

                console.error('Falha ao ordenar propriedades', ex);
                console.error('formData', formData);
                return null;

            }

        },

        filterProperties: (formData, properties) => {

            if (!formData || !properties)
                return null;

            if (typeof formData !== "object" || typeof properties !== "object")
                return null;

            if (!properties.length)
                return null;

            if (properties.length === 0)
                return null;

            let validArray = false;

            try {

                validArray = properties.reduce((acc, cur) => {

                    if (typeof cur !== "string")
                        return false;

                    return acc;

                }, true);

            } catch {

                validArray = false;

            }

            if (!validArray)
                return null;

            try {

                return properties.reduce((acc, cur) => {

                    acc[cur] = formData[cur];
                    return acc;

                }, {});

            } catch (ex) {

                console.error('Falha ao filtrar propriedades', ex);
                console.error('formData', formData);
                console.error('properties', properties);
                return null;

            }

        }

    }

}