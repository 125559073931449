//#region Imports

import React from "react";
import Chart from "chart.js";
import { Link } from 'react-router-dom';
import ok from "assets/img/brand/home/Rectangle.png";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions
} from "variables/charts.jsx";

//#endregion

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark">Home</h1>
                <img className="rectangle-orange" alt="..." src={ok} />
              </div>
            </Col>
          </Row>
          <Row className="pl-5 pb-4 pt-5">
            <Col lg="12">
              <div className="">
                <h1 className="title text-dark">O que você deseja acessar?</h1>
                <small className="subtitle">
                  Escolha a opção desejada nos atalhos ou no menu lateral.
                </small>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="header pt-5 pt-md-6">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row className="mb-4">
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Pessoas">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/pessoas.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/pessoas-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Pessoas</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Pastorais">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/pastorais.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/pastorais-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Pastorais</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Financeiro">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/financeiro.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/financeiro-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Financeiro</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Eventos">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/convite.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/convite-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Eventos</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                {/* <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Cursos">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/cursos.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/cursos-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Cursos</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col> */}
                {/* <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/RegistroPresenca">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/presenca.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/presenca-white.png")}
                            />
                          </div>
                        </Row> 
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Registro de presença</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col> */}
                {/* <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Estatisticas">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/estatisticas.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/estatisticas-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Estatísticas</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col> */}
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/CadastrosAuxiliares">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/cadastros-auxiliares.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/cadastros-auxiliares-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Cadastros Auxiliares</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <Link to="/admin/Certificados">
                      <CardBody className="card-body">
                        <Row className="justify-content-center">
                          <div className="card-home">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/certificados.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-hover">
                            <img
                              alt="..."
                              src={require("assets/img/brand/home/certificados-white.png")}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-center">
                          <div className="card-title">
                            <h3 className="card-text">Certificados</h3>
                          </div>
                        </Row>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Index;
