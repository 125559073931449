//#region Imports

import Modal from "react-modal";
import React from "react";
import Wizard from "components/Utils/Wizard";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import Loading from "components/Utils/Loading.jsx";
import { formater } from "components/Utils/formater";
import { diruma } from "components/Utils/diruma";
import { Link } from "react-router-dom";
import DataList from "components/Utils/dataList.jsx";
import { dateHelper } from "components/Utils/dateHelper";
import { Container, Row, Col } from "reactstrap";

//#endregion



class Engajamento extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      accessLevelId: null,
      errorMessage: "",
      eventId: null,
      personName: null,
      formData: {},

      attendanceResponse: [],
      attendanceBulkDelete: [],
      attendanceEditingIndex: null,

      observationResponse: [],
      observationBulkDelete: [],
      observationEditingIndex: null,

      personResponse: [],

      message: {
        visible: false,
        leftButtomText: "Ok",
        rightButtomText: null,
        text: "teste",
        title: "",
        closeCallBack: () => {},
        leftButtomClickCallBack: () => {},
        rightButtomClickCallBack: () => {},
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Validations

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "attendanceDate",
          validations: [diruma.validations.date, diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "attendanceDescription",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "observationDate",
          validations: [diruma.validations.date, diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "observationDescription",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    //#endregion

    //#region Event Binding

    this.deleteAttendance = this.deleteAttendance.bind(this);
    this.saveAttendance = this.saveAttendance.bind(this);
    this.editAttendance = this.editAttendance.bind(this);
    this.cancelAttendanceEditing = this.cancelAttendanceEditing.bind(this);

    this.deleteObservation = this.deleteObservation.bind(this);
    this.saveObservation = this.saveObservation.bind(this);
    this.editObservation = this.editObservation.bind(this);
    this.cancelObservationEditing = this.cancelObservationEditing.bind(this);

    this.pageBulk = this.pageBulk.bind(this);
    this.observationBulk = this.observationBulk.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadWizard = this.loadWizard.bind(this);

    this.personGet = this.personGet.bind(this);
    this.eventAttendanceGet = this.eventAttendanceGet.bind(this);
    this.eventObservationGet = this.eventObservationGet.bind(this);

    //#endregion
  }

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Tipo do <br /> evento
          </>,
          "/admin/Evento/tipoEvento/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Completed
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> do evento
          </>,
          "/admin/Evento/Casamento/infoCasamento/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Completed
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "/admin/Evento/Casamento/data/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasDate
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/documentos/?id=" + this.state.eventId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "/admin/Evento/Casamento/testemunhas/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasWitness
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "/admin/Evento/Casamento/cerimonial/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasCeremony
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "/admin/Evento/Casamento/engajamento/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "/admin/Evento/Casamento/atividades/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasActivity
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Tipo do <br /> evento
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> do evento
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    let attendanceTableCollumns = [];
    let attendanceTableData = [];

    let observationTableCollumns = [];
    let observationTableData = [];

    let priestSelectItems = [];

    if (this.state.personResponse) {
      if (this.state.personResponse.length > 0) {
        try {
          priestSelectItems = this.state.personResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["attendancePriestPersonId"] === item.id
                    ? true
                    : false
                }
                value={item.id}
                key={"attendancePerson" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          priestSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (
      this.state.attendanceResponse.length &&
      this.state.personResponse.length
    ) {
      attendanceTableCollumns = [
        { name: "Descrição", size: 20, property: "description" },
        { name: "Data", size: 20, property: "date" },
        { name: "Sacerdote", size: 20, property: "priestName" },
        { name: "", size: 5, property: "editarButton" },
        { name: "", size: 5, property: "excluirButton" },
      ];

      this.state.attendanceResponse.forEach((item, index) => {
        let priestName = " --- ";
        let priest = this.state.personResponse.find(
          (search) => search.id === parseInt(item.priestPersonId)
        );

        if (priest) priestName = priest.name;

        let deletedItem = !!this.state.attendanceBulkDelete.find(
          (search) => item.id === search
        );

        if (!deletedItem) {
          let day = new Date(item.date).getDate().toString();
          let month = (new Date(item.date).getMonth() + 1).toString();
          let year = new Date(item.date).getFullYear().toString();

          attendanceTableData.push({
            ...item,
            ...{
              editarButton: (
                <button
                  type="button"
                  className="btn btn-sm edit"
                  key={index}
                  onClick={() => this.editAttendance(index)}
                >
                  Editar
                </button>
              ),
              excluirButton: (
                <button
                  type="button"
                  className="btn btn-sm exc"
                  key={index}
                  onClick={() => this.showDeleteAttendanceConfirm(index)}
                >
                  Excluir
                </button>
              ),
              description: item.description,
              date:
                (day.length === 1 ? "0" + day : day) +
                "/" +
                (month.length === 1 ? "0" + month : month) +
                "/" +
                (year.length === 1 ? "0" + year : year),
              priestName: priestName,
            },
          });
        }
      });
    }

    if (this.state.observationResponse.length) {
      observationTableCollumns = [
        { name: "Descrição", size: 20, property: "description" },
        { name: "Data", size: 20, property: "date" },
        { name: "", size: 5, property: "editarButton" },
        { name: "", size: 5, property: "excluirButton" },
      ];

      this.state.observationResponse.forEach((item, index) => {
        let deletedItem = !!this.state.observationBulkDelete.find(
          (search) => item.id === search
        );

        if (!deletedItem) {
          let day = new Date(item.date).getDate().toString();
          let month = (new Date(item.date).getMonth() + 1).toString();
          let year = new Date(item.date).getFullYear().toString();

          observationTableData.push({
            ...item,
            ...{
              editarButton: (
                <button
                  type="button"
                  className="btn btn-sm edit"
                  key={index}
                  onClick={() => this.editObservation(index)}
                >
                  Editar
                </button>
              ),
              excluirButton: (
                <button
                  type="button"
                  className="btn btn-sm exc"
                  key={index}
                  onClick={() => this.showDeleteObservationConfirm(index)}
                >
                  Excluir
                </button>
              ),
              description: item.description,
              date:
                (day.length === 1 ? "0" + day : day) +
                "/" +
                (month.length === 1 ? "0" + month : month) +
                "/" +
                (year.length === 1 ? "0" + year : year),
            },
          });
        }
      });
    }

    return (
      <>
        {/* //#region Modal */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              {!this.state.message.leftButtomText || (
                <button
                  className="btn btn-group button-no"
                  onClick={() => {
                    this.state.message._defaultCloseAction(this);
                    this.state.message.leftButtomClickCallBack();
                  }}
                >
                  {this.state.message.leftButtomText}
                </button>
              )}
              {!this.state.message.rightButtomText || (
                <button
                  className="btn btn-group button-no"
                  onClick={() => {
                    this.state.message._defaultCloseAction(this);
                    this.state.message.rightButtomClickCallBack();
                  }}
                >
                  {this.state.message.rightButtomText}
                </button>
              )}
            </div>
          </Modal>
        </>
        {/* //#endregion Modal */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">Novo casamento</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>

        <div className="container-fluid minHeightFooter mt-2">
          <Row className="pl-4">
            <Col>
              <div className="pt-6">
                <h2 className="text-dark mb-5 font-weight-900">
                  Atendimento com o sarcedote
                </h2>
              </div>
            </Col>
          </Row>
          <Row lg="12" className="pl-47">
            <Col lg="6">
              <div className="form-group">
                <label className="text-family font-weight-900 pb-1 mt-2">
                  Descrição
                  <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>
                </label>
                <input
                  className="form-control text-dark font-weight-900 mt--2"
                  ref="attendanceDescription"
                  id="attendanceDescription"
                  name="attendanceDescription"
                  value={
                    this.state.formData["attendanceDescription"]
                      ? this.state.formData["attendanceDescription"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
            <Col lg="3">
              <div class="form-group">
                <label
                  for="attendanceDate"
                  class="form-control-label text-dark font-weight-900"
                >
                  Data
                  <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>
                </label>
                <input
                  class="form-control text-dark"
                  id="attendanceDate"
                  name="attendanceDate"
                  ref="attendanceDate"
                  value={
                    this.state.formData["attendanceDate"]
                      ? this.state.formData["attendanceDate"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
            <Col lg="3">
              <div class="form-group">
                <label className="text-dark font-weight-900">Sarcedote</label>
                <select
                  name="attendancePriestPersonId"
                  id="attendancePriestPersonId"
                  className="form-control text-dark font-weight-900 mb-4"
                  onChange={this.onInputChange}
                >
                  <option value="">Selecione</option>
                  {priestSelectItems.length === 0 || priestSelectItems}
                </select>
              </div>
            </Col>
          </Row>
          <div className="container-fluid mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  {this.state.attendanceEditingIndex === null || (
                    <button
                      type="button"
                      className="btn-info-person-cancel mr-2"
                      onClick={() => {
                        this.cancelAttendanceEditing();
                      }}
                    >
                      Cancelar Edição
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn-person-search ml-4"
                    onClick={() => {
                      this.saveAttendance();
                    }}
                  >
                    {this.state.attendanceEditingIndex === null
                      ? "Adicionar"
                      : "Editar"}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="pt-4 pl-5">
            <Col lg="12">
              {this.state.attendanceResponse.length > 0 ? (
                <>
                  <DataList
                    tableCollumns={attendanceTableCollumns}
                    tableData={attendanceTableData}
                    paging={false}
                  />
                </>
              ) : (
                <> </>
              )}
            </Col>
          </Row>
        </div>

        {!(
          this.state.accessLevelId === "1" || this.state.accessLevelId === "3"
        ) || (
          <div className="container-fluid minHeightFooter ">
            <Row className="pl-4">
              <Col>
                <div className="pt-6">
                  <h2 className="text-dark mb-5 font-weight-900">Prontuário</h2>
                </div>
              </Col>
            </Row>

            <Row lg="12" className="pl-47">
              <Col lg="3">
                <div class="form-group">
                  <label
                    for="observationDate"
                    class="form-control-label text-dark font-weight-900"
                  >
                    Data
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  <input
                    class="form-control text-dark"
                    id="observationDate"
                    name="observationDate"
                    ref="observationDate"
                    value={
                      this.state.formData["observationDate"]
                        ? this.state.formData["observationDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="9">
                <div className="form-group">
                  <label className="text-family font-weight-900">
                    Sarcedote
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  <input
                    disabled
                    type="name"
                    className="form-control text-dark"
                    ref="observationPriest"
                    id="observationPriest"
                    name="observationPriest"
                    value={
                      this.state.formData["observationPriest"]
                        ? this.state.formData["observationPriest"]
                        : this.state.personName
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row lg="12" className="pl-47">
              <Col lg="12">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1 mt-2">
                    Observações
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  <textarea
                    rows="7"
                    className="form-control text-dark font-weight-900"
                    id="observationDescription"
                    name="observationDescription"
                    onChange={this.onInputChange}
                    value={
                      this.state.formData["observationDescription"]
                        ? this.state.formData["observationDescription"]
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>

            <div className="container-fluid mt-6">
              <Row>
                <Col>
                  <div className="float-lg-right">
                    {this.state.observationEditingIndex === null || (
                      <button
                        type="button"
                        className="btn-info-person-cancel mr-2"
                        onClick={() => {
                          this.cancelObservationEditing();
                        }}
                      >
                        Cancelar Edição
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn-person-search ml-4"
                      onClick={() => {
                        this.saveObservation();
                      }}
                    >
                      {this.state.observationEditingIndex === null
                        ? "Adicionar"
                        : "Editar"}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="pt-4 pl-5">
              <Col lg="12">
                {this.state.observationResponse.length > 0 ? (
                  <>
                    <DataList
                      tableCollumns={observationTableCollumns}
                      tableData={observationTableData}
                      paging={false}
                    />
                  </>
                ) : (
                  <> </>
                )}
              </Col>
            </Row>
          </div>
        )}

        <div className="container-fluid minHeightFooter mt-2">
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Eventos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>

                  <button
                    type="button"
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.pageBulk();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar e concluir"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let personName = window.localStorage.getItem(
      constants.LocalStorage.UserName
    );
    let accessLevelId = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelId
    );

    console.log("💥 accessLevelId 💥", accessLevelId);

    if (id)
      this.setState({
        eventId: id,
        personName: personName,
        accessLevelId: accessLevelId,
      });
  }

  componentDidMount() {
    this.personGet();
    this.eventAttendanceGet();
    this.eventObservationGet();
    this.loadWizard();
  }

  //#endregion

  //#region Custom Events

  //#region Attendance Events

  cancelAttendanceEditing() {
    //this.setState({ formData: {}, attendanceEditingIndex: null });
    this.setState((currentState) => ({
      attendanceEditingIndex: null,
      formData: {
        ...currentState.formData,
        ...{
          attendanceDescription: null,
          attendanceDate: null,
          attendancePriestPersonId: null,
        },
      },
    }));
  }

  showDeleteAttendanceConfirm(index) {
    this.setState((state) => ({
      message: {
        ...state.message,
        ...{
          leftButtomText: "Sim",
          rightButtomText: "Não",
          title: "Aviso",
          visible: true,
          closeCallBack: () => {},
          leftButtomClickCallBack: () => {
            this.deleteAttendance(index);
          },
          rightButtomClickCallBack: () => {
            alert("teste");
          },
          text: (
            <>
              Deseja realmente <span className="errorText"> excluir </span> esse
              registro?
            </>
          ),
        },
      },
    }));
  }

  deleteAttendance(index) {
    let attendanceResponse = this.state.attendanceResponse;
    let attendanceBulkDelete = this.state.attendanceBulkDelete;

    if (!this.state.attendanceResponse[index]) {
      console.error("Falha ao encontrar indice de exclusão");
      return;
    }

    let removed = attendanceResponse.splice(index, 1)[0];

    if (removed.id) attendanceBulkDelete.push(removed.id);

    if (this.state.attendanceEditingIndex === null) {
      this.setState({
        attendanceResponse: attendanceResponse,
        attendanceBulkDelete: attendanceBulkDelete,
      });
    } else {
      if (this.state.attendanceEditingIndex === index) {
        this.setState({
          attendanceResponse: attendanceResponse,
          attendanceEditingIndex: null,
          attendanceBulkDelete: attendanceBulkDelete,
        });
      } else {
        if (this.state.attendanceEditingIndex > index)
          this.setState({
            attendanceResponse: attendanceResponse,
            attendanceEditingIndex: this.state.attendanceEditingIndex - 1,
            attendanceBulkDelete: attendanceBulkDelete,
          });
        else
          this.setState({
            attendanceResponse: attendanceResponse,
            attendanceBulkDelete: attendanceBulkDelete,
          });
      }
    }
  }

  editAttendance(index) {
    let editItem = this.state.attendanceResponse[index];

    if (!editItem) {
      console.error("Falha ao encontrar indice de edição");
      return;
    }

    let date = dateHelper.formatToBrazilian(editItem.date); //new Date(editItem.date.split("/")[1] + '/' + editItem.date.split("/")[0] + '/' + editItem.date.split("/")[2]);
    let description = editItem.description;
    let priestPersonId = editItem.priestPersonId;
    this.setState({
      attendanceEditingIndex: index,
      formData: {
        attendanceDate: date,
        attendanceDescription: description,
        attendancePriestPersonId: priestPersonId,
      },
    });
  }

  saveAttendance() {
    // let validations = diruma.validadeOnChange(this.validation, this.state.formData, null, true);

    // if (!validations.sucess) {

    //     this.setState((state) => ({
    //         message: {
    //             ...state.message, ...{
    //                 leftButtomText: "Ok",
    //                 rightButtomText: null,
    //                 title: 'Aviso',
    //                 visible: true,
    //                 closeCallBack: () => { },
    //                 leftButtomClickCallBack: () => { },
    //                 rightButtomClickCallBack: () => { },
    //                 text: <>
    //                     Por favor verifique os campos destacados em <span className="errorText"> vermelho </span> para prosseguir:

    //                     <ul className="errorSumary">
    //                         {

    //                             validations.errors.map((el, currentIndex) => {

    //                                 return <li key={"validationItem_" + currentIndex} >{el}</li>

    //                             })

    //                         }
    //                     </ul>
    //                 </>

    //             }
    //         }
    //     }));

    //     return;

    // }

    let formData = this.state.formData;
    let attendanceResponse = this.state.attendanceResponse;
    let date = new Date(
      formData.attendanceDate.split("/")[1] +
        "/" +
        formData.attendanceDate.split("/")[0] +
        "/" +
        formData.attendanceDate.split("/")[2]
    );
    let description = formData.attendanceDescription;
    let priestPersonId = formData.attendancePriestPersonId;
    let eventId = this.state.eventId;

    if (this.state.attendanceEditingIndex === null) {
      attendanceResponse.push({
        date: date,
        description: description,
        priestPersonId: priestPersonId,
        eventId: eventId,
      });
    } else {
      let index = this.state.attendanceEditingIndex;

      if (!this.state.attendanceResponse[index]) {
        console.error("Falha ao encontrar indice de edição");
        return;
      }

      attendanceResponse[index].date = date;
      attendanceResponse[index].description = description;
      attendanceResponse[index].priestPersonId = priestPersonId;
    }

    //this.setState({ attendanceResponse: attendanceResponse, attendanceEditingIndex: null, formData: {} });
    this.setState((currentState) => ({
      attendanceResponse: attendanceResponse,
      attendanceEditingIndex: null,
      formData: {
        ...currentState.formData,
        ...{
          attendanceDescription: null,
          attendanceDate: null,
          attendancePriestPersonId: null,
        },
      },
    }));
  }

  //#endregion

  //#region Observation Events

  cancelObservationEditing() {
    //this.setState({ formData: {}, observationEditingIndex: null });
    this.setState((currentState) => ({
      observationEditingIndex: null,
      formData: {
        ...currentState.formData,
        ...{
          observationDescription: null,
          observationDate: null,
          observationPriest: null,
        },
      },
    }));
  }

  showDeleteObservationConfirm(index) {
    this.setState((state) => ({
      message: {
        ...state.message,
        ...{
          leftButtomText: "Sim",
          rightButtomText: "Não",
          title: "Aviso",
          visible: true,
          closeCallBack: () => {},
          leftButtomClickCallBack: () => {
            this.deleteObservation(index);
          },
          rightButtomClickCallBack: () => {
            alert("teste");
          },
          text: (
            <>
              Deseja realmente <span className="errorText"> excluir </span> esse
              registro?
            </>
          ),
        },
      },
    }));
  }

  deleteObservation(index) {
    let observationResponse = this.state.observationResponse;
    let observationBulkDelete = this.state.observationBulkDelete;

    if (!this.state.observationResponse[index]) {
      console.error("Falha ao encontrar indice de exclusão");
      return;
    }

    let removed = observationResponse.splice(index, 1)[0];

    if (removed.id) observationBulkDelete.push(removed.id);

    if (this.state.observationEditingIndex === null) {
      this.setState({
        observationResponse: observationResponse,
        observationBulkDelete: observationBulkDelete,
      });
    } else {
      if (this.state.observationEditingIndex === index) {
        this.setState({
          observationResponse: observationResponse,
          observationEditingIndex: null,
          observationBulkDelete: observationBulkDelete,
        });
      } else {
        if (this.state.observationEditingIndex > index)
          this.setState({
            observationResponse: observationResponse,
            observationEditingIndex: this.state.observationEditingIndex - 1,
            observationBulkDelete: observationBulkDelete,
          });
        else
          this.setState({
            observationResponse: observationResponse,
            observationBulkDelete: observationBulkDelete,
          });
      }
    }
  }

  editObservation(index) {
    let editItem = this.state.observationResponse[index];

    if (!editItem) {
      console.error("Falha ao encontrar indice de edição");
      return;
    }

    let date = dateHelper.formatToBrazilian(editItem.date); //new Date(editItem.date.split("/")[1] + '/' + editItem.date.split("/")[0] + '/' + editItem.date.split("/")[2]);
    let description = editItem.description;
    let priestName = null;
    let priest = this.state.personResponse.find(
      (search) => search.id === editItem.priestPersonId
    );

    if (priest) priestName = priest.name;

    this.setState({
      observationEditingIndex: index,
      formData: {
        observationDate: date,
        observationDescription: description,
        observationPriest: priestName,
      },
    });
  }

  saveObservation() {
    // let validations = diruma.validadeOnChange(this.validation, this.state.formData, null, true);

    // if (!validations.sucess) {

    //     this.setState((state) => ({
    //         message: {
    //             ...state.message, ...{
    //                 leftButtomText: "Ok",
    //                 rightButtomText: null,
    //                 title: 'Aviso',
    //                 visible: true,
    //                 closeCallBack: () => { },
    //                 leftButtomClickCallBack: () => { },
    //                 rightButtomClickCallBack: () => { },
    //                 text: <>
    //                     Por favor verifique os campos destacados em <span className="errorText"> vermelho </span> para prosseguir:

    //                     <ul className="errorSumary">
    //                         {

    //                             validations.errors.map((el, currentIndex) => {

    //                                 return <li key={"validationItem_" + currentIndex} >{el}</li>

    //                             })

    //                         }
    //                     </ul>
    //                 </>

    //             }
    //         }
    //     }));

    //     return;

    // }

    let formData = this.state.formData;
    let observationResponse = this.state.observationResponse;
    let date = new Date(
      formData.observationDate.split("/")[1] +
        "/" +
        formData.observationDate.split("/")[0] +
        "/" +
        formData.observationDate.split("/")[2]
    );
    let description = formData.observationDescription;
    let eventId = this.state.eventId;

    if (this.state.observationEditingIndex === null) {
      observationResponse.push({
        date: date,
        description: description,
        eventId: eventId,
      });
    } else {
      let index = this.state.observationEditingIndex;

      if (!this.state.observationResponse[index]) {
        console.error("Falha ao encontrar indice de edição");
        return;
      }

      observationResponse[index].date = date;
      observationResponse[index].description = description;
    }

    this.setState({
      observationResponse: observationResponse,
      observationEditingIndex: null,
      formData: {},
    });
  }

  //#endregion

  loadWizard() {
    const { eventId } = this.state;
    let id = parseInt(eventId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
    let data = { eventId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  pageBulk() {
    if (
      this.state.attendanceBulkDelete.length === 0 &&
      this.state.attendanceResponse.length === 0 &&
      this.state.observationBulkDelete.length === 0 &&
      this.state.observationResponse.length === 0
    )
      window.location.href = "/admin/Eventos";
    //let formDataApi = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);

    let urlAttendance =
      constants.Endpoint.UrlBase + constants.Endpoint.EventAttendance.Bulk;
    let datattendance = {
      toSave: this.state.attendanceResponse,
      toDelete: this.state.attendanceBulkDelete,
    };

    smoothApi.post(
      urlAttendance,
      datattendance,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            changeLoading: false,
          }));

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                closeCallBack: () => {},
                leftButtomClickCallBack: () => {},
                rightButtomClickCallBack: () => {},
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            changeLoading: false,
          }));

          return;
        }

        if (result.response == null) {
          this.setState({
            errorMessage: messages.Errors.BlankResponse,
            submitLoading: false,
            changeLoading: false,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.observationBulk();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          changeLoading: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  observationBulk() {
    if (
      this.state.observationBulkDelete.length === 0 &&
      this.state.observationResponse.length === 0
    )
      window.location.href = "/admin/Eventos";
    //let formDataApi = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.EventObservation.Bulk;
    let data = {
      toSave: this.state.observationResponse,
      toDelete: this.state.observationBulkDelete,
    };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            changeLoading: false,
          }));

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                closeCallBack: () => {},
                leftButtomClickCallBack: () => {},
                rightButtomClickCallBack: () => {},
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            changeLoading: false,
          }));

          return;
        }

        if (result.response == null) {
          this.setState({
            errorMessage: messages.Errors.BlankResponse,
            submitLoading: false,
            changeLoading: false,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        window.location.href =
          "/admin/Evento/Casamento/atividades/?id=" + this.state.eventId;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          changeLoading: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "attendanceDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "attendanceDescription":
          value = formater.maxLength(target.value, 200);
          break;

        case "observationDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "observationDescription":
          value = formater.maxLength(target.value, 200);
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      target,
      false
    );
  }

  personGet() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.GetBasicInfoByAccessLevel;

    let data = {
      accessLevelIds: [1, 3],
    };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        )
          return;

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let tratedResponse = result.response;

        this.setState({ personResponse: tratedResponse });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  eventAttendanceGet() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.EventAttendance.GetByEvent +
      "?eventId=" +
      this.state.eventId;

    smoothApi.get(
      url,
      null,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        )
          return;

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ attendanceResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  eventObservationGet() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.EventObservation.GetByEvent +
      "?eventId=" +
      this.state.eventId;

    smoothApi.get(
      url,
      null,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        )
          return;

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ observationResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
  //#endregion
}

export default Engajamento;
