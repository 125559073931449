/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="admin-footer">
        <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="5">
                <div className="text-center texto-azul-footer text-xl-left">
                  <p>Feito com
                  <img
                  className="p-1"
                      alt="Amor"
                      src={require("assets/img/brand/heart 1.png")}
                    />
                    pela Mult-Connect</p>
                </div>
              </Col>
              <Col xl="7">
                <div className="text-center texto-azul-footer">
                  <p>Paróquia Nossa Senhora do Brasil - Todos os direitos reservados
                  <img
                      className="pl-1 pb-2"
                      alt="Nossa Senhora do Brasil"
                      src={require("assets/img/brand/nsb-01 1.png")}
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
      </footer>
    );
  }
}

export default Footer;
