import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import messages from "variables/messages";
import constants from "variables/constants";

import { diruma } from "components/Utils/diruma";
import Loading from "components/Utils/Loading.jsx";
import { formater } from "components/Utils/formater";
import DataList from "components/Utils/dataList.jsx";
import InputSearch from "components/Utils/inputSearch";
import { smoothApi } from "components/Utils/smoothApi.js";
import ButtonGroupNsb from "components/Utils/buttonGroupNsb";
import DialogConfirm from "components/Utils/dialogConfirm.jsx";

import { Container, Row, Col, Form, UncontrolledTooltip } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class CadastroEntrada extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payerNameOption: "payerName",

      messageExclusion: "",
      messageSubmit: "",
      openConfirm: false,
      confirmSubmit: false,
      submitLoading: false,
      deleteLoading: false,
      addPaymentLoading: false,
      exclusaoModal: false,
      alreadyError: false,
      formData: {},
      serviceResponse: null,
      renderPaymentList: false,
      paymentMethodResponse: null,
      eventBaptizedResponse: null,
      eventMassesResponse: null,
      eventWeddingsResponse: null,
      incomeTypeResponse: null,
      paymentResponse: [],
      tempEventData: null,
      tempEventType: null,
      onlyView: false,
      sugestedList: [],
      modalIsOpen: false,
      personId: 0,
      paymentId: 0,
      entryId: 0,
      totalAmount: 0,
      indicePayment: -1,
      accessLevelId: 0,
      accessLevelName: "",
      sugestedPayer: {},
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.deletePayment = this.deletePayment.bind(this);
    this.loadSugestedPerson = this.loadSugestedPerson.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    // let totalAmount = 0;
    let paymentMethodSelectItems = [];
    let incomeTypeSelectItems = [];
    let eventSelectItems = [];

    if (this.state.paymentResponse) {
      tableCollumns = [
        { name: "Nome do pagante", size: 20, property: "payerName" },
        { name: "Valor", size: 13, property: "amountReal" },
        { name: "Forma de pagamento", size: 14, property: "paymentMethod" },
        { name: "Descrição", size: 16, property: "description" },
        { name: "Nº do documento", size: 13, property: "documentNumber" },
        { name: "", size: 5, property: "excluirButton" },
      ];

      tableData = this.state.paymentResponse;

      tableData = tableData.map((item, index) => {
        let paymentMethod = "";
        let treatedAmount = item.amount;

        if (!item.paymentMethod) {
          if (item.paymentMethodId) {
            if (parseInt(item.paymentMethodId) === 1)
              paymentMethod = "Dinheiro";

            if (parseInt(item.paymentMethodId) === 2) paymentMethod = "Cheque";

            if (parseInt(item.paymentMethodId) === 3) paymentMethod = "Boleto";

            if (parseInt(item.paymentMethodId) === 4)
              paymentMethod = "Cartão - Débito";

            if (parseInt(item.paymentMethodId) === 5)
              paymentMethod = "Cartão - Crédito";

            if (parseInt(item.paymentMethodId) === 6)
              paymentMethod = "Transferência bancária";
          }
        }

        if (treatedAmount.indexOf("R$") !== -1)
          treatedAmount = formater.numberMonetary(treatedAmount);

        if (
          treatedAmount.length === 1 ||
          treatedAmount.length === 2 ||
          treatedAmount.length > 4
        ) {
          treatedAmount = parseFloat(treatedAmount);
          treatedAmount = treatedAmount.toFixed(2);
        }

        let amount = formater.monetaryBRL(treatedAmount + "", true);

        return {
          ...item,
          ...{
            payerName:
              item.payerPerson ||
              item.payerName ||
              (item.payer !== null && item.payer.name),
            excluirButton: (
              <button
                disabled={this.state.onlyView ? true : false}
                type="button"
                className="btn btn-sm exc"
                key={index}
                onClick={() => this.openModal(index)}
              >
                Excluir
              </button>
            ),
            amountReal: amount,
            paymentMethod: item.paymentMethod
              ? item.paymentMethod
              : paymentMethod,
          },
        };
      });
    }

    if (this.state.paymentMethodResponse) {
      if (this.state.paymentMethodResponse.length > 0) {
        try {
          paymentMethodSelectItems = this.state.paymentMethodResponse.map(
            (item) => {
              return (
                <option
                  selected={
                    this.state.formData["newPaymentMethodId"] === item.id
                      ? true
                      : false
                  }
                  value={item.id}
                  key={"situation" + item.id}
                >
                  {item.name}
                </option>
              );
            }
          );
        } catch (ex) {
          paymentMethodSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.incomeTypeResponse) {
      if (this.state.incomeTypeResponse.length > 0) {
        try {
          incomeTypeSelectItems = this.state.incomeTypeResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["incomeTypeId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          incomeTypeSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.eventBaptizedResponse) {
      if (this.state.eventBaptizedResponse.length > 0) {
        try {
          eventSelectItems = this.state.eventBaptizedResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["eventId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {!item.eventBaptisms.length > 0 ||
                  item.eventBaptisms[0].baptizedName}
              </option>
            );
          });
        } catch (ex) {
          eventSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.eventWeddingsResponse) {
      if (this.state.eventWeddingsResponse.length > 0) {
        try {
          eventSelectItems = this.state.eventWeddingsResponse.map((item) => {
            let groomName = "";
            let brideName = "";

            if (item.eventWeddings[0].groomPersonId)
              groomName = item.eventWeddings[0].groom.name;
            else groomName = item.eventWeddings[0].groomName;

            if (item.eventWeddings[0].bridePersonId)
              brideName = item.eventWeddings[0].bride.name;
            else brideName = item.eventWeddings[0].brideName;

            groomName = groomName.charAt(0).toUpperCase() + groomName.slice(1);
            brideName = brideName.charAt(0).toUpperCase() + brideName.slice(1);

            return (
              <option
                selected={
                  this.state.formData["eventId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {!item.eventWeddings.length > 0 ||
                  "Casamento " + groomName + " e " + brideName}
              </option>
            );
          });
        } catch (ex) {
          eventSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.eventMassesResponse) {
      if (this.state.eventMassesResponse.length > 0) {
        try {
          eventSelectItems = this.state.eventMassesResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["eventId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {!item.eventMasses.length > 0 ||
                  item.eventMasses[0].massIntention}
              </option>
            );
          });
        } catch (ex) {
          eventSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let validationHandleSubmitPayment = [];

    if (!this.state.paymentResponse.length) {
      validationHandleSubmitPayment = [
        {
          ref: "newAmount",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: this.state.payerNameOption,
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ];
    }

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "incomeTypeId",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "paymentDate",
          validations: [diruma.validations.required],
          customValidations: [],
        },

        {
          ref: "paymentDate",
          validations: [diruma.validations.date, diruma.validations.required],
          customValidations: [],
        },
        ...validationHandleSubmitPayment,
      ],
    };

    this.validationAddPayment = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "newAmount",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: this.state.payerNameOption,
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    if (
      this.state.formData["eventDate"] &&
      this.state.formData["eventTypeId"]
    ) {
      if (
        this.state.formData["eventDate"] !== this.state.tempEventData ||
        this.state.formData["eventTypeId"] !== this.state.tempEventType
      ) {
        let eventDate = this.state.formData["eventDate"];
        let eventType = this.state.formData["eventTypeId"];

        this.loadEvents(eventDate, eventType);
      }
    }
    return (
      <>
        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir esta entrada?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.entryId}
                onClick={(e) => this.deletePayment(this.state.entryId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.exclusaoModal}
            onRequestClose={() => {
              this.setState({ exclusaoModal: false });
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel="Exclusão de Registro"
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.setState({ exclusaoModal: false });
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir esta entrada?
            </h2>
            <div className="text-center">
              <button
                id=""
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.paymentId}
                onClick={() => {
                  this.deleteEntry();
                }}
              >
                {this.state.deleteLoading ? <Loading load="Sim" /> : "Sim"}
              </button>
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.setState({ exclusaoModal: false });
                }}
              >
                Não
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Nova entrada</h1>
                <img
                  className=" "
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>

          <Form>
            <Row lg="12" className="pt-7 pl-5">
              <Col md="3" sm="6">
                <label className="text-family font-weight-900">
                  Tipo
                  <small className="text-gray">
                    <i> - obrigatório</i>
                  </small>
                </label>
                <select
                  disabled={this.state.onlyView ? true : false}
                  name="incomeTypeId"
                  id="incomeTypeId"
                  ref="incomeTypeId"
                  className={
                    "text-dark font-weight-900 form-control showFilterActive "
                  }
                  onChange={this.onInputChange}
                >
                  <option value="">Selecione</option>
                  {incomeTypeSelectItems.length === 0 || incomeTypeSelectItems}
                </select>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Responsável
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  {this.state.onlyView ? (
                    <input
                      type="name"
                      className="form-control text-dark font-weight-900 mt--2"
                      id="paymentResponsible"
                      name="paymentResponsible"
                      ref="paymentResponsible"
                      value={
                        this.state.formData.registerPerson
                          ? this.state.formData.registerPerson.name
                          : ""
                      }
                      disabled
                    />
                  ) : (
                    <input
                      type="name"
                      className="form-control text-dark font-weight-900 mt--2"
                      id="paymentResponsible"
                      name="paymentResponsible"
                      ref="paymentResponsible"
                      value={window.localStorage.getItem(
                        constants.LocalStorage.UserName
                      )}
                      disabled
                    />
                  )}
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Data
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  <input
                    ref="paymentDate"
                    disabled={this.state.onlyView ? true : false}
                    type="text"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="paymentDate"
                    name="paymentDate"
                    value={
                      this.state.formData["paymentDate"]
                        ? this.state.formData["paymentDate"]
                        : this.state.formData["paymentDate"]
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pl-5 mb-4">
              <Col lg="3">
                <label className="text-dark font-weight-900">
                  Está relacionado a um evento?
                </label>
                <ButtonGroupNsb
                  id="relationshipEvent"
                  disabled={this.state.onlyView ? true : false}
                  option1="Não"
                  option2="Sim"
                  key="relationshipEvent"
                  activeIndex={this.state.formData["relationshipEvent"]}
                  dflex="Sim"
                  changeHandler={this.onInputChange}
                />
              </Col>
              {!(this.state.formData["relationshipEvent"] === "1") || (
                <>
                  <Col lg="3">
                    <div>
                      <label
                        for="eventStartDate"
                        class="form-control-label text-dark"
                      >
                        Data do evento
                      </label>
                      <input
                        disabled={this.state.onlyView ? true : false}
                        class={"form-control text-dark font-weight-900"}
                        type="date"
                        id="eventDate"
                        value={
                          this.state.formData["eventDate"]
                            ? this.state.formData["eventDate"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>

                  <Col md="3" sm="6">
                    <label className="text-family font-weight-900">
                      Tipo do evento
                    </label>
                    <select
                      disabled={this.state.onlyView ? true : false}
                      name="eventTypeId"
                      id="eventTypeId"
                      ref="eventTypeId"
                      class={
                        "text-dark font-weight-900 form-control showFilterActive"
                      }
                      onChange={this.onInputChange}
                    >
                      <option value="">Tipo de evento</option>
                      <option
                        value="1"
                        selected={
                          this.state.formData.eventTypeId === 1 ? true : false
                        }
                      >
                        Batizado
                      </option>
                      <option
                        value="2"
                        selected={
                          this.state.formData.eventTypeId === 2 ? true : false
                        }
                      >
                        Casamento
                      </option>
                      <option
                        value="3"
                        selected={
                          this.state.formData.eventTypeId === 3 ? true : false
                        }
                      >
                        Missa
                      </option>
                    </select>
                  </Col>

                  <Col md="3" sm="6">
                    <label className="text-family font-weight-900">
                      Selecione o evento
                    </label>
                    <select
                      disabled={
                        this.state.formData["eventDate"]
                          ? this.state.formData["eventTypeId"]
                            ? this.state.onlyView
                              ? true
                              : false
                            : true
                          : true
                      }
                      name="eventId"
                      id="eventId"
                      ref="eventId"
                      className={
                        "text-dark font-weight-900 form-control showFilterActive "
                      }
                      onChange={this.onInputChange}
                    >
                      <option value="">Selecione</option>
                      {eventSelectItems.length === 0 || eventSelectItems}
                    </select>
                  </Col>
                </>
              )}
            </Row>

            <Row lg="12" className="pl-5">
              <Col lg="12">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Observações
                  </label>
                  <textarea
                    disabled={this.state.onlyView ? true : false}
                    type="name"
                    rows="7"
                    className="form-control text-dark font-weight-900"
                    id="comments"
                    name="comments"
                    value={
                      this.state.formData["comments"]
                        ? this.state.formData["comments"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>

            <Row className="pl-5 pr-5 pb-3">
              <Col lg="12">
                <div className="pt-4">
                  <h1 className="text-dark font-weight-900 pb-1">Pagamentos</h1>
                </div>
              </Col>
            </Row>

            <Row lg="12" className="pl-5">
              <Col lg="3">
                <label className="text-dark font-weight-900">
                  Pagante é paroquiano?
                </label>
                <ButtonGroupNsb
                  id="payingIsParishioner"
                  disabled={this.state.onlyView ? true : false}
                  option1="Não"
                  option2="Sim"
                  key="payingIsParishioner"
                  activeIndex={this.state.formData["payingIsParishioner"]}
                  dflex="Sim"
                  changeHandler={this.onInputChange}
                />
              </Col>

              {this.state.formData["payingIsParishioner"] === "0" ||
                this.state.formData["payingIsParishioner"] === undefined ? (
                <Col lg="9">
                  <div className="form-group mb-4">
                    <label className="text-dark font-weight-900">
                      Nome do pagante
                      <small className="text-gray">
                        <i> - obrigatório</i>
                      </small>
                    </label>
                    <input
                      disabled={this.state.onlyView ? true : false}
                      type="text"
                      className="form-control text-dark font-weight-900"
                      id="payerName"
                      name="payerName"
                      ref="payerName"
                      value={
                        this.state.formData["payerName"]
                          ? this.state.formData["payerName"]
                          : ""
                      }
                      onChange={this.onInputChange}
                    />
                  </div>
                </Col>
              ) : (
                <Col lg="6" className="mt-inputSearch">
                  <InputSearch
                    ref="PayerPersonId"
                    name="PayerPersonId"
                    title="Nome do pagante"
                    fieldId="payerName"
                    pageHandler="financial"
                    value={this.state.sugestedPayer}
                    changeHandler={(id) => {
                      this.loadSugestedPerson(id);
                    }}
                    deleteHandler={() => {
                      this.clearFormState();
                    }}
                    sugestedList={this.state.sugestedList}
                    personId={this.state.personId}
                    disabled={this.state.onlyView ? true : false}
                    hasPhoto={true}
                  />
                </Col>
              )}
            </Row>

            <Row lg="12" className="pl-5">
              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-2">
                    Valor
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  <input
                    ref="newAmount"
                    disabled={this.state.onlyView ? true : false}
                    type="text"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="newAmount"
                    name="newAmount"
                    value={
                      this.state.formData["newAmount"]
                        ? this.state.formData["newAmount"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col md="3" sm="6">
                <label className="text-family font-weight-900">
                  Forma de pagamento
                </label>
                <select
                  disabled={this.state.onlyView ? true : false}
                  name="newPaymentMethodId"
                  id="newPaymentMethodId"
                  className={
                    "text-dark font-weight-900 form-control showFilterActive "
                  }
                  onChange={this.onInputChange}
                >
                  {" "}
                  {/* Colocar validação de classe */}
                  <option
                    value=""
                    selected={
                      this.state.formData["newPaymentMethodId"] ? false : true
                    }
                  >
                    Selecione
                  </option>
                  {paymentMethodSelectItems.length === 0 ||
                    paymentMethodSelectItems}
                </select>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-2">
                    Descrição
                  </label>
                  <input
                    disabled={this.state.onlyView ? true : false}
                    type="text"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="newDescription"
                    name="newDescription"
                    ref="newDescription"
                    value={
                      this.state.formData["newDescription"]
                        ? this.state.formData["newDescription"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-2">
                    N° do documento
                  </label>
                  <input
                    disabled={this.state.onlyView ? true : false}
                    type="text"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="newDocumentNumber"
                    name="newDocumentNumber"
                    ref="newDocumentNumber"
                    value={
                      this.state.formData["newDocumentNumber"]
                        ? this.state.formData["newDocumentNumber"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <div className="container-fluid mb-6 mt-6">
              <Row>
                <Col>
                  {this.state.onlyView || (
                    <div className="float-lg-right">
                      <button
                        type="button"
                        className="btn-person-search ml-4"
                        onClick={() => {
                          this.addPayment();
                        }}
                      >
                        {this.state.addPaymentLoading ? (
                          <Loading load="Sim" />
                        ) : (
                          "Adicionar"
                        )}
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
          {!this.state.confirmSubmit || (
            <section class="container">
              <Row>
                <Col col="md-2">
                  <div
                    id="msgExclusion"
                    className={
                      " " +
                      (this.state.isVisibleDeleteMsg ? "msgVisiblePerson" : "")
                    }
                  >
                    <DialogConfirm
                      open={this.state.openConfirm}
                      title="Pagamento"
                      text={this.state.messageSubmit}
                      success="Sim"
                      handleClose={() => {
                        this.setState({ openConfirm: false });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </section>
          )}
          <Row className="pt-4 pl-5">
            <Col lg="12">
              {this.state.renderPaymentList ? (
                <>
                  <DataList
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                  />
                </>
              ) : (
                <>{!this.state.addPaymentLoading || <h1> Carregando...</h1>}</>
              )}
              {this.state.totalAmount > 0 ? (
                <div className="pt-5">
                  <h1 className="text-dark font-weight-bold">
                    Total:{" "}
                    {formater.monetaryBRL(this.state.totalAmount + "", true)}{" "}
                  </h1>
                </div>
              ) : (
                <> </>
              )}
            </Col>
          </Row>

          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col lg="12">
                {this.state.paymentId !== 0 ? (
                  <Link
                    to={`/blank/Financeiro/emitirRecibo/?id=${this.state.paymentId}`}
                    target="_blank"
                  >
                    <button type="button" className="btn-person-search">
                      Emitir recibo
                    </button>
                  </Link>
                ) : (
                  <>
                    <UncontrolledTooltip
                      delay={0}
                      trigger="hover focus"
                      target="emitirRecibo"
                    >
                      Você deve salvar seu registro antes de emitir o recibo.
                    </UncontrolledTooltip>
                    <button
                      id="emitirRecibo"
                      type="button"
                      className="btn-person-search"
                    >
                      Emitir recibo
                    </button>
                  </>
                )}

                {this.state.accessLevelId === "1" ||
                  this.state.accessLevelId === "2" ? (
                  <button
                    id="deletePerson"
                    type="button"
                    className="btn-info-person-exc ml-4"
                    onClick={() => {
                      this.setState({ exclusaoModal: true });
                    }}
                  >
                    Excluir registro
                  </button>
                ) : (
                  <></>
                )}
                <div className="float-lg-right">
                  <Link to={"/admin/Financeiro"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  {this.state.paymentId === 0 ? (
                    <button
                      type="button"
                      className="btn-info-person ml-4"
                      onClick={() => {
                        this.handleSubmit();
                      }}
                    >
                      {this.state.submitLoading ? (
                        <Loading load="Sim" />
                      ) : (
                        "Salvar"
                      )}
                    </button>
                  ) : (
                    <>
                      <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="salvarRegistro"
                      >
                        Você não tem permissão para editar este registro.
                      </UncontrolledTooltip>
                      <button
                        id="salvarRegistro"
                        type="button"
                        className="btn-info-person ml-4"
                        onClick={() => {
                          this.handleSubmit();
                        }}
                      >
                        Salvar
                      </button>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }

  openModal(id) {
    this.setState({ entryId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({ confirmSubmit: false });
      }.bind(this),
      4000
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let date = new Date();

    if (id) {
      this.setState({ paymentId: id });
    }

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();

    date =
      (day.length === 1 ? "0" + day : day) +
      "/" +
      (month.length === 1 ? "0" + month : month) +
      "/" +
      (year.length === 1 ? "0" + year : year);

    this.setState({
      formData: Object.assign(this.state.formData, {
        paymentDate: date,
        payingIsParishioner: "0",
      }),
    });

    let accessLevelId = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelId
    );
    let accessLevelName = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelName
    );

    this.setState({
      accessLevelId: accessLevelId,
      accessLevelName: accessLevelName,
    });
  }

  componentDidMount() {
    this.getPayment();
    this.loadPerson();
    this.loadPaymentMethod();
    this.loadIncomeType();
  }

  clearFormState() {
    this.setState({ sugestedPayer: null, personId: 0 });
  }

  loadSugestedPerson(personId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;

        this.setState({ sugestedPayer: ret, personId: ret.id });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "paymentDate":
          value = formater.template(target.value, "00/00/0000");
          break;

        case "newAmount":
          value = formater.monetaryBRL(target.value, true);
          break;

        default:
          value = target.value;
      }


    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "payingIsParishioner") {
      if (value === "0")
        this.setState({
          sugestedPayer: null,
          personId: 0,
          payerNameOption: "payerName",
        });
      if (value === "1") {
        // document.getElementById("payerName").remove("validationError");
        this.setState({
          payerNameOption: "PayerPersonId",

          formData: Object.assign(this.state.formData, {
            payerName: "",
          }),
        });
      }
    }
  }

  getPayment() {
    if (this.state.paymentId) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase +
        constants.Endpoint.Payment.Get +
        "/" +
        this.state.paymentId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          console.warn("resultado", result);
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let valorTotal = 0;

          if (ret.eventId) ret.relationshipEvent = "1";
          else ret.relationshipEvent = "0";

          if (ret.event) {
            ret.eventTypeId = ret.event.eventTypeId;
          }

          if (ret.event) {
            if (ret.event.eventReservations.length > 0) {
              let eventDay = new Date(ret.event.eventReservations[0].eventDate)
                .getDate()
                .toString();
              let eventMonth = (
                new Date(ret.event.eventReservations[0].eventDate).getMonth() +
                1
              ).toString();
              let eventYear = new Date(ret.event.eventReservations[0].eventDate)
                .getFullYear()
                .toString();

              ret.eventDate =
                (eventYear.length === 1 ? "0" + eventYear : eventYear) +
                "-" +
                (eventMonth.length === 1 ? "0" + eventMonth : eventMonth) +
                "-" +
                (eventDay.length === 1 ? "0" + eventDay : eventDay);
            }
          }

          if (ret.personId) {
            this.setState({ sugestedPayer: ret.person });
            ret.payingIsParishioner = "1";
          } else {
            ret.payingIsParishioner = "0";
          }

          let paymentDay = new Date(ret.paymentDate).getDate().toString();
          let paymentMonth = (
            new Date(ret.paymentDate).getMonth() + 1
          ).toString();
          let paymentYear = new Date(ret.paymentDate).getFullYear().toString();

          ret.paymentDate =
            (paymentDay.length === 1 ? "0" + paymentDay : paymentDay) +
            "/" +
            (paymentMonth.length === 1 ? "0" + paymentMonth : paymentMonth) +
            "/" +
            (paymentYear.length === 1 ? "0" + paymentYear : paymentYear);

          if (ret.paymentAmounts) {
            valorTotal = ret.paymentAmounts.reduce(
              (total, valor) => total + valor.amount,
              0
            );

            valorTotal = parseFloat(valorTotal);

            valorTotal = valorTotal.toFixed(2);

            if (ret.paymentAmounts.length > 0) {
              ret.paymentAmounts.map((item) => {
                item.amount = item.amount.toFixed(2);

                return item;
              });

              this.setState({
                paymentResponse: ret.paymentAmounts,
                renderPaymentList: true,
              });
            }
          }

          let formData = { ...this.state.formData, ...ret };

          formData = smoothApi.helpers.orderProperties(formData);
          this.setState({
            formData: formData,
            onlyView: true,
            totalAmount: valorTotal,
          });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  loadEvents(eventDate, eventType) {
    if (!eventDate || !eventType) return;

    let formData = {};
    formData.eventTypeIds = [];

    formData.eventTypeIds.push(eventType);

    formData.eventStartDate = eventDate;
    formData.eventEndDate = eventDate;
    formData.Name = "";

    this.setState({ tempEventData: eventDate, tempEventType: eventType });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Search;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            eventLoading: false,
            renderEventList: false,
            errorEventNotFound: true,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            eventLoading: false,
            renderEventList: false,
            errorEventNotFound: true,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            eventLoading: false,
            renderEventList: false,
            errorEventNotFound: true,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total === 0) {
          this.setState({
            eventLoading: false,
            renderEventList: false,
            eventNotFound: true,
            openEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Evento não encontrado",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.length > 0) {
          if (result.response[0].eventBaptisms.length > 0)
            this.setState({
              eventLoading: false,
              renderEventList: true,
              eventNotFound: false,
              openEventNotFound: false,
              errorEventNotFound: false,
              openErrorEventNotFound: false,
              errorMessage: "",
              eventBaptizedResponse: result.response,
              eventMassesResponse: null,
              eventWeddingsResponse: null,
            });

          if (result.response[0].eventMasses.length > 0)
            this.setState({
              eventLoading: false,
              renderEventList: true,
              eventNotFound: false,
              openEventNotFound: false,
              errorEventNotFound: false,
              openErrorEventNotFound: false,
              errorMessage: "",
              eventMassesResponse: result.response,
              eventBaptizedResponse: null,
              eventWeddingsResponse: null,
            });

          if (result.response[0].eventWeddings.length > 0)
            this.setState({
              eventLoading: false,
              renderEventList: true,
              eventNotFound: false,
              openEventNotFound: false,
              errorEventNotFound: false,
              openErrorEventNotFound: false,
              errorMessage: "",
              eventWeddingsResponse: result.response,
              eventBaptizedResponse: null,
              eventMassesResponse: null,
            });
        } else {
          this.setState({
            eventLoading: false,
            renderEventList: true,
            eventNotFound: false,
            openEventNotFound: false,
            errorEventNotFound: false,
            openErrorEventNotFound: false,
            errorMessage: "",
            eventMassesResponse: null,
            eventBaptizedResponse: null,
            eventWeddingsResponse: null,
          });
        }
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          eventLoading: false,
          renderEventList: false,
          errorEventNotFound: true,
          openErrorEventNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  loadPaymentMethod() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.PaymentMethod.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success === null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ paymentMethodResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadIncomeType() {
    this.setState({ incomeTypeResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.IncomeType.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ incomeTypeResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.GetBasicInfo;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let personList = result.response.map(function (item, indice) {
          return {
            id: item.id,
            name: item.name,
            pictureFileName: item.pictureFileName,
          };
        });

        this.setState({ sugestedList: personList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  addPayment() {
    let validations = diruma.validadeOnChange(
      this.validationAddPayment,
      this.state.formData,
      null,
      true
    );

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }

    this.setState({
      renderPaymentList: true,
      addPaymentLoading: true,
      indicePayment: this.state.indicePayment + 1,
    });

    let { formData, personId, sugestedPayer } = this.state;

    let paymentList = this.state.paymentResponse;
    let newPaymentList = [...paymentList];

    let payment = {
      name: "",
    };

    if (parseInt(personId)) {
      payment.payerPersonId = parseInt(personId);
      payment.payerPerson = sugestedPayer.name;
      payment.name = sugestedPayer.name;
    }

    payment.id = null;
    payment.indice = this.state.indicePayment + 1;

    if (formData.payerName) {
      payment.payerName = formData.payerName;
      payment.payerPersonId = null;
      payment.name = formData.payerName;
    }

    if (formData.newAmount) payment.amount = formData.newAmount;

    if (formData.newPaymentMethodId) {
      if (parseInt(formData.newPaymentMethodId) === 1)
        payment.paymentMethod = "Dinheiro";

      if (parseInt(formData.newPaymentMethodId) === 2)
        payment.paymentMethod = "Cheque";

      if (parseInt(formData.newPaymentMethodId) === 3)
        payment.paymentMethod = "Boleto";

      if (parseInt(formData.newPaymentMethodId) === 4)
        payment.paymentMethod = "Cartão - Débito";

      if (parseInt(formData.newPaymentMethodId) === 5)
        payment.paymentMethod = "Cartão - Crédito";

      if (parseInt(formData.newPaymentMethodId) === 6)
        payment.paymentMethod = "Transferência bancária";

      payment.paymentMethodId = formData.newPaymentMethodId;
    }

    if (formData.newDescription) payment.description = formData.newDescription;

    if (formData.newDocumentNumber)
      payment.documentNumber = formData.newDocumentNumber;

    if (payment.amount) {
      let totalAmount = this.state.totalAmount;
      let amount = payment.amount;

      amount = formater.numberMonetary(amount);
      amount = parseFloat(amount);
      totalAmount = parseFloat(totalAmount);

      totalAmount += amount;

      totalAmount = totalAmount.toFixed(2);

      newPaymentList.push(payment);

      delete formData.newAmount;
      delete formData.newDescription;
      delete formData.newDocumentNumber;
      delete formData.newPaymentMethodId;
      delete formData.payingIsParishioner;
      delete formData.sugestedPayer;
      delete formData.payerName;

      let nonDuplicatePaymentList = this.nonDuplicated(newPaymentList);

      if (nonDuplicatePaymentList.length > 2) {
        this.setState((state) => ({
          payerNameOption: "payerName",
          formData: Object.assign(this.state.formData, {
            payingIsParishioner: "0",
          }),
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              text: (
                <>
                  Ocorreu um erro durante o registro, por favor verifique a{" "}
                  <span className="errorText"> mensagem </span> e tente
                  novamente:
                  <ul className="errorSumary">
                    <li>Limite de 2 pagantes por evento.</li>
                  </ul>
                </>
              ),
            },
          },
          addPaymentLoading: false,
        }));

        return;
      } else {
        this.setState({
          sugestedPayer: {},
          personId: 0,
          paymentResponse: newPaymentList,
          addPaymentLoading: false,
          totalAmount: totalAmount,
          formData: Object.assign(this.state.formData, {
            payingIsParishioner: "0",
          }),
          payerNameOption: "payerName",
        });

        return;
      }
    } else {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro durante o registro, por favor verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li>Valor do pagamento é obrigatório</li>
                </ul>
              </>
            ),
          },
        },
        addPaymentLoading: false,
      }));

      return;
    }
  }

  deleteEntry() {
    this.setState({ deleteLoading: true });

    let paymentId = this.state.paymentId;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Payment.Delete +
      "/" +
      paymentId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({
            exclusaoModal: false,
            deleteLoading: false,
            paymentLoading: false,
            renderPaymentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            exclusaoModal: false,
            deleteLoading: false,
            paymentLoading: false,
            renderPaymentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null) {
          this.setState({
            exclusaoModal: false,
            deleteLoading: false,
            paymentLoading: false,
            renderPaymentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.BlankResponse, result);
          return;
        }

        this.setState({
          exclusaoModal: false,
          deleteLoading: false,
          paymentLoading: false,
          renderPaymentList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
          namePayment: "",
        });

        window.location.href = "/admin/Financeiro";
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          paymentLoading: false,
          deleteLoading: false,
          renderPaymentList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  deletePayment(indice) {
    let paymentList = this.state.paymentResponse;
    let totalAmount = this.state.totalAmount;
    let amount = null;

    amount = parseFloat(formater.numberMonetary(paymentList[indice].amount));

    paymentList.splice(indice, 1);

    if (paymentList.length <= 0) this.setState({ renderPaymentList: false });

    totalAmount = parseFloat(totalAmount) - amount;

    totalAmount = totalAmount.toFixed(2);

    this.setState({
      modalIsOpen: false,
      paymentList: paymentList,
      totalAmount: totalAmount,
    });
  }

  nonDuplicated(paymentList = []) {
    paymentList = paymentList.map((item) => {
      return {
        ...item,
        parishioner: !!item.payerPersonId,
      };
    });

    let nonDuplicatePayment = [];

    paymentList.forEach((item, index) => {
      const filteredPayment = nonDuplicatePayment.filter((payment) => {
        return (
          payment.name === paymentList[index].name &&
          payment.parishioner === paymentList[index].parishioner
        );
      });

      if (!filteredPayment.length) {
        nonDuplicatePayment.push(paymentList[index]);
      }
    });

    return nonDuplicatePayment;
  }

  handleSubmit() {
    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    let formData = this.state.formData;

    let formDataSubmit = {};

    let payment = this.state.paymentResponse;
    let alreadyError = this.state.alreadyError;

    if (!payment.length) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro durante o registro, por favor verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li key={"validationItem_"}>Pagamento é obrigatório.</li>
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
        alreadyError: true,
      }));

      return;
    }

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }

    this.setState({ submitLoading: true });

    if (this.state.onlyView) return;

    formDataSubmit.paymentAmounts = [];

    payment.map((item) => {
      delete item.paymentMethod;

      if (String(item.amount.replace(/[^\d]+/g, "")).length >= 6) {
        item.amount = item.amount.replace(/[^\d,]+/g, "");
        item.amount = item.amount.replace(",", ".");
      } else {
        item.amount = formater.numberMonetary(item.amount, alreadyError);
      }
      formDataSubmit.paymentAmounts.push(item);
    });

    if (this.state.personId) formDataSubmit.personId = this.state.personId;
    else formDataSubmit.personId = null;

    if (formData.relationshipEvent === "0" || !formData.relationshipEvent)
      formDataSubmit.eventId = null;

    if (formData.paymentDate.indexOf("/") !== -1) {
      let day = formData.paymentDate.split("/")[0];
      let month = formData.paymentDate.split("/")[1];
      let year = formData.paymentDate.split("/")[2];

      formDataSubmit.paymentDate = year + "-" + month + "-" + day;
    }

    delete formDataSubmit.payingIsParishioner;
    delete formDataSubmit.relationshipEvent;
    delete formDataSubmit.eventTypeId;
    delete formDataSubmit.eventDate;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Payment.Post;
    let data = { ...formData, ...formDataSubmit };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            alreadyError: true,
          }));

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            alreadyError: true,
          }));

          return;
        }

        let ret = result;

        this.setState({
          submitLoading: false,
          paymentId: ret.response.id,
          onlyView: true,
          openConfirm: true,
          confirmSubmit: true,
          messageSubmit: "salvo com sucesso ",
        });
        this.timeOutSubmit();
        this.getPayment();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }
}

export default CadastroEntrada;
