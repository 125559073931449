export default Object.freeze({
  Endpoint: {
    UrlBase: "https://nsb-web-api.azurewebsites.net", // PRD 
    // UrlBase: 'https://localhost:44347', // Local

    State: {
      Get: "/state/get",
      GetPaged: "/state/getPaged",
    },

    City: {
      Get: "/city/get",
      GetPaged: "/city/getPaged",
      GetByState: "/city/getByState",
    },

    AccessLevel: {
      Get: "/accessLevel/get",
      GetPaged: "/accessLevel/getPaged",
    },

    FamilyStatus: {
      Get: "/familyStatus/get",
      GetPaged: "/familyStatus/getPaged",
    },

    DocsAttached: {
      Get: "/document/get",
      Post: "/document",
      Delete: "/document",
      GetByPerson: "/document/getByPerson",
      GetByEvent: "/document/getByEvent",
    },

    CourseType: {
      Post: "/course",
      getCourseType: "/courseType/get",
      GetPaged: "/courseType/getPaged",
      Put: "/courseType",
      Delete: "/courseType",
    },

    Location: {
      Post: "/location",
      GetPaged: "/Location/GetPaged",
      Get: "/location",
      Put: "/location",
      Delete: "/location",
      SearchByDate: "/Location/SearchDisponibilityByDate"
    },

    Celebrant: {
      GetPaged: "/celebrant/getPaged",
      Get: "/celebrant/get",
      Put: "/celebrant",
      Delete: "/celebrant",
    },

    Provider: {
      Get: "/provider/get",
      GetPaged: "/provider/getPaged",
      Post: "/provider",
      Delete: "/provider",
      Search: "/provider/search",
      PagedSearch: "/provider/pagedSearch",
    },

    Event: {
      Get: "/event/get",
      GetPaged: "/event/getPaged",
      Post: "/event",
      Delete: "/event",
      PagedSearch: "/event/pagedSearch",
      Search: "/event/search",
      Download: "/event/download",
      Export: "/event/export",
      Wizard: "/event/wizard",
      CalendarSearch: "/event/calendarSearch",
      GetSummarizedWeddingSheet: "/event/getSummarizedWeddingSheet",
    },

    EventActivity: {
      Get: "/eventActivity/get",
      GetPaged: "/eventActivity/getPaged",
      Post: "/eventActivity",
      Delete: "/eventActivity",
      GetByEvent: "/eventActivity/getByEvent",
      Bulk: "/eventActivity/bulk",
    },

    EventObservation: {
      Get: "/eventObservation/get",
      GetPaged: "/eventObservation/getPaged",
      Post: "/eventObservation",
      Delete: "/eventObservation",
      GetByEvent: "/eventObservation/getByEvent",
      Bulk: "/eventObservation/bulk",
    },

    EventAttendance: {
      Get: "/eventAttendance/get",
      GetPaged: "/eventAttendance/getPaged",
      Post: "/eventAttendance",
      Delete: "/eventAttendance",
      GetByEvent: "/eventAttendance/getByEvent",
      Bulk: "/eventAttendance/bulk",
    },

    EventReservation: {
      GetPaged: "/eventReservation/getPaged",
      Get: "/eventReservation/get",
      Put: "/eventReservation",
      Post: "/eventReservation",
      Delete: "/eventReservation",
      GetByEvent: "/eventReservation/getByEvent",
    },

    EventStatus: {
      Get: "/eventStatus/get",
    },

    Pastoral: {
      Get: "/pastoral/get",
      GetPaged: "/pastoral/getPaged",
      Post: "/pastoral",
      Delete: "/pastoral",
      PagedSearch: "/pastoral/pagedSearch",
      Search: "/pastoral/search",
      Download: "/pastoral/download",
      Export: "/pastoral/export",
      Wizard: "/pastoral/wizard",
      CalendarSearch: "/pastoral/calendarSearch",
    },

    WeddingCeremony: {
      Get: "/weddingCeremony/get",
      GetPaged: "/weddingCeremony/getPaged",
      Post: "/weddingCeremony",
      Delete: "/weddingCeremony",
      GetByEvent: "/weddingCeremony/getByEvent",
    },

    WeddingWitness: {
      Get: "/weddingWitness/get",
      GetPaged: "/weddingWitness/getPaged",
      Post: "/weddingWitness",
      Delete: "/weddingWitness",
      GetByEvent: "/weddingWitness/getByEvent",
      Bulk: "/weddingWitness/bulk",
    },

    Payment: {
      Get: "/payment/get",
      GetPaged: "/payment/getPaged",
      Search: "/payment/search",
      PagedSearch: "/payment/pagedSearch",
      Post: "/payment",
      Delete: "/payment",
      Download: "/payment/download",
      Export: "/payment/export",
    },

    PaymentMethod: {
      Get: "/paymentMethod/get",
      GetPaged: "/paymentMethod/getPaged",
    },

    IncomeType: {
      Get: "/incomeType/get",
      GetPaged: "/incomeType/getPaged",
    },

    PaymentType: {
      Get: "/paymentType/get",
      GetPaged: "/paymentType/getPaged",
    },

    Service: {
      GetPaged: "/service/getPaged",
      Get: "/service/get",
    },

    Pessoas: {
      GetBasicInfo: "/person/GetBasicInfo",
      GetBasicInfoByAccessLevel: "/person/GetBasicInfoByAccessLevel",
      Get: "/person/get",
      GetPaged: "/person/getPaged",
      GetPersonBonds: "/person/getPersonBonds",
      Delete: "/person",
      Search: "/person/search",
      PagedSearch: "/person/pagedSearch",
      Download: "/person/download",
      Export: "/person/export",
      Post: "/person",
      Wizard: "/person/wizard",
    },

    Family: {
      Search: "/family/search",
      Put: "/family",
    },

    Outras: {
      GetByPerson: "/personAditionalInfo/getByPerson",
      Put: "/personAditionalInfo",
    },

    Tithe: {
      Get: "/tithe/get",
      GetPaged: "/tithe/getPaged",
      Put: "/tithe",
      Delete: "/tithe",
      GetByPerson: "/tithe/getByPerson",
    },

    Recurrence: {
      Get: "/recurrence/get",
      GetPaged: "/recurrence/getPaged",
    },

    TithePaymentMethod: {
      Get: "/tithePaymentMethod/get",
      GetPaged: "/tithePaymentMethod/getPaged",
    },

    Auth: {
      Login: "/auth/login",
      ForgotPass: "/auth/forgotPassword",
      ChangePass: "/auth/changePassword",
    },

    Situation: {
      Get: "/situation/get",
      GetPaged: "/situation/getPaged",
    },

    Profession: {
      Get: "/profession/get",
      GetPaged: "/profession/getPaged",
    },

    Education: {
      Get: "/education/get",
      GetPaged: "/education/getPaged",
    },

    Ministry: {
      Get: "/ministry/get",
      GetPaged: "/ministry/getPaged",
    },

    Course: {
      Get: "/course/get",
      GetPaged: "/course/getPaged",
      getWithOpenRegistration: "/course/getWithOpenRegistration",
      getWithOpenRegistrationCourse:
        "/courseType/getWithOpenRegistrationCourse",
      PagedSearch: "/course/pagedSearch",
      courseRegistration: "/CourseRegistration/getByCourse",
      Export: "/course/export",
      Download: "/course/download",
      ExportByCourse: "/courseRegistration/exportByCourse",
      DownloadByCourse: "/courseRegistration/download",
      CourseType: "/courseType/get",
      CourseRegistrationGetByPerson: "/CourseRegistration/GetByPerson",
      CourseRegistration: '/CourseRegistration/MassRegistration',
      DeleteCourse: "/course"
    },

    Responsability: {
      Get: "/responsability/get",
      GetPaged: "/responsability/getPaged",
    },
  },

  Auth: {
    DefaultHeader: "Authorization",
    DefaultScheme: "Bearer",
    ContentType: "content-type",
  },

  LocalStorage: {
    Token: "MultToken",
    UserName: "UserName",
    UserPhoto: "UserPhoto",
    AccessLevelId: "AccessLevelId",
    AccessLevelName: "AccessLevelName",
    PersonId: "PersonId",
    EventType: "EventType",
  },

  AllowedExtensions: {
    Document: ["jpg", "jpeg", "png", "gif", "bmp", "svg", "pdf", "doc", "docx"],
  },

  EventStatusId: {
    Reservado: 1,
    Contratado: 2,
    Cancelado: 3,
    CanceladoComPendencia: 4,
  },

  EventTypeId: {
    Batizado: 1,
    Casamento: 2,
    Missa: 3,
  },

  EventTypes: [
    { id: 1, name: "Batismo", boopColor: "#AE79C8" },
    { id: 2, name: "Casamento", boopColor: "#FE8CC3" },
    { id: 3, name: "Missa", boopColor: "#62D8F2" },
  ],

  Modal: {
    DefaultStyle: {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "440px",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
      },
    },
  },

  Pagination: {
    DefaultPageSize: 10,
  },
});
