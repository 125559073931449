//#region Imports

import Modal from 'react-modal';
import React from "react";
import DialogConfirm from '../../../components/Utils/dialogConfirm.jsx';
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import messages from "variables/messages";
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Form,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";


//#endregion

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '440px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '3px'
    }
};


class Fornecedores extends React.Component {

    //#region Constructor

    constructor(props) {

        super(props);

        //#region Initial State

        this.state = {

            providerResponse: null,
            serviceResponse: null,
            providerLoading: true,
            renderProviderList: true,
            providerNotFound: false,
            openProviderNotFound: false,
            errorProviderNotFound: false,
            openErrorProviderNotFound: false,
            errorMessage: '',
            messageExclusion: '',
            isVisibleDeleteMsg: false,
            modalIsOpen: false,
            currentPage: 0,
            confirmExclusao: false,
            openConfirm: false,
            formData: { serviceIds: [] },
            searchMode: false,
            registerLoading: false,
            message: {

                visible: false,
                text: 'teste',
                title: '',
                closeCallBack: null,
                _defaultCloseAction: (_this) => {

                    if (!_this)
                        return;

                    if (typeof _this !== 'object')
                        return;

                    if (!_this.state || !_this.setState)
                        return;

                    if (typeof _this.state !== 'object' || typeof _this.setState !== 'function')
                        return;

                    if (_this.state.message.closeCallBack) {

                        if (typeof _this.state.closeCallBack === 'function')
                            _this.state.message.closeCallBack();

                    }

                    _this.setState((state) => ({ message: { ...state.message, ...{ visible: false, text: '', title: '', closeCallBack: null } } }));

                }

            }

        }


        //#endregion 

        //#region Event Binding

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.loadDataList = this.loadDataList.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.timeOutExclusion = this.timeOutExclusion.bind(this);
        this.clearFormState = this.clearFormState.bind(this);
        this.submitSearch = this.submitSearch.bind(this);

        //#endregion

    }

    render() {

        let tableCollumns = [];
        let tableData = [];
        let total = 0;
        let serviceSelectItems = [];
        let temp = this.state.providerResponse;



        if (this.state.providerResponse && this.state.providerResponse.list && this.state.providerResponse.total) {

            tableCollumns = [
                { name: 'Nome da empresa', size: 25, property: 'name' },
                { name: 'Responsável', size: 25, property: 'contactName' },
                { name: 'Serviços', size: 25, property: 'services' },
                { name: '', size: 5, property: 'editarButton' },
                { name: '', size: 5, property: 'excluirButton' }
            ];

            tableData = this.state.providerResponse.list;
            tableData = tableData.map((item) => {

                return {
                    ...item, ...
                    {
                        editarButton: <Link to={`/admin/Cadastros/cadastroFornecedor/?id=${item.id}`} ><button className="btn btn-sm edit">Editar</button></Link>,
                        excluirButton: <button type="button" className="btn btn-sm exc" key={item.id} onClick={() => this.openModal(item.id)}>Excluir</button>,
                        services: item.providerService.reduce((prevValue, providerService) => prevValue + providerService.service.name + '; ', '')
                    }

                };

            });

            total = this.state.providerResponse.total;

        }

        let pageChangeHandler = (index) => {

            if (this.state.searchMode)
                this.submitSearch(index);
            else
                this.loadDataList(index, false);

        };


        if (this.state.serviceResponse) {

            if (this.state.serviceResponse.length > 0) {

                try {

                    serviceSelectItems = this.state.serviceResponse.map((item) => {

                        return (

                            <DropdownItem
                                value={item.id}
                                disabled={true}


                            >
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input input-checkbox" id={item.name} onClick={e => this.onInputChange(e)} checked={this.state.formData[item.name] ? true : false} />
                                    <label class="custom-control-label" for={item.name}>{item.name}</label><br />
                                </div>
                            </DropdownItem>
                        );

                    });

                } catch (ex) {

                    serviceSelectItems = [];
                    console.error('Falha ao interpretar resposta', ex);

                }


            }


        }

        return (
            <>

                {/* //#region Modal */}

                <>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <span className="float-lg-right x" onClick={this.closeModal}>X</span>
                        <h2 className="text-dark text-center pt-4 pb-4 text-modal">Tem certeza de que deseja <br /> excluir este registro?</h2>
                        <div className="text-center">
                            <button id="alertExclusion" className="alertExclusion btn btn-group button-yes mr-4" key={this.state.providerId} onClick={() => this.deleteProvider(this.state.providerId)} >Sim</button>
                            <button className="btn btn-group button-no" onClick={this.closeModal} >Não</button>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={this.state.message.visible}
                        onRequestClose={() => { this.state.message._defaultCloseAction(this); }}
                        style={constants.Modal.DefaultStyle}
                        contentLabel={this.state.message.title}
                    >
                        <span className="float-lg-right x" onClick={() => { this.state.message._defaultCloseAction(this); }}>X</span>
                        <h2 className="text-dark text-center pt-4 pb-4 text-modal">{this.state.message.text}</h2>
                        <div className="text-center">
                            <button className="btn btn-group button-no" onClick={() => { this.state.message._defaultCloseAction(this); }}>OK</button>
                        </div>
                    </Modal>

                </>
                {/* //#endregion Modal */}

                <Container fluid className="minHeightFooter bg-body">
                    <Row className="pt-4 pl-5">
                        <Col lg="6">
                            <div>
                                <h1 className="text-dark">Fornecedores</h1>
                                <img className="rectangle-orange"
                                    alt="..."
                                    src={require("assets/img/brand/home/Rectangle.png")}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <Link to="/admin/Cadastros/cadastroFornecedor">
                                <button className="btn button-add-people float-lg-right text-white mt-2">+ Novo Registro</button>
                            </Link>
                        </Col>
                    </Row>

                    <Form>
                        <Row lg="12" className="pt-7 pl-5">
                            <Col lg="8">

                                <div className="form-group">
                                    <label className="text-family font-weight-900 pb-1">Buscar</label>
                                    <input type="name" className={"form-control text-dark font-weight-900 "} id="name" name="name" value={this.state.formData['name']} placeholder="Digite o nome" onChange={this.onInputChange} />
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="form-group">

                                    <UncontrolledDropdown>
                                        <label className="text-family text-dark font-weight-900 pt-2 ">Buscar por serviço</label>
                                        <DropdownToggle
                                            className={"form-control text-dark font-weight-900 text-left default-select "}
                                            onClick={e => e.preventDefault()}

                                        >
                                            <label className="text-select font-weight-900 label-checkbox-fornecedores pt-1">Selecione</label>

                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow col-12" >
                                            {(serviceSelectItems.length === 0 ||

                                                serviceSelectItems

                                            )}

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                </div>
                            </Col>
                        </Row>

                        <Row className="pt-4 pt-5 pr-5">
                            <Col lg="12">
                                <div className="float-lg-right">
                                    <button className="btn-person-clean" type="reset" onClick={this.clearFormState}>Limpar</button>
                                    <button className="btn-person-search ml-4" type="button" onClick={() => { this.submitSearch(0); }}>Buscar</button>
                                </div>
                            </Col>
                        </Row>

                        <Row className="pt-5">
                            {(this.state.providerNotFound) ?
                                <section class="container">
                                    <Row>
                                        <Col col="md-12">
                                            <div id="msgExclusion" className={" " + (this.state.isVisibleDeleteMsg ? "msgVisiblePerson" : "")}>
                                                <DialogConfirm
                                                    open={this.state.openProviderNotFound}
                                                    text={this.state.messageExclusion}
                                                    handleClose={() => { this.setState({ openProviderNotFound: false }) }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                                :
                                <>
                                </>
                            }

                            {(this.state.errorProviderNotFound) ?
                                <section class="container">
                                    <Row>
                                        <Col col="md-12">
                                            <div id="msgExclusion" className={" " + (this.state.isVisibleDeleteMsg ? "msgVisiblePerson" : "")}>
                                                <DialogConfirm
                                                    open={this.state.openErrorProviderNotFound}
                                                    text={this.state.errorMessage}
                                                    handleClose={() => { this.setState({ openErrorProviderNotFound: false }) }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                                :
                                <>
                                </>
                            }



                            {(!this.state.confirmExclusao) ||
                                <section class="container">
                                    <Row>
                                        <Col col="md-12">
                                            <div id="msgExclusion" className={" " + (this.state.isVisibleDeleteMsg ? "msgVisiblePerson" : "")}>
                                                <DialogConfirm
                                                    open={this.state.openConfirm}
                                                    title={this.state.nameProvider}
                                                    text={this.state.messageExclusion}
                                                    success="Sim"
                                                    handleClose={() => { this.setState({ openConfirm: false }) }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                            }
                            {(this.state.confirmExclusao) ||
                                <section class="container">
                                    <Row>
                                        <Col col="md-12">
                                            <div id="msgExclusion" className={" " + (this.state.isVisibleDeleteMsg ? "msgVisiblePerson" : "")}>
                                                <DialogConfirm
                                                    open={this.state.openConfirm}
                                                    title="Erro"
                                                    text={this.state.messageExclusion}
                                                    handleClose={() => { this.setState({ openConfirm: false }) }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                            }

                        </Row>
                        <div className="pt-5 pb-5 text-lg-center">

                            <h2 className="text-dark font-weight-bold">Fornecedores cadastrados</h2>

                        </div>
                        <Row className="pt-4 pl-5">

                            <Col lg="12">
                                {(this.state.renderProviderList) ?
                                    <DataList loading={this.state.providerLoading} tableCollumns={tableCollumns} tableData={tableData} paging={true} currentPage={this.state.currentPage} pageSize={constants.Pagination.DefaultPageSize} total={total} pageChangeHandler={(index) => { pageChangeHandler(index); }} />
                                    :
                                    <>
                                        {((this.state.providerNotFound || this.state.errorProviderNotFound) ||

                                            <h1> Carregando...</h1>

                                        )}

                                    </>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        );
    }

    //#region Component Events

    componentDidMount() {
        this.loadDataList(0, true);
        this.loadSelects();
    }

    //#endregion

    //#region Custom Events

    confirmExclusion(name) {
        this.loadDataList(0, false);

        if (name) {
            this.setState({ confirmExclusao: true, openConfirm: true, nameProvider: name, messageExclusion: 'excluido com sucesso ' });
            this.timeOutExclusion();
        }
        else {
            this.setState({ confirmExclusao: false, openConfirm: true, messageExclusion: 'na exclusão do registro ' });
            this.timeOutExclusion();
        }
    }

    clearFormState() {
        this.setState({ formData: { serviceIds: [] }, searchMode: false, errorMessage: '', providerNotFound: false, errorProviderNotFound: false });
        this.loadDataList(0, false);

    }

    openModal(id) {

        this.setState({ providerId: id, modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    loadDataList(currentPage, firstLoading) {

        this.setState({ providerLoading: true, currentPage: currentPage });

        if (!firstLoading)
            this.setState({ providerLoading: false })

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Provider.GetPaged;
        let data = 'CurrentPage=' + currentPage + '&PageSize=' + constants.Pagination.DefaultPageSize;

        smoothApi.get(url, data, token,

            (result) => {


                if (!result || !result.errors || !result.response || result.success == null) {

                    console.log(messages.Errors.UnexpectedFormat, result);
                    this.setState({ providerLoading: false, renderProviderList: false, errorMessage: messages.Errors.UnexpectedFormat });
                    return;

                }

                if (!result.success) {

                    this.setState({ providerLoading: false, renderProviderList: false, errorMessage: messages.Errors.ResponseError });
                    console.log(result.errors);
                    return;

                }

                if (result.response == null) {

                    this.setState({ providerLoading: false, renderProviderList: false, errorMessage: messages.Errors.BlankResponse });
                    return;

                }
                
                this.setState({ providerLoading: false, renderProviderList: true, errorMessage: '', providerResponse: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

                this.setState({ providerLoading: false, renderProviderList: false, errorMessage: messages.Errors.HttpError });

            }

        );

    }

    loadSelects() {
        this.loadServices();
    }

    loadServices() {

        this.setState({ serviceResponse: null });

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Service.Get;
        let data = null;

        smoothApi.get(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {

                    console.error(messages.Errors.UnexpectedFormat, result);
                    return;

                }

                if (!result.success) {

                    console.error(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {

                    console.error(messages.Errors.BlankResponse, result.errors);
                    return;

                }

                this.setState({ serviceResponse: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

            }

        );


    }

    deleteProvider(providerId) {

        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Provider.Delete + '/' + providerId;
        let data = null;

        smoothApi.delete(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {

                    this.setState({ modalIsOpen: false, providerLoading: false, renderProviderList: true, confirmExclusao: true, messageExclusion: '', errorMessage: '' });
                    this.confirmExclusion("");
                    console.error(messages.Errors.UnexpectedFormat, result);
                    return;

                }

                if (!result.success) {
                    this.setState({ modalIsOpen: false, providerLoading: false, renderProviderList: true, confirmExclusao: true, messageExclusion: '', errorMessage: '' });
                    this.confirmExclusion("");
                    console.error(messages.Errors.ResponseError, result.errors);
                }

                if (result.response == null) {
                    this.setState({ modalIsOpen: false, providerLoading: false, renderProviderList: true, confirmExclusao: true, messageExclusion: '', errorMessage: '' });
                    this.confirmExclusion("");
                    console.error(messages.Errors.BlankResponse, result);
                    return;

                }
                this.setState({ modalIsOpen: false, providerLoading: false, renderProviderList: true, confirmExclusao: false, errorMessage: '', messageExclusion: '', nameProvider: '' });
                this.confirmExclusion(result.response.name);

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

                this.setState({ providerLoading: false, renderProviderList: false, errorMessage: messages.Errors.HttpError });

            }

        );

    }

    timeOutExclusion() {

        setTimeout(
            function () {
                this.setState({ isVisibleDeleteMsg: true });
            }
                .bind(this),
            4000
        );

        this.setState({ isVisibleDeleteMsg: false });

    }

    onInputChange(event) {

        const target = event.target;
        const name = target.id;
        let value = '';

        if (target.type === 'checkbox') {

            value = target.checked;

        }

        else {

            value = target.value;

        }

        this.setState({

            formData: Object.assign(this.state.formData, { [name]: value })

        });

        console.log('formData', this.state.formData);

    }


    submitSearch(currentPage) {

        if (this.state.formData == null)
            return;

        if (currentPage === undefined || currentPage === null)
            currentPage = 0;

        this.setState({ providerLoading: true, currentPage: currentPage, searchMode: true });
        let formData = this.state.formData;
        let serviceSelected = [];
        formData.serviceIds = [];

        if (formData['Assessoria'] !== undefined)
            if (formData['Assessoria'] === true)
                serviceSelected.push('Assessoria');

        if (formData['Decoração'] !== undefined)
            if (formData['Decoração'] === true)
                serviceSelected.push('Decoração');

        if (formData['Fotografia'] !== undefined)
            if (formData['Fotografia'] === true)
                serviceSelected.push('Fotografia');

        if (formData['Música'] !== undefined)
            if (formData['Música'] === true)
                serviceSelected.push('Música');

        if (formData['Vídeo'] !== undefined)
            if (formData['Vídeo'] === true)
                serviceSelected.push('Vídeo');

        if (formData['Salão de festas'] !== undefined)
            if (formData['Salão de festas'] === true)
                serviceSelected.push('Salão de festas');

        this.state.serviceResponse.map((item) => {

            if (!(serviceSelected.indexOf(item.name) === -1)) {

                if (formData.serviceIds.indexOf(item.id) === -1)
                    formData.serviceIds.push(item.id);

            }

        })


        formData.currentPage = currentPage;
        formData.pageSize = constants.Pagination.DefaultPageSize;
        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url = constants.Endpoint.UrlBase + constants.Endpoint.Provider.PagedSearch;
        let data = formData;

        smoothApi.post(url, data, token,

            (result) => {

                if (!result || !result.errors || !result.response || result.success == null) {
                    console.error(messages.Errors.UnexpectedFormat, result);
                    this.setState({ providerLoading: false, renderProviderList: false, errorProviderNotFound: true, openErrorProviderNotFound: true, errorMessage: messages.Errors.UnexpectedFormat });
                    return;

                }

                if (!result.success) {
                    this.setState({ providerLoading: false, renderProviderList: false, errorProviderNotFound: true, openErrorProviderNotFound: true, errorMessage: messages.Errors.ResponseError });
                    console.error(messages.Errors.ResponseError, result.errors);
                    return;

                }

                if (result.response == null) {
                    this.setState({ providerLoading: false, renderProviderList: false, errorProviderNotFound: true, openErrorProviderNotFound: true, errorMessage: messages.Errors.BlankResponse });
                    console.error(messages.Errors.BlankResponse);
                    return;

                }

                if (result.response.total === 0) {
                    this.setState({ providerLoading: false, renderProviderList: false, providerNotFound: true, openProviderNotFound: true, errorMessage: messages.Errors.BlankResponse, messageExclusion: 'Fornecedor não encontrado' });
                    console.error(messages.Errors.BlankResponse);
                    return;
                }

                this.setState({ providerLoading: false, renderProviderList: true, providerNotFound: false, openProviderNotFound: false, errorProviderNotFound: false, openErrorProviderNotFound: false, errorMessage: '', providerResponse: result.response });

            },

            (error, httpCode) => {

                if (httpCode === 401)
                    window.location.href = "/auth/Login";

                this.setState({ providerLoading: false, renderProviderList: false, errorProviderNotFound: true, openErrorProviderNotFound: true, errorMessage: messages.Errors.HttpError });

            }

        );

    }

}


export default Fornecedores;
