import React from "react";

import Modal from "react-modal";
import { formatDate } from "components/Utils/FormatDate";

import messages from "variables/messages";
import constants from "variables/constants";

import Wizard from "components/Utils/Wizard";
import Loading from "components/Utils/Loading";
import DataList from "components/Utils/dataList";
import { smoothApi } from "components/Utils/smoothApi";

import { Container, Row, Col } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};
class Atividade extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardResponse: null,
      formData: {
        courseId: "",
        essayAnswer: "",
      },
      isDeceased: false,
      personId: -1,

      listCourseByPerson: [],

      courseTypeResponse: [],

      addCoursesLoading: false,
      renderCoursesList: false,
      coursesResponse: [],

      courseSelectItems: [],

      modalIsOpen: false,
      courseRegistrationId: 0,

      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let courseSelectItems = [];

    let courseTypeSelectItems = [];

    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "/admin/Pessoas/Pessoa/?id=" + this.state.personId,
          wizzardConfiguration.Status.Completed
        )
      );

      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "/admin/Pessoas/Familia/?id=" + this.state.personId,
          this.state.wizardResponse.hasFamily
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );

      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "/admin/Pessoas/Grupo/?id=" + this.state.personId,
          this.state.wizardResponse.hasMinistry
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Disabled
        )
      );

      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "/admin/Pessoas/Atividade/?id=" + this.state.personId,
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "/admin/Pessoas/Outras/?id=" + this.state.personId,
          this.state.wizardResponse.hasPersonAdditionalInfo
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Pessoas/Documento/?id=" + this.state.personId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "/admin/Pessoas/Dizimo/?id=" + this.state.personId,
          this.state.wizardResponse.hasTithe
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Informações <br /> pessoais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Família</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Grupos e <br /> Pastorais
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Atividades e <br /> Cursos
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>
            Outras <br /> informações
          </>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Dizimo</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    if (this.state.renderCoursesList) {
      tableCollumns = [
        { name: "Nome do curso", size: 20, property: "name" },
        {
          name: "Início de inscrição",
          size: 13,
          property: "registrationPeriodInit",
        },
        {
          name: "Final da inscrição",
          size: 13,
          property: "registrationPeriodFinish",
        },
        { name: "", size: 5, property: "excluirButton" },
      ];
    }

    tableData = this.state.listCourseByPerson;

    tableData = tableData.map((item, index) => {
      return {
        ...item,
        excluirButton: (
          <button
            type="button"
            className="btn btn-sm exc"
            key={index}
            onClick={() => this.openModal(item.courseRegistrationId)}
          >
            Excluir
          </button>
        ),
      };
    });

    if (this.state.courseSelectItems.length) {
      courseSelectItems = this.state.courseSelectItems.map((item) => {
        try {
          return (
            <option
              id={item.id}
              className={item.essayAnswer}
              value={item.id}
              key={"education" + item.id}
            >
              {item.name}
            </option>
          );
        } catch (err) {
          courseSelectItems = [];
        }
      });
    }

    if (this.state.courseTypeResponse) {
      if (this.state.courseTypeResponse.length > 0) {
        try {
          courseTypeSelectItems = this.state.courseTypeResponse.map((item) => {
            return (
              <option value={item.id} key={"situation" + item.id}>
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          courseTypeSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);
          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              OK
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span className="float-lg-right x" onClick={this.closeModal}>
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            Tem certeza de que deseja <br /> excluir este curso?
          </h2>
          <div className="text-center">
            <button
              id="alertExclusion"
              className="alertExclusion btn btn-group button-yes mr-4"
              key={this.state.courseRegistrationId}
              onClick={(e) =>
                this.deleteCourse(this.state.courseRegistrationId)
              }
            >
              Sim
            </button>
            <button
              className="btn btn-group button-no"
              onClick={this.closeModal}
            >
              Não
            </button>
          </div>
        </Modal>
        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.formData["name"]}
                  {!this.state.isDeceased || (
                    <small className="text-deceased"> - falecido(a)</small>
                  )}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Row className="pl-5">
            <Col lg="4">
              <label className="text-dark font-weight-900">Tipo de curso</label>{" "}
              {/* loadingSelect e disabled TEMPORARIO */}
              <select
                name="courseTypeId"
                id="courseTypeId"
                className={
                  "text-dark font-weight-900 form-control showFilterActive " +
                  // (courseTypeSelectItems.length === 0
                  //   ? "loadingSelect "
                  //   : "") +
                  (this.state.formData["courseTypeId"] ? "isFilterActive" : "")
                }
                onChange={(event) => {
                  this.onInputChange(event);
                  this.getCourses(event.target.value);
                }}
              >
                <option
                  value=""
                  selected={
                    this.state.formData.courseTypeId === "" ? true : false
                  }
                >
                  Selecione
                </option>
                {courseTypeSelectItems.length === 0 || courseTypeSelectItems}
              </select>
            </Col>
            <Col lg="4">
              <div className="form-group pl-5">
                <label className="text-dark font-weight-900">Cursos</label>
                <select
                  disabled={this.state.coursesResponse.length}
                  name="courseId"
                  id="courseId"
                  className="form-control text-dark font-weight-900 mb-4"
                  onChange={(event) => {
                    this.onInputChange(event);

                    this.setState({
                      formData: Object.assign(this.state.formData, {
                        essayAnswer: event.target.id,
                      }),
                    });
                  }}
                >
                  <option
                    value=""
                    selected={
                      this.state.formData.courseId === "" ? true : false
                    }
                  >
                    Selecione
                  </option>
                  {courseSelectItems.length === 0 || courseSelectItems}
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="pr-6 pt-4">
              {this.state.onlyView || (
                <div className="float-lg-right">
                  <button
                    disabled={this.state.coursesResponse.length}
                    type="button"
                    className="btn-person-search "
                    onClick={() => {
                      this.addCourse();
                    }}
                  >
                    {this.state.addPaymentLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Adicionar"
                    )}
                  </button>
                </div>
              )}
            </Col>
          </Row>
          <Row className="pt-8 pl-5">
            <Col lg="12">
              {this.state.renderCoursesList ? (
                <>
                  <DataList
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                  />
                </>
              ) : (
                <Col lg="12">
                  <div className="d-flex justify-content-center">
                    <h3>Não há cursos disponíves</h3>
                  </div>
                </Col>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({ personId: id });
    }
  }

  componentDidMount() {
    this.loadWizard();
    this.getCoursesByPerson();
    this.loadCourses();

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let personList = result.response.map(function (item, indice) {
          return {
            id: item.id,
            name: item.name,
            pictureFileName: item.pictureFileName,
          };
        });

        this.setState({ sugestedList: personList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadPerson();
  }

  getCourses(id = 0) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Course.getWithOpenRegistrationCourse +
      "/" +
      id;
    let data = null;

    console.log("id", id);

    if (id !== 0 || id !== "") {
      smoothApi.get(url, data, token, (result) => {
        if (!result || !result.errors || !result.response) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let courseResponse = [];

        result.response.forEach((item) => {
          courseResponse = item.courses.map((course) => {
            return {
              id: course.id,
              name: course.name,
              essayAnswer:
                course.essayQuestion === "undefined"
                  ? ""
                  : course.essayQuestion,
            };
          });
        });

        this.setState({
          courseSelectItems: [...courseResponse],
        });
      });
    } else {
      this.setState({
        courseSelectItems: [],
      });
    }
  }

  loadCourses() {
    this.setState({ courseTypeResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Course.CourseType;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ courseTypeResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getCoursesByPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Course.CourseRegistrationGetByPerson +
      "/" +
      this.state.personId;
    let data = null;

    smoothApi.get(url, data, token, (result) => {
      if (!result || !result.errors || !result.response) {
        console.error(messages.Errors.UnexpectedFormat, result);
        return;
      }

      if (!result.success) {
        console.log(messages.Errors.ResponseError, result.errors);
        return;
      }

      if (result.response == null) {
        console.log(messages.Errors.BlankResponse, result.errors);
        return;
      }
      let data = result.response;

      if (data.length) {
        data = data.map((item) => {
          return {
            ...item,
            name: item.courseName,
            registrationPeriodInit: formatDate(item.courseStartDate),
            registrationPeriodFinish: formatDate(item.courseEndDate),
          };
        });
        this.setState({
          renderCoursesList: true,
          listCourseByPerson: data,
        });
      } else {
        this.setState({
          renderCoursesList: false,
          listCourseByPerson: data,
        });
      }
    });
  }

  openModal(id) {
    this.setState({ courseRegistrationId: id, modalIsOpen: true });
  }

  loadWizard() {
    const { personId } = this.state;
    let id = parseInt(personId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Wizard;
    let data = { personId: id };

    if (personId > 0) {
      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          this.setState({ wizardResponse: result.response });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      this.state.personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        formData.id = ret.id;
        formData.name = ret.name;

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    // this.loadFamily();
  }

  addCourse() {
    this.setState({
      addCoursesLoading: true,
      renderCoursesList: true,
    });

    const { formData } = this.state;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Course.CourseRegistration;

    let data = {
      courseId: formData.courseId,
      personId: this.state.personId,
      essayAnswer: formData.essayAnswer,
    };

    smoothApi.post(url, data, token, (result) => {
      if (
        !result ||
        !result.errors ||
        !result.response ||
        result.success == null
      ) {
        console.error(messages.Errors.UnexpectedFormat, result);
        this.setState({ errorMessage: messages.Errors.UnexpectedFormat });
      }

      if (!result.success) {
        console.error(messages.Errors.ResponseError, result.errors);

        this.setState((state) => ({
          modalIsOpen: false,
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              text: (
                <>
                  Ocorreu um erro durante a inscrição, por favor verifique a{" "}
                  <span className="errorText"> mensagem </span> e tente
                  novamente:
                  <ul className="errorSumary">
                    {result.errors.map((el, currentIndex) => {
                      return (
                        <li key={"validationItem_" + currentIndex}>
                          {el + "."}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ),
            },
          },
        }));

        return;
      }

      if (result.response == null) {
        this.setState({ errorMessage: messages.Errors.BlankResponse });
        console.error(messages.Errors.BlankResponse);
        return;
      }
    });

    setTimeout(() => {
      this.setState({
        formData: Object.assign(this.state.formData, {
          courseTypeId: "",
          courseId: "",
          essayAnswer: "",
        }),
      });
      this.getCoursesByPerson();
    }, 300);
  }

  deleteCourse(id) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Course.DeleteCourseRegistration +
      "/" +
      id;
    let data = null;

    smoothApi.delete(url, data, token, (result) => {
      if (
        !result ||
        !result.errors ||
        !result.response ||
        result.success == null
      ) {
        console.error(messages.Errors.UnexpectedFormat, result);
        this.setState({ errorMessage: messages.Errors.UnexpectedFormat });
      }

      if (!result.success) {
        console.error(messages.Errors.ResponseError, result.errors);

        this.setState((state) => ({
          modalIsOpen: false,
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              text: (
                <>
                  Ocorreu um erro durante a exclusão, por favor verifique a{" "}
                  <span className="errorText"> mensagem </span> e tente
                  novamente:
                  <ul className="errorSumary">
                    {result.errors.map((el, currentIndex) => {
                      return (
                        <li key={"validationItem_" + currentIndex}>
                          {el + "."}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ),
            },
          },
        }));

        return;
      }

      if (result.response == null) {
        this.setState({ errorMessage: messages.Errors.BlankResponse });
        console.error(messages.Errors.BlankResponse);
        return;
      }

      this.setState({
        modalIsOpen: false,
      });

      setTimeout(() => {
        this.getCoursesByPerson();
      }, 300);
    });
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }
}

export default Atividade;
