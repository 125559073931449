import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import messages from "variables/messages";
import constants from "variables/constants";

import Wizard from "components/Utils/Wizard";
import Loading from "components/Utils/Loading.jsx";
import InputSearch from "components/Utils/inputSearch";
import { smoothApi } from "components/Utils/smoothApi.js";

import { Container, Row, Col, Form, UncontrolledTooltip } from "reactstrap";

class Cerimonial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitLoading: false,

      modalIsOpen: false,

      formData: {},
      eventId: 0,

      celebrantsResponse: null,
      wizardResponse: null,

      sugestedWeddingPlannerProvider: {},
      sugestedDecorationProvider: {},
      sugestedPhotographyProvider: {},
      sugestedMusicProvider: {},
      sugestedVideoProvider: {},
      sugestedWeddingHallProvider: {},

      //Input Search Lists
      weddingPlannerProviderList: [],
      decorationProviderList: [],
      photographyProviderList: [],
      musicProviderList: [],
      videoProviderList: [],
      weddingHallProviderList: [],

      //Prider Ids
      weddingPlannerProviderId: -1,
      decorationProviderId: -1,
      photographyProviderId: -1,
      musicProviderId: -1,
      videoProviderId: -1,
      weddingHallProviderId: -1,

      //Modal Message
      errorMessage: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        // {
        //   ref: "celebrantId",
        //   validations: [diruma.validations.required],
        //   customValidations: [],
        // },
      ],
    };

    this.loadWizard = this.loadWizard.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
  }

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Tipo do <br /> evento
        </>,
        "/admin/Evento/tipoEvento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Completed
      )
    );
    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Informações <br /> do evento
        </>,
        "/admin/Evento/Casamento/infoCasamento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Completed
      )
    );

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "/admin/Evento/Casamento/data/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasDate
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/documentos/?id=" + this.state.eventId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "/admin/Evento/Casamento/testemunhas/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasWitness
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "/admin/Evento/Casamento/cerimonial/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          wizzardConfiguration.Status.Active
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "/admin/Evento/Casamento/engajamento/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasAttendance &&
            this.state.wizardResponse.hasChart
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "/admin/Evento/Casamento/atividades/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasActivity
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    let celebrantsSelectItems = [];

    if (this.state.celebrantsResponse) {
      if (this.state.celebrantsResponse.length > 0) {
        try {
          celebrantsSelectItems = this.state.celebrantsResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["celebrantId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          celebrantsSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        {/* //#region Modal */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>
        {/* //#endregion Modal */}

        <Container fluid className=" bg-body">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Novo casamento</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-9">
          <Form>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">Cerimônia</h2>
                </div>
              </Col>
            </Row>

            <Row lg="12" className="ml-2 mb-2">
              <Col lg="6" className="form-group">
                <label className="text-dark font-weight-900">
                  Detalhes da cerimônia{" "}
                  <small className="text-gray">
                    <i>- obrigatório</i>
                  </small>
                </label>
              </Col>
              <Col lg="6" className="form-group">
                <label className="text-dark font-weight-900">
                  Celebrante escalado{" "}
                  <small className="text-gray">
                    <i>- obrigatório</i>
                  </small>
                </label>
              </Col>
            </Row>

            <Row>
              <Col lg="1">
                <div className="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="isReligious"
                      onChange={this.onInputChange}
                      checked={
                        this.state.formData["isReligious"] ? true : false
                      }
                    />
                    <label
                      class="custom-control-label pb-3 text-dark"
                      for="isReligious"
                    >
                      Religioso
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
              <Col lg="1">
                <div className="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="hasCivilEffect"
                      onChange={this.onInputChange}
                      checked={
                        this.state.formData["hasCivilEffect"] ? true : false
                      }
                    />
                    <label
                      class="custom-control-label pb-3 text-dark"
                      for="hasCivilEffect"
                    >
                      Efeito Civil
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
              <Col lg="1">
                <div className="form-group">
                  <div class="custom-control custom-checkbox ml-4">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="hasMass"
                      onChange={this.onInputChange}
                      checked={this.state.formData["hasMass"] ? true : false}
                    />
                    <label
                      class="custom-control-label pb-3 text-dark"
                      for="hasMass"
                    >
                      Missa
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
              <Col lg="1">
                <div className="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="hasCommunion"
                      onChange={this.onInputChange}
                      checked={
                        this.state.formData["hasCommunion"] ? true : false
                      }
                    />
                    <label
                      class="custom-control-label pb-3 text-dark"
                      for="hasCommunion"
                    >
                      Comunhão
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
              <Col lg="2">
                <div className="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input input-checkbox"
                      id="hasBells"
                      onChange={this.onInputChange}
                      checked={this.state.formData["hasBells"] ? true : false}
                    />
                    <label
                      class="custom-control-label pb-3 text-dark"
                      for="hasBells"
                    >
                      Sinos
                    </label>
                    <br />
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <select
                  disabled={this.state.onlyView ? true : false}
                  name="celebrantId"
                  id="celebrantId"
                  ref="celebrantId"
                  className={
                    "text-dark font-weight-900 form-control showFilterActive "
                  }
                  onChange={this.onInputChange}
                >
                  {" "}
                  {/* Colocar validação de classe */}
                  <option
                    value=""
                    selected={this.state.formData[""] ? false : true}
                  >
                    Selecione
                  </option>
                  {celebrantsSelectItems.length === 0 || celebrantsSelectItems}
                </select>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">
                    Fornecedores
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-inputSearch">
                <InputSearch
                  title="Assessoria"
                  fieldId="weddingPlannerProvider"
                  pageHandler="event"
                  value={this.state.sugestedWeddingPlannerProvider}
                  changeHandler={(id) => {
                    this.setState({ weddingPlannerProviderId: id });
                  }}
                  deleteHandler={(id) => {
                    this.setState({ weddingPlannerProviderId: -1 });
                  }}
                  sugestedList={this.state.weddingPlannerProviderList}
                  hasPhoto={false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-inputSearch">
                <InputSearch
                  title="Decoração"
                  fieldId="decorationProvider"
                  pageHandler="event"
                  value={this.state.sugestedDecorationProvider}
                  changeHandler={(id) => {
                    this.setState({ decorationProviderId: id });
                  }}
                  deleteHandler={(id) => {
                    this.setState({ decorationProviderId: -1 });
                  }}
                  sugestedList={this.state.decorationProviderList}
                  hasPhoto={false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-inputSearch">
                <InputSearch
                  title="Fotografia"
                  fieldId="photographyProvider"
                  pageHandler="event"
                  value={this.state.sugestedPhotographyProvider}
                  changeHandler={(id) => {
                    this.setState({ photographyProviderId: id });
                  }}
                  deleteHandler={(id) => {
                    this.setState({ photographyProviderId: -1 });
                  }}
                  sugestedList={this.state.photographyProviderList}
                  hasPhoto={false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-inputSearch">
                <InputSearch
                  title="Música"
                  fieldId="musicProvider"
                  pageHandler="event"
                  value={this.state.sugestedMusicProvider}
                  changeHandler={(id) => {
                    this.setState({ musicProviderId: id });
                  }}
                  deleteHandler={(id) => {
                    this.setState({ musicProviderId: -1 });
                  }}
                  sugestedList={this.state.musicProviderList}
                  hasPhoto={false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-inputSearch">
                <InputSearch
                  title="Vídeo"
                  fieldId="videoProvider"
                  pageHandler="event"
                  value={this.state.sugestedVideoProvider}
                  changeHandler={(id) => {
                    this.setState({ videoProviderId: id });
                  }}
                  deleteHandler={(id) => {
                    this.setState({ videoProviderId: -1 });
                  }}
                  sugestedList={this.state.videoProviderList}
                  hasPhoto={false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-inputSearch">
                <InputSearch
                  title="Salão de festas"
                  fieldId="weddingHallProvider"
                  pageHandler="event"
                  value={this.state.sugestedWeddingHallProvider}
                  changeHandler={(id) => {
                    this.setState({ weddingHallProviderId: id });
                  }}
                  deleteHandler={(id) => {
                    this.setState({ weddingHallProviderId: -1 });
                  }}
                  sugestedList={this.state.weddingHallProviderList}
                  hasPhoto={false}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">
                    Outras Informações
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Observações
                  </label>
                  <textarea
                    type="name"
                    rows="7"
                    className="form-control text-dark font-weight-900"
                    id="comments"
                    name="comments"
                    value={this.state.formData["comments"]}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>

          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                {this.state.paymentId !== 0 ? (
                  <>
                    <Link
                      to={
                        `/blank/Evento/Casamento/imprimirFicha/?id=` +
                        this.state.eventId
                      }
                      target="_blank"
                    >
                      <button type="button" className="btn-person-search">
                        Imprimir ficha
                      </button>
                    </Link>
                    <Link
                      className="ml-4"
                      to={
                        `/blank/Evento/Casamento/imprimirFichaResumida/?id=` +
                        this.state.eventId
                      }
                      target="_blank"
                    >
                      <button type="button" className="btn-person-search">
                        Imprimir ficha resumida
                      </button>
                    </Link>
                  </>
                ) : (
                  <>
                    <UncontrolledTooltip
                      delay={0}
                      trigger="hover focus"
                      target="emitirRecibo"
                    >
                      Você deve salvar seu registro antes de Imprimir a Ficha.
                    </UncontrolledTooltip>
                    <button
                      id="emitirRecibo"
                      type="button"
                      className="btn-person-search"
                    >
                      Emitir recibo
                    </button>
                  </>
                )}

                <div className="float-lg-right">
                  <Link to={"/admin/Eventos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>

                  <button
                    type="button"
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar e continuar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id });
  }

  componentDidMount() {
    this.loadSelects();

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.WeddingCeremony.GetByEvent;
    let data = "eventId=" + this.state.eventId;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;
        let provider = {};

        formData.id = ret.id;
        formData.comments = ret.comments;
        formData.celebrantId = ret.celebrantId;

        if (ret.isReligious) formData.isReligious = true;

        if (ret.hasCivilEffect) formData.hasCivilEffect = true;

        if (ret.hasMass) formData.hasMass = true;

        if (ret.hasCommunion) formData.hasCommunion = true;

        if (ret.hasBells) formData.hasBells = true;

        if (ret.weddingPlannerProvider) {
          provider = {
            id: ret.weddingPlannerProvider.id,
            name: ret.weddingPlannerProvider.name,
          };

          this.setState({
            sugestedWeddingPlannerProvider: provider,
            weddingPlannerProviderId: ret.weddingPlannerProvider.id,
          });
        }

        if (ret.decorationProvider) {
          provider = {
            id: ret.decorationProvider.id,
            name: ret.decorationProvider.name,
          };

          this.setState({
            sugestedDecorationProvider: provider,
            decorationProviderId: ret.decorationProvider.id,
          });
        }

        if (ret.photographyProvider) {
          provider = {
            id: ret.photographyProvider.id,
            name: ret.photographyProvider.name,
          };

          this.setState({
            sugestedPhotographyProvider: provider,
            photographyProviderId: ret.photographyProvider.id,
          });
        }

        if (ret.musicProvider) {
          provider = { id: ret.musicProvider.id, name: ret.musicProvider.name };

          this.setState({
            sugestedMusicProvider: provider,
            musicProviderId: ret.musicProvider.id,
          });
        }

        if (ret.videoProvider) {
          provider = { id: ret.videoProvider.id, name: ret.videoProvider.name };

          this.setState({
            sugestedVideoProvider: provider,
            videoProviderId: ret.videoProvider.id,
          });
        }

        if (ret.weddingHallProvider) {
          provider = {
            id: ret.weddingHallProvider.id,
            name: ret.weddingHallProvider.name,
          };

          this.setState({
            sugestedWeddingHallProvider: provider,
            weddingHallProviderId: ret.weddingHallProvider.id,
          });
        }

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadWizard();
    this.loadProviders();
    this.loadCelebrants();
  }

  //#endregion

  //#region Custom Events

  loadWizard() {
    const { eventId } = this.state;
    let id = parseInt(eventId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
    let data = { eventId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadCelebrants() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Celebrant.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ celebrantsResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadProviders() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Service.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let weddingPlannerProviderList = result.response[0].providerService.map(
          function (item, indice) {
            return {
              id: item.providerId,
              name: item.provider.name,
            };
          }
        );

        let decorationProviderList = result.response[1].providerService.map(
          function (item, indice) {
            return {
              id: item.providerId,
              name: item.provider.name,
            };
          }
        );

        let photographyProviderList = result.response[2].providerService.map(
          function (item, indice) {
            return {
              id: item.providerId,
              name: item.provider.name,
            };
          }
        );

        let musicProviderList = result.response[3].providerService.map(
          function (item, indice) {
            return {
              id: item.providerId,
              name: item.provider.name,
            };
          }
        );

        let videoProviderList = result.response[4].providerService.map(
          function (item, indice) {
            return {
              id: item.providerId,
              name: item.provider.name,
            };
          }
        );

        let weddingHallProviderList = result.response[5].providerService.map(
          function (item, indice) {
            return {
              id: item.providerId,
              name: item.provider.name,
            };
          }
        );

        this.setState({
          weddingPlannerProviderList: weddingPlannerProviderList,
          decorationProviderList: decorationProviderList,
          photographyProviderList: photographyProviderList,
          musicProviderList: musicProviderList,
          videoProviderList: videoProviderList,
          weddingHallProviderList: weddingHallProviderList,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  clearFormState() {
    this.setState({ formData: {} });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  handleSubmit() {
    // let validations = diruma.validadeOnChange(
    //   this.validation,
    //   this.state.formData,
    //   null,
    //   true
    // );

    // if (!validations.sucess) {
    //   this.setState((state) => ({
    //     message: {
    //       ...state.message,
    //       ...{
    //         title: "Aviso",
    //         visible: true,
    //         text: (
    //           <>
    //             Por favor verifique os campos destacados em{" "}
    //             <span className="errorText"> vermelho </span> para prosseguir:
    //             <ul className="errorSumary">
    //               {validations.errors.map((el, currentIndex) => {
    //                 return <li key={"validationItem_" + currentIndex}>{el}</li>;
    //               })}
    //             </ul>
    //           </>
    //         ),
    //       },
    //     },
    //   }));

    //   return;
    // }
    const { formData } = this.state;

    // if (
    //   !formData.isReligious &&
    //   !formData.hasCivilEffect &&
    //   !formData.hasMass &&
    //   !formData.hasCommunion &&
    //   !formData.hasBells
    // )
    //   validationMessages.push(
    //     <li>{"Selecione um dos detalhes da cerimônia"}</li>
    //   );

    // if (validationMessages.length) {
    //   this.setState((state) => ({
    //     message: {
    //       ...state.message,
    //       ...{
    //         title: "Aviso",
    //         visible: true,
    //         text: (
    //           <>
    //             Por favor verifique a{" "}
    //             <span className="errorText"> mensagem </span> para prosseguir:
    //             <ul className="errorSumary">{validationMessages}</ul>
    //           </>
    //         ),
    //       },
    //     },
    //   }));

    //   return;
    // }

    this.setState({ submitLoading: true });

    if (formData.celebrantId) {
      formData.celebrantId = parseInt(formData.celebrantId);
    }

    if (this.state.weddingPlannerProviderId !== -1)
      formData.weddingPlannerProviderId = this.state.weddingPlannerProviderId;
    else delete formData.weddingPlannerProviderId;

    if (this.state.decorationProviderId !== -1)
      formData.decorationProviderId = this.state.decorationProviderId;
    else delete formData.decorationProviderId;

    if (this.state.photographyProviderId !== -1)
      formData.photographyProviderId = this.state.photographyProviderId;
    else delete formData.photographyProviderId;

    if (this.state.musicProviderId !== -1)
      formData.musicProviderId = this.state.musicProviderId;
    else delete formData.musicProviderId;

    if (this.state.videoProviderId !== -1)
      formData.videoProviderId = this.state.videoProviderId;
    else delete formData.videoProviderId;

    if (this.state.weddingHallProviderId !== -1)
      formData.weddingHallProviderId = this.state.weddingHallProviderId;
    else delete formData.weddingHallProviderId;

    if (this.state.eventId) formData.eventId = this.state.eventId;

    if (Object.values(formData).length > 1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase + constants.Endpoint.WeddingCeremony.Post;
      let data = formData;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro, por favor verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro, por favor verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
            }));

            return;
          }

          if (result.response == null) {
            this.setState({
              errorMessage: messages.Errors.BlankResponse,
              submitLoading: false,
            });
            console.error(messages.Errors.BlankResponse);
            return;
          }

          this.setState({ submitLoading: false, errorMessage: "" });

          window.location.href =
            "/admin/Evento/Casamento/Engajamento" +
            (result.response.eventId ? "?id=" + result.response.eventId : "");
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    } else {
      window.location.href =
        "/admin/Evento/Casamento/Engajamento" +
        (this.state.eventId ? "?id=" + this.state.eventId : "");
    }
  }
}

export default Cerimonial;
