//#region Imports

import React from "react";

import Modal from "react-modal";
import DialogConfirm from "../../components/Utils/dialogConfirm.jsx";
import { Link } from "react-router-dom";
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import messages from "variables/messages";
import {
  Container,
  Row,
  Col,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//#endregion

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class Eventos extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    this.state = {
      eventStatusResponse: [],

      formData: { eventTypeIds: [] },
      renderEventList: true,
      eventLoading: null,
      eventResponse: null,
      nameEvent: null,
      eventId: 0,
      confirmExclusao: false,
      isVisibleDeleteMsg: false,
      eventNotFound: false,
      openEventNotFound: false,
      errorEventNotFound: false,
      openErrorEventNotFound: false,
      errorMessage: "",
      messageExclusion: "",
      modalIsOpen: false,
      currentPage: 0,
      openConfirm: false,
      searchMode: false,
      exporting: false,
      isChecked: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.statusOfTable = this.statusOfTable.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;
    let serviceSelectItems = [];
    let temp = this.state.eventResponse;

    if (
      this.state.eventResponse &&
      this.state.eventResponse.list &&
      this.state.eventResponse.total
    ) {
      tableCollumns = [
        { name: "Tipo de evento", size: 20, property: "eventType" },
        { name: "Solicitante", size: 20, property: "requester" },
        { name: "Data", size: 20, property: "date" },
        { name: "Horário", size: 20, property: "schedule" },
        { name: "Status", size: 15, property: "status" },
        { name: "", size: 10, property: "editarButton" },
        { name: "", size: 10, property: "excluirButton" },
      ];

      tableData = this.state.eventResponse.list;
      tableData = tableData.map((item) => {
        let day = "";
        let month = "";
        let year = "";
        let eventStartTime = "";
        let eventEndTime = "";
        let urlEventInfo = "";

        if (item.eventReservations.length > 0) {
          day = new Date(item.eventReservations[0].eventDate)
            .getDate()
            .toString();
          month = (
            new Date(item.eventReservations[0].eventDate).getMonth() + 1
          ).toString();
          year = new Date(item.eventReservations[0].eventDate)
            .getFullYear()
            .toString();

          eventStartTime = item.eventReservations[0].eventStartTime.substring(
            0,
            5
          );
          eventEndTime = item.eventReservations[0].eventEndTime.substring(0, 5);
        }

        switch (item.eventType.id) {
          case 1:
            urlEventInfo = "Batismo/infoBatismo";
            break;

          case 2:
            urlEventInfo = "Casamento/infoCasamento";
            break;

          case 3:
            urlEventInfo = "Missa/infoMissa";
            break;

          default:
            urlEventInfo = "tipoEvento";
            break;
        }

        return {
          ...item,
          ...{
            status: item.eventReservations.length
              ? this.statusOfTable(item.eventReservations[0].eventStatusId)
              : "",
            editarButton: (
              <Link to={`/admin/Evento/` + urlEventInfo + `/?id=${item.id}`}>
                <button className="btn btn-sm edit">Editar</button>
              </Link>
            ),
            excluirButton: (
              <button
                type="button"
                className="btn btn-sm exc"
                key={item.id}
                onClick={() => this.openModal(item.id)}
              >
                Excluir
              </button>
            ),
            requester: item.person ? item.person.name : item.responsibleName,
            eventType:
              (item.eventTypeId === 1 ? "Batizado" : "") ||
              (item.eventTypeId === 2 ? "Casamento" : "") ||
              (item.eventTypeId === 3 ? "Missa" : ""),
            date:
              day && month && year
                ? (day.length === 1 ? "0" + day : day) +
                  "/" +
                  (month.length === 1 ? "0" + month : month) +
                  "/" +
                  (year.length === 1 ? "0" + year : year)
                : "",
            schedule:
              eventStartTime && eventEndTime
                ? eventStartTime + " - " + eventEndTime
                : "",
          },
        };
      });

      total = this.state.eventResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.submitSearch(index);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir este registro?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.eventId}
                onClick={() => this.deleteEvent(this.state.eventId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Eventos</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
            <Col lg="6">
              <Link to="/admin/Evento/tipoEvento">
                <button className="btn button-add-people float-lg-right text-white mt-2">
                  + Novo Registro
                </button>
              </Link>
            </Col>
          </Row>

          <Form>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div className="pt-4 pb-4">
                  <input
                    type="text"
                    id="name"
                    value={
                      this.state.formData["name"]
                        ? this.state.formData["name"]
                        : ""
                    }
                    class={
                      "form-control showFilterActive text-dark " +
                      (this.state.formData["name"] ? "filterActive" : "")
                    }
                    placeholder="Digite o nome do solicitante ou noivo(a)"
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div className="pt-4">
                  <h2 className="text-dark">Ou utilize os filtros abaixo:</h2>
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pt-4 pl-5">
              <Col lg="4">
                <div className="form-group">
                  <UncontrolledDropdown>
                    <label className="text-dark font-weight-900 pt-1">
                      Tipo de evento
                    </label>
                    <DropdownToggle
                      className={
                        "form-control text-dark font-weight-900  mt--1 text-left default-select " +
                        (this.state.formData.eventTypeIds.length > 0
                          ? "filterActive"
                          : "")
                      }
                      onClick={(e) => e.preventDefault()}
                    >
                      <label className="text-select font-weight-900 label-checkbox">
                        Selecione
                      </label>
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdown-menu-arrow col-12"
                      flip={false}
                    >
                        <div class="custom-control custom-checkbox select-search">
                          <input
                            type="checkbox"
                            class={"custom-control-input input-checkbox"}
                            name="eventTypeIds"
                            id={"baptism=" + 1}
                            onChange={this.onInputChange}
                            checked={
                              this.state.formData.eventTypeIds.indexOf("1") !==
                              -1
                                ? true
                                : false
                            }
                          />
                          <label
                            class="custom-control-label pb-3"
                            for={"baptism=" + 1}
                          >
                            Batizado
                          </label>
                          <br />
                        </div>

                        <div class="custom-control custom-checkbox select-search">
                          <input
                            type="checkbox"
                            class={
                              "custom-control-input input-checkbox showFilterActive"
                            }
                            name="eventTypeIds"
                            id={"marriage=" + 2}
                            onChange={this.onInputChange}
                            checked={
                              this.state.formData.eventTypeIds.indexOf("2") !==
                              -1
                                ? true
                                : false
                            }
                          />
                          <label
                            class="custom-control-label pb-3"
                            for={"marriage=" + 2}
                          >
                            Casamento
                          </label>
                          <br />
                        </div>

                        <div class="custom-control custom-checkbox select-search">
                          <input
                            type="checkbox"
                            class={
                              "custom-control-input input-checkbox showFilterActive"
                            }
                            name="eventTypeIds"
                            id={"mass=" + 3}
                            onChange={this.onInputChange}
                            checked={
                              this.state.formData.eventTypeIds.indexOf("3") !==
                              -1
                                ? true
                                : false
                            }
                          />
                          <label
                            class="custom-control-label pb-3"
                            for={"mass=" + 3}
                          >
                            Missa
                          </label>
                          <br />
                        </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
              <Col lg="4">
                <div class="form-group">
                  <label
                    for="eventStartDate"
                    class="form-control-label text-dark"
                  >
                    Período (De)
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["eventStartDate"]
                        ? "filterActive"
                        : "")
                    }
                    type="date"
                    id="eventStartDate"
                    value={
                      this.state.formData["eventStartDate"]
                        ? this.state.formData["eventStartDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="4">
                <div class="form-group">
                  <label
                    for="eventEndDate"
                    class="form-control-label text-dark"
                  >
                    Período (Até)
                  </label>
                  <input
                    class={
                      "form-control text-dark " +
                      (this.state.formData["eventEndDate"]
                        ? "filterActive"
                        : "")
                    }
                    type="date"
                    id="eventEndDate"
                    value={
                      this.state.formData["eventEndDate"]
                        ? this.state.formData["eventEndDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-4 pt-5 pr-5">
              <Col lg="12">
                <div className="float-lg-right">
                  <button
                    className="btn-person-clean"
                    type="reset"
                    onClick={this.clearFormState}
                  >
                    Limpar
                  </button>
                  <button
                    className="btn-person-search ml-4"
                    type="button"
                    onClick={() => {
                      this.submitSearch(0);
                    }}
                  >
                    Buscar
                  </button>
                </div>
              </Col>
            </Row>
          </Form>

          <Row className="pt-5">
            {this.state.eventNotFound ? (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openEventNotFound}
                        text={this.state.messageExclusion}
                        handleClose={() => {
                          this.setState({ openEventNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorEventNotFound ? (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openErrorEventNotFound}
                        text={this.state.errorMessage}
                        handleClose={() => {
                          this.setState({ openErrorEventNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {!this.state.confirmExclusao || (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title={this.state.nameEvent}
                        text={this.state.messageExclusion}
                        success="Sim"
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
            {this.state.confirmExclusao || (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title="Erro"
                        text={this.state.messageExclusion}
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
          </Row>
          <div className="pt-5 pb-5 text-lg-center">
            <h2 className="text-dark font-weight-bold">Eventos cadastrados</h2>
          </div>
          <Row className="pt-4 pl-5">
            <Col lg="12">
              {this.state.renderEventList ? (
                <DataList
                  loading={this.state.eventLoading}
                  tableCollumns={tableCollumns}
                  tableData={tableData}
                  paging={true}
                  currentPage={this.state.currentPage}
                  pageSize={constants.Pagination.DefaultPageSize}
                  total={total}
                  pageChangeHandler={(index) => {
                    pageChangeHandler(index);
                  }}
                  exportHandler={exportHandler}
                  exporting={true}
                  exportProcessing={this.state.exporting}
                />
              ) : (
                <>
                  {this.state.eventNotFound ||
                    this.state.errorEventNotFound || <h1> Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentDidMount() {
    if (constants.LocalStorage.PersonId)
      window.localStorage.setItem(constants.LocalStorage.PersonId, 0);

    this.submitSearch(0);

    //this.loadSelects();
    this.loadEventStatus();
  }

  loadEventStatus() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.EventStatus.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ eventStatusResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  statusOfTable(eventStatusId) {
    const { eventStatusResponse } = this.state;

    let eventName = "";

    eventStatusResponse.forEach((item) => {
      if (item.id === eventStatusId) {
        eventName = item.name;
      }
    });

    return eventName;
  }

  confirmExclusion(name) {
    this.submitSearch(0);

    if (name) {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        nameEvent: name,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ eventId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  clearFormState() {
    this.setState({
      formData: { eventTypeIds: [] },
      searchMode: false,
      errorMessage: "",
      eventNotFound: false,
      ErrorEventNotFound: false,
    });
    this.submitSearch(0);
  }

  deleteEvent(eventId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Event.Delete +
      "/" +
      eventId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({
            modalIsOpen: false,
            eventLoading: false,
            renderEventList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            modalIsOpen: false,
            eventLoading: false,
            renderEventList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null) {
          this.setState({
            modalIsOpen: false,
            eventLoading: false,
            renderEventList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.BlankResponse, result);
          return;
        }
        this.setState({
          modalIsOpen: false,
          eventLoading: false,
          renderEventList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
          nameEvent: "",
        });

        this.confirmExclusion(
          (result.response.eventTypeId === 1 ? "Batizado" : "") ||
            (result.response.eventTypeId === 2 ? "Casamento" : "") ||
            (result.response.eventTypeId === 3 ? "Missa" : "")
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          eventLoading: false,
          renderEventList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let name = "";
    let value = "";
    let isDropdown = false;

    if (!target.name) {
      if (target.id.indexOf("=") !== -1) name = target.id.split("=")[1];
      else name = target.id;
    } else name = target.name;

    if (target.type === "checkbox") {
      isDropdown = true;

      if (this.state.formData[name].length > 0) {
        if (this.state.formData[name].indexOf(target.id.split("=")[1]) === -1) {
          value = target.id;
        } else value = "";
      } else value = target.id;
    } else {
      value = "";
      value = target.value;
    }

    if (isDropdown === false) {
      this.setState({
        formData: Object.assign(this.state.formData, { [name]: value }),
      });
    }

    value = value.split("=")[1];

    if (isDropdown) {
      if (value) {
        this.state.formData[name].push(value);
      } else {
        this.state.formData[name].splice(
          this.state.formData[name].indexOf(target.id.split("=")[1]),
          1
        );
      }
    }

    if (this.state.formData.eventTypeIds.length > 0)
      this.setState({ isChecked: true });
    else this.setState({ isChecked: false });
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage === undefined || currentPage === null) currentPage = 0;

    this.setState({
      eventLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });
    let formData = this.state.formData;
    if (formData.name == null){
      formData.name = ""
    }

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.PagedSearch;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {

        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            eventLoading: false,
            renderEventList: false,
            errorEventNotFound: true,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            eventLoading: false,
            renderEventList: false,
            errorEventNotFound: true,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            eventLoading: false,
            renderEventList: false,
            errorEventNotFound: true,
            openErrorEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total === 0) {
          this.setState({
            eventLoading: false,
            renderEventList: false,
            eventNotFound: true,
            openEventNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Evento não encontrado",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          eventLoading: false,
          renderEventList: true,
          eventNotFound: false,
          openEventNotFound: false,
          errorEventNotFound: false,
          openErrorEventNotFound: false,
          errorMessage: "",
          eventResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          eventLoading: false,
          renderEventList: false,
          errorEventNotFound: true,
          openErrorEventNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          constants.Endpoint.UrlBase +
            constants.Endpoint.Event.Download +
            "?fileKey=" +
            result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default Eventos;
