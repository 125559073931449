//#region Imports

import Modal from "react-modal";
import React from "react";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import { objectToFormData } from "object-to-formdata";
import DataList from "components/Utils/dataList.jsx";
import messages from "variables/messages";
import { formater } from "components/Utils/formater";
import InputSearch from "components/Utils/inputSearch";
import { diruma } from "components/Utils/diruma";
import Loading from "components/Utils/Loading.jsx";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";

//#endregion


class CadastroPastoral extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      messageExclusion: "",
      submitLoading: false,
      formData: { pastoralMembers: [] },
      personId: -1,
      sugestedList: [],
      pastoralResponse: [],
      sugestedCoordinator: {},
      isPastoralChange: -1,
      addPastoralLoading: false,
      renderPastoralList: false,
      modalPastoralOpen: false,
      cleanInputSugestion: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Event Binding

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadSugestedCoordinator = this.loadSugestedCoordinator.bind(this);
    this.deletePastoral = this.deletePastoral.bind(this);
    this.editPastoral = this.editPastoral.bind(this);
    this.clearFormState = this.clearFormState.bind(this);

    //#endregion
  }

  render() {
    let pastoralTableCollumns = [];
    let pastoralTableData = [];

    if (this.state.pastoralResponse) {
      pastoralTableCollumns = [
        { name: "Nome", size: 40, property: "coordinateName" },
        { name: "Função", size: 40, property: "functionName" },
        { name: "", size: 10, property: "editarButton" },
        { name: "", size: 10, property: "excluirButton" },
      ];

      pastoralTableData = this.state.pastoralResponse;

      pastoralTableData = pastoralTableData.map((item, index) => {
        let functionName = "";
        let functionId = item.functionId;

        switch (functionId) {
          case "1":
            functionName = "Coordenador";
            break;

          case "2":
            functionName = "Membro do conselho";
            break;

          case "3":
            functionName = "Membro da equipe";
            break;

          default:
            functionName = "-";
            break;
        }

        return {
          ...item,
          ...{
            editarButton: (
              <button
                type="button"
                className="btn btn-sm edit"
                key={index}
                onClick={() => this.editPastoral(index)}
              >
                Editar
              </button>
            ),
            excluirButton: (
              <button
                type="button"
                className="btn btn-sm exc"
                key={index}
                onClick={() => this.openModal(index)}
              >
                Excluir
              </button>
            ),
            functionName: functionName,
          },
        };
      });
    }

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "pastoralName",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    console.log("testeExclusao", this.state.sugestedCoordinator);

    return (
      <>
        {/* //#region Modal */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>
        {/* //#endregion Modal */}

        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Pastorais</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>

          <Form>
            <Row className="pl-4 pt-5">
              <Col lg="12">
                <div className="pt-4">
                  <h1 className="text-dark font-weight-900 pb-1">
                    Dados da pastoral
                  </h1>
                </div>
              </Col>
            </Row>
            <Row className="pt-4 pl-5">
              <Col lg="12">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Nome da pastoral
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="pastoralName"
                    name="pastoralName"
                    ref="pastoralName"
                    value={
                      this.state.formData["pastoralName"]
                        ? this.state.formData["pastoralName"]
                        : ""
                    }
                    placeholder="Digite o nome"
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pl-4 pt-3">
              <Col lg="12">
                <div className="pt-4">
                  <h1 className="text-dark font-weight-900 pb-1">Membros</h1>
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pl-5 pt-4">
              <Col lg="6" className="mt-inputSearch pt-1">
                <InputSearch
                  title="Coordenador"
                  fieldId="coordinator"
                  pageHandler="pastoral"
                  value={this.state.sugestedCoordinator}
                  changeHandler={(id) => {
                    this.loadSugestedCoordinator(id);
                  }}
                  deleteHandler={() => {
                    this.clearFormState();
                  }}
                  sugestedList={this.state.sugestedList}
                  personId={this.state.personId}
                  hasPhoto={false}
                  cleanInput={this.state.cleanInputSugestion}
                />
              </Col>

              <Col lg="3">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Função
                  </label>
                  <select
                    disabled={this.state.eventId ? true : false}
                    name="functionId"
                    id="functionId"
                    ref="functionId"
                    class={
                      "text-dark font-weight-900 form-control showFilterActive"
                    }
                    onChange={this.onInputChange}
                  >
                    <option value="">Selecione</option>
                    <option
                      value="1"
                      selected={
                        this.state.formData.functionId === "1" ? true : false
                      }
                    >
                      Coordenador
                    </option>
                    <option
                      value="2"
                      selected={
                        this.state.formData.functionId === "2" ? true : false
                      }
                    >
                      Membro do conselho
                    </option>
                    <option
                      value="3"
                      selected={
                        this.state.formData.functionId === "3" ? true : false
                      }
                    >
                      Membro da equipe
                    </option>
                  </select>
                </div>
              </Col>
            </Row>

            <div className="container-fluid mb-6 mt-6">
              <Row>
                <Col>
                  <div className="float-lg-right">
                    {this.state.isPastoralChange !== -1 ? (
                      <button
                        type="button"
                        className="btn-info-person-cancel mr-2"
                        onClick={() => {
                          this.pastoralEditionCancel();
                        }}
                      >
                        Cancelar Edição
                      </button>
                    ) : (
                      <> </>
                    )}
                    <button
                      type="button"
                      className="btn-person-search ml-4"
                      onClick={() => {
                        this.addPastoral();
                      }}
                    >
                      {this.state.isPastoralChange === -1 ? (
                        <>
                          {this.state.addPastoralLoading ? (
                            <Loading load="Sim" />
                          ) : (
                            "Adicionar membro"
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.addPastoralLoading ? (
                            <Loading load="Sim" />
                          ) : (
                            "Editar"
                          )}
                        </>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            <Row className="pt-4 pl-5">
              <Col lg="12">
                {this.state.renderPastoralList ? (
                  <>
                    <DataList
                      tableCollumns={pastoralTableCollumns}
                      tableData={pastoralTableData}
                    />
                  </>
                ) : (
                  <>
                    {!this.state.addPastoralLoading || <h1> Carregando...</h1>}
                  </>
                )}
              </Col>
            </Row>
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Pastorais"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({ personId: id });
    }
  }

  componentDidMount() {
    this.loadSelects();
  }

  //#endregion

  //#region Custom Events

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "name":
          value = formater.maxLength(target.value, 100);
          break;

        default:
          value = target.value;
      }


    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  loadSelects() {
    this.loadPerson();
  }

  openModal(id) {
    this.setState({ pastoralId: id, modalPastoralOpen: true });
  }

  closeModal() {
    this.setState({ modalPastoralOpen: false });
  }

  clearFormState() {
    this.setState({
      sugestedCoordinator: null,
      personId: 0,
      cleanInputSugestion: true,
    });
  }

  pastoralEditionCancel() {
    let formData = this.state.formData;

    delete formData.functionId;

    this.clearFormState();

    this.setState({ isPastoralChange: -1, formData: formData });
  }

  loadSugestedCoordinator(personId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let formData = this.state.formData;
        let ret = result.response;

        this.setState({ sugestedCoordinator: ret, personId: ret.id });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let personList = result.response.map(function (item, indice) {
          return {
            id: item.id,
            name: item.name,
          };
        });

        this.setState({ sugestedList: personList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  addPastoral() {
    this.setState({ addPastoralLoading: true });

    let formData = this.state.formData;
    let pastoralList = this.state.pastoralResponse;
    let pastoralSugested = this.state.sugestedCoordinator;
    let isChange = this.state.isPastoralChange;
    let coordinateName = null;

    if (pastoralSugested)
      if (pastoralSugested.id) coordinateName = pastoralSugested.name;

    if (coordinateName && formData.functionId) {
      if (isChange === -1) {
        pastoralList.push({
          coordinatorId: pastoralSugested.id,
          coordinateName: coordinateName,
          functionId: formData.functionId,
        });

        delete formData.functionId;

        this.clearFormState();
        this.setState({
          addPastoralLoading: false,
          renderPastoralList: true,
          pastoralResponse: pastoralList,
        });
      } else {
        pastoralList.splice(isChange, 1);

        pastoralList.push({
          coordinatorId: pastoralSugested.id,
          coordinateName: coordinateName,
          functionId: formData.functionId,
        });

        delete formData.functionId;

        isChange = -1;

        this.clearFormState();
        this.setState({
          addPastoralLoading: false,
          renderPastoralList: true,
          pastoralResponse: pastoralList,
          isPastoralChange: isChange,
        });
      }
    } else {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro durante o registro, por favor verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li>preencha os campos de Membros</li>
                </ul>
              </>
            ),
          },
        },
        addPastoralLoading: false,
      }));

      return;
    }
  }

  deletePastoral(index) {
    let pastoralList = this.state.pastoralResponse;

    pastoralList.splice(index, 1);

    if (pastoralList.length <= 0) this.setState({ renderPastoralList: false });

    this.setState({ modalPastoralOpen: false, pastoralResponse: pastoralList });
  }

  editPastoral(index) {
    let formData = this.state.formData;
    let pastoralList = this.state.pastoralResponse;

    let itemEdit = pastoralList[index];

    this.clearFormState();

    formData = { ...itemEdit };

    this.setState({ formData: formData, isPastoralChange: index });
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let formData = this.state.formData;
    let pastoralList = this.state.pastoralResponse;

    if (pastoralList.length > 0) {
      formData.pastoralMembers = pastoralList.map((item) => {
        return item;
      });
    }

    window.location.href = "/admin/Pastorais";

    formData = objectToFormData(formData);

    // let token = window.localStorage.getItem(constants.LocalStorage.Token);
    // let url = constants.Endpoint.UrlBase + constants.Endpoint.Pastoral.Post;
    // let data = formData;

    // smoothApi.post(url, data, token,

    //     (result) => {

    //         if (result.success == null) {

    //             console.error(messages.Errors.UnexpectedFormat, result);
    //             this.setState({ submitLoading: false, errorMessage: messages.Errors.UnexpectedFormat });

    //             return;

    //         }

    //         if (!result.success) {

    //             console.error(messages.Errors.ResponseError, result.errors);

    //             this.setState((state) => ({
    //                 message: {
    //                     ...state.message, ...{
    //                         title: 'Aviso',
    //                         visible: true,
    //                         text: <>
    //                             Ocorreu um erro durante o registro, por favor verifique a <span className="errorText"> mensagem </span> e tente novamente:

    //                             <ul className="errorSumary">
    //                                 {

    //                                     result.errors.map((el, currentIndex) => {

    //                                         return <li key={"validationItem_" + currentIndex} >{el + "."}</li>

    //                                     })

    //                                 }
    //                             </ul>
    //                         </>

    //                     }
    //                 },
    //                 submitLoading: false
    //             }));

    //             return;

    //         }

    //     },

    //     (error, httpCode) => {

    //         if (httpCode === 401)
    //             window.location.href = "/auth/Login";

    //         this.setState({ submitLoading: false, errorMessage: messages.Errors.HttpError });

    //     }

    // );
  }
}

export default CadastroPastoral;
