import React from "react";

import messages from "variables/messages";
import constants from "variables/constants";

import Dating from "components/Utils/Dating";
import { smoothApi } from "components/Utils/smoothApi";

import { Container, Row, Col } from "reactstrap";

class Agenda extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},

      loadMonthEventsResponse: null,
      eventId: 0,

      calendarSelectedYear: new Date().getFullYear(),
      calendarSelectedMonth: new Date().getMonth() + 1,
      calendarSelectedDay: new Date().getDate(),
    };

    this.dateClickHandler = this.dateClickHandler.bind(this);
    this.loadMonthEvents = this.loadMonthEvents.bind(this);
    this.navigationClickHandler = this.navigationClickHandler.bind(this);
  }

  render() {
    let diaLabel = "Selecione uma data";
    let calendarMarkedDays = [];

    let selectedDateEvents = [
      <div key={"noEvents"} style={{ marginTop: "20px" }} className="text-dark">
        <h3>Clique em algum dia no calendário ao lado</h3>
        <div>
          <i
            style={{ fontSize: "300px", marginTop: "30px", color: "#3F4C70" }}
            className="fas fa-calendar-alt"
          ></i>
        </div>
      </div>,
    ];

    if (this.state.calendarSelectedDay) {
      selectedDateEvents = (
        <div
          key={"noEvents"}
          style={{ marginTop: "20px", textAlign: "center" }}
          className="text-dark"
        >
          <img
            style={{ width: "50%", marginBottom: "10px" }}
            alt="Nao ha eventos para esta data"
            src={require("assets/img/images/calendar.png")}
          />
          <h1 className="text-dark">Não há eventos para esta data</h1>
        </div>
      );
    }

    if (this.state.calendarSelectedDay) {
      let selectedDate = new Date(
        this.state.calendarSelectedYear,
        this.state.calendarSelectedMonth - 1,
        this.state.calendarSelectedDay
      );

      if (!isNaN(selectedDate.getTime())) {
        let weekDay = "";
        let monthName = "";

        switch (selectedDate.getDay()) {
          case 0:
            weekDay = "Domingo";
            break;
          case 1:
            weekDay = "Segunda-feira";
            break;
          case 2:
            weekDay = "Terça-feira";
            break;
          case 3:
            weekDay = "Quarta-feira";
            break;
          case 4:
            weekDay = "Quinta-feira";
            break;
          case 5:
            weekDay = "Sexta-feira";
            break;
          case 6:
            weekDay = "Sábado";
            break;
        }

        switch (selectedDate.getMonth()) {
          case 0:
            monthName = "janeiro";
            break;
          case 1:
            monthName = "fevereiro";
            break;
          case 2:
            monthName = "março";
            break;
          case 3:
            monthName = "abril";
            break;
          case 4:
            monthName = "maio";
            break;
          case 5:
            monthName = "junho";
            break;
          case 6:
            monthName = "julho";
            break;
          case 7:
            monthName = "agosto";
            break;
          case 8:
            monthName = "setembro";
            break;
          case 9:
            monthName = "outubro";
            break;
          case 10:
            monthName = "novembro";
            break;
          case 11:
            monthName = "dezembro";
            break;
        }

        diaLabel = weekDay + ", " + selectedDate.getDate() + " de " + monthName;
      }
    }

    if (
      this.state.loadMonthEventsResponse &&
      this.state.loadMonthEventsResponse.length
    ) {
      calendarMarkedDays = this.state.loadMonthEventsResponse.map((el) => {
        let tempData = new Date(el.eventDate);

        if (isNaN(tempData.getTime())) return 0;

        return tempData.getDate();
      });

      let selectedDate = new Date(
        this.state.calendarSelectedYear,
        this.state.calendarSelectedMonth - 1,
        this.state.calendarSelectedDay
      );

      if (!isNaN(selectedDate.getTime())) {
        if (
          !!calendarMarkedDays.find((el) => {
            return el === selectedDate.getDate();
          })
        ) {
          let dayEvents = [];

          this.state.loadMonthEventsResponse.forEach((el) => {
            let tempData = new Date(el.eventDate);

            if (!isNaN(tempData.getTime())) {
              if (tempData.getDate() === selectedDate.getDate()) {
                dayEvents.push(el);
              }
            }
          });

          if (!!dayEvents && dayEvents.length) {
            selectedDateEvents = dayEvents.map((el) => {
              let boopColor = "#E1E2EF";
              let textElementClasses = "";
              let elementError = false;

              if (
                el.eventStatusId === constants.EventStatusId.Cancelado ||
                el.eventStatusId ===
                  constants.EventStatusId.CanceladoComPendencia
              ) {
                textElementClasses = " lineThrough";
              } else {
                let eventType = constants.EventTypes.find((el2) => {
                  return el2.id === el.eventTypeId;
                });

                if (!eventType) {
                  console.error(
                    "Unable to find compatible event type id for: " +
                      el.eventTypeId
                  );
                  elementError = true;
                } else {
                  boopColor = eventType.boopColor;
                }
              }

              if (elementError) {
                return <></>;
              } else {
                return (
                  <div
                    style={{ marginTop: "20px" }}
                    key={"eventDetails " + el.eventId}
                  >
                    <i
                      style={{ color: boopColor, fontSize: "20px" }}
                      className="fas fa-circle"
                    ></i>
                    <span
                      className={"text-dark" + textElementClasses}
                      style={{ fontSize: "20px", marginLeft: "10px" }}
                    >
                      {el.eventStartTime.split(":").slice(0, 2).join(":") +
                        " - " +
                        el.eventEndTime.split(":").slice(0, 2).join(":") +
                        " - " +
                        el.eventDescription}
                    </span>
                  </div>
                );
              }
            });
          }
        }
      }
    }

    return (
      <>
        <Container fluid>
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">Agenda</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid minHeightFooter mt-5 mb-5">
          <Row lg="12">
            <Col lg="6">
              <Dating
                navigationClickHandler={this.navigationClickHandler}
                dateClickHandler={this.dateClickHandler}
                markedDays={calendarMarkedDays}
                day={this.state.calendarSelectedDay}
                month={this.state.calendarSelectedMonth}
                year={this.state.calendarSelectedYear}
              />
            </Col>
            <Col lg="6">
              <>
                <h1 className="text-dark">{diaLabel}</h1>

                <div>{selectedDateEvents}</div>
              </>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.EventReservation.GetByEvent;
    let data = "eventId=" + this.state.eventId;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        let hasError = false;

        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          hasError = true;
        } else if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          hasError = true;
        } else if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          hasError = true;
        }

        if (hasError) {
          this.loadMonthEvents(
            this.state.calendarSelectedMonth,
            this.state.calendarSelectedYear
          );
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        if (this.state.eventId)
          if (this.state.eventId !== 0)
            window.localStorage.setItem(
              constants.LocalStorage.EventId,
              this.state.eventId
            );

        formData.id = ret.id;

        function dateFormat(date) {
          let day = "";
          let month = "";
          let year = "";
          let dateFormated = "";

          day = new Date(date).getDate().toString();
          month = (new Date(date).getMonth() + 1).toString();
          year = new Date(date).getFullYear().toString();

          dateFormated =
            (day.toString().length === 1 ? "0" + day : day) +
            "/" +
            (month.toString().length === 1 ? "0" + month : month) +
            "/" +
            (year.toString().length === 1 ? "0" + year : year);

          return dateFormated;
        }

        let eventStartTime = ret.eventStartTime.substring(0, 5);
        let eventEndTime = ret.eventEndTime.substring(0, 5);

        formData.eventDate = dateFormat(ret.eventDate);
        formData.eventStartTime = eventStartTime;
        formData.eventEndTime = eventEndTime;

        if (ret.reservationValidUntilDate)
          formData.reservationValidUntilDate = dateFormat(
            ret.reservationValidUntilDate
          );

        if (ret.eventStatusId) formData.eventStatusId = ret.eventStatusId;

        let eventDay = new Date(ret.eventDate).getDate().toString();
        let eventMonth = (new Date(ret.eventDate).getMonth() + 1).toString();
        let eventYear = new Date(ret.eventDate).getFullYear().toString();

        this.loadMonthEvents(eventMonth, eventYear);

        this.setState({
          formData: formData,
          calendarSelectedDay: eventDay,
          calendarSelectedMonth: eventMonth,
          calendarSelectedYear: eventYear,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  navigationClickHandler(left) {
    let targetMonth = this.state.calendarSelectedMonth;
    let targetYear = this.state.calendarSelectedYear;
    let targetDay = 1;

    if (left) targetMonth--;
    else targetMonth++;

    if (targetMonth > 12) {
      targetMonth = 1;
      targetYear++;
    }

    if (targetMonth < 1) {
      targetMonth = 12;
      targetYear--;
    }

    if (new Date().getMonth() + 1 === targetMonth)
      targetDay = new Date().getDate();

    let selectedDate =
      (targetDay.toString().length === 1 ? "0" + targetDay : targetDay) +
      "/" +
      (targetMonth.toString().length === 1 ? "0" + targetMonth : targetMonth) +
      "/" +
      (targetYear.toString().length === 1 ? "0" + targetYear : targetYear);

    this.setState({
      calendarSelectedYear: targetYear,
      calendarSelectedMonth: targetMonth,
      calendarSelectedDay: targetDay,
      formData: Object.assign(this.state.formData, { eventDate: selectedDate }),
    });

    this.loadMonthEvents(targetMonth, targetYear);
  }

  dateClickHandler(day, date, sameMonth) {
    if (sameMonth) {
      this.setState({ calendarSelectedDay: day });
    } else {
      let targetMonth = this.state.calendarSelectedMonth;
      let targetYear = this.state.calendarSelectedYear;

      if (this.state.calendarSelectedMonth - 2 === date.getMonth())
        targetMonth--;
      else targetMonth++;

      if (targetMonth > 12) {
        targetMonth = 1;
        targetYear++;
      }

      if (targetMonth < 1) {
        targetMonth = 12;
        targetYear--;
      }

      this.setState({
        calendarSelectedYear: targetYear,
        calendarSelectedMonth: targetMonth,
        calendarSelectedDay: day,
      });
    }

    if (date) {
      let eventDay = new Date(date).getDate().toString();
      let eventMonth = (new Date(date).getMonth() + 1).toString();
      let eventYear = new Date(date).getFullYear().toString();

      let selectedDate =
        (eventDay.length === 1 ? "0" + eventDay : eventDay) +
        "/" +
        (eventMonth.length === 1 ? "0" + eventMonth : eventMonth) +
        "/" +
        (eventYear.length === 1 ? "0" + eventYear : eventYear);

      this.setState({
        formData: Object.assign(this.state.formData, {
          eventDate: selectedDate, eventStartTime: "", eventEndTime: ""
        }),
      });
    }
  }

  loadMonthEvents(month, year) {
    this.setState({ loadMonthEventsResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Event.CalendarSearch;
    let data = { year: year, month: month };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ loadMonthEventsResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
}

export default Agenda;
