//#region Imports

import React from "react";

import {
  Container,
  Row,
  Col
} from "reactstrap";

//#endregion

class Certificados extends React.Component {

  //#region Render
  render() {

    //#endregion
    return (
      <>
        <Container fluid className="minHeightFooter">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark">Certificados</h1>
                <img className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  
  //#region Events

  //#endregion

  //#region Component Events

  //#endregion

  //#region Custom Components

  //#endregion

  
}

export default Certificados;
