//#region Imports

import React from "react";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import { Link } from 'react-router-dom';

//#endregion

class CadastrosAuxiliares extends React.Component {

  //#region Render
  render() {

    //#endregion
    return (
      <>
        <Container fluid className="minHeightFooter">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark">Cadastros Auxiliares</h1>
                <img className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Row className="pl-5 pb-4 pt-6">
            <Col lg="12">
              <div className="">
                <h1 className="title text-dark">O que deseja cadastrar?</h1>
              </div>
            </Col>
          </Row>
          <Row className="mb-4 pt-4 pl-5">
            <Col lg="2" xl="2">
              <Card className="card-stats mb-4 mb-xl-0">
                <Link to="/admin/Cadastros/tipoCurso">
                  <CardBody className="card-register">
                    <Row className="justify-content-center">
                      <div className="card-title-register">
                        <h3 className="card-text">Tipos de cursos</h3>
                      </div>
                    </Row>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col lg="2" xl="2">
              <Card className="card-stats mb-4 mb-xl-0">
                <Link to="/admin/Cadastros/celebrantes">
                  <CardBody className="card-register">
                    <Row className="justify-content-center">
                      <div className="card-title-register">
                        <h3 className="card-text">Celebrantes</h3>
                      </div>
                    </Row>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col lg="2" xl="2">
              <Card className="card-stats mb-4 mb-xl-0">
                <Link to="/admin/Cadastros/fornecedores">
                  <CardBody className="card-register">
                    <Row className="justify-content-center">
                      <div className="card-title-register">
                        <h3 className="card-text">Fornecedores</h3>
                      </div>
                    </Row>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col lg="2" xl="2">
              <Card className="card-stats mb-4 mb-xl-0">
                <Link to="/admin/Cadastros/locais">
                  <CardBody className="card-register">
                    <Row className="justify-content-center">
                      <div className="card-title-register">
                        <h3 className="card-text">Locais</h3>
                      </div>
                    </Row>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  //#endregion

  //#region Custom Components

  //#endregion

}

export default CadastrosAuxiliares;
