
import Modal from "react-modal";
import React from "react";
import DialogConfirm from "../../components/Utils/dialogConfirm.jsx";
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import { smoothApi } from "components/Utils/smoothApi.js";
import messages from "variables/messages";
import { Link } from "react-router-dom";
import "assets/scss/custom/_selectSearch.scss";
import {
  Container,
  Row,
  Col,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { formater } from "components/Utils/formater.js";
import { formatNumberOfContact } from "components/Utils/formarNumberOfContact.js";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },

};

class Pessoas extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      personResponse: null,
      personLoading: true,
      renderPersonList: true,
      errorMessage: "",
      errorOfDeletePersona: "",
      messageExclusion: "",
      isVisibleDeleteMsg: false,
      hasLinkOpen: false,
      hasLinkEventOpen: false,
      onSubimitModalError: false,
      currentPage: 0,
      personId: -1,
      namePerson: "",
      confirmExclusao: false,
      personNotFound: false,
      errorPersonNotFound: false,
      openErrorPersonNotFound: false,
      openPersonNotFound: false,
      openConfirm: false,
      formData: {
        NameOrEmail: "",
        baptismSituationIds: [],
        optInSituationsIds: [],
        typesOptSearch: {},
        eucharistSituationIds: [],
        chrismSituationIds: [],
        marriageSituationIds: [],
        birthMonths: [],
        professionIds: [],
        minAgeMaxAge: [],
        courseId: "",
      },
      situationResponse: null,
      ministryResponse: null,
      professionResponse: null,
      responsabilityResponse: null,
      courseResponse: null,
      searchMode: false,
      filterisActive: false,
      exporting: false,
      isChecked: false,
      accessLevelId: 0,
      accessLevelName: 0,
      hasLink: {
        showChildrenNames: false,
        showEventDescriptions: false,
        spouseName: null,
        fatherName: null,
        motherName: null,
        childrenNames: null,
        eventDescriptions: null,
      },
    };

    //#endregion

    //#region Event Binding

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.deletePerson = this.deletePerson.bind(this);
    this.confirmExclusion = this.confirmExclusion.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
    this.clearFormState = this.clearFormState.bind(this);

    //#endregion
  }


  render() {
    let ministrySelectItems = [];
    let baptismSelectItems = [];
    let eucharistSelectItems = [];
    let chrismSelectItems = [];
    let marriageSelectItems = [];
    let optInSelectItems = [];
    let professionSelectItems = [];
    let responsabilitySelectItems = [];
    let courseSelectItems = [];
    let tableCollumns = [];
    let tableData = [];
    let childrenNamesItems = [];
    let eventDescriptionsItems = [];
    let total = 0;

    if (this.state.situationResponse) {
      if (this.state.situationResponse.length > 0) {
        try {
          baptismSelectItems = this.state.situationResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div className="custom-control custom-checkbox select-search" >
                <input
                  checked={
                    this.state.formData.baptismSituationIds.indexOf(
                      idValue
                    ) !== -1
                      ? true
                      : false
                  }
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={"baptismSituationIds=" + item.id}
                  name="baptismSituationIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  className="custom-control-label"
                  for={"baptismSituationIds=" + item.id}
                >
                  {item.description}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          baptismSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.length > 0) {
        try {
          eucharistSelectItems = this.state.situationResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div className="custom-control custom-checkbox select-search">
                <input
                  checked={
                    this.state.formData.eucharistSituationIds.indexOf(
                      idValue
                    ) !== -1
                      ? true
                      : false
                  }
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={"eucharistSituationIds=" + item.id}
                  name="eucharistSituationIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  className="custom-control-label"
                  for={"eucharistSituationIds=" + item.id}
                >
                  {item.description}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          eucharistSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.length > 0) {
        try {
          chrismSelectItems = this.state.situationResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div className="custom-control custom-checkbox select-search">
                <input
                  checked={
                    this.state.formData.chrismSituationIds.indexOf(
                      idValue
                    ) !== -1
                      ? true
                      : false
                  }
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={"chrismSituationIds=" + item.id}
                  name="chrismSituationIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  className="custom-control-label"
                  for={"chrismSituationIds=" + item.id}
                >
                  {item.description}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          chrismSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.length > 0) {
        try {
          marriageSelectItems = this.state.situationResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div className="custom-control custom-checkbox select-search">
                <input
                  checked={
                    this.state.formData.marriageSituationIds.indexOf(
                      idValue
                    ) !== -1
                      ? true
                      : false
                  }
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={"marriageSituationIds=" + item.id}
                  name="marriageSituationIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  className="custom-control-label"
                  for={"marriageSituationIds=" + item.id}
                >
                  {item.description}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          marriageSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.hasLink.childrenNames) {
      if (this.state.hasLink.childrenNames.length > 0) {
        try {
          childrenNamesItems = this.state.hasLink.childrenNames.map((item) => {
            return (
              <div className="pt-2">
                <span className="hasLink">{item}</span>
              </div>
            );
          });
        } catch (ex) {
          childrenNamesItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.hasLink.eventDescriptions) {
      if (this.state.hasLink.eventDescriptions.length > 0) {
        try {
          eventDescriptionsItems = this.state.hasLink.eventDescriptions.map(
            (item) => {
              return (
                <div className="pt-4">
                  <span className="hasLink">{item}</span>
                </div>
              );
            }
          );
        } catch (ex) {
          eventDescriptionsItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.ministryResponse) {
      if (this.state.ministryResponse.length > 0) {
        try {
          ministrySelectItems = this.state.ministryResponse.map((item) => {
            return (
              <option value={item.id} key={"situation" + item.id}>
                {item.description}
              </option>
            );
          });
        } catch (ex) {
          ministrySelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.professionResponse) {
      if (this.state.professionResponse.length > 0) {
        try {
          professionSelectItems = this.state.professionResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div className="custom-control custom-checkbox select-search">
                <input
                  checked={
                    this.state.formData.professionIds.indexOf(idValue) !== -1
                      ? true
                      : false
                  }
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={"professionIds=" + item.id}
                  name="professionIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  className="custom-control-label"
                  for={"professionIds=" + item.id}
                >
                  {item.description}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          professionSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.length > 0) {
        try {
          optInSelectItems = this.state.situationResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div className="custom-control custom-radio select-search">
                <input
                  aria-busy={true}
                  checked={
                    this.state.formData.optInSituationsIds.indexOf(
                      idValue
                    ) !== -1
                      ? true
                      : false
                  }

                  type="radio"
                  className="custom-control-input input-radio"

                  id={"optInSituationsIds=" + item.id}
                  name="optInSituationsIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  className="custom-control-label"
                  style={customStyles}
                  for={"optInSituationsIds=" + item.id}
                >
                  {item.description}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          optInSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }




    if (this.state.responsabilityResponse) {
      if (this.state.responsabilityResponse.length > 0) {
        try {
          responsabilitySelectItems = this.state.responsabilityResponse.map(
            (item) => {
              return (
                <option value={item.id} key={"situation" + item.id}>
                  {item.responsability1}
                </option>
              );
            }
          );
        } catch (ex) {
          responsabilitySelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.courseResponse) {
      if (this.state.courseResponse.length > 0) {
        try {
          courseSelectItems = this.state.courseResponse.map((item) => {
            return (
              <option value={item.id} key={"situation" + item.id}>
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          courseSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    if (
      this.state.personResponse &&
      this.state.personResponse.list &&
      this.state.personResponse.total
    ) {
      tableCollumns = [
        { name: "Nome", size: 25, property: "name" },
        { name: "E-mail", size: 25, property: "email" },
        { name: "Telefone", size: 15, property: "homePhone" },
        { name: "Celular", size: 15, property: "mobilePhone" },
        { name: "", size: 10, property: "editarButton" },
        { name: "", size: 10, property: "excluirButton" },
      ];

      tableData = this.state.personResponse.list;
      tableData = tableData.map((item) => {
        return {
          ...item,
          ...{
            mobilePhone: formatNumberOfContact(item.mobilePhone),
            editarButton: (
              <Link to={`/admin/Pessoas/Pessoa/?id=${item.id}`}>
                <button className="btn btn-sm edit">Editar</button>
              </Link>
            ),
            excluirButton: (
              <button
                className="btn btn-sm exc"
                key={item.id}
                onClick={() => this.checkPersonLink(item.id)}
              >
                Excluir
              </button>
            ),
          },
        };
      });

      total = this.state.personResponse.total;
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.hasLinkEventOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Não é possível excluir essa pessoa <br /> pois está vinculada
              a(os) evento(s):
            </h2>
            <div className="text-center">
              {!this.state.hasLink.showEventDescriptions || (
                <>
                  <h3 className="text-dark text-center pb-2 text-modal">
                    {eventDescriptionsItems}
                  </h3>
                </>
              )}
            </div>

            <div className="text-center pt-4">
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Ok
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.hasLinkOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja excluir? <br /> Esta pessoa possui
              vínculos de:
            </h2>
            <div className="text-center">
              {!this.state.hasLink.fatherName || (
                <h3 className="text-dark text-center pb-2 text-modal">
                  Pai:{" "}
                  <span className="hasLink">
                    {this.state.hasLink.fatherName}
                  </span>
                </h3>
              )}

              {!this.state.hasLink.motherName || (
                <h3 className="text-dark text-center pb-2 text-modal">
                  Mãe:{" "}
                  <span className="hasLink">
                    {this.state.hasLink.motherName}
                  </span>
                </h3>
              )}

              {!this.state.hasLink.spouseName || (
                <h3 className="text-dark text-center pb-2 text-modal">
                  Conjuge:{" "}
                  <span className="hasLink">
                    {this.state.hasLink.spouseName}
                  </span>
                </h3>
              )}

              {!this.state.hasLink.showChildrenNames || (
                <>
                  <h3 className="text-dark text-center pb-2 text-modal">
                    Filho(s):
                    {childrenNamesItems}
                  </h3>
                </>
              )}
            </div>

            <div className="text-center pt-4">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.personId}
                onClick={() => this.deletePerson(this.state.personId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.onSubimitModalError}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Erro na exclusão
            </h2>
            <div className="text-center">

              <span className="text-red text-center">{this.state.errorOfDeletePersona}</span>
            </div>

            <div className="text-center pt-4">
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Entendi
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.hasNotLinkOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir esta pessoa?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.personId}
                onClick={() => this.deletePerson(this.state.personId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modal */}

        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Pessoas</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
            <Col lg="6">
              <Link to="/admin/Pessoas/Pessoa">
                <button className="btn button-add-people float-lg-right text-white mt-2">
                  + Novo Registro
                </button>
              </Link>
            </Col>
          </Row>

          <Form>
            <Row className="pt-4 pl-5 pr-5">
              <Col lg="12">
                <div>
                  <div className="pt-4 pb-4">
                    <input
                      type="text"
                      id="nameOrEmail"
                      name="nameOrEmail"
                      className={
                        "form-control showFilterActive text-dark " +
                        (this.state.formData["nameOrEmail"]
                          ? "filterActive"
                          : "")
                      }
                      placeholder="Digite o nome ou e-mail"
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="pt-4">
                    <h2 className="text-dark">Ou utilize os filtros abaixo:</h2>
                  </div>

                  <div>
                    <Row>
                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Sexo
                        </label>
                        <select
                          name="isMale"
                          id="isMale"
                          className={
                            "form-control showFilterActive text-dark font-weight-900 " +
                            (this.state.formData["isMale"]
                              ? "isFilterActive"
                              : "")
                          }
                          onChange={this.onInputChange}
                        >
                          <option value="">Selecione</option>
                          <option value="_boolTrue">Masculino</option>
                          <option value="_boolFalse">Feminino</option>
                        </select>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Batismo
                            </label>
                            <DropdownToggle
                              className={
                                "form-control text-dark font-weight-900 tp-3 mt--1 text-left default-select " +
                                (this.state.formData.baptismSituationIds
                                  .length > 0
                                  ? "filterActive"
                                  : "") +
                                (baptismSelectItems.length === 0
                                  ? " loadingSelect "
                                  : "")
                              }
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow col-12">
                              {baptismSelectItems.length === 0 ||
                                baptismSelectItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Primeira Comunhão
                            </label>
                            <DropdownToggle
                              className={
                                "form-control text-dark font-weight-900 tp-3 mt--1 text-left default-select " +
                                (this.state.formData.eucharistSituationIds
                                  .length > 0
                                  ? "filterActive"
                                  : "") +
                                (eucharistSelectItems.length === 0
                                  ? " loadingSelect "
                                  : "")
                              }
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow col-12">
                              {eucharistSelectItems.length === 0 ||
                                eucharistSelectItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Crisma
                            </label>
                            <DropdownToggle
                              className=
                              "form-control text-dark font-weight-900 tp-3 mt--1 text-left default-select "
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow col-12">
                              {chrismSelectItems.length === 0 ||
                                chrismSelectItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Notificação por email?
                            </label>
                            <DropdownToggle
                              className=
                              "form-control text-dark font-weight-900 tp-3 mt--1 text-left default-select "
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow col-12">
                              {optInSelectItems.length === 0 ||
                                optInSelectItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Casamento
                            </label>
                            <DropdownToggle
                              className={
                                "form-control text-dark font-weight-900 tp-4 mt--1 text-left default-select " +
                                (this.state.formData.marriageSituationIds
                                  .length > 0
                                  ? "filterActive"
                                  : "") +
                                (marriageSelectItems.length === 0
                                  ? " loadingSelect "
                                  : "")
                              }
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow col-12">
                              {marriageSelectItems.length === 0 ||
                                marriageSelectItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Filhos
                        </label>
                        <select
                          name="haveChild"
                          id="haveChild"
                          onChange={this.onInputChange}
                          className={
                            "form-control showFilterActive text-dark font-weight-900 " +
                            (this.state.formData["haveChild"]
                              ? "isFilterActive"
                              : "")
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="_boolTrue">Sim</option>
                          <option value="_boolFalse">Não</option>
                        </select>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Dizimista
                        </label>
                        <select
                          name="haveTithe"
                          id="haveTithe"
                          onChange={this.onInputChange}
                          className={
                            "form-control showFilterActive text-dark font-weight-900 " +
                            (this.state.formData["haveTithe"]
                              ? "isFilterActive"
                              : "")
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="_boolTrue">Sim</option>
                          <option value="_boolFalse">Não</option>
                        </select>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Possui e-mail
                        </label>
                        <select
                          name="haveEmail"
                          id="haveEmail"
                          onChange={this.onInputChange}
                          className={
                            "form-control showFilterActive text-dark font-weight-900 " +
                            (this.state.formData["haveEmail"]
                              ? "isFilterActive"
                              : "")
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="_boolTrue">Sim</option>
                          <option value="_boolFalse">Não</option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Aniversariantes
                            </label>
                            <DropdownToggle
                              className={
                                "form-control text-dark font-weight-900 tp-4 mt--1 text-left default-select " +
                                (this.state.formData.birthMonths.length > 0
                                  ? "filterActive"
                                  : "")
                              }
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>

                            <DropdownMenu
                              className="dropdown-menu-arrow col-12 yre"
                              flip={false}
                            >
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"january=" + 1}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "1"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"january=" + 1}
                                >
                                  Janeiro
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"february=" + 2}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "2"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"february=" + 2}
                                >
                                  Fevereiro
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"march=" + 3}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "3"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"march=" + 3}
                                >
                                  Março
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"april=" + 4}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "4"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"april=" + 4}
                                >
                                  Abril
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"may=" + 5}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "5"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"may=" + 5}
                                >
                                  Maio
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"june=" + 6}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "6"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"june=" + 6}
                                >
                                  Junho
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"july=" + 7}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "7"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"july=" + 7}
                                >
                                  Julho
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"august=" + 8}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "8"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"august=" + 8}
                                >
                                  Agosto
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"september=" + 9}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "9"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"september=" + 9}
                                >
                                  Setembro
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"october=" + 10}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "10"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"october=" + 10}
                                >
                                  Outubro
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"november=" + 11}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "11"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"november=" + 11}
                                >
                                  Novembro
                                </label>
                                <br />
                              </div>
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="birthMonths"
                                  id={"december=" + 12}
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.birthMonths.indexOf(
                                      "12"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for={"december=" + 12}
                                >
                                  Dezembro
                                </label>
                                <br />
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Faixa etária
                            </label>
                            <DropdownToggle
                              className={
                                "form-control text-dark font-weight-900 tp-4 mt--1 text-left default-select " +
                                (this.state.formData.minAgeMaxAge.length > 0
                                  ? "filterActive"
                                  : "")
                              }
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow col-12"
                              flip={false}
                            >
                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="minAgeMaxAge"
                                  id="minAgeMaxAge=0|6"
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.minAgeMaxAge.indexOf(
                                      "0|6"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for="minAgeMaxAge=0|6"
                                >
                                  0 a 6 anos
                                </label>
                                <br />
                              </div>

                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="minAgeMaxAge"
                                  id="minAgeMaxAge=7|13"
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.minAgeMaxAge.indexOf(
                                      "7|13"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for="minAgeMaxAge=7|13"
                                >
                                  7 a 13 anos
                                </label>
                                <br />
                              </div>

                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="minAgeMaxAge"
                                  id="minAgeMaxAge=14|18"
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.minAgeMaxAge.indexOf(
                                      "14|18"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for="minAgeMaxAge=14|18"
                                >
                                  14 a 18 anos
                                </label>
                                <br />
                              </div>

                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="minAgeMaxAge"
                                  id="minAgeMaxAge=19|29"
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.minAgeMaxAge.indexOf(
                                      "19|29"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for="minAgeMaxAge=19|29"
                                >
                                  19 a 29 anos
                                </label>
                                <br />
                              </div>

                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="minAgeMaxAge"
                                  id="minAgeMaxAge=30|59"
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.minAgeMaxAge.indexOf(
                                      "30|59"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for="minAgeMaxAge=30|59"
                                >
                                  30 a 59 anos
                                </label>
                                <br />
                              </div>

                              <div className="custom-control custom-checkbox select-search">
                                <input
                                  type="checkbox"
                                  className="custom-control-input input-checkbox"
                                  name="minAgeMaxAge"
                                  id="minAgeMaxAge=60|999"
                                  onChange={this.onInputChange}
                                  checked={
                                    this.state.formData.minAgeMaxAge.indexOf(
                                      "60|999"
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="custom-control-label pb-3"
                                  for="minAgeMaxAge=60|999"
                                >
                                  60 anos ou +
                                </label>
                                <br />
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>

                      {/* <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Pastoral
                        </label>{" "}
                        <select
                          name="ministryIds"
                          id="ministryIds"
                          disabled
                          className={
                            "text-dark font-weight-900 form-control showFilterActive loadingSelect " +
                            (ministrySelectItems.length === 0
                              ? "loadingSelect "
                              : "") +
                            (this.state.formData["ministryIds"]
                              ? "isFilterActive"
                              : "")
                          }
                          onChange={this.onInputChange}
                        >
                          {" "}
                          <option value="">Selecione</option>
                          {ministrySelectItems.length === 0 ||
                            ministrySelectItems}
                        </select>
                      </Col> */}

                      {/* <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Função
                        </label>{" "}
                        <select
                          name="responsabilityIds"
                          id="responsabilityIds"
                          disabled
                          className={
                            "text-dark font-weight-900 form-control showFilterActive loadingSelect " +
                            (responsabilitySelectItems.length === 0
                              ? "loadingSelect "
                              : "") +
                            (this.state.formData["responsabilityIds"]
                              ? "isFilterActive"
                              : "")
                          }
                          onChange={this.onInputChange}
                        >
                          {" "}
                          <option>Selecione</option>
                          {responsabilitySelectItems.length === 0 ||
                            responsabilitySelectItems}
                        </select>
                      </Col> */}

                      <Col md="3" sm="6" className="pt-4">
                        <div className="form-group">
                          <UncontrolledDropdown>
                            <label className="text-dark font-weight-900">
                              Setor de atuação
                            </label>
                            <DropdownToggle
                              className={
                                "form-control text-dark font-weight-900 tp-3 mt--1 text-left default-select" +
                                (this.state.formData.professionIds.length > 0
                                  ? " filterActive"
                                  : "") +
                                (professionSelectItems.length === 0
                                  ? " loadingSelect "
                                  : "")
                              }
                              onClick={(e) => e.preventDefault()}
                            >
                              <label className="text-select font-weight-900 label-checkbox">
                                Selecione
                              </label>
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow col-12 yre"
                              flip={false}
                            >
                              {professionSelectItems.length === 0 ||
                                professionSelectItems}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Participou do tipo de curso
                        </label>{" "}
                        {/* loadingSelect e disabled TEMPORARIO */}
                        <select
                          name="hasDoneCourse"
                          id="hasDoneCourse"
                          onChange={this.onInputChange}
                          className={
                            "form-control showFilterActive text-dark font-weight-900  " +
                            (this.state.formData["hasDoneCourse"]
                              ? "isFilterActive"
                              : "")
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="_boolTrue">Sim</option>
                          <option value="_boolFalse">Não</option>
                        </select>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Tipo de curso
                        </label>{" "}
                        {/* loadingSelect e disabled TEMPORARIO */}
                        <select
                          name="courseId"
                          id="courseId"
                          className={
                            "text-dark font-weight-900 form-control showFilterActive " +
                            // (courseSelectItems.length === 0
                            //   ? "loadingSelect "
                            //   : "") +
                            (this.state.formData["courseId"]
                              ? "isFilterActive"
                              : "")
                          }
                          disabled={
                            this.state.formData["hasDoneCourse"] &&
                              this.state.formData["hasDoneCourse"] === "_boolTrue"
                              ? false
                              : true
                          }
                          onChange={this.onInputChange}
                        >
                          <option
                            value=""
                            selected={
                              this.state.formData.courseId === "" ? true : false
                            }
                          >
                            Selecione
                          </option>
                          {courseSelectItems.length === 0 || courseSelectItems}
                        </select>
                      </Col>

                      <Col md="3" sm="6" className="pt-4">
                        <label className="text-dark font-weight-900">
                          Quando participou
                        </label>{" "}
                        {/* loadingSelect e disabled TEMPORARIO */}
                        <input
                          type="text"
                          className={
                            "form-control text-dark " +
                            (this.state.formData["eventPeriod"]
                              ? "filterActive"
                              : "")
                          }
                          id="eventPeriod"
                          name="eventPeriod"
                          value={
                            this.state.formData["eventPeriod"]
                              ? formater.number(
                                this.state.formData["eventPeriod"]
                              )
                              : ""
                          }
                          disabled={
                            this.state.formData["hasDoneCourse"] &&
                              this.state.formData["hasDoneCourse"] === "_boolTrue"
                              ? false
                              : true
                          }
                          onChange={this.onInputChange}
                        />
                        {/* <select
                          name="eventPeriod"
                          id="eventPeriod"
                          onChange={this.onInputChange}
                          className={
                            "form-control showFilterActive text-dark font-weight-900  " +
                            (this.state.formData["eventPeriod"]
                              ? "isFilterActive"
                              : "")
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="|15">Nos ultímos 15 dias</option>
                          <option value="|31">Nos ultimo mês</option>
                          <option value="|186">Nos ultimo semestre</option>
                          <option value="372|9999">Nos ultimo semestre</option>
                        </select> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pt-4 pt-5 pr-5">
              <Col lg="12">
                <div className="float-lg-right">
                  <button
                    className="btn-person-clean"
                    type="reset"
                    onClick={this.clearFormState}
                  >
                    Limpar
                  </button>
                  <button
                    className="btn-person-search ml-4"
                    type="button"
                    onClick={() => {
                      this.submitSearch(0);
                    }}
                  >
                    Buscar
                  </button>
                </div>
              </Col>
            </Row>
          </Form>

          <Row className="pt-4 pl-5">
            {this.state.personNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openPersonNotFound}
                        text={this.state.messageExclusion}
                        handleClose={() => {
                          this.setState({ openPersonNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorPersonNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openErrorPersonNotFound}
                        text={this.state.errorMessage}
                        handleClose={() => {
                          this.setState({ openErrorPersonNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            <Col lg="12">
              <div>
                <div className="title-people pt-4 pb-5">
                  <h1>Pessoas cadastradas</h1>
                  {!this.state.confirmExclusao || (
                    <section className="container">
                      <Row>
                        <Col col="md-12">
                          <div
                            id="msgExclusion"
                            className={
                              " " +
                              (this.state.isVisibleDeleteMsg
                                ? "msgVisiblePerson"
                                : "")
                            }
                          >
                            <DialogConfirm
                              open={this.state.openConfirm}
                              title={this.state.namePerson}
                              text={this.state.messageExclusion}
                              success="Sim"
                              handleClose={() => {
                                this.setState({ openConfirm: false });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </section>
                  )}
                  {this.state.confirmExclusao || (
                    <section className="container">
                      <Row>
                        <Col col="md-12">
                          <div id="msgExclusion" className="">
                            <DialogConfirm
                              open={this.state.openConfirm}
                              title="Erro"
                              text={this.state.messageExclusion}
                              handleClose={() => {
                                this.setState({ openConfirm: false });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </section>
                  )}
                </div>
                {this.state.renderPersonList ? (
                  <DataList
                    loading={this.state.personLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                    exportHandler={exportHandler}
                    exporting={true}
                    exportProcessing={this.state.exporting}
                  />
                ) : (
                  <>
                    {this.state.personNotFound ||
                      this.state.errorPersonNotFound || <h1> Carregando...</h1>}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let accessLevelId = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelId
    );
    let accessLevelName = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelName
    );

    this.setState({
      accessLevelId: accessLevelId,
      accessLevelName: accessLevelName,
    });
  }

  componentDidMount() {
    this.loadDataList(0, true);
    this.loadSelects();
  }

  confirmExclusion(name) {
    this.loadDataList(0, false);

    if (name) {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        namePerson: name,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id, hasRegisters) {
    switch (hasRegisters) {
      case "person":
        this.setState({ personId: id, hasLinkOpen: true });
        break;

      case "event":
        this.setState({ personId: id, hasLinkEventOpen: true });
        break;

      default:
        this.setState({ personId: id, hasNotLinkOpen: true });
    }
  }

  closeModal() {
    this.setState({
      hasLinkOpen: false,
      hasNotLinkOpen: false,
      hasLinkEventOpen: false,
      onSubimitModalError: false,
    });
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ personLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ personLoading: false });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.GetPaged;
    let data =
      "CurrentPage=" +
      currentPage +
      "&PageSize=" +
      constants.Pagination.DefaultPageSize;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            personLoading: false,
            renderPersonList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          personLoading: false,
          renderPersonList: true,
          errorMessage: "",
          personResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;
    let formData = this.state.formData;

    let tempEventPeriod = formData.eventPeriod;
    let tempMinAge = [];
    let tempMaxAge = [];
    let tempMinAgeMaxAge = [];

    let newFormData = {};

    function undo(data) {
      if (data === "_boolTrue") {
        return (data = true);
      } else if (data === "_boolFalse") {
        return (data = false);
      }
    }

    newFormData.isMale = undo(formData.isMale);
    newFormData.haveEmail = undo(formData.haveEmail);
    newFormData.haveChild = undo(formData.haveChild);
    newFormData.haveTithe = undo(formData.haveTithe);
    newFormData.hasDoneCourse = undo(formData.hasDoneCourse);

    formData.minAgeMaxAge.forEach((item) => {
      tempMinAgeMaxAge.push(item);
    });

    delete formData.eventPeriod;
    formData.minAgeMaxAge = [];

    if (tempMinAgeMaxAge) {
      tempMinAgeMaxAge.map((item) => {
        if (item.split("|").length === 2) {
          let treatingMinAge = item.split("|")[0];
          let treatingMaxAge = item.split("|")[1];

          if (treatingMinAge) tempMinAge.push(treatingMinAge);

          if (treatingMaxAge) tempMaxAge.push(treatingMaxAge);
        }
      });
    }

    if (tempMinAge.length > 0) formData.minAge = Math.min(...tempMinAge);

    if (tempMaxAge.length > 0) formData.maxAge = Math.max(...tempMaxAge);

    if (tempEventPeriod) {
      if (tempEventPeriod.split("|").length === 2) {
        let treatingMinEvent = tempEventPeriod.split("|")[0];
        let treatingMaxEvent = tempEventPeriod.split("|")[1];

        if (treatingMinEvent) formData.eventPastMinDays = treatingMinEvent;

        if (treatingMaxEvent) formData.eventPastMaxDays = treatingMaxEvent;
      }
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.Export;
    let data = { ...formData, ...newFormData };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          constants.Endpoint.UrlBase +
          constants.Endpoint.Pessoas.Download +
          "?fileKey=" +
          result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }

  checkPersonLink(personId) {
    this.setState({
      hasLink: Object.assign(
        this.state.hasLink,
        { fatherName: null },
        { motherName: null },
        { spouseName: null },
        { childrenNames: null },
        {
          showChildrenNames: false,
        },
        {
          showEventDescriptions: false,
        }
      ),
    });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.GetPersonBonds;
    let data = "personId=" + personId;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null) {
          this.setState({
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.BlankResponse, result);
          return;
        }

        let ret = result.response;
        let childrenNames = [];
        let eventDescriptions = [];
        let hasRegisters = "none";

        if (ret.fatherName) {
          
          this.setState({
            hasLink: Object.assign(this.state.hasLink, {
              fatherName: ret.fatherName,
            }),
          });
          hasRegisters = "person";
        }

        if (ret.motherName) {
          this.setState({
            hasLink: Object.assign(this.state.hasLink, {
              motherName: ret.motherName,
            }),
          });
          hasRegisters = "person";
        }

        if (ret.spouseName) {
          this.setState({
            hasLink: Object.assign(this.state.hasLink, {
              spouseName: ret.spouseName,
            }),
          });
          hasRegisters = "person";
        }

        if (ret.childrenNames.length > 0) {
          childrenNames = ret.childrenNames.map((item) => {
            return item;
          });

          this.setState({
            hasLink: Object.assign(this.state.hasLink, {
              childrenNames: childrenNames,
            }),
            hasLink: Object.assign(this.state.hasLink, {
              showChildrenNames: true,
            }),
          });

          hasRegisters = "person";
        }

        if (ret.eventDescriptions.length > 0) {
          eventDescriptions = ret.eventDescriptions.map((item) => {
            return item;
          });

          this.setState({
            hasLink: Object.assign(this.state.hasLink, {
              eventDescriptions: eventDescriptions,
            }),
            hasLink: Object.assign(this.state.hasLink, {
              showEventDescriptions: true,
            }),
          });

          hasRegisters = "event";
        }

        this.openModal(personId, hasRegisters);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  deletePerson(personId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Delete +
      "/" +
      personId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({
            hasLinkOpen: false,
            hasNotLinkOpen: false,
            hasLinkEventOpen: false,
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorOfDeletePersona: result.errors[0],
            onSubimitModalError: true,
          });
          this.confirmExclusion("");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            hasLinkOpen: false,
            hasNotLinkOpen: false,
            hasLinkEventOpen: false,
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            messageExclusion: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null) {
          this.setState({
            hasLinkOpen: false,
            hasNotLinkOpen: false,
            hasLinkEventOpen: false,
            personLoading: false,
            renderPersonList: true,
            confirmExclusao: true,
            messageExclusion: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.BlankResponse, result);
          return;
        }

        this.setState({
          hasLinkOpen: false,
          hasNotLinkOpen: false,
          hasLinkEventOpen: false,
          personLoading: false,
          renderPersonList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
          namePerson: "",
        });
        this.confirmExclusion(result.response.name);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let name = "";
    let value = "";
    let isDropdown = false;
    let isRadio = false;

    if (target.name === "hasDoneCourse" && target.value === "_boolFalse") {
      this.setState({
        formData: Object.assign(this.state.formData, {
          eventPeriod: "",
          courseId: "",
        }),
      });
    }

    if (target.name === "eventPeriod" && target.value.length > 4) return;

    if (!target.name) name = target.id.split("=")[1];
    else name = target.name;

    if (target.type === "checkbox") {
      isDropdown = true;
      value = "";

      if (this.state.formData[name].length > 0) {
        if (this.state.formData[name].indexOf(target.id.split("=")[1]) === -1) {
          value = target.id;
        } else value = "";
      } else value = target.id;
    } else if (target.type === "select-multiple") {
      value = [];

      Object.values(target.selectedOptions).map((item) => {
        value.push(item.value);
      });
    }
    else if (target.type === "radio") {
      isRadio = true;
      value = "";
      if (this.state.formData[name].length > 0) {
        if (this.state.formData[name].indexOf(target.id.split("=")[1]) === -1) {
          value = target.id;
        } else value = "";
      } else value = target.id;
    }
    else {
      value = target.value;
    }

    if (isDropdown === false) {
      this.setState({
        formData: Object.assign(this.state.formData, { [name]: value }),
      });
    }

    value = value.split("=")[1];

    if (isDropdown) {


      if (value) {
        this.state.formData[name].push(value);
      } else {
        this.state.formData[name].splice(
          this.state.formData[name].indexOf(target.id.split("=")[1]),
          1
        );
      }
    }

    if (isRadio) {
      if (value) {
        this.state.formData[name] = []
        this.state.formData[name].push(value);
      } else {
        this.state.formData[name] = []

      }
      console.log(this.state.formData[name])
    }

    if (this.state.formData) this.setState({ isChecked: true });
    else this.setState({ isChecked: false });
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage === undefined || currentPage == null) currentPage = 0;

    this.setState({
      personLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    var selectedOptions = this.state.formData.optInSituationsIds

    var confirmOptIn = false;
    var confirmOptOut = false;
    var confirmNotAnswer = false;

    for (var i = 0; i < selectedOptions.length; i++) {

      if (selectedOptions[i] === 1) {
        confirmOptIn = true;
      }
      if (selectedOptions[i] === 2) {
        confirmOptOut = true;
      }
      if (selectedOptions[i] === 3) {
        confirmNotAnswer = true;
      }
    }



    this.state.formData.typesOptSearch.optIn = confirmOptIn;
    this.state.formData.typesOptSearch.optOut = confirmOptOut;
    this.state.formData.typesOptSearch.notAnswer = confirmNotAnswer;


    let formData = this.state.formData;

    if (formData.isMale) {
      formData.isMale = formData.isMale === "_boolTrue";
    }

    if (formData.haveChild) {
      formData.haveChild = formData.haveChild === "_boolTrue";
    }

    if (formData.haveTithe) {
      formData.haveTithe = formData.haveTithe === "_boolTrue";
    }

    if (formData.haveEmail) {
      formData.haveEmail = formData.haveEmail === "_boolTrue";
    }

    if (formData.hasDoneCourse) {
      formData.hasDoneCourse = formData.hasDoneCourse === "_boolTrue";
    }

    let tempEventPeriod = formData.eventPeriod;
    let tempMinAge = [];
    let tempMaxAge = [];
    let tempMinAgeMaxAge = [];

    formData.minAgeMaxAge.map((item) => {
      tempMinAgeMaxAge.push(item);
    });

    delete formData.eventPeriod;

    if (tempMinAgeMaxAge) {
      tempMinAgeMaxAge.map((item) => {
        if (item.split("|").length === 2) {
          let treatingMinAge = item.split("|")[0];
          let treatingMaxAge = item.split("|")[1];

          if (treatingMinAge) tempMinAge.push(treatingMinAge);

          if (treatingMaxAge) tempMaxAge.push(treatingMaxAge);
        }
      });
    }

    if (tempMinAge.length > 0) formData.minAge = Math.min(...tempMinAge);

    if (tempMaxAge.length > 0) formData.maxAge = Math.max(...tempMaxAge);

    if (tempEventPeriod) {
      if (tempEventPeriod.split("|").length === 2) {
        let treatingMinEvent = tempEventPeriod.split("|")[0];
        let treatingMaxEvent = tempEventPeriod.split("|")[1];

        if (treatingMinEvent) formData.eventPastMinDays = treatingMinEvent;

        if (treatingMaxEvent) formData.eventPastMaxDays = treatingMaxEvent;
      }
    }

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.PagedSearch;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            personLoading: false,
            renderPersonList: false,
            errorPersonNotFound: true,
            openErrorPersonNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            errorPersonNotFound: true,
            openErrorPersonNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response === null) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            errorPersonNotFound: true,
            openErrorPersonNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total === 0) {
          this.setState({
            personLoading: false,
            renderPersonList: false,
            personNotFound: true,
            openPersonNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Pessoa não encontrada",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        function undo(data) {
          if (data === true) {
            return (data = "_boolTrue");
          } else if (data === false) {
            return (data = "_boolFalse");
          }
        }

        formData.isMale = undo(formData.isMale);
        formData.haveEmail = undo(formData.haveEmail);
        formData.haveChild = undo(formData.haveChild);
        formData.haveTithe = undo(formData.haveTithe);
        formData.hasDoneCourse = undo(formData.hasDoneCourse);

        this.setState({
          formData,
          personLoading: false,
          renderPersonList: true,
          personNotFound: false,
          openPersonNotFound: false,
          errorPersonNotFound: false,
          openErrorPersonNotFound: false,
          errorMessage: "",
          personResponse: result.response,
          messageExclusion: "",
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorPersonNotFound: true,
          openErrorPersonNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  loadSelects() {
    this.loadSituations();
    this.loadProfessions();
    this.loadMinistries();
    this.loadCourses();
    this.loadResponsabilities();
  }

  loadSituations() {
    this.setState({ situationResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Situation.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ situationResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadProfessions() {
    this.setState({ professionResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Profession.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }
        this.setState({ professionResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadMinistries() {
    this.setState({ ministryResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Ministry.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ ministryResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadCourses() {
    this.setState({ courseResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Course.CourseType;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ courseResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  clearFormState() {
    this.setState({
      formData: {
        baptismSituationIds: [],
        eucharistSituationIds: [],
        optInSituationsIds: [],
        typesOptSearch: {},
        chrismSituationIds: [],
        marriageSituationIds: [],
        birthMonths: [],
        professionIds: [],
        minAgeMaxAge: [],
      },
      searchMode: false,
      personNotFound: false,
      errorPersonNotFound: false,
      errorMessage: "",
    });
    this.loadDataList(0, false);
  }

  loadResponsabilities() {
    this.setState({ responsabilityResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Responsability.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ responsabilityResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
}

export default Pessoas;
