//#region Imports

import Modal from "react-modal";
import React from "react";
import DialogConfirm from "components/Utils/dialogConfirm.jsx";
import DataList from "components/Utils/dataList.jsx";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import Loading from "components/Utils/Loading.jsx";
import { formater } from "components/Utils/formater";
import { diruma } from "components/Utils/diruma";

import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//#endregion

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class Financeiro extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      errorMessage: "",
      isVisibleDeleteMsg: false,
      personId: 0,
      eventId: 0,
      paymentId: 0,
      namePayment: null,
      submitLoading: false,
      paymentLoading: false,
      currentPage: 0,
      formData: { incomeTypeIds: [] },
      otherReasonRequired: false,
      paymentResponse: null,
      confirmExclusao: false,
      paymentNotFound: false,
      openpaymentNotFound: false,
      errorPaymentNotFound: false,
      renderPaymentList: true,
      paymentMethodResponse: null,
      incomeTypeResponse: null,
      openConfirm: false,
      exporting: false,
      accessLevelId: 0,
      accessLevelName: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Validations

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "description",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "amount",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    //#endregion

    //#region Event Binding

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.paymentChange = this.paymentChange.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;
    let paymentMethodSelectItems = [];
    let incomeTypeSelectItems = [];

    if (
      this.state.paymentResponse &&
      this.state.paymentResponse.list &&
      this.state.paymentResponse.total
    ) {
      tableCollumns = [
        { name: "Recibo nº", size: 15, property: "id" },
        { name: "Tipo de entrada", size: 25, property: "incomeType" },
        { name: "Responsável", size: 25, property: "registerPerson" },
        { name: "Data", size: 25, property: "date" },
        { name: "Valor total", size: 20, property: "totalAmount" },
        { name: "", size: 15, property: "visualizarButton" },
        {
          name: "",
          size:
            this.state.accessLevelId === "1" || this.state.accessLevelId === "2"
              ? 15
              : 0,
          property: "excluirButton",
        },
      ];

      tableData = this.state.paymentResponse.list;
      tableData = tableData.map((item) => {
        let day = new Date(item.paymentDate).getDate().toString();
        let month = (new Date(item.paymentDate).getMonth() + 1).toString();
        let year = new Date(item.paymentDate).getFullYear().toString();

        let valorTotal = item.paymentAmounts.reduce(
          (total, valor) => total + valor.amount,
          0
        );

        valorTotal = valorTotal.toFixed(2);

        return {
          ...item,
          ...{
            visualizarButton: (
              <Link to={`/admin/Financeiro/cadastroEntrada/?id=${item.id}`}>
                <button className="btn btn-sm edit">Visualizar</button>
              </Link>
            ),
            excluirButton:
              this.state.accessLevelId === "1" ||
                this.state.accessLevelId === "2" ? (
                <button
                  type="button"
                  className="btn btn-sm exc"
                  key={item.id}
                  onClick={() => this.openModal(item.id)}
                >
                  Excluir
                </button>
              ) : (
                ""
              ),
            registerPerson: item.registerPerson
              ? item.registerPerson.name
                ? item.registerPerson.name
                : ""
              : "",
            incomeType: item.incomeType
              ? item.incomeType.name
                ? item.incomeType.name
                : ""
              : "",
            date:
              (day.length === 1 ? "0" + day : day) +
              "/" +
              (month.length === 1 ? "0" + month : month) +
              "/" +
              (year.length === 1 ? "0" + year : year),
            totalAmount: formater.monetaryBRL(valorTotal + "", true),
          },
        };
      });

      total = this.state.paymentResponse.total;
    }

    if (this.state.incomeTypeResponse) {
      if (this.state.incomeTypeResponse.length > 0) {
        try {
          incomeTypeSelectItems = this.state.incomeTypeResponse.map((item) => {
            let idValue = item.id + "";

            return (
              <div class="custom-control custom-checkbox select-search">
                <input
                  checked={
                    this.state.formData.incomeTypeIds.indexOf(idValue) !== -1
                      ? true
                      : false
                  }
                  type="checkbox"
                  class="custom-control-input input-checkbox"
                  id={"incomeTypeIds=" + item.id}
                  name="incomeTypeIds"
                  onClick={(e) => this.onInputChange(e)}
                />
                <label
                  class="custom-control-label"
                  for={"incomeTypeIds=" + item.id}
                >
                  {item.name}
                </label>
                <br />
              </div>
            );
          });
        } catch (ex) {
          incomeTypeSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir este registro?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.paymentId}
                onClick={() => this.deletePayment(this.state.paymentId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Financeiro</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
            <Col lg="6">
              <Link to="/admin/Financeiro/cadastroEntrada">
                <button className="btn button-add-people float-lg-right text-white mt-2">
                  + Nova entrada
                </button>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          {/* <Form> */}
          <Row>
            <Col>
              <div className="pt-4">
                <h2 className="text-dark mb-5 font-weight-900 ml-5">
                  Filtrar por:
                </h2>
              </div>
            </Col>
          </Row>
          <Row lg="12" className="pt-4 pl-5">
            <Col lg="3">
              <div className="form-group">
                <label
                  for="registerPersonName"
                  className="form-conqtrol-label text-dark font-weight-bold"
                >
                  Responsável
                </label>
                <input
                  type="text"
                  id="registerPersonName"
                  name="registerPersonName"
                  value={
                    this.state.formData["registerPersonName"]
                      ? this.state.formData["registerPersonName"]
                      : ""
                  }
                  class={
                    "form-control showFilterActive text-dark " +
                    (this.state.formData["registerPersonName"]
                      ? "filterActive"
                      : "")
                  }
                  placeholder="Digite o nome do responsável"
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <UncontrolledDropdown>
                  <label className="text-dark font-weight-900">
                    Tipo de entrada
                  </label>
                  <DropdownToggle
                    className={
                      "form-control text-dark font-weight-900 tp-3 mt--1 text-left default-select " +
                      (this.state.formData.incomeTypeIds.length > 0
                        ? "filterActive"
                        : "")
                    }
                    onClick={(e) => e.preventDefault()}
                  >
                    <label className="text-select font-weight-900 label-checkbox">
                      Selecione
                    </label>
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-arrow col-12 yre"
                    flip={false}
                  >
                    {incomeTypeSelectItems.length === 0 ||
                      incomeTypeSelectItems}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
            <Col lg="3">
              <div class="form-group">
                <label
                  for="paymentStartDate"
                  class="form-control-label text-dark"
                >
                  Período (De)
                </label>
                <input
                  class={
                    "form-control text-dark " +
                    (this.state.formData["paymentStartDate"]
                      ? "filterActive"
                      : "")
                  }
                  type="date"
                  id="paymentStartDate"
                  value={
                    this.state.formData["paymentStartDate"]
                      ? this.state.formData["paymentStartDate"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
            <Col lg="3">
              <div class="form-group">
                <label
                  for="paymentEndDate"
                  class="form-control-label text-dark"
                >
                  Período (Até)
                </label>
                <input
                  class={
                    "form-control text-dark " +
                    (this.state.formData["paymentEndDate"]
                      ? "filterActive"
                      : "")
                  }
                  type="date"
                  id="paymentEndDate"
                  value={
                    this.state.formData["paymentEndDate"]
                      ? this.state.formData["paymentEndDate"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row lg="12" className="pt-2 pl-5">
            <Col lg="3">
              <div class="form-group">
                <label for="id" class="form-control-label text-dark">
                  Recibo nº
                </label>
                <input
                  type="text"
                  class={
                    "form-control text-dark " +
                    (this.state.formData["id"] ? "filterActive" : "")
                  }
                  id="id"
                  value={
                    this.state.formData["id"]
                      ? formater.number(this.state.formData["id"])
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>

          <div className="container-fluid mb-6 mt-4">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <button
                    className="btn-person-clean"
                    type="reset"
                    onClick={this.clearFormState}
                  >
                    Limpar
                  </button>
                  <button
                    className="btn-person-search ml-4"
                    type="button"
                    onClick={() => {
                      this.submitSearch(0);
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Buscar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="pt-5">
            {this.state.paymentNotFound ? (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={!this.state.openpaymentNotFound}
                        text={this.state.messageExclusion}
                        handleClose={() => {
                          this.setState({ openpaymentNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorPaymentNotFound ? (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={!this.state.openerrorPaymentNotFound}
                        text={this.state.errorMessage}
                        handleClose={() => {
                          this.setState({ openerrorPaymentNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {!this.state.confirmExclusao || (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title={this.state.namePayment}
                        text={this.state.messageExclusion}
                        success="Sim"
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
            {this.state.confirmExclusao || (
              <section class="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title="Erro"
                        text={this.state.messageExclusion}
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
          </Row>
          <div className="pt-5 pb-5 text-lg-center">
            <h1 className="text-dark font-weight-bold">Entradas</h1>
          </div>
          <Row className="pt-4 pl-5">
            <Col lg="12">
              {this.state.renderPaymentList ? (
                <DataList
                  loading={this.state.paymentLoading}
                  tableCollumns={tableCollumns}
                  tableData={tableData}
                  paging={true}
                  currentPage={this.state.currentPage}
                  pageSize={constants.Pagination.DefaultPageSize}
                  total={total}
                  pageChangeHandler={(index) => {
                    pageChangeHandler(index);
                  }}
                  exportHandler={exportHandler}
                  exporting={true}
                  exportProcessing={this.state.exporting}
                />
              ) : (
                <>
                  {this.state.paymentNotFound ||
                    this.state.errorPaymentNotFound || <h1> Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
          {/* </Form> */}
        </div>
      </>
    );
  }

  //#region Events

  confirmExclusion(id) {
    this.loadDataList(0, false);

    if (id) {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        namePayment: "Entrada",
        messageExclusion: "excluida com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ paymentId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  //#endregion

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id)
      this.setState({
        eventId: id,
        personId: window.localStorage.getItem(constants.LocalStorage.PersonId),
      });

    let accessLevelId = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelId
    );
    let accessLevelName = window.localStorage.getItem(
      constants.LocalStorage.AccessLevelName
    );

    this.setState({
      accessLevelId: accessLevelId,
      accessLevelName: accessLevelName,
    });
  }

  componentDidMount() {
    this.loadSelects();
  }

  //#endregion

  //#region Custom Events

  clearFormState() {
    this.setState({
      formData: { incomeTypeIds: [] },
      searchMode: false,
      paymentNotFound: false,
      errorPaymentNotFound: false,
      errorMessage: "",
    });
    this.loadDataList(0, true);
  }

  loadSelects() {
    this.loadDataList(0, true);
    this.loadIncomeType();
  }

  paymentChange(paymentId) {
    // this.setState({ paymentId: paymentId });
    // let token = window.localStorage.getItem(constants.LocalStorage.Token);
    // let url = constants.Endpoint.UrlBase + constants.Endpoint.Payment.Get + '/' + paymentId;
    // let data = null;
    // smoothApi.get(url, data, token,
    //     (result) => {
    //         if (!result || !result.errors || !result.response || result.success == null) {
    //             console.error(messages.Errors.UnexpectedFormat, result);
    //             return;
    //         }
    //         if (!result.success) {
    //             console.log(messages.Errors.ResponseError, result.errors);
    //             return;
    //         }
    //         if (result.response == null) {
    //             console.log(messages.Errors.BlankResponse, result.errors);
    //             return;
    //         }
    //         let ret = result.response;
    //         let formData = { ...this.state.formData, ...ret };
    //         let day = new Date(formData.dueDate).getDate().toString();
    //         let month = (new Date(formData.dueDate).getMonth() + 1).toString();
    //         let year = new Date(formData.dueDate).getFullYear().toString();
    //         let dueDate =
    //             (year.length === 1 ? "0" + year : year) + "-" +
    //             (month.length === 1 ? "0" + month : month) + "-" +
    //             (day.length === 1 ? "0" + day : day);
    //         formData.dueDate = dueDate;
    //         formData.amount = formater.monetaryBRL((ret.amount + ""), true);
    //         this.setState({ formData: formData });
    //     },
    //     (error, httpCode) => {
    //         if (httpCode === 401)
    //             window.location.href = "/auth/Login";
    //     }
    // );
  }

  loadIncomeType() {
    this.setState({ incomeTypeResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.IncomeType.Get;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ incomeTypeResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ paymentLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ paymentLoading: false });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Payment.GetPaged;
    let data =
      "CurrentPage=" +
      currentPage +
      "&PageSize=" +
      constants.Pagination.DefaultPageSize;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          paymentLoading: false,
          renderPaymentList: true,
          errorMessage: "",
          paymentResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          paymentLoading: false,
          renderPaymentList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  deletePayment(paymentId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Payment.Delete +
      "/" +
      paymentId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({
            modalIsOpen: false,
            paymentLoading: false,
            renderPaymentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            modalIsOpen: false,
            paymentLoading: false,
            renderPaymentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null) {
          this.setState({
            modalIsOpen: false,
            paymentLoading: false,
            renderPaymentList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.BlankResponse, result);
          return;
        }

        this.setState({
          modalIsOpen: false,
          paymentLoading: false,
          renderPaymentList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
          namePayment: "",
        });

        this.confirmExclusion(result.response.id);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          paymentLoading: false,
          renderPaymentList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    let name = "";
    let value = "";
    let isDropdown = false;

    if (!target.name) {
      if (target.id.indexOf("=") !== -1) name = target.id.split("=")[1];
      else name = target.id;
    } else name = target.name;

    if (target.type === "checkbox") {
      isDropdown = true;
      value = "";

      if (this.state.formData[name].length > 0) {
        if (this.state.formData[name].indexOf(target.id.split("=")[1]) === -1) {
          value = target.id;
        } else value = "";
      } else value = target.id;
    } else if (target.type === "select-multiple") {
      value = [];

      Object.values(target.selectedOptions).map((item) => {
        value.push(item.value);
      });
    } else {
      value = target.value;
    }

    if (isDropdown === false) {
      this.setState({
        formData: Object.assign(this.state.formData, { [name]: value }),
      });
    }

    value = value.split("=")[1];

    if (isDropdown) {
      if (value) {
        this.state.formData[name].push(value);
      } else {
        this.state.formData[name].splice(
          this.state.formData[name].indexOf(target.id.split("=")[1]),
          1
        );
      }
    }

    if (this.state.formData) this.setState({ isChecked: true });
    else this.setState({ isChecked: false });
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage === undefined || currentPage === null) currentPage = 0;

    this.setState({
      paymentLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    let formData = this.state.formData;

    if (!formData.registerPersonName) {
      formData.registerPersonName = ""
    }

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Payment.PagedSearch;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            errorPaymentNotFound: true,
            openerrorPaymentNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            errorPaymentNotFound: true,
            openerrorPaymentNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            errorPaymentNotFound: true,
            openerrorPaymentNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total === 0) {
          this.setState({
            paymentLoading: false,
            renderPaymentList: false,
            paymentNotFound: true,
            openPaymentNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Pessoa não encontrada",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          paymentLoading: false,
          renderPaymentList: true,
          paymentNotFound: false,
          openPaymentNotFound: false,
          errorPaymentNotFound: false,
          openerrorPaymentNotFound: false,
          errorMessage: "",
          paymentResponse: result.response,
          messageExclusion: "",
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          paymentLoading: false,
          renderPaymentList: false,
          errorPaymentNotFound: true,
          openerrorPaymentNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });

        console.log("");
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Payment.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });
        window.open(
          constants.Endpoint.UrlBase +
          constants.Endpoint.Payment.Download +
          "?fileKey=" +
          result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }

  //#endregion
}

export default Financeiro;
