import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";
import { objectToFormData } from "object-to-formdata";

import messages from "variables/messages";
import constants from "variables/constants";

import Wizard from "components/Utils/Wizard";
import { diruma } from "components/Utils/diruma";
import Loading from "components/Utils/Loading.jsx";
import { formater } from "components/Utils/formater";
import InputSearch from "components/Utils/inputSearch";
import { smoothApi } from "components/Utils/smoothApi.js";
import ButtonGroupNsb from "components/Utils/buttonGroupNsb";

import { Container, Row, Col } from "reactstrap";

class InfoCasamento extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      eventId: 0,
      submitLoading: false,
      personGetResponse: {},
      formData: {},
      phone: "",
      eventUpdate: false,
      wizardResponse: null,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "companyCnpj",
          validations: [diruma.validations.cnpj],
          customValidations: [],
        },
        {
          ref: "groomName",
          ignoreMissingElement: true,
          validations: [],
          customValidations: [
            (value, sucess, message) => {
              if (this.state.formData.groomPersonId === "1")
                return diruma.returnModel(value, true, "");

              return diruma.validations.required(value, sucess, message);
            },
          ],
        },
        {
          ref: "brideName",
          ignoreMissingElement: true,
          validations: [],
          customValidations: [
            (value, sucess, message) => {
              if (this.state.formData.bridePersonId === "1")
                return diruma.returnModel(value, true, "");

              return diruma.validations.required(value, sucess, message);
            },
          ],
        },
        {
          ref: "responsibleName",
          ignoreMissingElement: true,
          validations: [],
          customValidations: [
            (value, sucess, message) => {
              if (this.state.formData.isResponsiblePerson === "1")
                return diruma.returnModel(value, true, "");

              return diruma.validations.required(value, sucess, message);
            },
          ],
        },
        {
          ref: "responsibleCpf",
          ignoreMissingElement: true,
          validations: [],
          customValidations: [
            (value, sucess, message) => {
              if (this.state.formData.isResponsiblePerson === "1")
                return diruma.returnModel(value, true, "");

              return diruma.validations.cpf(value, sucess, message);
            },
          ],
        },
        {
          ref: "responsibleEmail",
          ignoreMissingElement: true,
          validations: [],
          customValidations: [
            (value, sucess, message) => {
              if (this.state.formData.isResponsiblePerson === "1")
                return diruma.returnModel(value, true, "");

              return diruma.validations.email(value, sucess, message);
            },
          ],
        },
        {
          ref: "responsibleMobilePhone",
          ignoreMissingElement: true,
          validations: [],
          customValidations: [
            (value, sucess, message) => {
              if (this.state.formData.isResponsiblePerson === "1")
                return diruma.returnModel(value, true, "");

              return diruma.validations.required(value, sucess, message);
            },
          ],
        },
      ],
    };

    this.getPerson = this.getPerson.bind(this);
    this.loadWizard = this.loadWizard.bind(this);
    this.loadEvent = this.loadEvent.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Tipo do <br /> evento
        </>,
        "/admin/Evento/tipoEvento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Completed
      )
    );
    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Informações <br /> do evento
        </>,
        "/admin/Evento/Casamento/infoCasamento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Active
      )
    );

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "/admin/Evento/Casamento/data/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasDate
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/documentos/?id=" + this.state.eventId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "/admin/Evento/Casamento/testemunhas/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasWitness
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "/admin/Evento/Casamento/cerimonial/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasCeremony
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "/admin/Evento/Casamento/engajamento/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasAttendance &&
            this.state.wizardResponse.hasChart
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "/admin/Evento/Casamento/atividades/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasActivity
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Testemunhas</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Cerimonial</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Engajamento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Atividades</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    let personSugestionList = [];
    let phoneEngaged = "";
    let phoneFiancee = "";

    if (this.state.personGetResponse) {
      if (this.state.personGetResponse.length > 0) {
        try {
          this.state.personGetResponse.forEach((item) => {
            if (!item.deceased) {
              personSugestionList.push({
                id: item.id,
                name: item.name,
                pictureFileName: item.pictureFileName,
                phone: item.mobilePhone,
              });
            }
          });
        } catch (ex) {
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.eventUpdate
                    ? "Editar casamento"
                    : "Novo casamento"}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Row>
            <Col>
              <div className="pt-4">
                <h2 className="text-dark mb-5 font-weight-900 ">
                  Informações dos noivos
                </h2>
              </div>
            </Col>
          </Row>

          <Row lg="12">
            <Col lg="3">
              <label className="text-dark font-weight-900">
                Noivo é paroquiano?
              </label>
              <ButtonGroupNsb
                id="isGroomPerson"
                option1="Não"
                option2="Sim"
                activeIndex={this.state.formData["isGroomPerson"]}
                dflex="Sim"
                changeHandler={this.onInputChange}
              />
            </Col>

            {this.state.formData.isGroomPerson === "1" ? (
              <>
                <Col lg="6" className="mt-inputSearch">
                  <InputSearch
                    title="Nome do Noivo"
                    fieldId="GroomInputSearch"
                    sugestedList={personSugestionList}
                    changeHandler={(id) => {
                      if (id < 1) id = null;

                      this.setState((currentState) => ({
                        formData: {
                          ...currentState.formData,
                          ...{ groomPersonId: id },
                        },
                      }));
                    }}
                    value={(() => {
                      if (
                        this.state.formData &&
                        this.state.formData.groomPersonId
                      ) {
                        let treatedId = parseInt(
                          this.state.formData.groomPersonId
                        );

                        if (!isNaN(treatedId)) {
                          let personSearch = personSugestionList.filter(
                            (item) => item.id === treatedId
                          );

                          if (personSearch.length === 1) {
                            phoneEngaged = personSearch[0].phone;
                            return personSearch[0];
                          } else {
                            phoneEngaged = "";
                          }
                        }
                      }

                      return null;
                    })()}
                    hasPhoto={true}
                    isMandatory={true}
                  />
                </Col>
                <Col lg="3" className="engaged-mt">
                  <label className="text-dark font-weight-900">
                    Telefone Celular{" "}
                  </label>
                  <input
                    value={formater.template(phoneEngaged, "(00) 00000-0000")}
                    disabled
                    type="text"
                    className="form-control text-dark font-weight-900"
                  />
                </Col>
              </>
            ) : (
              <Col lg="9">
                <div className="form-group mb-inputSearch">
                  <label className="text-dark font-weight-900">
                    Nome do noivo{" "}
                    <small className="text-gray">
                      <i>- obrigatório</i>
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="groomName"
                    name="groomName"
                    ref="groomName"
                    value={
                      this.state.formData["groomName"]
                        ? this.state.formData["groomName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="3">
              <label className="text-dark font-weight-900">
                Noiva é paroquiana?
              </label>
              <ButtonGroupNsb
                id="isBridePerson"
                option1="Não"
                option2="Sim"
                activeIndex={this.state.formData["isBridePerson"]}
                dflex="Sim"
                changeHandler={this.onInputChange}
              />
            </Col>

            {this.state.formData.isBridePerson === "1" ? (
              <>
                <Col lg="6" className="mt-inputSearch">
                  <InputSearch
                    title="Nome da Noiva"
                    fieldId="BrideInputSearch"
                    sugestedList={personSugestionList}
                    changeHandler={(id) => {
                      if (id < 1) id = null;

                      this.setState((currentState) => ({
                        formData: {
                          ...currentState.formData,
                          ...{ bridePersonId: id },
                        },
                      }));
                    }}
                    value={(() => {
                      if (
                        this.state.formData &&
                        this.state.formData.bridePersonId
                      ) {
                        let treatedId = parseInt(
                          this.state.formData.bridePersonId
                        );

                        if (!isNaN(treatedId)) {
                          let personSearch = personSugestionList.filter(
                            (item) => item.id === treatedId
                          );

                          if (personSearch.length === 1) {
                            phoneFiancee = personSearch[0].phone;
                            return personSearch[0];
                          } else {
                            phoneFiancee = "";
                          }
                        }
                      }

                      return null;
                    })()}
                    hasPhoto={true}
                    isMandatory={true}
                  />
                </Col>

                <Col lg="3" className="engaged-mt">
                  <label className="text-dark font-weight-900">
                    Telefone Celular{" "}
                  </label>
                  <input
                    value={formater.template(phoneFiancee, "(00) 00000-0000")}
                    disabled
                    type="text"
                    className="form-control text-dark font-weight-900"
                  />
                </Col>
              </>
            ) : (
              <Col lg="9">
                <div className="form-group mb-inputSearch">
                  <label className="text-dark font-weight-900">
                    Nome da noiva{" "}
                    <small className="text-gray">
                      <i>- obrigatório</i>
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="brideName"
                    name="brideName"
                    ref="brideName"
                    value={
                      this.state.formData["brideName"]
                        ? this.state.formData["brideName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="3">
              <label className="text-dark font-weight-900">
                Responsável é paroquiano?
              </label>
              <ButtonGroupNsb
                id="isResponsiblePerson"
                option1="Não"
                option2="Sim"
                key="isResponsiblePerson"
                activeIndex={this.state.formData["isResponsiblePerson"]}
                dflex="Sim"
                changeHandler={this.onInputChange}
              />
            </Col>

            {this.state.formData.isResponsiblePerson === "1" ? (
              <Col lg="6" className="mt-inputSearch">
                <InputSearch
                  title="Nome da responsável pela contratação"
                  fieldId="ResponsibleInputSearch"
                  sugestedList={personSugestionList}
                  changeHandler={(id) => {
                    if (id < 1) id = null;

                    this.setState((currentState) => ({
                      formData: {
                        ...currentState.formData,
                        ...{ responsiblePersonId: id },
                      },
                    }));
                  }}
                  value={(() => {
                    if (
                      this.state.formData &&
                      this.state.formData.responsiblePersonId
                    ) {
                      let treatedId = parseInt(
                        this.state.formData.responsiblePersonId
                      );

                      if (!isNaN(treatedId)) {
                        let personSearch = personSugestionList.filter(
                          (item) => item.id === treatedId
                        );

                        if (personSearch.length === 1) return personSearch[0];
                      }
                    }

                    return null;
                  })()}
                  hasPhoto={true}
                  isMandatory={true}
                />
              </Col>
            ) : (
              <Col lg="9">
                <div className="form-group mb-inputSearch">
                  <label className="text-dark font-weight-900">
                    Nome da responsável pela contratação{" "}
                    <small className="text-gray">
                      <i>- obrigatório</i>
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="responsibleName"
                    name="responsibleName"
                    ref="responsibleName"
                    value={
                      this.state.formData["responsibleName"]
                        ? this.state.formData["responsibleName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            )}
          </Row>

          {this.state.formData.isResponsiblePerson === "1" ? (
            <></>
          ) : (
            <Row>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-dark font-weight-900">CPF</label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="responsibleCpf"
                    value={
                      this.state.formData["responsibleCpf"]
                        ? this.state.formData["responsibleCpf"]
                        : ""
                    }
                    name="responsibleCpf"
                    ref="responsibleCpf"
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Telefone Celular
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  </label>
                  <input
                    className="form-control text-dark font-weight-900"
                    type="text"
                    id="responsibleMobilePhone"
                    name="responsibleMobilePhone"
                    value={
                      this.state.formData["responsibleMobilePhone"]
                        ? this.state.formData["responsibleMobilePhone"]
                        : ""
                    }
                    ref="responsibleMobilePhone"
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-dark font-weight-900">E-mail</label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="responsibleEmail"
                    name="responsibleEmail"
                    ref="responsibleEmail"
                    value={
                      this.state.formData["responsibleEmail"]
                        ? this.state.formData["responsibleEmail"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg="6">
              <div className="form-group">
                <label className="text-dark font-weight-900">
                  Nome da empresa
                </label>
                <input
                  type="text"
                  className="form-control text-dark font-weight-900"
                  id="companyName"
                  name="companyName"
                  ref="companyName"
                  value={
                    this.state.formData["companyName"]
                      ? this.state.formData["companyName"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label className="text-dark font-weight-900">CNPJ</label>
                <input
                  type="text"
                  className="form-control text-dark font-weight-900"
                  id="companyCnpj"
                  name="companyCnpj"
                  ref="companyCnpj"
                  value={
                    this.state.formData["companyCnpj"]
                      ? this.state.formData["companyCnpj"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="form-group">
                <label className="text-dark font-weight-900">Telefone</label>
                <input
                  type="text"
                  className="form-control text-dark font-weight-900"
                  id="companyPhone"
                  name="companyPhone"
                  ref="companyPhone"
                  value={
                    this.state.formData["companyPhone"]
                      ? this.state.formData["companyPhone"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>

          <Row lg="12">
            <Col lg="12">
              <div className="form-group">
                <label className="text-dark font-weight-900">Observações</label>
                <textarea
                  rows="7"
                  className="form-control text-dark font-weight-900"
                  id="comments"
                  name="comments"
                  onChange={this.onInputChange}
                  value={
                    this.state.formData["comments"]
                      ? this.state.formData["comments"]
                      : ""
                  }
                />
              </div>
            </Col>
          </Row>

          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Eventos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar e continuar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id, eventUpdate: true });
  }

  componentDidMount() {
    this.getPerson();
    this.loadWizard();

    if (this.state.eventId) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        constants.Endpoint.UrlBase +
        constants.Endpoint.Event.Get +
        "/" +
        this.state.eventId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let eventTypeId = ret.eventTypeId.toString();

          window.localStorage.setItem(
            constants.LocalStorage.EventType,
            eventTypeId
          );

          this.loadEvent(result.response);
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }

    let formData = this.state.formData;
    let validationMessages = [];

    if (formData.isGroomPerson === "1" && !formData.groomPersonId)
      validationMessages.push(<li>{"Selecione uma noiva"}</li>);

    if (formData.isBridePerson === "1" && !formData.bridePersonId)
      validationMessages.push(<li>{"Selecione um noivo"}</li>);

    if (formData.isResponsiblePerson === "1" && !formData.responsiblePersonId)
      validationMessages.push(<li>{"Selecione um responsável"}</li>);

    if (validationMessages.length) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique a{" "}
                <span className="errorText"> mensagem </span> para prosseguir:
                <ul className="errorSumary">{validationMessages}</ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }

    let formDataAPI = { eventWedding: {} };

    if (formData.isGroomPerson === "1")
      formDataAPI.eventWedding.groomPersonId = formData.groomPersonId;
    else formDataAPI.eventWedding.groomName = formData.groomName;

    if (formData.isBridePerson === "1")
      formDataAPI.eventWedding.bridePersonId = formData.bridePersonId;
    else formDataAPI.eventWedding.brideName = formData.brideName;

    if (formData.isResponsiblePerson === "1") {
      formDataAPI.personId = formData.responsiblePersonId;
    } else {
      formDataAPI.responsibleName = formData.responsibleName;
      formDataAPI.responsibleCpf = formater.number(formData.responsibleCpf);
      formDataAPI.responsibleHomePhone = formater.number(
        formData.responsibleHomePhone
      );
      formDataAPI.responsibleMobilePhone = formater.number(
        formData.responsibleMobilePhone
      );
      formDataAPI.responsibleEmail = formData.responsibleEmail;
    }

    if (!!formData.companyName)
      formDataAPI.eventWedding.companyName = formData.companyName;

    if (!!formData.companyCnpj)
      formDataAPI.eventWedding.companyCnpj = formater.template(
        formData.companyCnpj,
        "00.000.000/0000-00"
      );

    if (!!formData.companyPhone)
      formDataAPI.eventWedding.companyPhone = formater.template(
        formData.companyPhone,
        "(00) 0000-0000"
      );

    if (!!formData.comments)
      formDataAPI.eventWedding.comments = formData.comments;

    if (this.state.eventId) formDataAPI.id = this.state.eventId;

    formDataAPI.eventTypeId = 2;

    let convertedFormData = objectToFormData(formDataAPI);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Post;

    smoothApi.post(
      url,
      convertedFormData,
      token,

      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          this.setState((state) => ({
            waitingResponseSubmit: false,
            submitLoading: false,
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));
          return;
        }

        if (result.response == null) {
          this.setState({ waitingResponseSubmit: false });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        window.location.href =
          "/admin/Evento/Casamento/data/?id=" + result.response.id;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          submitLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
          waitingResponseSubmit: false,
        });
      }
    );
  }

  getPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.GetBasicInfo;

    smoothApi.get(
      url,
      null,
      token,

      (result) => {
        console.log(result);
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ personGetResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadEvent(ret) {
    let redirectUrl = "/admin/Evento/Casamento/infoCasamento";

    if (!ret) window.location.href = redirectUrl;

    if (!ret.eventTypeId || ret.eventTypeId !== 2)
      window.location.href = redirectUrl;

    if (!ret.eventWeddings || !ret.eventWeddings.length)
      window.location.href = redirectUrl;

    let eventWedding = ret.eventWeddings[0];

    if (!eventWedding) window.location.href = redirectUrl;

    let responseFilter = [
      "responsibleName",
      "responsibleCpf",
      "responsibleHomePhone",
      "responsibleMobilePhone",
      "responsibleEmail",
    ];
    let formData = {
      ...this.state.formData,
      ...smoothApi.helpers.filterProperties(ret, responseFilter),
    };

    formData.responsibleCpf = formater.template(
      formData.responsibleCpf,
      "000.000.000-00"
    );
    formData.responsibleHomePhone = formater.template(
      formData.responsibleHomePhone,
      "(00) 0000-0000"
    );
    formData.responsibleMobilePhone = formater.template(
      formData.responsibleMobilePhone,
      "(00) 00000-0000"
    );

    if (!!eventWedding.groomPersonId) {
      formData.groomPersonId = eventWedding.groomPersonId;
      formData.isGroomPerson = "1";
    } else {
      formData.groomName = eventWedding.groomName;
      formData.isGroomPerson = "0";
    }

    if (!!eventWedding.bridePersonId) {
      formData.bridePersonId = eventWedding.bridePersonId;
      formData.isBridePerson = "1";
    } else {
      formData.brideName = eventWedding.brideName;
      formData.isBridePerson = "0";
    }

    if (!!ret.personId) {
      formData.responsiblePersonId = ret.personId;
      formData.isResponsiblePerson = "1";
    } else {
      formData.isResponsiblePerson = "0";
    }

    if (!!eventWedding.companyName)
      formData.companyName = eventWedding.companyName;

    if (!!eventWedding.companyCnpj)
      formData.companyCnpj = formater.template(
        eventWedding.companyCnpj,
        "00.000.000/0000-00"
      );

    if (!!eventWedding.companyPhone)
      formData.companyPhone = formater.template(
        eventWedding.companyPhone,
        "(00) 0000-0000"
      );
    if (!!eventWedding.comments) formData.comments = eventWedding.comments;

    formData = smoothApi.helpers.orderProperties(formData);
    this.setState({ formData: formData });
  }

  loadWizard() {
    const { eventId } = this.state;
    let id = parseInt(eventId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
    let data = { eventId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "responsibleCpf":
          value = formater.template(target.value, "000.000.000-00");
          break;

        case "responsibleHomePhone":
          value = formater.template(target.value, "(00) 0000-0000");
          break;

        case "responsibleMobilePhone":
          value = formater.template(target.value, "(00) 00000-0000");
          break;

        case "companyCnpj":
          value = formater.template(target.value, "00.000.000/0000-00");
          break;

        case "companyPhone":
          value = formater.template(target.value, "(00) 0000-0000");
          break;

        case "groomName":
          value = formater.maxLength(target.value, 100);
          break;

        case "brideName":
          value = formater.maxLength(target.value, 100);
          break;

        case "responsibleName":
          value = formater.maxLength(target.value, 100);
          break;

        case "responsibleEmail":
          value = formater.maxLength(target.value, 254);
          break;

        case "companyName":
          value = formater.maxLength(target.value, 254);
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "isParishionerEngaged") {
      if (value === "0") this.setState({ sugestedEngaged: null, engagedId: 0 });
    }

    if (name === "isParishionerFiancee") {
      if (value === "0") this.setState({ sugestedFiancee: null, fianceeId: 0 });
    }

    if (name === "isParishionerResponsible") {
      if (value === "0")
        this.setState({ sugestedResponsible: null, personId: 0 });
    }

    diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      target,
      false
    );
  }
}

export default InfoCasamento;
