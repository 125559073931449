//#region Imports

import React from "react";
import { Link } from 'react-router-dom';
import constants from "variables/constants";

// reactstrap components
import {
  Container,
  Row
} from "reactstrap";

//#endregion

class Profile extends React.Component {

  constructor(props) {

    super(props);

  }

  clearLocalStorage() {

    window.localStorage.setItem(constants.LocalStorage.Token, '');
    window.localStorage.setItem(constants.LocalStorage.UserName, '');
    window.localStorage.setItem(constants.LocalStorage.UserPhoto, '');
    window.localStorage.setItem(constants.LocalStorage.AccessLevelId, '');
    window.localStorage.setItem(constants.LocalStorage.AccessLevelName, '');

  }

  render() {

    let userPhoto = window.localStorage.getItem(constants.LocalStorage.UserPhoto);

    if (!userPhoto)
      userPhoto = require("assets/img/brand/maskFamily.png");

    if (userPhoto === "null")
      userPhoto = require("assets/img/brand/maskFamily.png");


    let storageUserName = window.localStorage.getItem(constants.LocalStorage.UserName);
    let userName = '👤';

    if (!storageUserName)
      storageUserName = '👤';

    if (typeof storageUserName !== 'string')
      storageUserName = '👤';

    if (storageUserName.length < 17) {

      userName = storageUserName;

    } else {

      if (storageUserName.indexOf(' ') === -1) {

        userName = storageUserName.substring(0, 14) + '...';

      } else {

        let nameArray = storageUserName.split(' ');
        let selectedName = '';

        while (nameArray.length > 0) {

          let currentName = nameArray.join(' ');

          if (currentName.length < 17) {

            selectedName = currentName;
            nameArray = [];

          } else {

            nameArray.pop();

          }

        }

        if (!selectedName) {

          selectedName = storageUserName.substring(0, 14) + '...';

        }

        userName = selectedName;

      }

    }

    return (
      <>
        {/* Page content */}
        <Container className="pt-4">
          <Row className="xs-12">
            <div className="media align-items-center">
              <span className="avatar avatar-md rounded-circle">

                <img alt="Image placeholder" src={userPhoto} className="photo-avatar" />

              </span>
              <div className="user-name ml-3">
                <div>
                  <span className="font-weight-bold text-dark">
                    {userName}
                  </span>
                </div>
                <div>
                  <Link to={"/auth/Login"} onClick={this.clearLocalStorage}>
                    <span className="logout color-main-salmon">Sair</span>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>

      </>
    );
  }
}

export default Profile;
