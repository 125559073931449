import Index from "views/Index.jsx";

import Agenda from "views/pages/agenda";
import Cursos from "views/pages/cursos";
import Test from "views/pages/test.jsx";
import Eventos from "views/pages/eventos";
import Maps from "views/examples/Maps.jsx";
import Atividade from "views/pages/Pessoa/atividade";
import Icons from "views/examples/Icons.jsx";
import Login from "views/examples/Login.jsx";
import Pessoas from "views/pages/pessoas.jsx";
import Tables from "views/examples/Tables.jsx";
import Casamentos from "views/pages/casamentos";
import Financeiro from "views/pages/financeiro";
import Profile from "views/examples/Profile.jsx";
import Dizimo from "views/pages/Pessoa/dizimo.jsx";
import Pessoa from "views/pages/Pessoa/pessoa.jsx";
import Register from "views/examples/Register.jsx";
import Estatisticas from "views/pages/estatisticas";
import ErrorAdmin from "views/pages/ErrorAdmin.jsx";
import Certificados from "views/pages/certificados";
import ErrorAuth from "views/examples/ErrorAuth.jsx";
import Familia from "views/pages/Pessoa/familia.jsx";
import Configuracoes from "views/pages/configuracoes";
import OutrasInfos from "views/pages/Pessoa/outras.jsx";
import Documento from "views/pages/Pessoa/documento.jsx";
import Documentos from "views/pages/Evento/documentos.jsx";
import TipoEvento from "views/pages/Evento/tipoEvento.jsx";
import RegistroPresenca from "views/pages/registroPresenca";
import TipoCurso from "views/pages/Cadastros/tipoCurso.jsx";
import InfoMissa from "views/pages/Evento/Missa/infoMissa.jsx";
import DataMissa from "views/pages/Evento/Missa/dataMissa.jsx";
import CadastroDeCurso from "views/pages/Cursos/cadastroCursos";
import Celebrantes from "views/pages/Cadastros/celebrantes.jsx";
import CadastrosAuxiliares from "views/pages/cadastrosAuxiliares";
import Fornecedores from "views/pages/Cadastros/fornecedores.jsx";
import EmitirRecibo from "views/pages/Financeiro/emitirRecibo.jsx";
import LoginAlteraSenha from "views/examples/LoginAlteraSenha.jsx";
import InfoBatismo from "views/pages/Evento/Batismo/infoBatismo.jsx";
import LoginEsqueciSenha from "views/examples/LoginEsqueciSenha.jsx";
import DataBatismo from "views/pages/Evento/Batismo/dataBatismo.jsx";
import Cerimonial from "views/pages/Evento/Casamento/cerimonial.jsx";
import Atividades from "views/pages/Evento/Casamento/atividades.jsx";
import FichaResumida from "views/pages/Evento/Casamento/fichaResumida";
import Engajamento from "views/pages/Evento/Casamento/engajamento.jsx";
import Testemunhas from "views/pages/Evento/Casamento/testemunhas.jsx";
import CadastroEntrada from "views/pages/Financeiro/cadastroEntrada.jsx";
import LoginPrimeiroAcesso from "views/examples/LoginPrimeiroAcesso.jsx";
import CadastroPastoral from "views/pages/Pastoral/cadastroPastoral.jsx";
import InfoCasamento from "views/pages/Evento/Casamento/infoCasamento.jsx";
import DataCasamento from "views/pages/Evento/Casamento/dataCasamento.jsx";
import ImprimirFicha from "views/pages/Evento/Casamento/imprimirFicha.jsx";
import CadastroFornecedor from "views/pages/Cadastros/cadastroFornecedor.jsx";
import Locais from "views/pages/Cadastros/locais.jsx"

var routes = [
  {
    path: "/index",
    name: "Home",
    icon: "fas fa-home",
    component: Index,
    layout: "/admin",
    visible: true,
    breadcumb: "Home Teste",
  },
  {
    path: "/test",
    name: "Home",
    icon: "fas fa-home",
    component: Test,
    layout: "/blank",
    visible: false,
    breadcumb: "teste",
  },
  {
    path: "/Pessoas",
    name: "Pessoas",
    icon: "fas fa-user-friends",
    component: Pessoas,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Cadastros/tipoCurso",
    name: "TipoCurso",
    component: TipoCurso,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Cadastros/locais",
    name: "TipoCurso",
    component: Locais,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Cadastros/celebrantes",
    name: "Celebrantes",
    component: Celebrantes,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Cadastros/fornecedores",
    name: "Fornecedores",
    component: Fornecedores,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Cadastros/cadastroFornecedor",
    name: "CadastroFornecedor",
    component: CadastroFornecedor,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Pessoas/Pessoa",
    name: "Pessoa",
    icon: "fas fa-user-friends",
    component: Pessoa,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Pessoas/Atividade",
    name: "Pessoa",
    icon: "fas fa-user-friends",
    component: Atividade,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Pessoas/Documento",
    name: "Documento",
    component: Documento,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Pessoas/Outras",
    name: "Outras Informações",
    component: OutrasInfos,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Pessoas/Dizimo",
    name: "Dízimo",
    component: Dizimo,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Pessoas/Familia",
    name: "Familia",
    component: Familia,
    layout: "/admin",
    visible: false,
  },
  // {
  //   path: "/Pastorais",
  //   name: "Pastorais",
  //   icon: "fas fa-users",
  //   component: Pastorais,
  //   layout: "/admin",
  //   visible: true,
  // },
  {
    path: "/Pastoral/CadastroPastoral",
    name: "CadastroPastoral",
    icon: "fas fa-users",
    component: CadastroPastoral,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/Casamentos",
    name: "Casamentos",
    icon: "fas fa-heart",
    component: Casamentos,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/Eventos",
    name: "Eventos",
    icon: "far fa-calendar-alt",
    component: Eventos,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Evento/tipoEvento",
    name: "TipoEvento",
    component: TipoEvento,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Batismo/infoBatismo",
    name: "InfoBatismo",
    component: InfoBatismo,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Batismo/Data",
    name: "DataBatismo",
    component: DataBatismo,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Casamento/infoCasamento",
    name: "InfoCasamento",
    component: InfoCasamento,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Casamento/Data",
    name: "DataCasamento",
    component: DataCasamento,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Casamento/testemunhas",
    name: "Testemunhas",
    component: Testemunhas,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Casamento/cerimonial",
    name: "Cerimonial",
    component: Cerimonial,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Casamento/engajamento",
    name: "Engajamento",
    component: Engajamento,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Casamento/atividades",
    name: "Atividades",
    component: Atividades,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Missa/infoMissa",
    name: "InfoMissa",
    component: InfoMissa,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Evento/Missa/Data",
    name: "DataMissa",
    component: DataMissa,
    layout: "/admin",
    visible: false,
  },
  // {
  //   path: "/Financeiro/entradas",
  //   name: "Financeiro",
  //   icon: "fas fa-hand-holding-usd",
  //   component: Entradas,
  //   layout: "/admin",
  //   visible: true
  // },
  {
    path: "/Financeiro",
    name: "Financeiro",
    icon: "fas fa-hand-holding-usd",
    component: Financeiro,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Financeiro/cadastroEntrada",
    name: "cadastroEntrada",
    icon: "fas fa-hand-holding-usd",
    component: CadastroEntrada,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Financeiro/emitirRecibo",
    name: "emitirRecibo",
    icon: "fas fa-hand-holding-usd",
    component: EmitirRecibo,
    layout: "/blank",
    visible: false,
  },
  {
    path: "/Evento/Casamento/imprimirFicha",
    name: "imprimirFicha",
    icon: "fas fa-hand-holding-usd",
    component: ImprimirFicha,
    layout: "/blank",
    visible: false,
  },

  {
    path: "/Evento/Casamento/imprimirFichaResumida",
    name: "imprimirFichaResumida",
    icon: "fas fa-hand-holding-usd",
    component: FichaResumida,
    layout: "/blank",
    visible: false,
  },
  {
    path: "/Evento/documentos",
    name: "Documentos",
    component: Documentos,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Cursos",
    name: "Cursos",
    icon: "fas fa-graduation-cap",
    component: Cursos,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Cursos/CadastroDeCurso",
    name: "Cursos",
    icon: "fas fa-graduation-cap",
    component: CadastroDeCurso,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/RegistroPresenca",
    name: "Registro de presenças",
    icon: "fas fa-user-check",
    component: RegistroPresenca,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/CadastrosAuxiliares",
    name: "Cadastros Auxiliares",
    icon: "far fa-list-alt",
    component: CadastrosAuxiliares,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Estatisticas",
    name: "Estatísticas",
    icon: "far fa-chart-bar",
    component: Estatisticas,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Certificados",
    name: "Certificados",
    icon: "far fa-chart-bar",
    component: Certificados,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Configuracoes",
    name: "Configurações",
    icon: "fas fa-cogs",
    component: Configuracoes,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/Agenda",
    name: "Agenda",
    icon: "fas fa-calendar",
    component: Agenda,
    layout: "/admin",
    visible: true,
  },

  // -------------------------------- ERROR -------------------------------- //
  {
    path: "*",
    name: "ErrorAdmin",
    icon: "fas fa-times",
    component: ErrorAdmin,
    layout: "/admin",
    visible: false,
  },
  // -------------------------------- ERROR -------------------------------- //

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/Login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/LoginEsqueciSenha",
    name: "Esqueci minha senha",
    icon: "ni ni-key-25 text-info",
    component: LoginEsqueciSenha,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/LoginAlteraSenha",
    name: "Alterar minha senha",
    icon: "ni ni-key-25 text-info",
    component: LoginAlteraSenha,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/LoginPrimeiroAcesso",
    name: "Alterar minha senha",
    icon: "ni ni-key-25 text-info",
    component: LoginPrimeiroAcesso,
    layout: "/auth",
    visible: false,
  },

  // -------------------------------- ERROR -------------------------------- //
  {
    path: "*",
    name: "ErrorAuth",
    icon: "fas fa-times",
    component: ErrorAuth,
    layout: "/auth",
    visible: false,
  },
  // -------------------------------- ERROR -------------------------------- //
];
export default routes;
