//#region Imports

import Modal from "react-modal";
import React from "react";
import Wizard from "components/Utils/Wizard";
import constants from "variables/constants";
import messages from "variables/messages";
import { smoothApi } from "components/Utils/smoothApi.js";
import InputSearch from "components/Utils/inputSearch";
import ButtonGroupNsb from "components/Utils/buttonGroupNsb";
import { objectToFormData } from "object-to-formdata";
import Loading from "components/Utils/Loading.jsx";
import { formater } from "components/Utils/formater";
import { diruma } from "components/Utils/diruma";

import { Link } from "react-router-dom";

import { Container, Row, Col, Form } from "reactstrap";

//#endregion



class InfoMissa extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    //#region Initial State

    this.state = {
      errorMessage: "",
      personId: 0,
      eventId: 0,
      submitLoading: false,
      sugestedPerson: {},
      sugestedList: [],
      formData: {},
      eventUpdate: false,
      wizardResponse: null,
      otherReasonRequired: false,
      disabledField: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    //#endregion

    //#region Validations

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        // {
        //   ref: "responsibleName",
        //   validations: [diruma.validations.required],
        //   customValidations: [],
        // },
        {
          ref: "responsibleMobilePhone",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "responsibleCpf",
          validations: [diruma.validations.cpf],
          customValidations: [],
        },
        {
          ref: "responsibleEmail",
          validations: [diruma.validations.email],
          customValidations: [],
        },
      ],
    };

    //#endregion

    //#region Event Binding

    this.loadWizard = this.loadWizard.bind(this);
    this.loadSugestedPerson = this.loadSugestedPerson.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    //#endregion
  }

  //#endregion

  //#region Render

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizardNodeConfiguration = [];

    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Tipo do <br /> evento
        </>,
        "/admin/Evento/tipoEvento/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Completed
      )
    );
    wizardNodeConfiguration.push(
      wizzardConfiguration.Configuration.Formater(
        <>
          Informações <br /> do evento
        </>,
        "/admin/Evento/Missa/infoMissa/" +
          (this.state.eventId ? "?id=" + this.state.eventId : ""),
        wizzardConfiguration.Status.Active
      )
    );

    if (this.state.wizardResponse) {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "/admin/Evento/Missa/data/" +
            (this.state.eventId ? "?id=" + this.state.eventId : ""),
          this.state.wizardResponse.hasDate
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "/admin/Evento/documentos/?id=" + this.state.eventId,
          this.state.wizardResponse.hasDocument
            ? wizzardConfiguration.Status.Completed
            : wizzardConfiguration.Status.Enabled
        )
      );
    } else {
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Data do evento</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
      wizardNodeConfiguration.push(
        wizzardConfiguration.Configuration.Formater(
          <>Documentos</>,
          "",
          wizzardConfiguration.Status.Disabled
        )
      );
    }

    //#endregion

    return (
      <>
        {/* //#region Modals */}

        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        {/* //#endregion Modals */}

        <Container fluid className="pb-7">
          <Row className="pt-4 pl-5">
            <Col lg="12">
              <div>
                <h1 className="text-dark text-person">
                  {this.state.eventUpdate ? "Editar missa" : "Nova missa"}
                </h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>
          <Wizard nodes={wizardNodeConfiguration} />
        </Container>
        <div className="container-fluid minHeightFooter mt-3">
          <Form>
            <Row>
              <Col>
                <div className="pt-4">
                  <h2 className="text-dark mb-5 font-weight-900 ">
                    Informações da missa
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <label className="text-dark font-weight-900">
                  É paroquiano?
                </label>
                <ButtonGroupNsb
                  id="isParishioner"
                  option1="Não"
                  option2="Sim"
                  key="isParishioner"
                  activeIndex={this.state.formData["isParishioner"]}
                  dflex="Sim"
                  changeHandler={this.onInputChange}
                />
              </Col>

              {this.state.formData["isParishioner"] === "0" ||
              this.state.formData["isParishioner"] === undefined ? (
                <Col lg="6">
                  <div className="form-group">
                    <label className="text-dark font-weight-900">
                      Nome do solicitante{" "}
                      <small className="text-gray">
                        <i>- obrigatório</i>
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control text-dark font-weight-900"
                      id="responsibleName"
                      name="responsibleName"
                      ref="responsibleName"
                      value={
                        this.state.formData["responsibleName"]
                          ? this.state.formData["responsibleName"]
                          : ""
                      }
                      onChange={this.onInputChange}
                    />
                  </div>
                </Col>
              ) : (
                <Col lg="6" className="mt-inputSearch">
                  <InputSearch
                    ref="responsibleName"
                    name="responsibleName"
                    title="Nome do solicitante"
                    fieldId="responsibleName"
                    pageHandler="event"
                    value={this.state.sugestedPerson}
                    changeHandler={(id) => {
                      this.loadSugestedPerson(id);
                    }}
                    deleteHandler={() => {
                      this.clearFormState();
                    }}
                    sugestedList={this.state.sugestedList}
                    personId={this.state.personId}
                    hasPhoto={true}
                    isMandatory={true}
                  />
                </Col>
              )}
              <Col lg="3">
                <div className="form-group">
                  <label className="text-dark font-weight-900">CPF</label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="responsibleCpf"
                    value={
                      this.state.formData["responsibleCpf"]
                        ? this.state.formData["responsibleCpf"]
                        : ""
                    }
                    name="responsibleCpf"
                    ref="responsibleCpf"
                    onChange={this.onInputChange}
                    disabled={this.state.disabledField ? true : false}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Telefone residencial
                  </label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="responsibleHomePhone"
                    value={
                      this.state.formData["responsibleHomePhone"]
                        ? this.state.formData["responsibleHomePhone"]
                        : ""
                    }
                    onChange={this.onInputChange}
                    disabled={this.state.disabledField ? true : false}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Telefone Celular{" "}
                    <small className="text-gray">
                      <i>- obrigatório</i>
                    </small>
                  </label>
                  <input
                    className="form-control text-dark font-weight-900"
                    type="text"
                    id="responsibleMobilePhone"
                    name="responsibleMobilePhone"
                    ref="responsibleMobilePhone"
                    value={
                      this.state.formData["responsibleMobilePhone"]
                        ? this.state.formData["responsibleMobilePhone"]
                        : ""
                    }
                    onChange={this.onInputChange}
                    disabled={this.state.disabledField ? true : false}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-dark font-weight-900">E-mail</label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="responsibleEmail"
                    name="responsibleEmail"
                    ref="responsibleEmail"
                    value={
                      this.state.formData["responsibleEmail"]
                        ? this.state.formData["responsibleEmail"]
                        : ""
                    }
                    onChange={this.onInputChange}
                    disabled={this.state.disabledField ? true : false}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="form-group">
                  <label className="text-dark font-weight-900">
                    Intenção da missa{" "}
                    <small className="text-gray">
                      <i>- obrigatório</i>
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control text-dark font-weight-900"
                    id="massIntention"
                    name="massIntention"
                    ref="massIntention"
                    value={
                      this.state.formData["massIntention"]
                        ? this.state.formData["massIntention"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div className="container-fluid mb-6 mt-6">
            <Row>
              <Col>
                <div className="float-lg-right">
                  <Link to={"/admin/Eventos"}>
                    <button type="button" className="btn-info-person-cancel">
                      Cancelar
                    </button>
                  </Link>
                  <button
                    className="btn-info-person ml-4"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar e continuar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  //#region Events

  //#endregion

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ eventId: id, eventUpdate: true });
  }

  componentDidMount() {
    this.loadWizard();

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Event.Get +
      "/" +
      this.state.eventId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let ret = result.response;

        if (ret.person) {
          formData.isParishioner = "1";
          formData.personId = ret.person.id;
          formData.responsibleName = ret.person.name;
          formData.responsibleCpf = formater.template(
            ret.person.cpf,
            "000.000.000-00"
          );
          formData.responsibleHomePhone = formater.template(
            ret.person.homePhone,
            "(00) 0000-0000"
          );
          formData.responsibleMobilePhone = formater.template(
            ret.person.mobilePhone,
            "(00) 00000-0000"
          );
          formData.responsibleEmail = ret.person.email;

          this.setState({
            sugestedPerson: ret.person,
            personId: ret.person.id,
          });
        } else {
          formData.isParishioner = "0";
          formData.id = ret.id;
          formData.responsibleName = ret.responsibleName;
          formData.responsibleCpf = formater.template(
            ret.responsibleCpf,
            "000.000.000-00"
          );
          formData.responsibleHomePhone = formater.template(
            ret.responsibleHomePhone,
            "(00) 0000-0000"
          );
          formData.responsibleMobilePhone = formater.template(
            ret.responsibleMobilePhone,
            "(00) 00000-0000"
          );
          formData.responsibleEmail = ret.responsibleEmail;
        }

        formData.massIntention = ret.eventMasses[0].massIntention;

        if (this.state.personId)
          if (this.state.personId !== 0)
            window.localStorage.setItem(
              constants.LocalStorage.PersonId,
              this.state.personId
            );

        let eventTypeId = ret.eventTypeId.toString();

        window.localStorage.setItem(
          constants.LocalStorage.EventType,
          eventTypeId
        );

        this.setState({ formData: formData });

        if (formData.personId) this.setState({ disabledField: true });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );

    this.loadPerson();
  }

  loadPerson() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase + constants.Endpoint.Pessoas.GetBasicInfo;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let personList = result.response.map(function (item, indice) {
          if (item.deceased) {
            return null;
          }

          return {
            id: item.id,
            name: item.name,
            pictureFileName: item.pictureFileName,
          };
        });

        personList = personList.filter(function (item) {
          return item !== null;
        });

        this.setState({ sugestedList: personList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  //#endregion

  //#region Custom Events

  clearFormState() {
    this.setState({
      formData: { isParishioner: "1" },
      sugestedPerson: null,
      personId: 0,
      disabledField: false,
    });
  }

  loadSugestedPerson(personId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      constants.Endpoint.UrlBase +
      constants.Endpoint.Pessoas.Get +
      "/" +
      personId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let formData = this.state.formData;
        let ret = result.response;

        formData.personId = ret.id;
        formData.responsibleName = ret.name;
        formData.responsibleCpf = formater.template(ret.cpf, "000.000.000-00");
        formData.responsibleHomePhone = formater.template(
          ret.homePhone,
          "(00) 0000-0000"
        );
        formData.responsibleMobilePhone = formater.template(
          ret.mobilePhone,
          "(00) 00000-0000"
        );
        formData.responsibleEmail = ret.email;

        this.setState({
          formData: formData,
          sugestedPerson: ret,
          personId: ret.id,
          disabledField: true,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadWizard() {
    const { eventId } = this.state;
    let id = parseInt(eventId);
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Wizard;
    let data = { eventId: id };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ wizardResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "responsibleCpf":
          value = formater.template(target.value, "000.000.000-00");
          break;

        case "responsibleHomePhone":
          value = formater.template(target.value, "(00) 0000-0000");
          break;

        case "responsibleMobilePhone":
          value = formater.template(target.value, "(00) 00000-0000");
          break;

        case "schedule":
          value = formater.template(target.value, "00:00");
          break;

        default:
          value = target.value;
      }


    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "isParishioner") {
      if (value === "0")
        this.setState({
          formData: { isParishioner: "0" },
          sugestedPerson: null,
          personId: 0,
          disabledField: false,
        });

      if (value === "1")
        this.setState({
          formData: { isParishioner: "1" },
          sugestedPerson: null,
          personId: 0,
          disabledField: false,
        });
    }
  }

  handleSubmit() {
    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    this.setState({ submitLoading: true });

    let formData = this.state.formData;
    let tempFormData = {};

    formData.eventMass = {};

    formData.responsibleCpf = formater.number(formData.responsibleCpf);
    formData.responsibleHomePhone = formater.number(
      formData.responsibleHomePhone
    );
    formData.responsibleMobilePhone = formater.number(
      formData.responsibleMobilePhone
    );

    if (this.state.eventId) formData.id = this.state.eventId;

    formData.eventTypeId = 3;

    tempFormData = { ...tempFormData, ...formData };

    if (formData.personId) {
      if (formData.personId !== undefined) {
        if (formData.personId !== 0) {
          delete formData.responsibleName;
          delete formData.responsibleCpf;
          delete formData.responsibleHomePhone;
          delete formData.responsibleMobilePhone;
          delete formData.responsibleEmail;
          delete formData.isParishioner;
        }
      }
    }

    if (formData.massIntention) {
      formData.eventMass.massIntention = formData.massIntention;
      delete formData.massIntention;
    } else {
      formData.eventMass.massIntention = null;
      delete formData.massIntention;
    }

    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = constants.Endpoint.UrlBase + constants.Endpoint.Event.Post;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            submitLoading: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro, por favor verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
          }));

          formData = { ...formData, ...tempFormData };
          tempFormData = {};

          this.setState({ formData: formData });

          return;
        }

        this.setState({ submitLoading: false });

        if (this.state.personId) {
          window.localStorage.setItem(
            constants.LocalStorage.PersonId,
            this.state.personId
          );
        }

        window.location.href =
          "/admin/Evento/Missa/data?id=" + result.response.id;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }
  //#endregion
}

export default InfoMissa;
