/**
 * 
 * @param {String} number 
 * @returns {String}
 */
export function formatNumberOfContact(number = '') {
    const cleanedPhoneNumber = number ? number.replace(/\D/g, '') : '';
    if (cleanedPhoneNumber.length === 10) {
        return `(${cleanedPhoneNumber.slice(0, 2)}) ${cleanedPhoneNumber.slice(2, 6)}-${cleanedPhoneNumber.slice(6)}`;
    } else if (cleanedPhoneNumber.length === 11) {
        return `(${cleanedPhoneNumber.slice(0, 2)}) ${cleanedPhoneNumber[2]} ${cleanedPhoneNumber.slice(3, 7)}-${cleanedPhoneNumber.slice(7)}`;
    } else {
        return number;
    }
}
