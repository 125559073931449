export const diruma = {
  returnModel: (value, sucess, message) => {
    return { value: value, sucess: sucess, message: message };
  },

  validations: {
    required: (value, ignoreEmpty) => {
      let isEmpty = diruma.helpers.isEmpty(value);
      let haveValidValue = diruma.helpers.haveValidValue(value);

      if (ignoreEmpty && isEmpty) return diruma.returnModel(value, true, "");

      if (typeof value !== "string")
        return diruma.returnModel(value, false, "Value não é texto");

      if (!haveValidValue)
        return diruma.returnModel(value, false, "Este campo é obrigatório");

      return diruma.returnModel(value, true, "");
    },

    cpf: (value) => {
      let message = "CPF inválido";
      let isEmpty = diruma.helpers.isEmpty(value);
      let haveValidValue = diruma.helpers.haveValidValue(value);

      if (isEmpty) return diruma.returnModel(value, true, "");

      if (!haveValidValue) return diruma.returnModel(value, false, message);

      let treatedValue = diruma.helpers.treatNumbers(value);

      if (treatedValue.length !== 11)
        return diruma.returnModel(value, false, message);

      try {
        let soma = 0;
        let resto;

        if (treatedValue === "00000000000")
          return diruma.returnModel(value, false, message);

        for (var i = 1; i <= 9; i++)
          soma = soma + parseInt(treatedValue.substring(i - 1, i)) * (11 - i);

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) resto = 0;

        if (resto !== parseInt(treatedValue.substring(9, 10)))
          return diruma.returnModel(value, false, message);

        soma = 0;

        for (i = 1; i <= 10; i++)
          soma = soma + parseInt(treatedValue.substring(i - 1, i)) * (12 - i);

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) resto = 0;

        if (resto !== parseInt(treatedValue.substring(10, 11)))
          return diruma.returnModel(value, false, message);

        return diruma.returnModel(value, true, "");
      } catch {
        return diruma.returnModel(value, false, message);
      }
    },

    cnpj: (value) => {
      let message = "CPNJ inválido";
      let isEmpty = diruma.helpers.isEmpty(value);
      let haveValidValue = diruma.helpers.haveValidValue(value);

      if (isEmpty) return diruma.returnModel(value, true, "");

      if (!haveValidValue) return diruma.returnModel(value, false, message);

      let treatedValue = diruma.helpers.treatNumbers(value);

      if (treatedValue.length !== 14)
        return diruma.returnModel(value, false, message);

      try {
        if (/^(\d)\1+$/.test(treatedValue))
          return diruma.returnModel(value, false, message);

        let t = treatedValue.length - 2,
          d = treatedValue.substring(t),
          d1 = parseInt(d.charAt(0)),
          d2 = parseInt(d.charAt(1)),
          calc = (x) => {
            let n = treatedValue.substring(0, x),
              y = x - 7,
              s = 0,
              r = 0;

            for (let i = x; i >= 1; i--) {
              s += n.charAt(x - i) * y--;
              if (y < 2) y = 9;
            }

            r = 11 - (s % 11);
            return r > 9 ? 0 : r;
          };

        if (calc(t) === d1 && calc(t + 1) === d2)
          return diruma.returnModel(value, true, "");
        else return diruma.returnModel(value, false, message);
      } catch {
        return diruma.returnModel(value, false, message);
      }
    },

    date: (value) => {
      let message = "Data inválida";
      let isEmpty = diruma.helpers.isEmpty(value);
      let haveValidValue = diruma.helpers.haveValidValue(value);

      if (isEmpty) return diruma.returnModel(value, true, "");

      if (!haveValidValue) return diruma.returnModel(value, false, message);

      if (value.length !== 10) return diruma.returnModel(value, false, message);

      if (value[2] !== "/" || value[5] !== "/")
        return diruma.returnModel(value, false, message);

      let treatedValue = diruma.helpers.treatNumbers(value);

      if (treatedValue.length !== 8)
        return diruma.returnModel(value, false, message);

      let parsedDate = new Date(
        treatedValue[2] +
          treatedValue[3] +
          "/" +
          treatedValue[0] +
          treatedValue[1] +
          "/" +
          treatedValue[4] +
          treatedValue[5] +
          treatedValue[6] +
          treatedValue[7]
      );

      if (isNaN(parsedDate.getTime()))
        return diruma.returnModel(value, false, message);

      return diruma.returnModel(value, true, "");
    },

    email: (value) => {
      let message = "E-mail inválido";
      let isEmpty = diruma.helpers.isEmpty(value);
      let haveValidValue = diruma.helpers.haveValidValue(value);

      if (isEmpty) return diruma.returnModel(value, true, "");

      if (!haveValidValue) return diruma.returnModel(value, false, message);

      let regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let treatedEmail = value.toLowerCase();

      if (!regex.test(treatedEmail))
        return diruma.returnModel(value, false, message);

      return diruma.returnModel(value, true, "");
    },

    time24Hour: (value) => {
      let message = "Horário inválido";
      let isEmpty = diruma.helpers.isEmpty(value);
      let haveValidValue = diruma.helpers.haveValidValue(value);

      if (isEmpty) return diruma.returnModel(value, true, "");

      if (!haveValidValue) return diruma.returnModel(value, false, message);

      let regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

      if (!regex.test(value)) return diruma.returnModel(value, false, message);

      return diruma.returnModel(value, true, "");
    },
  },

  helpers: {
    haveValidValue: (value) => {
      if (typeof value !== "string") return false;

      if (!value) return false;

      if (!value.trim()) return false;

      return true;
    },

    isEmpty: (value) => {
      if (value === undefined || value === null || value === "") return true;

      return false;
    },

    treatNumbers: (value) => {
      if (!value) return "";

      if (typeof value !== "string") return "";

      return value.replace(/\D/g, "") + "";
    },
  },

  validadeOnChange: (configuration, formData, currentTarget, checkAll) => {
    if (configuration === null || configuration === undefined) return;

    if (!configuration.refs || !configuration.elements) return;

    if (
      typeof configuration.refs !== "function" ||
      typeof configuration.elements !== "object"
    )
      return;

    if (!configuration.elements.length || configuration.elements.length === 0)
      return;

    if (formData === null || formData === undefined) return;

    let result = { sucess: true, errors: [] };

    if (checkAll) {
      configuration.elements.forEach((currentItemConfig) => {
        let currentTarget = configuration.refs()[currentItemConfig.ref];

        if (!currentTarget) {
          result.sucess = true;

          if (!currentItemConfig.ignoreMissingElement) {
            console.error(
              "O elemento " +
                currentItemConfig.ref +
                " não foi encontrado por sua ref",
              currentItemConfig
            );
            result.errors.push(
              "O elemento " +
                currentItemConfig.ref +
                " não foi encontrado por sua ref"
            );
            result.sucess = false;
          }

          return;
        }

        if (currentTarget.classList) {
          currentTarget.classList.remove("validationError");
        }

        let currentItemValue = currentTarget.value;

        currentItemConfig.validations.forEach((val) => {
          let validationResult = val(currentItemValue, false);

          if (!validationResult.sucess) {
            result.sucess = false;

            if (currentTarget.classList) {
              currentTarget.classList.add("validationError");
            }

            result.errors.push(validationResult.message);
          }
        });

        currentItemConfig.customValidations.forEach((val) => {
          let validationResult = val(currentItemValue, false);

          console.log("validationResult", validationResult);

          if (!validationResult.sucess) {
            result.sucess = false;

            if (currentTarget.classList) {
              currentTarget.classList.add("validationError");
            }

            result.errors.push(validationResult.message);
          }
        });
      });

      return result;
    } else {
      if (!currentTarget) return;

      let currentConfigSearch = configuration.elements.filter((el) => {
        return el.ref === currentTarget.id;
      });
      let currentItemValue = currentTarget.value;

      if (!currentConfigSearch || currentConfigSearch.length === 0) {
        return result;
      }

      if (currentTarget.classList) {
        currentTarget.classList.remove("validationError");
      }

      let currentItemConfig = currentConfigSearch[0];

      let validationMessages = currentItemConfig.validations.map((val) => {
        let validationResult = val(currentItemValue, true);

        if (!validationResult.sucess) {
          result.sucess = false;

          if (currentTarget.classList) {
            currentTarget.classList.add("validationError");
          }

          return validationResult.message;
        } else {
          return "";
        }
      });

      if (!result.sucess) {
        result.errors = validationMessages.filter((el) => {
          return !!el;
        });
        return result;
      }

      let customValidationMessages = currentItemConfig.customValidations.map(
        (val) => {
          let validationResult = val(currentItemValue, true);

          if (!validationResult.sucess) {
            result.sucess = false;

            if (currentTarget.classList) {
              currentTarget.classList.add("validationError");
            }

            return validationResult.message;
          } else {
            return "";
          }
        }
      );

      if (!result.sucess)
        result.errors = customValidationMessages.filter((el) => {
          return !!el;
        });

      return result;
    }
  },
};
